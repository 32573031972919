import { getStores } from "../../services/stores";
import { getClients } from "../../services/clients";
import ClientForm from "./clientForm";
import { useEffect, useState } from "react";
import { getProducts } from "../../services/products";
import { useSelector } from "react-redux";
import { NETWORK } from "./../../settings";
import ProductAndSummary from "./productsAndSummary";
import { countries } from "../../utils/countries";
import { notify } from "./../../redux/features/alert";
import { useDispatch } from "react-redux";
import { updatePhoneAndEmail } from "../../services/clients";
import {
  getdeliveryFee,
  checkGiftCard,
  getOrders,
} from "../../services/orders";
import {
  updateDelivery_address,
  updateBilling_address,
} from "../../services/clients";
import { createOrder, returnAddressAsString } from "./../../services/orders";
import moment from "moment/moment";
import ProgressBar from "../../components/loader/progressBar";
import { getCategoriesPOS } from "./../../services/categories";
import KycError from "../../components/kycError";
export default function CommercialInvoice() {
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store.auth);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState({ label: "", value: 0 });
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [typeOfOrder, setTypeOfOrder] = useState("Delivery");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [payment, setPayment] = useState({ label: "Stripe", id: "Card" });
  const [status, setStatus] = useState({ label: "Not paid", id: "Not paid" });
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState(undefined);
  const [deliveryFee, setDeliveryFee] = useState(undefined);
  const [deliveryFeeCalculated, setDeliveryFeeCalculated] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [search, setSearch] = useState("");
  const [deliveryChanged, setDeliveryChanged] = useState(true);
  const [time, setTime] = useState(moment().format("hh:mm"));
  const [dateError, setDateError] = useState(false);
  const [selectedCustomerError, setSelectedCustomerError] = useState(false);
  const [orderComplete, setOrderComplete] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [billingChanged, setBillingChanged] = useState(false);
  const [categories, setCategories] = useState([]);
  const [giftcard, setGiftcard] = useState("");
  const [giftcardValue, setGiftcardValue] = useState(0);
  const [giftCardError, setGiftCardError] = useState("");
  const [ordersList, setOrdersList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({ label: "", id: 0 });
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const [editOrderView, seteditOrderView] = useState(false);
  const [billing, setBilling] = useState({
    street: "",
    street_number: "",
    neighborhood: "",
    city: "",
    state: "",
    country: {
      code: "",
      label: "",
    },
    zipcode: "",
  });
  const [deliveryError, setDeliveryError] = useState({
    street: "",
    street_number: "",
    neighborhood: "",
    city: "",
    state: "",
    country: {
      code: "",
      label: "",
    },
    zipcode: "",
  });
  const [billingError, setBillingError] = useState({
    street: "",
    street_number: "",
    neighborhood: "",
    city: "",
    state: "",
    country: {
      code: "",
      label: "",
    },
    zipcode: "",
  });
  const [address, setAddress] = useState({
    street: "",
    city: "",
    street_number: "",
    neighborhood: "",
    state: "",
    zipcode: "",
    country: {
      code: "",
      label: "",
    },
  });
  const [deliveryOpen, setDeliveryOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);
  const [copyBilling, setCopyBilling] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedCustomerData, setSelectedCustomerData] = useState(undefined);
  const fetchClients = () => {
    return new Promise((resolve, reject) => {
      try {
        getClients().then((res) => {
          setClients(res);
          resolve(res);
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleChangeAdrres = (prop, value) => {
    setDeliveryChanged(true);
    setDeliveryFeeCalculated(false);
    setAddress((address) => ({ ...address, [prop]: value }));
  };
  const handleChangeBilling = (prop, value) => {
    setBillingChanged(true);
    setBilling((address) => ({ ...address, [prop]: value }));
  };
  useEffect(() => {
    getStores().then((res) => {
      setSelectedStore({ label: res[0].name, value: res[0].id });
      setStores(res);
    });
    fetchClients();
  }, []);

  const getStoreURL = () => {
    var storeObj = stores.find((el) => el.id === selectedStore.value);
    if (storeObj) return storeObj?.subdomain ? storeObj.subdomain : "";
    return "";
  };

  function returnIdNumber() {
    return Math.floor(Math.random() * 10000);
  }

  const searchProducts = () => {
    setIsloading(true);
    var params = {
      store: selectedStore.value,
      network: NETWORK,
    };
    if (auth?.is_superuser) {
      params.network = NETWORK;
    }
    if (selectedStore === 0) {
      delete params.store;
    }
    getProducts(params)
      .then((res) => {
        setProducts(res);
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
      });
  };

  const searchCategories = () => {
    getCategoriesPOS(NETWORK, selectedStore.value).then((response) => {
      setCategories(response);
    });
  };

  const searchOrders = () => {
    var params = {
      date_delivery: "ALL",
      a_fazer: true,
      fazendo: false,
      feito: false,
      depot: false,
      transit: false,
      delivered: false,
      baking: false,
      store: selectedStore.value,
      network: NETWORK,
    };
    getOrders(params, selectedStore.value).then((response) => {
      setOrdersList(response);
    });
  };
  useEffect(() => {
    if (selectedStore.value > 0) {
      searchProducts();
      searchCategories();
      searchOrders();
    }
  }, [selectedStore.value]);
  const handleChangeEditClient = (client, name) => {
    setCopyBilling(false);
    if (client?.shipping_address) {
      var country = countries.find(
        (el) => el?.code === client?.shipping_address?.country
      );
      client.shipping_address.country = country;
    }
    if (client?.billing_address) {
      var country = countries.find(
        (el) => el.code === client.billing_address.country
      );
      if (country) {
        client.billing_address.country = country;
      }
    }
    if (!client.profile.phone_number.includes(".")) {
      setPhone(client.profile.phone_number);
    } else {
      setPhone("");
    }
    if (!client.email.includes("noreply")) {
      setEmail(client.email);
    } else {
      setEmail("");
    }
    setSelectedCustomerData(client);
    if (client.billing_address) setBilling(client.billing_address);
    if (client.shipping_address) setAddress(client.shipping_address);

    setSelectedClient({ label: name, id: client.id });
  };
  const handleChangeClient = (e) => {
    setCopyBilling(false);
    var client = clients.find((client) => client.id === e.id);
    if (client?.shipping_address) {
      var country = countries.find(
        (el) => el?.code === client?.shipping_address?.country
      );
      client.shipping_address.country = country;
    }
    if (client?.billing_address) {
      var country = countries.find(
        (el) => el.code === client.billing_address.country
      );
      if (country) {
        client.billing_address.country = country;
      }
    }
    if (typeof client.phone_number === "string") {
      if (!client?.phone_number.includes(".")) setPhone(client.phone_number);
    }
    if (!client.email.includes("noreply")) setEmail(client.email);
    setSelectedCustomerData(client);
    setBilling(client.billing_address);
    setAddress(client.shipping_address);
    setSelectedClient(e);
  };
  const handleChangeClientSelect = (e, _clients) => {
    var client = _clients.find((client) => client.id === e.id);
    if (client?.shipping_address) {
      var country = countries.find(
        (el) => el?.code === client?.shipping_address?.country
      );
      client.shipping_address.country = country;
    }
    if (client?.billing_address) {
      var country = countries.find(
        (el) => el?.code === client?.billing_address?.country
      );
      client.billing_address.country = country;
    }
    setBilling(client.billing_address);
    setAddress(client.shipping_address);
    calculateDeliveryFee(client.shipping_address, true);
    setSelectedClient(e);
  };
  const fillEditOrderData = (items, order) => {
    var formatedProducts = [];
    items.map((item, idx) => {
      var foundIdx = -1;
      formatedProducts.forEach((el, index) => {
        if (item.product.product_id === el.product_id) {
          foundIdx = index;
        }
      });
      if (foundIdx > -1) {
        var foundVariation = item.product.variation.find(
          (el) => el.id === item?.variation?.id
        );
        if (foundVariation) {
          formatedProducts[foundIdx].selected_variation.push({
            id: foundVariation.id,
            name: foundVariation.name,
            barcode: foundVariation.barCode,
            sku: foundVariation.sku,
            price: foundVariation.price,
            stock: foundVariation.id.stock,
            cost: foundVariation.cost,
            amount: item.quantity,
          });
        }
      } else {
        if (item.variation) {
          var foundVariation = item.product.variation.find(
            (el) => el.id === item.variation.id
          );
          formatedProducts.push({
            ...item.product,
            position: item.position,
            amount: 1,
            selected_variation: [{ ...foundVariation, amount: item.quantity }],
          });
        } else {
          formatedProducts.push({
            ...item.product,
            position: item.position,
            amount: item.quantity,
            selected_variation: [],
          });
        }
      }
    });
    setTypeOfOrder(
      order.type_of_order.includes("-D") ? "Delivery" : "Collection"
    );
    setDate(moment(order.date_delivery).format("YYYY-MM-DD"));
    setTime(moment(order.date_delivery).format("hh:mm:ss"));
    if (order.payment === "Stripe") {
      setPayment({ label: "Stripe", id: "Card" });
    }
    if (order.payment === "Invoice") {
      setPayment({ label: "Invoice", id: "Invoice" });
    }
    setSelectedProducts(formatedProducts);
  };
  const handleChangeOrder = (e) => {
    setSelectedOrder(e);
    const order = ordersList.find((el) => el.order_id === e.id);
    setSelectedOrderData(order);
    fillEditOrderData(order.items, order);
    handleChangeEditClient(order.owner.user, order.owner.name);
  };
  const selectProduct = (newProduct) => {
    setDeliveryFeeCalculated(false);
    selectedProducts.map((item) => {
      if ((item.variation.length > 0) & !item.selected_variation) {
        deselectProduct(item.product_id);
      }
    });
    if (
      !selectedProducts.find(
        (product) => product.product_id === newProduct.product_id
      )
    ) {
      if (newProduct.variation.length > 0) {
        newProduct.selected_variation = [
          { ...newProduct.variation[0], amount: 1 },
        ];
      } else {
        newProduct.selected_variation = [];
      }
      newProduct.amount = 1;
      setCurrentProduct(newProduct);
      setSelectedProducts((selectedProducts) => [
        ...selectedProducts,
        newProduct,
      ]);
    } else {
      setCurrentProduct(newProduct);
    }
  };
  const deselectProduct = (id) => {
    var _selectedItems = [...selectedProducts];
    var index = 0;
    _selectedItems.map((item, idx) => {
      if (item.product_id === id) {
        index = idx;
      }
    });
    _selectedItems.splice(index, 1);
    setSelectedProducts(_selectedItems);
  };
  const inCreaseAmount = (id) => {
    setDeliveryFeeCalculated(false);

    var _selectedItems = [...selectedProducts];
    _selectedItems.map((item, idx) => {
      if (item.product_id === id) {
        _selectedItems[idx].amount += 1;
      }
    });
    setSelectedProducts(_selectedItems);
  };

  const decreaseAmount = (id) => {
    setDeliveryFeeCalculated(false);

    var _selectedItems = [...selectedProducts];
    var index;
    _selectedItems.map((item, idx) => {
      if (item.product_id === id) {
        index = idx;
        _selectedItems[idx].amount -= 1;
      }
    });
    if (_selectedItems[index]?.amount < 1) {
      _selectedItems.splice(index, 1);
    }
    setSelectedProducts(_selectedItems);
  };
  const checkBilling = () => {
    var errors = {
      street: "",
      city: "",
      street_number: "",
      neighborhood: "",
      state: "",
      zipcode: "",
      country: "",
    };
    var entered = false;
    var keys = [
      "street",
      "street_number",
      "neighborhood",
      "city",
      "state",
      "country",
      "zipcode",
    ];
    keys.map((key) => {
      if (billing[key] === "" || billing[key] === null || !billing[key]) {
        errors[key] = "This field cannot be empty";
        entered = true;
      }
    });
    if (entered) setBillingOpen(true);
    setBillingError(errors);
    return entered;
  };
  var checkAddressForm = (ignoreCheckForm, address) => {
    var entered = false;
    var _deliveryErrors = { ...deliveryError };
    if (typeOfOrder === "Delivery") {
      var keys = [
        "street",
        "street_number",
        "neighborhood",
        "city",
        "state",
        "country",
        "zipcode",
      ];
      keys.map((key) => {
        if (address[key] === "" || address[key] === null || !address[key]) {
          _deliveryErrors[key] = "This field cannot be empty";
          entered = true;
        }
      });
      setDeliveryError(_deliveryErrors);
    }
    if (entered) {
      setDeliveryOpen(true);
    }
    if (entered && !ignoreCheckForm) {
      if (Object.keys(selectedClient).length < 1) {
        dispatch(
          notify({ message: "Please select a customer", severity: "error" })
        );
        setSelectedCustomerError(true);
      } else {
        dispatch(
          notify({
            message: "Please check the errors in the fields below",
            severity: "error",
          })
        );
      }
    }
    return entered;
  };
  const getStoreAddress = () => {
    const store = stores.find((_store) => _store.id === selectedStore.value);
    return store.address;
  };

  const getStoreSelected = stores.find(
    (_store) => _store.id === selectedStore.value
  );

  const translateErrorMessage = (error) => {
    if (error === "No Amount was returned by Delivery Parter.") {
      dispatch(
        notify({
          message: "This delivery address is out of range",
          severity: "ERROr",
        })
      );
      return;
    }
    if (error.includes("ZERO_RESULTS")) {
      dispatch(
        notify({
          message: "This delivery address is out of range",
          severity: "ERROr",
        })
      );
      return;
    }
    dispatch(
      notify({
        message: "Something went wrong",
        severity: "ERROr",
      })
    );
    return;
  };

  const itemTotal = (item) => {
    if (item.selected_variation && item.variation.length > 0) {
      var sum = 0;
      item?.selected_variation.map((el) => {
        sum += el.price * el.amount;
      });
      return sum;
    }
    return item.amount * item.price;
  };

  const totalOrderItems = () => {
    var total = 0;
    selectedProducts.map((product) => {
      var totalExtras = 0;
      product.extras.map((extra) => {
        if (extra.quantity > 1) {
          totalExtras += extra.quantity - 1 * extra.price;
        }
      });
      total += itemTotal(product) + totalExtras;
    });

    return total;
  };
  const calculateDeliveryFee = async (_delivery, ignoreCheckForm) => {
    try {
      if (
        !checkAddressForm(ignoreCheckForm, _delivery) &&
        typeOfOrder === "Delivery"
      ) {
        var order_items_value = totalOrderItems();
        setIsloading(true);

        if (!order_items_value) order_items_value = 0;
        getdeliveryFee(
          getStoreAddress(),
          _delivery,
          selectedStore.value,
          order_items_value
        ).then((res) => {
          setDeliveryFee(res);
          setIsloading(false);
          setDeliveryFeeCalculated(true);
        });
      }
    } catch (error) {
      translateErrorMessage(error);
      setDeliveryFee({ ...deliveryFee, delivery_price: 0 });
      setIsloading(false);
    }
  };
  const handleSearch = (e) => {
    setSearch(e);
  };
  const addExtra = (product_id, extra_idx) => {
    var _products = [...selectedProducts];
    _products.map((product, idx) => {
      if (
        product.product_id === product_id &&
        _products[idx].extras[extra_idx].quantity <=
        _products[idx].extras[extra_idx].limit - 1
      ) {
        _products[idx].extras[extra_idx].quantity += 1;
      }
    });
    setSelectedProducts(_products);
  };
  const removeExtra = (product_id, extra_idx) => {
    var _products = [...selectedProducts];
    _products.map((product, idx) => {
      if (
        product.product_id === product_id &&
        _products[idx].extras[extra_idx].quantity > 0
      ) {
        _products[idx].extras[extra_idx].quantity -= 1;
      }
    });
    setSelectedProducts(_products);
  };
  const pickSize = (id, selected_variation) => {
    var _products = [...selectedProducts];
    _products.map((_product, idx) => {
      if (_product.product_id === id) {
        _products[idx].selected_variation = selected_variation;
      }
    });
    setSelectedProducts(_products);
  };

  const getSelectedVariations = (product_id) => {
    const _product = selectedProducts.find(
      (el) => el.product_id === product_id
    );
    if (!_product) return [];
    return _product["selected_variation"] ? _product["selected_variation"] : [];
  };

  const pickVariation = (selected_variation, product_id) => {
    var _products = [...selectedProducts];
    var variationIncluded = false;
    _products.map((_product, idx) => {
      if (_product.product_id === product_id) {
        _products[idx].selected_variation = _products[
          idx
        ].selected_variation.filter((el) => {
          if (el.id !== selected_variation) return true;
          else {
            variationIncluded = true;
            return false;
          }
        });
        if (!variationIncluded) {
          _products[idx].selected_variation.push({
            ..._product.variation.find((el) => el.id === selected_variation),
            amount: 1,
          });
        }
        if (_products[idx].selected_variation.length === 0) {
          setTimeout(() => {
            deselectProduct(_products[idx].product_id);
          }, 200);
        }
      }
    });
    setSelectedProducts(_products);
  };

  const increaseVariationAmount = (variation_id, product_id) => {
    var _products = [...selectedProducts];
    _products.map((product, productIdx) => {
      if (product_id === product.product_id) {
        if (!product.selected_variation.find((el) => el.id === variation_id)) {
          pickVariation(variation_id, product_id);
        } else {
          product.selected_variation.map((variation, variationIdx) => {
            if (variation.id === variation_id) {
              _products[productIdx].selected_variation[
                variationIdx
              ].amount += 1;
            }
          });
        }
      }
    });
    setSelectedProducts(_products);
  };
  const decreaseVariationAmount = (variation_id, product_id) => {
    var _products = [...selectedProducts];
    _products.map((product, productIdx) => {
      if (product_id === product.product_id) {
        product.selected_variation.map((variation, variationIdx) => {
          if (variation.id === variation_id) {
            _products[productIdx].selected_variation[variationIdx].amount -= 1;
            if (
              _products[productIdx].selected_variation[variationIdx].amount ===
              0
            ) {
              _products[productIdx].selected_variation.splice(variationIdx, 1);
            }
          }
        });
        if (
          product.selected_variation.filter((el) => el.amount > 0).length < 1
        ) {
          _products.splice(productIdx, 1);
        }
      }
    });
    setSelectedProducts(_products);
  };
  const getPosition = (item, variation) => {
    var position = between(1000, 9999);
    if (editOrderView) {
      var oldIOrderItem;
      if (variation) {
        oldIOrderItem = selectedOrderData.items.find((el) => {
          return (
            el.product.product_id === item.product_id &&
            el.variation.id === variation
          );
        });
      } else {
        oldIOrderItem = selectedOrderData.items.find((el) => {
          return el.product.product_id === item.product_id;
        });
      }
      if (oldIOrderItem && oldIOrderItem.position) {
        position = oldIOrderItem.position;
      }
    }
    return position;
  };
  function between(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  const handleRemovedItems = (cartItems, oldOrderItems) => {
    var removedItems = oldOrderItems.filter((el) => {
      if (
        !cartItems.find((item) => {
          if (item.product_id === el.product.product_id) {
            if (el.variation) {
              if (el.variation.id === item.variation.id) {
                return true;
              }
              return false;
            }
            return true;
          }
          return false;
        })
      )
        return true;
      else return false;
    });
    const payload = removedItems.map((item) => ({
      product_id: item.product.product_id,
      quantity: 0,
      extras: [],
      comment: "",
      position: item.position,
      variation: item.variation ?? null,
    }));
    return payload;
  };
  const formatProducts = (cartItems) => {
    let formatedProducts = [];
    cartItems.forEach((item) => {
      if (item.selected_variation.length > 0) {
        item.selected_variation.map((el) => {
          formatedProducts.push({
            product_id: item.product_id,
            quantity: el.amount,
            extras: [],
            comment: "",
            position: getPosition(item, el.id),
            variation: {
              id: el.id,
              price: el.price,
            },
          });
        });
      } else {
        formatedProducts.push({
          product_id: item.product_id,
          quantity: item.amount,
          extras: [],
          comment: "",
          position: getPosition(item, null),
        });
      }
    });

    if (editOrderView)
      formatedProducts.push(
        ...handleRemovedItems(formatedProducts, selectedOrderData.items)
      );
    return formatedProducts;
  };
  const verifygiftCard = () => {
    setIsloading(true);
    checkGiftCard(giftcard)
      .then((res) => {
        if (res.status === "success") {
          setGiftcardValue(res.value);
          setGiftCardError("");
        } else {
          setGiftcardValue(0);
          setGiftCardError(res.message);
        }
      })
      .catch((e) => {
        if (e.status === "error") {
          setGiftCardError(e.message);
          setGiftcardValue(0);
        }
      })
      .finally(() => {
        setIsloading(false);
      });
  };
  const dateTime = (date, time) => {
    try {
      if (time.includes("T")) {
        return time;
      } else {
        var dateObj = new Date(date + " " + time);
        return dateObj;
      }
    } catch (error) {
      return new Date(time);
    }
  };
  var checkForm = () => {
    var entered = false;
    var _deliveryErrors = { ...deliveryError };
    if (!selectedClient) {
      entered = true;
      setSelectedCustomerError(true);
    }
    if (!date) {
      entered = true;
      setDateError(true);
    }
    if (checkBilling()) {
      entered = true;
    }
    if (phone === "") {
      setPhoneError("This field is required");
      entered = true;
    }
    if (email === "") {
      setEmailError("This field is required");
      entered = true;
    }
    if (typeOfOrder === "Delivery") {
      if (address.street === "" || address.street === undefined) {
        _deliveryErrors.street = "This fiel cannot be empty";
        entered = true;
      }
      if (address.street_number === "" || address.street_number === undefined) {
        _deliveryErrors.street_number = "This fiel cannot be empty";
        entered = true;
      }
      if (address.city === "" || address.city === undefined) {
        _deliveryErrors.city = "This fiel cannot be empty";
        entered = true;
      }
      if (address.state === "" || address.state === undefined) {
        _deliveryErrors.state = "This fiel cannot be empty";
        entered = true;
      }
      if (address.neighborhood === "" || address.neighborhood === undefined) {
        _deliveryErrors.neighborhood = "This fiel cannot be empty";
        entered = true;
      }
      if (address.zipcode === "" || address.zipcode === undefined) {
        _deliveryErrors.zipcode = "This fiel cannot be empty";
        entered = true;
      }

      if (
        address.country === "" ||
        address.country === undefined ||
        !address.country
      ) {
        _deliveryErrors.country = "This fiel cannot be empty";
        entered = true;
      }
    }
    setDeliveryError(_deliveryErrors);
    if (entered) {
      if (!selectedClient) {
        dispatch(
          notify({ message: "Please select a customer", severity: "error" })
        );
        setSelectedCustomerError(true);
      } else {
        dispatch(
          notify({
            message: "Please check the errors in the fields below",
            severity: "error",
          })
        );
      }
    }
    return entered;
  };
  const reset = () => {
    setOrderComplete(false);
    searchProducts();
    setSelectedClient("");
    setAddress({
      street: "",
      city: "",
      street_number: "",
      neighborhood: "",
      state: "",
      zipcode: "",
      country: {
        code: "",
        label: "",
      },
    });
    setBilling({
      street: "",
      city: "",
      street_number: "",
      neighborhood: "",
      state: "",
      zipcode: "",
      country: {
        code: "",
        label: "",
      },
    });
    setDeliveryOpen(false);
    setBillingOpen(false);
    setSelectedProducts([]);
    setCurrentProduct({});
    setOrderData({});
    setSelectedOrderData(null);
    setSelectedOrder({ id: 0, label: 0 });
    setSelectedCustomerData(null);
    setSelectedClient("");
    setTime("hh:mm");
    setDate(moment().format("YYYY-MM-DD"));
    seteditOrderView(false);
    setPhone("");
    setEmail("");
  };
  const patchPhoneAndEmail = () => {
    return new Promise((resolve, reject) => {
      var phoneToPatch = phone;
      var emailToPatch = email;
      var clientPhone = editOrderView
        ? selectedCustomerData.profile.phone_number
        : selectedCustomerData.phone_number;
      if (phone === clientPhone) {
        phoneToPatch = null;
      }

      if (email === selectedCustomerData.email) {
        emailToPatch = null;
      }

      if (emailToPatch === null && phoneToPatch === null) {
        resolve();
      }

      updatePhoneAndEmail(selectedClient.id, phoneToPatch, emailToPatch)
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          if (error.field === "phone") {
            setPhoneError(error.message);
          }
          reject();
        });
    });
  };
  const submit = async (e) => {
    e.preventDefault();
    if (!checkForm()) {
      setIsloading(true);
      patchPhoneAndEmail()
        .then(async () => {
          if (deliveryChanged && typeOfOrder === "Delivery")
            await updateDelivery_address(address, selectedClient.id);
          if (billingChanged) {
            await updateBilling_address(billing, selectedClient.id);
          }
          let orderObjectToSend = {
            infos: {
              store_id: selectedStore.value,
              type_of_order: typeOfOrder,
              payment: payment.label,
              date_delivery: dateTime(date, time).toISOString(),
              delivery_fee:
                typeOfOrder === "Delivery" ? deliveryFee.delivery_price : 0,
              network_id: Number(NETWORK),
              is_paid: status.label === "Paid" ? true : false,
              billing_address: returnAddressAsString(billing),
              giftcard_code: giftcard ? giftcard : null,
            },
            client: selectedClient.id,
            address: {
              ...address,
              country: address?.country ? address.country["code"] : "",
            },
            products: formatProducts(selectedProducts),
          };
          if (typeOfOrder === "Collection") {
            delete orderObjectToSend.address;
            delete orderObjectToSend.infos.delivery_fee;
          }

          createOrder(orderObjectToSend, editOrderView, selectedOrder.id)
            .then((response) => {
              setIsloading(false);
              dispatch(
                notify({
                  message: "Order created successfully",
                  severity: "success",
                })
              );
              setOrderData(response);
              setOrderComplete(true);
              //  generatePDF(response);
            })
            .catch((error) => {
              setIsloading(false);
              dispatch(notify({ message: error, severity: "error" }));
            });
        })
        .catch((e) => {
          setIsloading(false);
        });
    } else {
      setIsloading(false);
    }
  };
  return (
    <div className="w-full min-h-screen flex md:flex-row flex-col">
      {auth.stripe_profiles.account_stripe_KYC_status ? <>
        <div className="md:w-1/3 w-full border-r-gray border-r-2 px-4">
          <ClientForm
            stores={stores}
            selectedStore={selectedStore}
            setSelectedStore={(e) => setSelectedStore(e)}
            clients={clients}
            selectedClient={selectedClient}
            handleChangeClient={(e) => handleChangeClient(e)}
            handleChangeAdrres={(prop, e) => handleChangeAdrres(prop, e)}
            address={address}
            payment={payment}
            setPayment={(e) => {
              setPayment(e);
            }}
            date={date}
            setDate={(e) => {
              setDateError(false);
              setDate(e);
            }}
            typeOfOrder={typeOfOrder}
            setTypeOfOrder={(e) => setTypeOfOrder(e)}
            status={status}
            setStatus={(e) => setStatus(e)}
            time={time}
            setTime={(e) => setTime(e)}
            deliveryErrors={deliveryError}
            selectedCustomerError={selectedCustomerError}
            dateError={dateError}
            setDeliveryOpen={(e) => setDeliveryOpen(e)}
            deliveryOpen={deliveryOpen}
            setBillingOpen={(e) => setBillingOpen(e)}
            billingOpen={billingOpen}
            billing={billing}
            copyBilling={copyBilling}
            setCopyBilling={setCopyBilling}
            billingErrors={billingError}
            handleChangeBilling={(prop, e) => handleChangeBilling(prop, e)}
            getClients={(newClient) => {
              fetchClients().then((response) => {
                if (newClient) {
                  handleChangeClientSelect(newClient, response);
                }
              });
            }}
            phone={phone}
            setPhone={(e) => setPhone(e)}
            phoneError={phoneError}
            email={email}
            setEmail={(e) => setEmail(e)}
            emailError={emailError}
            ordersList={ordersList}
            selectedOrder={selectedOrder}
            setSelectedOrder={(e) => handleChangeOrder(e)}
            editOrderView={editOrderView}
            setEditOrderView={() => {
              if (editOrderView) {
                setSelectedOrder({ label: "", id: 0 });
                setSelectedCustomerData(null);
              }
              seteditOrderView(!editOrderView);
            }}
          />
        </div>
        <div className="w-full md:w-2/3 h-full">
          {isLoading ? (
            <div className="w-full flex items-center justify-center">
              <ProgressBar />
            </div>
          ) : null}
          <ProductAndSummary
            products={
              search
                ? products.filter((product) =>
                  product.name.toLowerCase().includes(search.toLowerCase())
                )
                : products
            }
            selectedProducts={selectedProducts}
            storeData={getStoreSelected}
            selectProduct={(newProduct) => selectProduct(newProduct)}
            selectedProduct={currentProduct}
            typeOfOrder={typeOfOrder}
            inCreaseAmount={(id) => inCreaseAmount(id)}
            decreaseAmount={(id) => decreaseAmount(id)}
            deliveryFee={deliveryFee}
            delivery_fee_calculated={deliveryFeeCalculated}
            calculateDeliveryFee={() => calculateDeliveryFee(address, false)}
            search={search}
            setSearch={(e) => handleSearch(e)}
            addExtra={(product, extra) => addExtra(product, extra)}
            removeExtra={(product, extra) => removeExtra(product, extra)}
            deselectProduct={(id) => {
              deselectProduct(id);
            }}
            pickSize={(id, size) => pickSize(id, size)}
            submit={(e) => submit(e)}
            orderComplete={orderComplete}
            reset={() => reset()}
            orderData={orderData}
            query={search}
            categories={categories}
            selectedVariation={(product_id) => getSelectedVariations(product_id)}
            pickVariation={(selected_variaiton, product_id) =>
              pickVariation(selected_variaiton, product_id)
            }
            increaseVariationAmount={(variation_id, product_id) => {
              increaseVariationAmount(variation_id, product_id);
            }}
            decreaseVariationAmount={(variation_id, product_id) => {
              decreaseVariationAmount(variation_id, product_id);
            }}
            isPaid={status.id}
            paymentMethod={payment}
            giftcard={giftcard}
            setGiftCard={(e) => setGiftcard(e)}
            giftCardError={giftCardError}
            giftcardValue={giftcardValue}
            checkGiftcard={() => verifygiftCard()}
            editOrderView={editOrderView}
            getStoreURL={getStoreURL()}
          />
        </div> </>
        : <KycError profile={auth.stripe_profiles} />}

    </div>
  );
}
