import { Grid, Typography } from "@mui/material";
import logo from "./../../assets/images/flogi-logo.svg";
import check_icon from "./../../assets/images/check_icon.svg";
import ToggleSwitch from "./../../components/toggleSwitch";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import flogi from "./../../assets/images/flogi-logo.svg";

export default function StripePlans() {
  const [montlhy, setMonthly] = useState(true);
  return (
    <div className="flex justify-center h-full">
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className="title-onboarding" style={{ margin: "3rem 0" }}>
          Select Subscription
        </Typography>
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-full flex items-center justify-center">
            <ToggleSwitch
              value={montlhy}
              handleChange={(e) => setMonthly(!montlhy)}
            />
          </div>
          <div className="w-full h-[35rem] flex flex-row justify-center py-8 bg-black">
            <div className="flex flex-row justify-center gap-8 w-[50%] h-full">
              <div className="w-1/2 border border-gray-600 pt-4 relative">
                <div className="flex items-center justify-start gap-1 mb-6 px-4">
                  <img src={logo} />
                  <p className="text-[#ECECEC] text-[14px] text-center italic">
                    Boutique
                  </p>
                </div>
                <Typography className="text-[#F2F2F2]  px-4">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat.
                </Typography>
                <div className="px-4">
                  {[1, 2, 3, 4].map((el) => (
                    <div className="w-full flex items-center gap-4 mt-3">
                      <img src={check_icon} />
                      <p className="text-white">Lorem ipsum dolor sit amet</p>
                    </div>
                  ))}
                </div>
                <div className="w-full flex items-center justify-center absolute bottom-0">
                  <button
                    onClick={() =>
                      (window.location =
                        "https://buy.stripe.com/test_14kcQw7ce8zaeFq4gg")
                    }
                    className="w-full flex px-4 py-3 bg-[#612EF2] mt-5 text-white"
                  >
                    <Typography className="text-white text-center">
                      {montlhy ? "25" : "200"} CHF /{" "}
                      {montlhy ? "Month" : "Year"}
                    </Typography>
                  </button>
                </div>
              </div>
              <div className="w-1/2 border border-gray-600 pt-4 relative">
                <div className="flex items-center justify-start gap-1 mb-6 px-4">
                  <img src={logo} />
                  <p className="text-[#ECECEC] text-[14px] text-center italic">
                    Concierge
                  </p>
                </div>
                <Typography className="text-[#F2F2F2] px-4">
                  Stet clita kasd gubergren, no sea takimata sanctus est Lorem
                  ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
                  sadipscing elitr.
                </Typography>
                <div className="px-4">
                  {[1, 2, 3, 4].map((el) => (
                    <div className="w-full flex items-center gap-4 mt-3">
                      <img src={check_icon} />
                      <p className="text-white">Lorem ipsum dolor sit amet</p>
                    </div>
                  ))}
                </div>
                <div className="w-full flex items-center justify-center absolute bottom-0">
                  <button
                    onClick={() =>
                      (window.location =
                        "https://buy.stripe.com/test_14kcQw7ce8zaeFq4gg")
                    }
                    className="w-full flex px-4 py-3 bg-[#612EF2] mt-5 text-white"
                  >
                    <Typography className="text-white text-center">
                      Choose concierge{" "}
                    </Typography>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 justify-center bg-black w-screen pb-2">
          <Typography className="text-[#7A7A7A]">Powered by</Typography>
          <img style={{ height: "12px" }} src={flogi} alt="" />
        </div>
      </Grid>
    </div>
  );
}
