import { fetch as api } from "../utils/request";
import { NETWORK } from "../settings";
import { mountQueryURL } from "../utils/query";
import moment from "moment";
export const getClients = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `/users/api/clients/network_customers_only/?network=${NETWORK}`;
      let response = await api({
        method: "GET",
        url: url,
      });
      resolve(response);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const updateDelivery_address = async (delivery, uid) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/users/api/address/waiter_create_delivery_address/`,
        data: {
          ...delivery,
          country: delivery.country["code"],
          active: true,
          update_address: true,
          user_id: uid,
        },
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const updateBilling_address = async (billing, uid) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `users/api/address/waiter_create_address/`,
        data: {
          ...billing,
          country: billing.country.code,
          active: true,
          update_address: true,
          user_id: uid,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export function getClientsTable(params) {
  return new Promise(async (resolve, reject) => {
    try {
      const query = mountQueryURL(params);
      var response = await api({
        method: "GET",
        url: `users/api/clients/network_customers/${query}`,
      });
      if (params.period === "Today") {
        response.map((customer, idx) => {
          var orders = customer.orders.filter(
            (order) =>
              moment(order.date_ordered).format("DD/MM/YYYY") ===
              moment(new Date()).format("DD/MM/YYYY")
          );
          response[idx].orders = orders;
        });
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClientsDateRange(initial, end, store) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api({
        method: "GET",
        url: `users/api/clients/network_customers/?starts=${initial}&ends=${end}&network=${NETWORK}&store=${store}`,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

export function createClient(data) {
  return new Promise(async (resolve, reject) => {
    try {
      if (data["email"] && data["phone_number"]) {
        var clientSearch = await api({
          method: "GET",
          url: `users/api/clients/?email=${data["email"]}`,
        });
        if (clientSearch.count < 1) {
          data["password"] = "12345678";
          var response = await api({
            method: "POST",
            url: `/users/api/clients/register_by_email/`,
            data,
          });

          resolve(response.user.id);
        } else {
          reject("This client already exists in the database");
        }
      } else {
        var response = await api({
          method: "POST",
          url: `users/api/clients/guest_user/`,
          data,
        });

        resolve(response.user.id);
      }
    } catch (error) {
      console.log("error ", JSON.stringify(error), Object.keys(error));
      reject(error);
    }
  });
}

export function searchClient(email) {
  return new Promise(async (resolve, reject) => {
    try {
      var clientSearch = await api({
        method: "GET",
        url: `users/api/clients/?email=${email}`,
      });
      return clientSearch;
    } catch (error) {
      reject(error);
    }
  });
}

export function updatePhoneAndEmail(client, phone, email) {
  return new Promise(async (resolve, reject) => {
    try {
      var payload = {
        email,
        profile: {
          phone_number: phone,
        },
      };
      if (phone === null) delete payload.profile;
      if (email === null) delete payload.email;
      await api({
        method: "PATCH",
        url: `/users/api/clients/${client}/`,
        data: payload,
      });
      resolve({ status: "success", message: "", field: "" });
    } catch (error) {
      console.log("error ", error);
      if (error?.profile?.phone_number) {
        reject({
          status: "error",
          message: "This phone number is already in use",
          field: "phone",
        });
      } else {
        reject({
          status: "error",
          message: "Something went wrong",
          field: "",
        });
      }
    }
  });
}

export function getClientData() {
  return new Promise(async (resolve, reject) => {
    try {
      var clientData = await api({
        method: "GET",
        url: `users/api/clients/me/?network=${NETWORK}`,
        data:{
          network:NETWORK
        }
      });
      clientData.user.phone = clientData.user.profile.phone_number
      resolve(clientData.user);
    } catch (error) {
      reject(error);
    }
  });
}
