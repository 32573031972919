import { IconButton, Typography, TableRow, TableCell } from "@mui/material";
import ModalEditTag from "./editTag";
import { useState } from "react";
import ModalDeleteTag from "./deleteTag";
import { Close } from "@mui/icons-material";
export default function TagRow({
  tag,
  // loading,
  // setLoading,
  store,
  refresh,
}) {
  const [editTag, setEditTag] = useState(false);
  const [deleteTag, setDeleteTag] = useState(false);

  return (
    <>
      <TableRow className="py-4 table-cell-fix-border">
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[120px]"
        >
          <p>{tag.name}</p>
        </TableCell>

        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="w-min"
        >
          <div className="flex items-center justify-end w-full gap-2 pr-24 align-top">
            {tag.related_to_catagory ? null : (
              <button
                onClick={() => setEditTag(true)}
                className="bg-white mr-3 px-5 rounded-full h-fit text-black border border-[#DDDDDD]"
              >
                <Typography className="table-cell text-[#272727]">
                  Edit
                </Typography>
              </button>
            )}

            <IconButton
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
              onClick={() => setDeleteTag(true)}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
        </TableCell>
        <ModalDeleteTag
          tag={tag}
          open={deleteTag}
          handleClose={() => setDeleteTag(false)}
          refresh={() => refresh()}
        />

        <ModalEditTag
          open={editTag}
          handleClose={() => setEditTag(false)}
          store={store}
          refresh={() => refresh()}
          tag={tag}
        />
      </TableRow>
    </>
  );
}
