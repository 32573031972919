import { useState } from "react";
import Receipt from "../../components/receipt";
import { Typography, TableRow, TableCell, Button } from "@mui/material";
import formatDate from "date-fns/format";
import OrderStatusDialog from "./orderStatusDialog";
import format from "./../../utils/formatValues";

export default function TransactionRow(props) {
  const { order, store, currency, storeData } = props;
  const isPaid = (order) => {
    return order.is_paid;
  };
  
  const [showDialog, setShowDialog] = useState(false);
  const handleOpenDialog = () => setShowDialog(true);
  const handleCloseDialog = () => setShowDialog(false);

  const getStatusOrder = (order) => {
    if (order.status === "Fazendo") {
      return "Doing";
    } else if (order.status === "Feito") {
      return "Done";
    } else if (order.status === "Transit") {
      return "On the way";
    } else if (order.status === "Delivered") {
      return "Delivered";
    } else if (order.status === "Canceled") {
      return "Canceled";
    } else if (order.status === "Completed") {
      return "Completed";
    } else {
      return "To do";
    }
  };

  return (
    <TableRow
      key={order.order_id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        backgroundColor: isPaid(order) ? "#C3FFD0" : "#FFDADA",
      }}
    >
      <TableCell className="table-cell-border">
        <Receipt
          trigger={
            <button>
              <Typography
                style={{
                  color: isPaid(order) ? "#20C680" : "#FF5D5D",
                }}
              >
                {order.order_code}
              </Typography>
            </button>
          }
          order={order}
          storeData={storeData}
        />
      </TableCell>
      <TableCell className="table-cell-border" component="th" scope="row">
        <Typography style={{ color: isPaid(order) ? "#20C680" : "#FF5D5D" }}>
          {order.client ? order.client?.name : (order.owner?.name === "guest last_name" ? "Guest" : order.owner?.name)}
        </Typography>
      </TableCell>
      <TableCell className="table-cell-border">
        <Typography style={{ color: isPaid(order) ? "#20C680" : "#FF5D5D" }}>
          {order.payment === "none" ? "Incomplete" : order.payment}
        </Typography>
      </TableCell>
      <TableCell className="table-cell-border">
        <Typography style={{ color: isPaid(order) ? "#20C680" : "#FF5D5D" }}>
          {order.total_order.toFixed(2) ? format(order.total_order.toFixed(2)) : ""}{" "}
          {currency}
        </Typography>
      </TableCell>
      <TableCell className="table-cell-border">
        <Typography style={{ color: isPaid(order) ? "#20C680" : "#FF5D5D" }}>
          {order?.date_delivery
            ? formatDate(
                new Date(order?.date_delivery),
                "dd/MM/yyyy - hh:mm:ss"
              )
            : null}
        </Typography>
      </TableCell>
      <TableCell className="table-cell-border">
        <Typography style={{ color: isPaid(order) ? "#20C680" : "#FF5D5D" }}>
          {order.is_paid ? "Paid" : "Not paid"}
        </Typography>
      </TableCell>
      <TableCell onClick={handleOpenDialog} className="table-cell-border">
        <button>
          <Typography
            style={{
              cursor: "pointer",
              color: isPaid(order) ? "#20C680" : "#FF5D5D",
            }}
          >
            {getStatusOrder(order)}
          </Typography>
        </button>
      </TableCell>
      <OrderStatusDialog
        showDialog={showDialog}
        handleCloseDialog={handleCloseDialog}
        order={order}
      />
    </TableRow>
  );
}
