const format = (value) => {
  const formatedValue = new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return formatedValue;
};
export default format;

export const formatCellPhone = (phoneNumber) => {
  const phoneWithoutCharacter = phoneNumber.replace(/\D/g, "");
  if (phoneWithoutCharacter[0] === "5" && phoneWithoutCharacter[1] === "5") {
    const stateCode = phoneWithoutCharacter.slice(2, 4);
    const phoneNumberPartOne = phoneWithoutCharacter.slice(4, 9);
    const phoneNumberPartTwo = phoneWithoutCharacter.slice(9, 13);

    return `(${stateCode}) ${phoneNumberPartOne}-${phoneNumberPartTwo}`;
  }
  const stateCode = phoneWithoutCharacter.slice(0, 2);
  const phoneNumberPartOne = phoneWithoutCharacter.slice(2, 7);
  const phoneNumberPartTwo = phoneWithoutCharacter.slice(7, 11);

  return `(${stateCode}) ${phoneNumberPartOne}-${phoneNumberPartTwo}`;
};
