import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../redux/features/alert";
import { Button, Typography } from "@material-tailwind/react";
import flogi from "./../../assets/images/flogi-logo.svg";
import {
  checkAuthentication,
  sendForgotPassword,
} from "../../services/login";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff, ArrowBack } from "@mui/icons-material";
import Input from "./../../components/inputs";
export default function Authentication() {
  const [email, setEmail] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassWord] = useState(false);
  const [passwordError, SetPasswordError] = useState("");
  const [forgotPassword, setForgotPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEdge, setIsEdge] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  useEffect(() => {
    if (auth.authorized && auth.new_user === false) {
      if (auth.networks.length > 1) {
        window.location.pathname = "/network";
       return
      }
      if (!auth.account_stripe_KYC_status) {
        window.location.pathname = "/kyc";
        return
      }
      window.location.pathname = "dashboard/orders";
    }
  }, []);

  const checkForm = () => {
    var entered = false;
    if (!email) {
      setEmailError("This field is required");
      entered = true;
    }
    if (!password) {
      SetPasswordError("This field is required");
      entered = true;
    }
    return entered;
  };

  const submitForgotPassword = async (e) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(forgotEmail)) {
      try {
        await sendForgotPassword(forgotEmail)
          .then(() => {
            dispatch(
              notify({
                message: "We have sent you an email to reset your password",
                severity: "success",
              })
            );
            setForgotEmail("");
            setForgotPassword(false);
          })
          .catch(() => {
            dispatch(
              notify({
                message: "This email does not exist in the database",
                severity: "error",
              })
            );
          });
      } catch {
        dispatch(
          notify({
            message: "Something went wrong",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        notify({ message: "This email is not valid", severity: "error" })
      );
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (!checkForm()) {
      const credentials = { email: email.trim(), password };
      dispatch(checkAuthentication(credentials));
    } else {
      dispatch(
        notify({ message: "Please check the fields below", severity: "error" })
      );
    }
  };
  useEffect(() => {
    if (window.navigator.userAgent.indexOf("Edg") > -1) {
      setIsEdge(true);
    }
  }, []);
  return (
    <div className="w-full min-h-[100vh] pt-12 flex justify-center bg-black">
      <div className="flex items-center w-full mt-[-30px] sm:w-auto md:w-1/3 lg:w-1/4 justify-center flex-col px-5">
        {!forgotPassword ? (
          <div className="w-full">
            <div className="items-start flex w-full pb-6">
              <Typography className="text-white">Portal Access</Typography>
            </div>
            <form autocomplete="off">
              <div className="w-full flex flex-col gap-1 pb-8">
                <Input
                  className="custom-input"
                  autoComplete={isEdge ? "new-password" : "email"}
                  required
                  variant="standard"
                  fullWidth
                  value={email}
                  autoFill={false}
                  id={`email+${Date()}`}
                  error={emailError && !email}
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  name={`email+${Date()}`}
                  readOnly
                  readonly
                  list="autocompleteOff"
                />
                <Input
                  className="custom-input"
                  autoComplete={isEdge ? "new-password" : "password"}
                  variant="standard"
                  list="autocompleteOff"
                  size="lg"
                  required
                  fullWidth
                  value={password}
                  error={passwordError && !password}
                  label="Password"
                  id={`email+${Date()}`}
                  name={`email+${Date()}`}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        style={{ padding: "0px" }}
                        onClick={() => setShowPassWord(!showPassword)}
                      >
                        {showPassword ? (
                          <Visibility style={{ fill: "#7A7A7A" }} />
                        ) : (
                          <VisibilityOff style={{ fill: "#7A7A7A" }} />
                        )}
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="items-start flex w-full">
                  <Typography
                    onClick={() => setForgotPassword(true)}
                    className="text-[#3F3F3F] cursor-pointer mt-1"
                  >
                    Forgot password
                  </Typography>
                </div>
              </div>
            </form>
            <Button
              className="w-full bg-[#612EF2] rounded-full text-start normal-case py-[2px] px-[8px] shadow-grey hover:shadow-grey"
              onClick={(e) => submit(e)}
            >
              <Typography className="py-[2px] pl-[0.67rem] ml-[2px]">
                Sign in
              </Typography>
            </Button>
          </div>
        ) : (
          <div className="w-full">
            <div className="w-full flex items-center gap-2">
              <IconButton
                onClick={() => setForgotPassword(false)}
                style={{ padding: "0 2rem 2rem 0" }}
              >
                <ArrowBack style={{ fill: "white", height: "1.3rem" }} />
              </IconButton>
            </div>
            <div className="items-start flex w-full pb-6">
              <Typography className="text-white">
                Please insert the email you want to reset the password
              </Typography>
            </div>
            <Input
              required
              size="lg"
              variant="standard"
              fullWidth
              value={forgotEmail}
              onChange={(e) => setForgotEmail(e.target.value)}
              label="Email"
            />
            <Button
              className="w-full bg-[#612EF2] rounded-full text-start normal-case py-[2px] px-[8px] mt-[2.55rem] shadow-grey hover:shadow-grey"
              disabled={forgotEmail !== "" ? false : true}
              style={{ opacity: "1" }}
              onClick={(e) => submitForgotPassword(e)}
            >
              <Typography className="py-[2px] pl-[0.67rem] ml-[2px]">
                Send
              </Typography>
            </Button>
          </div>
        )}
      </div>
      <div className="absolute left-0 right-0 bottom-5 flex items-center gap-1 justify-center">
        <Typography className="text-[#7A7A7A]">Powered by</Typography>
        <img style={{ height: "12px" }} src={flogi} alt="" />
      </div>
    </div>
  );
}
