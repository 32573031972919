import { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import analytics from "./../../assets/menu/Analytics.svg";
import analytics_active from "./../../assets/menu/Analytics-active.svg";
import clients from "./../../assets/menu/Clients_list.svg";
import clients_active from "./../../assets/menu/Clients_list-active.svg";
import commercial_invoice from "./../../assets/menu/Commercial_invoice.svg";
import commercial_invoice_active from "./../../assets/menu/Commercial_invoice-active.svg";
import completed from "./../../assets/menu/Completed_orders.svg";
import completed_active from "./../../assets/menu/Completed_orders-active.svg";
import inventory from "./../../assets/menu/Inventory.svg";
import inventory_active from "./../../assets/menu/Inventory-active.svg";
import orders from "./../../assets/menu/Orders.svg";
import orders_active from "./../../assets/menu/Orders-active.svg";
import transactions from "./../../assets/menu/Transactions.svg";
import transactions_active from "./../../assets/menu/Transactions-active.svg";
import logout from "./../../assets/menu/Logout.svg";
import store from "./../../assets/menu/store.svg";
import store_active from "./../../assets/menu/store_active.svg";
import whatsappIcon from "./../../assets/menu/whatsapp.svg";
import stripe from "./../../assets/images/stripe.png";
import { useSelector } from "react-redux";
export default function Menu({ open, openWhatsApp }) {
  const { auth } = useSelector((state) => state.auth);
  const location = useLocation();
  const pages = [
    {
      title: "Orders",
      path: "/dashboard/orders",
      icon: orders,
      active_icon: orders_active,
    },
    {
      title: "Commercial invoice",
      path: "/dashboard/commercialInvoice",
      icon: commercial_invoice,
      active_icon: commercial_invoice_active,
    },
    {
      title: "Completed orders",
      path: "/dashboard/completed",
      icon: completed,
      active_icon: completed_active,
    },
    {
      title: "Inventory",
      path: "/dashboard/inventory",
      icon: inventory,
      active_icon: inventory_active,
    },
    {
      title: "Clients list",
      path: "/dashboard/clients",
      icon: clients,
      active_icon: clients_active,
    },
    {
      title: "Stores",
      path: "/dashboard/stores",
      icon: store,
      active_icon: store_active,
    },
    {
      title: "Transactions",
      path: "/dashboard/transactions",
      icon: transactions,
      active_icon: transactions_active,
    },
    {
      title: "Analytics",
      path: "/dashboard/analytics",
      icon: analytics,
      active_icon: analytics_active,
    },
    {
      title: "WhatsApp",
      path: "",
      icon: whatsappIcon,
      active_icon: whatsappIcon,
    },
    {
      title: "My plan",
      path: auth?.stripe_profiles?.stripe_login_link
        ? auth.stripe_profiles.stripe_login_link
        : "https://dashboard.stripe.com/login",
      icon: stripe,
      active_icon: stripe,
      external_url: true,
    },
    {
      title: "Logout",
      path: "/dashboard/logout",
      icon: logout,
      active_icon: logout,
    },
  ];

  return (
    <List>
      {pages.map((page, index) => (
        <ListItem
          key={page + " " + index}
          disablePadding
          sx={{
            display: "flex",
            backgroundColor:
              page.path === location.pathname ? "#612EF2" : "#000",
            transition: "background-color 300ms linear",
          }}
        >
          {page.title === "WhatsApp" ? (
            <Link>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 16px",
                  gap: ".8rem",
                }}
                onClick={() => openWhatsApp()}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    padding: 0,
                  }}
                >
                  <img
                    src={
                      page.path === location.pathname
                        ? page.active_icon
                        : page.icon
                    }
                    alt={""}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    display: open ? "flex" : "none",
                    color: page.path === location.pathname ? "#FFF" : "#7A7A7A",
                    transition: "color 300ms linear",
                    fontSize: "16px",
                    width: "15rem",
                    "& p": { fontSize: "16px" },
                  }}
                >
                  <p>{page.title}</p>
                </ListItemText>
              </ListItemButton>
            </Link>
          ) : (
            <Link
              target={page.external_url ? "_blank" : "_self"}
              to={page.path}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: "center",
                  alignItems: "center",
                  padding: page.external_url ? "0px 16px" : "0 4px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    padding: 0,
                  }}
                >
                  {page.external_url ? (
                    <img
                      style={{ width: 24, height: 24 }}
                      src={
                        page.path === location.pathname
                          ? page.active_icon
                          : page.icon
                      }
                      alt={""}
                    />
                  ) : (
                    <img
                      src={
                        page.path === location.pathname
                          ? page.active_icon
                          : page.icon
                      }
                      alt={""}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    display: open ? "flex" : "none",
                    color: page.path === location.pathname ? "#FFF" : "#7A7A7A",
                    transition: "color 300ms linear",
                    fontSize: "16px",
                    width: "15rem",
                    "& p": { fontSize: "16px" },
                    marginLeft: page.external_url ? "15px" : "0px",
                  }}
                >
                  <p>{page.title}</p>
                </ListItemText>
              </ListItemButton>
            </Link>
          )}
        </ListItem>
      ))}
    </List>
  );
}
