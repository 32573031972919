import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
import TagsRow from "./tagsRow";
export default function TagsTable({ tags, refresh, store }) {
  return (
    <TableContainer
      style={{ overflowX: "initial", maxWidth: "100% !important" }}
    >
      <Table
        disableColumnResize={true}
        stickyHeader
        className="table-auto min-w-[95vh] w-full pb-24"
      >
        <TableHead className="text-left border-gray border-b-2 sticky z-[50]">
          <TableCell className="table-cell-title font-normal pl-[16px] pb-[10px]">
            Name
          </TableCell>

          <TableCell className="table-cell-title"></TableCell>
        </TableHead>
        <TableBody>
          {tags.map((tag) => (
            <TagsRow tag={tag} refresh={() => refresh()} store={store} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
