import React, { useEffect } from "react";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import Editor from "../../../../components/CKeditor";
import { countries } from "../../../../utils/countries";
export default function FirstTab({
  name,
  nameError,
  handleName,
  ownerEmail,
  ownerEmailError,
  handleOwnerEmail,
  phoneNumber,
  phoneNumberError,
  handlePhoneNumber,
  slug,
  slugError,
  handleSlug,
  currency,
  handleCurrency,
  addressUrl,
  handleAddressUrl,
  stripeKey,
  handleStripeKey,
  stores,
  store,
  handleStore,
  orderPrefix,
  handlePrefix,
  description,
  terms,
  privacy,
  contacts,
  setDescription,
  setTerms,
  setPrivacy,
  setContacts,
  address,
  setAddress,
  addressError,
  setAddressObj,
  storeType,
  storeTypeError,
  handleStoreType,
  addressUrlError,
}) {
  useEffect(() => {
    getAddressOptions();
  }, [address.street]);

  const getAddressOptions = () => {
    if (address.street) {
      const input = document.getElementById("store-address-input");
      // eslint-disable-next-line no-undef
      var autocomplete = new google.maps.places.Autocomplete(input, {
        fields: ["address_components", "geometry"],
        types: ["address"],
      });
      autocomplete.addListener("place_changed", () => {
        fillInAddress(autocomplete);
      });
    }
  };
  function fillInAddress(autoComplete) {
    const place = autoComplete.getPlace();
    var emptyZip = true;
    var emptyNeighborhood = true;
    const addressObj = {
      street: "",
      street_number: "",
      neighborhood: "",
      city: "",
      zipcode: "",
      country: "",
      state: "",
    };
    if (place && place.address_components) {
      for (const component of place.address_components) {
        console.log("component ", component);

        // Process the address components as needed
        const type = component.types[0];
        if (type === "route") {
          addressObj.street = component.long_name;
        }
        //if (type === "street_number") {
        //  setAddress("street_number", component.long_name);
        //}
        if (type === "postal_code") {
          emptyZip = false;
          addressObj.zipcode = component.long_name;
        }
        if (type === "country") {
          addressObj.country = {
            label: component.long_name,
            code: component.short_name,
          };
        }
        if (type === "sublocality_level_1") {
          emptyNeighborhood = false;
          addressObj.neighborhood = component.long_name;
        }
        if (type === "administrative_area_level_1") {
          addressObj.state = component.long_name;
        }
        if (type === "administrative_area_level_2") {
          addressObj.city = component.long_name;
        }
      }
      setAddress("street_number", "aaaaaaaaaa");
      setAddressObj(addressObj);
      if (emptyNeighborhood) {
        setAddress("neighborhood", "");
      }
      if (emptyZip) {
        setAddress("zipcode", "");
      }
    }
  }
  return (
    <>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          fullWidth
          className="fix-label"
          style={{ marginBottom: "20px" }}
          required={true}
          error={nameError !== ""}
        >
          <InputLabel htmlFor="name-input">Name</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={name}
            onChange={handleName}
          />
          <FormHelperText>{nameError}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          className="fix-label"
          fullWidth
          style={{ marginBottom: "20px" }}
          required={true}
          error={ownerEmailError !== ""}
        >
          <InputLabel htmlFor="name-input">Email</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={ownerEmail}
            onChange={handleOwnerEmail}
          />
          <FormHelperText>{ownerEmailError}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          className="fix-label"
          fullWidth
          style={{ marginBottom: "20px" }}
          required={true}
          error={phoneNumberError !== ""}
        >
          <InputLabel htmlFor="name-input">Phone</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={phoneNumber}
            onChange={handlePhoneNumber}
          />
          <FormHelperText>{phoneNumberError}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          className="fix-label"
          fullWidth
          style={{ marginBottom: "20px" }}
          required={true}
          error={slugError !== ""}
        >
          <InputLabel htmlFor="name-input">Slug</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={slug}
            onChange={handleSlug}
          />
          <FormHelperText>{slugError}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          className="fix-label"
          fullWidth
          style={{ marginBottom: "20px" }}
        >
          <InputLabel htmlFor="name-input">Currency</InputLabel>
          <Select
            variant="standard"
            id="category-input"
            value={currency}
            onChange={handleCurrency}
          >
            <MenuItem value={"USD"}>{"US Dollar (USD)"}</MenuItem>
            <MenuItem value={"EUR"}>{"Euro (EUR)"}</MenuItem>
            <MenuItem value={"GBP"}>{"British Pound (GBP)"}</MenuItem>
            <MenuItem value={"CHF"}>{"Swiss Franc (CHF)"}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          className="fix-label"
          fullWidth
          style={{ marginBottom: "20px" }}
        >
          <InputLabel htmlFor="type-store-input">Type of store</InputLabel>
          <Select
            variant="standard"
            id="type-store-input"
            value={storeType}
            onChange={handleStoreType}
            required={true}
            error={storeTypeError !== ""}
            MenuProps={{
              className: "select-type-store-modal",
            }}
          >
            <MenuItem value={"Food"}>{"Food"}</MenuItem>
            <MenuItem value={"Fitness"}>{"Fitness"}</MenuItem>
            <MenuItem value={"Healthy"}>{"Healthy"}</MenuItem>
            <MenuItem value={"Vegan"}>{"Vegan"}</MenuItem>
            <MenuItem value={"Wine"}>{"Wine"}</MenuItem>
            <MenuItem value={"Supplement"}>{"Supplement"}</MenuItem>
            <MenuItem value={"Furniture"}>{"Furniture"}</MenuItem>
            <MenuItem value={"Grocery"}>{"Grocery"}</MenuItem>
            <MenuItem value={"Dessert"}>{"Dessert"}</MenuItem>
            <MenuItem value={"Clothes"}>{"Clothes"}</MenuItem>
            <MenuItem value={"Electronics"}>{"Electronics"}</MenuItem>
            <MenuItem value={"Coffee"}>{"Coffee"}</MenuItem>
          </Select>
          <FormHelperText error>{storeTypeError}</FormHelperText>
        </FormControl>
      </Grid>
      <div className="flex-col gap-3 flex">
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <FormControl
              className={
                address?.street ? "form-control" : "form-control-incompleted"
              }
              required
              style={{ width: "100%" }}
              error={
                addressError.street &&
                (address.street == "" || address.street == null)
              }
            >
              <TextField
                style={{ color: "#c7c7c7" }}
                value={address.street}
                onChange={(e) => setAddress("street", e.target.value)}
                fullWidth
                id={"store-address-input"}
                variant="standard"
                label="Street"
              />
              {addressError.street &&
                (address.street == "" || address.street == null) && (
                  <FormHelperText style={{ marginLeft: "0px" }}>
                    This field is required
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              className={
                address.street_number
                  ? "form-control"
                  : "form-control-incompleted"
              }
              required
              style={{ width: "100%" }}
              error={
                addressError.street_number &&
                (address.street_number == "" || address.street_number == null)
              }
            >
              <TextField
                value={address.street_number}
                onChange={(e) => setAddress("street_number", e.target.value)}
                fullWidth
                variant="standard"
                label="Number"
              />
              {addressError.street_number &&
                (address.street_number == "" ||
                  address.street_number == null) && (
                  <FormHelperText style={{ marginLeft: "0px" }}>
                    This field is required
                  </FormHelperText>
                )}{" "}
            </FormControl>
          </Grid>
        </Grid>
        <div className="w-full flex gap-2">
          <FormControl
            className={
              address.city ? "form-control" : "form-control-incompleted"
            }
            required
            style={{ width: "100%" }}
            error={
              addressError.city && (address.city == "" || address.city == null)
            }
          >
            <TextField
              value={address.city}
              onChange={(e) => setAddress("city", e.target.value)}
              fullWidth
              variant="standard"
              label="City"
            />{" "}
            {addressError.city &&
              (address.city == "" || address.city == null) && (
                <FormHelperText style={{ marginLeft: "0px" }}>
                  This field is required
                </FormHelperText>
              )}{" "}
          </FormControl>
        </div>
        <div className="w-full flex gap-2">
          <FormControl
            className={
              address.neighborhood ? "form-control" : "form-control-incompleted"
            }
            required
            style={{ width: "100%" }}
            error={
              addressError.neighborhood &&
              (address.neighborhood == "" || address.neighborhood == null)
            }
          >
            <TextField
              value={address.neighborhood}
              onChange={(e) => setAddress("neighborhood", e.target.value)}
              fullWidth
              variant="standard"
              label="Neighbourhood"
            />{" "}
            {addressError.neighborhood &&
              (address.neighborhood == "" || address.neighborhood == null) && (
                <FormHelperText style={{ marginLeft: "0px" }}>
                  This field is required
                </FormHelperText>
              )}{" "}
          </FormControl>
        </div>
        <div className="w-full flex gap-2">
          <div className="w-2/3">
            <FormControl
              className={
                address.state ? "form-control" : "form-control-incompleted"
              }
              style={{ width: "100%" }}
              required
              error={
                addressError.state &&
                (address.state == "" || address.state == null)
              }
            >
              <TextField
                value={address.state}
                onChange={(e) => setAddress("state", e.target.value)}
                fullWidth
                variant="standard"
                label="County/State"
              />{" "}
              {addressError.state &&
                (address.state == "" || address.state == null) && (
                  <FormHelperText style={{ marginLeft: "0px" }}>
                    This field is required
                  </FormHelperText>
                )}{" "}
            </FormControl>
          </div>
          <div className="w-1/3">
            <FormControl
              className={
                address.zipcode ? "form-control" : "form-control-incompleted"
              }
              style={{ width: "100%" }}
              required
              error={
                addressError.zipcode &&
                (address.zipcode == "" || address.zipcode == null)
              }
            >
              <TextField
                value={address.zipcode}
                onChange={(e) => setAddress("zipcode", e.target.value)}
                fullWidth
                variant="standard"
                label="Zipcode"
              />{" "}
              {addressError.zipcode &&
                (address.zipcode == "" || address.zipcode == null) && (
                  <FormHelperText style={{ marginLeft: "0px" }}>
                    This field is required
                  </FormHelperText>
                )}{" "}
            </FormControl>
          </div>{" "}
        </div>
        <div className="w-full flex gap-2"></div>
        <FormControl
          className={
            address.country?.code ? "form-control" : "form-control-incompleted"
          }
          style={{ width: "100%" }}
          required
          error={
            addressError.zipcode &&
            (address.country == "" || address.country == null)
          }
        >
          <Autocomplete
            id="country-select-demo"
            options={countries}
            error={
              addressError.country &&
              (address.country == "" || address.country == null)
            }
            value={address.country}
            onChange={(e, newValue) => {
              setAddress("country", newValue);
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code}) +{option.phone}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Country"
                style={{ borderRadius: "10000px" }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
                className="country-input"
              />
            )}
          />{" "}
          {addressError.country &&
            (address.country == "" || address.country == null) && (
              <FormHelperText style={{ marginLeft: "0px" }}>
                This field is required
              </FormHelperText>
            )}{" "}
        </FormControl>
      </div>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          className="fix-label"
          fullWidth
          style={{ marginBottom: "20px", marginTop: "20px" }}
          error={addressUrlError && addressUrl === ""}
        >
          <InputLabel htmlFor="name-input">URL</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={addressUrl}
            onChange={handleAddressUrl}
          />
          {addressUrlError && addressUrl === "" && (
            <FormHelperText style={{ marginLeft: "0px" }}>
              This field is required
            </FormHelperText>
          )}{" "}
        </FormControl>
      </Grid>{" "}
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          className="fix-label"
          style={{ marginBottom: "20px" }}
          fullWidth
        >
          <InputLabel htmlFor="name-input">Stripe Key</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={stripeKey}
            onChange={handleStripeKey}
          />
        </FormControl>
      </Grid>
      {stores && (
        <Grid item xs={12} spacing={5} sm={12}>
          <FormControl
            className="fix-label"
            fullWidth={true}
            style={{ marginBottom: "20px" }}
          >
            <InputLabel htmlFor="category-input">
              Import an establishment
            </InputLabel>
            <Select
              variant="standard"
              id="category-input"
              value={store}
              onChange={handleStore}
            >
              {stores.map((store, index) => (
                <MenuItem key={index} value={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          className="fix-label"
          fullWidth
          style={{ marginBottom: "35px" }}
        >
          <InputLabel htmlFor="name-input">Order of sale prefix</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={orderPrefix}
            onChange={handlePrefix}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12} style={{ marginBottom: "35px" }}>
        <Editor
          placeholder={"Store description"}
          value={description}
          handleChange={(e) => setDescription(e)}
        />
      </Grid>
      <Grid item xs={12} spacing={5} sm={12} style={{ marginBottom: "35px" }}>
        <Editor
          placeholder={"Contacts"}
          value={contacts}
          handleChange={(e) => setContacts(e)}
        />
      </Grid>
    </>
  );
}
