import React from "react";
import { Grid } from "@mui/material";
import Editor from "../../../../components/CKeditor";

export default function FourthTab({ terms, setTerms }) {
  return (
    <>
      <Grid item xs={12} spacing={5} sm={12} style={{ marginBottom: "35px" }}>
        <Editor
          placeholder={"Terms & Conditions"}
          value={terms}
          handleChange={(e) => setTerms(e)}
        />
      </Grid>
    </>
  );
}
