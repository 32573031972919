import auth from "./features/auth";
import alert from "./features/alert";
import network from "./features/network";
import fetchStore from "./features/fetchStore";

import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const reducers = combineReducers({
  auth,
  alert,
  network,
  fetchStore,
});

const persistConfig = {
  key: "state",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
