import { useState } from "react";

export default function CustomSwitch({ checked, onChange }) {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <label>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      <div
        style={{
          width: "55px",
          height: "22px", 
          borderRadius: "11px",
          background: isChecked ? "#20C680" : "#ccc",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "50%",
            background: "#fff",
            position: "absolute",
            top: "2px",
            left: isChecked ? "calc(100% - 20px)" : "2px",
            transition: "left 0.2s ease",
          }}
        />
      </div>
    </label>
  );
}