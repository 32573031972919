import { createSlice } from "@reduxjs/toolkit";

export const fetchStore = createSlice({
  name: "fetchStore",
  initialState: {
    count: 0,
  },
  reducers: {
    trigger: (state) => {
     state.count += 1
    },
  },
});

// Action creators are generated for each case reducer function
export const { trigger } = fetchStore.actions;

export default fetchStore.reducer;
