import { Radio, IconButton, Typography } from "@mui/material";
import format from "./../../utils/formatValues";
import { Add, Remove } from "@mui/icons-material";
export default function ProductCard({
  product,
  isSelected,
  selectProduct,
  storeData,
  inCreaseAmount,
  decreaseAmount,
  focused,
  selectedData,
  addExtra,
  removeExtra,
  deselectProduct,
  pickSize,
  selectedVariation,
  pickVariation,
  increaseVariationAmount,
  decreaseVariationAmount,
}) {
  const currency = storeData.currency;
  const isVariationSelected = (variation_id) => {
    return (
      selectedVariation(variation_id).filter((el) => el.id === variation_id)
        .length > 0
    );
  };
  const amount = (variation_id) => {
    var variation = selectedVariation(variation_id).filter(
      (el) => el.id === variation_id
    );
    if (variation[0]) {
      return variation[0]["amount"];
    }
    return 0;
  };
  return (
    <div
      /*
      onBlur={() => {
        if (!selectedData?.selected_variation & selectedData?.variation.length > 0) {
          deselectProduct();
        }
      }} */
      className="border-grey border-2 rounded relative cursor-pointer h-fit"
    >
      <img
        onClick={() => selectProduct(product)}
        src={product?.image}
        className="object-cover h-[150px] w-full rounded-t"
      />
      <div
        onClick={() => selectProduct(product)}
        className={`pt-3 px-2 ${
          product.variation.length > 0 ? "mb-12" : "pb-2"
        }`}
        style={{
          maxWidth: "18rem",
          maxHeight: "40px",
          overflow: "hidden",
        }}
      >
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            color: "#272727",
          }}
        >
          {product.name}
        </p>
      </div>
      {product.variation.length < 1 && (
        <div
          onClick={() => selectProduct(product)}
          className={`flex justify-center row items-center gap-3 transition-all opacity-${
            isSelected ? "100" : "0"
          } `}
        >
          <IconButton onClick={() => decreaseAmount()}>
            <Remove />{" "}
          </IconButton>
          <p>{selectedData?.amount}</p>
          <IconButton onClick={() => inCreaseAmount()}>
            <Add />{" "}
          </IconButton>
        </div>
      )}

      <div
        style={{
          transition: "max-height 500ms",
          // maxHeight: isSelected ? "300px" : "0px",
        }}
        className={`opacity-[${isSelected ? "1" : "0"}] overflow-hidden`}
      >
        {isSelected && product?.variation?.length > 0 && (
          <div className="border-t-2 border-t-grey w-full pt-2 pb-2 px-2">
            <Typography className="text-[#272727] pb-[7px]">
              Select variation
            </Typography>
            {product.variation.map((variation, idx) => (
              <div
                className="flex justify-between"
                //onClick={() => pickVariation(variation.id)}
                key={idx}
              >
                <div className="flex flex-col items-start justify-start">
                  <div className="flex items-center">
                    <Radio
                      checked={isVariationSelected(variation.id)}
                      onClick={() => pickVariation(variation.id)}
                    />
                    <p>{variation.name}</p>
                  </div>
                  <p>
                    {format(variation.price)} {currency}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    onClick={() => decreaseVariationAmount(variation.id)}
                  >
                    <Remove />
                  </IconButton>
                  <p className="text-[#272727]">{amount(variation.id)}</p>
                  <IconButton
                    onClick={() => increaseVariationAmount(variation.id)}
                  >
                    <Add />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
        {isSelected && selectedData?.extras?.length > 0 && (
          <div className="border-t-2 border-t-grey w-full pt-2 pb-2 px-2">
            <Typography className="pb-[1.1rem] text-[#272727]">
              Select extras
            </Typography>
            {selectedData.extras.map((extra, idx) => (
              <div className="flex justify-between" key={idx}>
                <div>
                  <p className="text-[#272727]">{extra.ingredient}</p>
                  <p className="text-start text-[#272727]">
                    {format(extra.price)} {currency}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={() => removeExtra(idx)}>
                    <Remove />
                  </IconButton>
                  <p className="text-[#272727]">{extra.quantity}</p>
                  <IconButton onClick={() => addExtra(idx)}>
                    <Add />
                  </IconButton>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="absolute top-0 flex items-center justify-between w-full pr-2">
        <Radio
          value={isSelected}
          onClick={() => {
            if (!isSelected) selectProduct(product);
            else deselectProduct();
          }}
          checked={isSelected}
        />
        <p className="bg-black rounded-full px-3 py-1 text-white">
          {format(
            product.variation.length > 0
              ? product.variation[0].price
              : product.price
          ) +
            " " +
            currency}
        </p>
      </div>
    </div>
  );
}
