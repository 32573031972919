import axios from "axios";

import {
  AUTH_ENDPOINT,
  API_ENDPOINT,
  AUTH_ENDPOINT_WAITERS,
} from "../settings";

/* Default error messages for failing requests. */
const errorMessages = {
  default: "Something went wrong",
  noResponse: "The server is down",
  network: "There is an error in your network",
};

/* This function handles three types of errors relationed to requests. */
function errorHandler(error) {
  if (error.response) {
    /* The server responded with a status code
    that falls out of the range of 2xx.  */
    if (typeof error.response.data === "object") {
      if (error.response.data.detail) {
        throw error.response.data.detail || errorMessages.default;
      }
      if (error.response.data.profile) {
        throw error.response.data || errorMessages.default;
      }
      throw error.response.data || errorMessages.default;
    } else {
      throw errorMessages.default;
    }
  } else if (error.request) {
    /* The request was made but no response was received. */
    throw error.request.response || errorMessages.noResponse;
  } else {
    /* Something went wrong in setting up the request. */
    throw error.message || errorMessages.network;
  }
}

/* For authentication requests. */
export async function fetchAuth(credentials) {
  try {
    const response = await axios.post(AUTH_ENDPOINT, credentials);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
}
export async function fetchAuthWaiter(credentials) {
  try {
    const response = await axios.post(AUTH_ENDPOINT_WAITERS, credentials);
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
}

/* Generic instance. For generic requests. */
const genericRequest = axios.create({
  baseURL: API_ENDPOINT,
});

/* Capturing the JWT token with interceptors. */
genericRequest.interceptors.request.use((req) => {
  const newRequest = req;
  return newRequest;
});

export async function fetch({ url, method, headers, data, params }) {
  try {
    const store = localStorage?.getItem("persist:state");
    const state = JSON.parse(store);
    const auth = JSON.parse(state.auth);
    const { token } = auth.auth;
    var _headers = headers ? headers : {};
    _headers.Authorization = `Bearer ${token}`;
    const response = await genericRequest({
      url,
      headers: _headers,
      method,
      data,
      params,
    });
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
}
