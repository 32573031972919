import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";

import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/auth";
import logo from "./../../assets/images/flogi-logo.svg";
import "./styles.css";

export default function FinishOnboarding() {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(logout());
      window.location.href = "/";
    }, 5000);
  }, []);
  
  return (
    <div className="flex justify-center h-screen bg-black">
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="flex flex-col justify-center items-center">
          <Typography className="title-onboarding" style={{ marginTop: "3rem" }}>
            You have completed on boarding!
          </Typography>
          <Typography className="title-onboarding">
            We have sent you an email to complete
          </Typography>
          <Typography className="title-onboarding">
            your verification with Stripe.
          </Typography>
        </div>
      </Grid>
    </div>
  );
}
