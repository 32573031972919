import { getClientsTable, getClientsDateRange } from "../../services/clients";
import { useState, useEffect } from "react";
import { getStores } from "../../services/stores";
import { NETWORK } from "./../../settings";
import StoreFilter from "../../components/storeFilter";
import { DateRange } from "@mui/icons-material";
import Search from "./../../components/bottomSearch";
import Select from "../../components/selectInput";
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ClientRow from "./clientRow";
import ProgressBar from "../../components/loader/progressBar";
import { notify } from "../../redux/features/alert";
import KycError from "../../components/kycError";
export default function Clients() {
  const [clients, setClients] = useState([]);
  const [stores, setStores] = useState([]);
  const [initialDate, setInitialDate] = useState(
    moment(new Date(new Date().setDate(new Date().getDate() - 30))).format(
      "YYYY-MM-DD"
    )
  );
  const [finalDate, setFinalDate] = useState(moment().format("YYYY-MM-DD"));

  const { auth } = useSelector((store) => store.auth);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    value: "All",
    label: "All customers",
  });
  const [query, setQuery] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientFilter, setClientFilter] = useState("all");
  const [activeStoreFilter, setActiveStoreFilter] = useState({
    value: 0,
    label: "",
  });
  const dispacth = useDispatch();
  const fetchStores = () => {
    getStores()
      .then((res) => {
        setActiveStoreFilter({ value: res[0].id, label: res[0].name });
        setStores(res);
      })
      .catch((e) => {
        console.log("err ", e);
      });
  };
  const fetchClients = () => {
    if (activeFilter.value === "All" || activeFilter.value === "Today") {
      setLoading(true);
      var params = {
        store: activeStoreFilter.value,
        network: NETWORK,
        period: activeFilter.value,
      };
      if (!auth.is_superuser || activeStoreFilter.value !== 0) {
        delete params.network;
      } else {
        delete params.store;
      }
      getClientsTable(params)
        .then((res) => {
          setClients(res);
          setLoading(false);
        })
        .catch((e) => {
          /*dispacth(
            notify({
              message: "An error occured while fetching the clients",
              severity: "error",
            })
          ); */
          setLoading(false);
        });
    }
  };
  const fetchClientsDateRange = (start, end) => {
    try {
      setLoading(true);
      getClientsDateRange(start, end, activeStoreFilter.value)
        .then((res) => {
          setClients(res);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);

          dispacth(
            notify({
              message: "An error occured while fetching the clients",
              severity: "error",
            })
          );
        });
    } catch (error) {
      setLoading(false);
    }
  };
  const getCurrencyStore = () => {
    const store = stores.find((_store) => _store.id === activeStoreFilter.value);
    return store?.currency;
  };
  useEffect(() => {
    fetchStores();
  }, []);
  useEffect(() => {
    fetchClients();
  }, [activeFilter, activeStoreFilter.value]);
  useEffect(() => {
    if (query) {
      setFiltered(
        clients.filter((client) =>
          client.name.toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      setFiltered(clients);
    }
  }, [query, clients.length]);
  const handleChangeFilter = (value) => {
    setActiveFilter(value);

    if (value.value === "Week" || value.value === "Month") {
      var end = moment(new Date()).format("YYYY-MM-DD");

      if (value.value === "Week") {
        var start = moment(
          new Date(new Date().setDate(new Date().getDate() - 7))
        ).format("YYYY-MM-DD");
      } else {
        var start = moment(
          new Date(new Date().setDate(new Date().getDate() - 30))
        ).format("YYYY-MM-DD");
      }
      try {
        fetchClientsDateRange(start, end);
      } catch (error) { }
    }
  };
  return (
    <div>
      {auth.stripe_profiles.account_stripe_KYC_status ?
        <>
          <div className="flex mx-4 flex-row flex-wrap mt-[43px] gap-2">
            <div className="w-[250px]">
              <StoreFilter
                stores={stores}
                activeStoreFilter={activeStoreFilter}
                setActiveStoreFilter={(value) => setActiveStoreFilter(value)}
              />
            </div>
            <div className="w-[250px] mt-[-16px] relative z-[300]">
              <Select
                options={[
                  { value: "All", label: "All customers" },
                  { value: "Today", label: "New customers today" },
                  { value: "Week", label: "New customers last week" },
                  { value: "Month", label: "New customers last month" },
                ]}
                value={activeFilter}
                onChange={(value) => handleChangeFilter(value)}
                placeholder={"Clients filter"}
              />
            </div>
          </div>
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <ProgressBar />
            </div>
          ) : (
            <div className="w-full flex items-center mt-[43px]">
              <TableContainer style={{ overflowX: "initial", maxHeight: "100vh" }}>
                <Table
                  stickyHeader
                  className="table-auto min-w-[800px] w-full mb-14"
                >
                  <TableHead className="text-left sticky z-[50]">
                    <TableRow>
                      <TableCell className="table-cell-title table-cell-fix-border">
                        <Typography className="font-normal">Name</Typography>
                      </TableCell>
                      <TableCell className="table-cell-title table-cell-fix-border">
                        <Typography className="font-normal">
                          Email
                        </Typography>
                      </TableCell>
                      <TableCell className="table-cell-title table-cell-fix-border">
                        <Typography className="font-normal">
                          Phone
                        </Typography>
                      </TableCell>
                      <TableCell className="table-cell-title table-cell-fix-border">
                        <Typography className="font-normal">
                          Stripe customer ID
                        </Typography>
                      </TableCell>
                      <TableCell className="table-cell-title table-cell-fix-border">
                        <Typography className="font-normal">
                          Orders
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtered.map((client) => (
                      <ClientRow client={client} key={client.id} storeCurrency={getCurrencyStore()} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          <Search
            value={query}
            handleChange={(e) => setQuery(e)}
            placeholder="Search customer"
          />
        </>
        : <KycError profile={auth.stripe_profiles} />}

    </div>
  );
}
