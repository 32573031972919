import React from "react";
import {
  Container,
  Grid,
  FormControl,
  IconButton,
  Typography,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Button } from "@material-tailwind/react";
import { notify } from "../../../redux/features/alert";
import { useDispatch } from "react-redux";
import { editOpenHours } from "./../../../services/stores";
import { Close } from "@mui/icons-material";
import { useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import moment from "moment";

export default function ModalOpenHours(props) {
  const { onClose, open, store, reset, attemptClose } = props;
  const [days, setDays] = React.useState([]);

  const dispatch = useDispatch();
  const [id, setId] = React.useState(0);
  const store_local = localStorage.getItem("state");
  const state = JSON.parse(store_local);
  //ad is_store_owner etc to line above
  // const [owner, setOwner] = React.useState(is_store_owner);
  // const [waiter, setWaiter] = React.useState(is_waiter);
  // const [manager, setManager] = React.useState(is_manager);

  useEffect(() => {
    setId(store.id);
  }, [state.authentication]);

  useEffect(() => {
    fillOpenHours();
  }, [store]);
  const fillOpenHours = () => {
    var openHoursDays = [];
    if (store?.open_hours) {
      for (
        let index = 0;
        index < Object.keys(store.open_hours).length;
        index++
      ) {
        const day = Object.keys(store.open_hours)[index];
        if (day !== "id") {
          openHoursDays.push({
            day: day,
            opened: moment(store.open_hours[day].opened, "HH:mm"),
            closed: moment(store.open_hours[day].closed, "HH:mm"),
            maxDateError: false,
            openAllDay:
              store.open_hours[day].opened === "00:01" &&
              store.open_hours[day].closed === "23:59"
                ? true
                : false,
            closedAllDAy:
              store.open_hours[day].opened === "00:00" &&
              store.open_hours[day].closed === "00:00"
                ? true
                : false,
          });
        }
      }
    } else {
      openHoursDays = [
        {
          day: "monday",
          opened: moment("08:00", "HH:mm"),
          closed: moment("18:00", "HH:mm"),
          maxDateError: false,
          closedAllDAy: false,
          openAllDay: false,
        },
        {
          day: "tuesday",
          opened: moment("08:00", "HH:mm"),
          closed: moment("18:00", "HH:mm"),
          maxDateError: false,
          closedAllDAy: false,
          openAllDay: false,
        },
        {
          day: "wednesday",
          opened: moment("08:00", "HH:mm"),
          closed: moment("18:00", "HH:mm"),
          maxDateError: false,
          closedAllDAy: false,
          openAllDay: false,
        },
        {
          day: "thursday",
          opened: moment("08:00", "HH:mm"),
          closed: moment("18:00", "HH:mm"),
          maxDateError: false,
          closedAllDAy: false,
          openAllDay: false,
        },
        {
          day: "friday",
          opened: moment("08:00", "HH:mm"),
          closed: moment("18:00", "HH:mm"),
          maxDateError: false,
          closedAllDAy: false,
          openAllDay: false,
        },
        {
          day: "saturday",
          opened: moment("08:00", "HH:mm"),
          closed: moment("18:00", "HH:mm"),
          maxDateError: false,
          closedAllDAy: false,
          openAllDay: false,
        },
        {
          day: "sunday",
          opened: moment("08:00", "HH:mm"),
          closed: moment("18:00", "HH:mm"),
          maxDateError: false,
          closedAllDAy: false,
          openAllDay: false,
        },
      ];
    }
    const weekDays = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    openHoursDays.sort(
      (a, b) => weekDays.indexOf(a.day) - weekDays.indexOf(b.day)
    );

    setDays(openHoursDays);
  };
  const handleClose = () => {
    // setAddressUrl('');
    // setMerchantOrderId('');
    onClose();
  };

  const submitCreation = async () => {
    var daysOfTheWeek = {
      id: id,
      monday: {
        opened: days[0].closedAllDAy
          ? "00:00"
          : days[0].openAllDay
          ? "00:01"
          : moment(days[0].opened).format("HH:mm"),
        closed: days[0].closedAllDAy
          ? "00:00"
          : days[0].openAllDay
          ? "23:59"
          : moment(days[0].closed).format("HH:mm"),
      },
      tuesday: {
        opened: days[1].closedAllDAy
          ? "00:00"
          : days[1].openAllDay
          ? "00:01"
          : moment(days[1].opened).format("HH:mm"),
        closed: days[1].closedAllDAy
          ? "00:00"
          : days[1].openAllDay
          ? "23:59"
          : moment(days[1].closed).format("HH:mm"),
      },
      wednesday: {
        opened: days[2].closedAllDAy
          ? "00:00"
          : days[2].openAllDay
          ? "00:01"
          : moment(days[2].opened).format("HH:mm"),
        closed: days[2].closedAllDAy
          ? "00:00"
          : days[2].openAllDay
          ? "23:59"
          : moment(days[2].closed).format("HH:mm"),
      },
      thursday: {
        opened: days[3].closedAllDAy
          ? "00:00"
          : days[3].openAllDay
          ? "00:01"
          : moment(days[3].opened).format("HH:mm"),
        closed: days[3].closedAllDAy
          ? "00:00"
          : days[3].openAllDay
          ? "23:59"
          : moment(days[3].closed).format("HH:mm"),
      },
      friday: {
        opened: days[4].closedAllDAy
          ? "00:00"
          : days[4].openAllDay
          ? "00:01"
          : moment(days[4].opened).format("HH:mm"),
        closed: days[4].closedAllDAy
          ? "00:00"
          : days[4].openAllDay
          ? "23:59"
          : moment(days[4].closed).format("HH:mm"),
      },
      saturday: {
        opened: days[5].closedAllDAy
          ? "00:00"
          : days[5].openAllDay
          ? "00:01"
          : moment(days[5].opened).format("HH:mm"),
        closed: days[5].closedAllDAy
          ? "00:00"
          : days[5].openAllDay
          ? "23:59"
          : moment(days[5].closed).format("HH:mm"),
      },
      sunday: {
        opened: days[6].closedAllDAy
          ? "00:00"
          : days[6].openAllDay
          ? "00:01"
          : moment(days[6].opened).format("HH:mm"),
        closed: days[6].closedAllDAy
          ? "00:00"
          : days[6].openAllDay
          ? "23:59"
          : moment(days[6].closed).format("HH:mm"),
      },
    };
    await editOpenHours(daysOfTheWeek, id)
      .then((result) => {
        dispatch(
          notify({
            message: "Open hours successfully edited",
            severity: "success",
          })
        );
        reset();
        handleClose();
      })
      .catch((e) => {
        dispatch(
          notify({
            message: e,
            severity: "error",
          })
        );
      });
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleChange = (_day, prop, time) => {
    setDays(
      days.map((item) => (item.day === _day ? { ...item, [prop]: time } : item))
    );
  };

  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center"
      open={open}
      onClose={attemptClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="w-full sm:w-[70%] md:w-[50%]  h-full sm:h-3/4 bg-white overflow-hidden relative">
          <div className="w-full h-full overflow-y-auto pt-5 pb-10">
            <div className="w-full flex flex-row px-10 items-center justify-between">
              <Typography variant="p">Open hours</Typography>
              <div className="">
                <IconButton
                  onClick={() => attemptClose()}
                  style={{
                    backgroundColor: "#fff",
                    fill: "#fff",
                    border: "1px solid #DDDDDD",
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                  }}
                >
                  <Close style={{ fill: "#DDDDDD" }} />{" "}
                </IconButton>
              </div>
            </div>

            <Container className="mb-10 mt-3 !mx-0 !px-0">
              {days.map((element, idx) => (
                <>
                  <Typography className="py-4 px-10">
                    {capitalizeFirstLetter(element.day)}
                  </Typography>

                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #eeeeee",
                    }}
                    container
                    spacing={2}
                  >
                    {" "}
                    <Grid item xs={4} spacing={5} sm={4}>
                      <FormControl
                        fullWidth
                        style={{ padding: "0 0 16px 40px" }}
                      >
                        <TimePicker
                          label="Opened"
                          value={element.opened}
                          variant="standard"
                          disabled={element.closedAllDAy || element.openAllDay}
                          onChange={(e) => {
                            handleChange(element.day, "opened", e);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          className="custom-datepicker-full custom-timepicker"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} spacing={5} sm={4}>
                      <FormControl
                        fullWidth
                        style={{ padding: "0 40px 16px 0" }}
                      >
                        <TimePicker
                          margin="normal"
                          id="time-picker-closed"
                          label="Closed"
                          variant="standard"
                          disabled={element.closedAllDAy || element.openAllDay}
                          value={element.closed}
                          maxDateMessage={
                            element.maxDateError
                              ? "The closed time must be after the opened time"
                              : ""
                          }
                          onChange={(e) => {
                            handleChange(element.day, "closed", e);
                            /**                          
                           const minTime = moment(element.opened, 'HH:mm');
                          if (minTime.isBefore(e)) {
                            handleChange(element.day, 'closed', e);
                            handleChange(element.day, 'maxDateError', false);
                          } else {
                            handleChange(element.day, 'maxDateError', true);
                          } */
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          className="custom-datepicker-full custom-timepicker"
                        />
                      </FormControl>
                    </Grid>
                    <div className="flex flex-col">
                      <Grid>
                        <FormControl fullWidth>
                          <FormControlLabel
                            className="form-control"
                            control={
                              <Checkbox
                                disabled={element.openAllDay}
                                checked={element.closedAllDAy}
                                onChange={(e) =>
                                  handleChange(
                                    element.day,
                                    "closedAllDAy",
                                    e.target.checked
                                  )
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Closed all day"
                          />
                        </FormControl>
                      </Grid>
                      <Grid>
                        <FormControl fullWidth style={{ marginBottom: "2px" }}>
                          <FormControlLabel
                            className="form-control"
                            control={
                              <Checkbox
                                disabled={element.closedAllDAy}
                                checked={element.openAllDay}
                                onChange={(e) =>
                                  handleChange(
                                    element.day,
                                    "openAllDay",
                                    e.target.checked
                                  )
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Open 24/7"
                          />
                        </FormControl>
                      </Grid>
                    </div>
                  </Grid>
                </>
              ))}
            </Container>
          </div>
          <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-60px] px-8 py-[.9rem] z-10">
            <button
              onClick={() => submitCreation()}
              className="text-white rounded-full border border-[#F2F2F2] flex justify-center items-center h-[28px] p-4 "
            >
              <Typography style={{ marginTop: "0", color: "#F2F2F2" }}>
                Submit
              </Typography>
            </button>
          </div>
        </div>
      </LocalizationProvider>
    </Modal>
  );
}
