import { useState } from "react";
import { getStores } from "../../services/stores";
import StoreRow from "./storeRow";
import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";
import AddStoreModal from "./modal/addStoreModal";
import { useEffect } from "react";
import ProgressBar from "../../components/loader/progressBar";
import ConfirmModal from "./../../components/confirmModal";
import { useSelector } from "react-redux";
import KycError from "../../components/kycError";
export default function Stores() {
  const { auth } = useSelector(store => store.auth)
  const [stores, setStores] = useState([]);
  const [create, setCreate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);

  const fetchStores = () => {
    setLoading(false);
    getStores().then((res) => {
      setStores(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchStores();
  }, []);
  return (
    <div className="">
      {auth.stripe_profiles.account_stripe_KYC_status ? <> <button
        onClick={() => setCreate(true)}
        className="px-5 bg-[#612EF2] text-white rounded-full mt-[43px] ml-4 h-[38px]"
      >
        <Typography>Add new store</Typography>
      </button>
        {loading ? (
          <div className="w-full  flex items-center justify-center">
            <ProgressBar />
          </div>
        ) : (
          <div className="w-full flex items-center overflow-auto mt-[43px]">
            <TableContainer style={{ overflowX: "initial", maxHeight: "100vh" }}>
              <Table stickyHeader size="small" aria-label="simple table">
                <TableHead className="border-gray border-b-2">
                  <TableRow>
                    <TableCell className="table-cell-title table-cell-fix-border">
                      <Typography className="font-normal pb-[10px]">Name</Typography>
                    </TableCell>
                    <TableCell className="table-cell-title table-cell-fix-border">
                      {" "}
                      <Typography className="font-normal pb-[10px]">Online</Typography>
                    </TableCell>
                    <TableCell className="table-cell-title table-cell-fix-border">
                      {" "}
                      <Typography className="font-normal pb-[10px]">Settings</Typography>
                    </TableCell>
                    <TableCell className="table-cell-title table-cell-fix-border">
                      {" "}
                      <Typography className="font-normal pb-[10px]">
                        Open hours
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stores.map((store) => (
                    <StoreRow
                      store={store}
                      stores={stores}
                      reset={() => fetchStores()}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        <AddStoreModal
          open={create}
          onClose={() => setCreate(false)}
          stores={stores}
          reset={() => fetchStores()}
          attemptClose={() => setConfirmModal(true)}
        />
        <ConfirmModal
          open={confirmModal}
          goBack={() => setConfirmModal(false)}
          handleClose={() => {
            setConfirmModal(false);
            setCreate(false);
          }}
        /></> : <KycError profile={auth.stripe_profiles} />}

    </div>
  );
}
