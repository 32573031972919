import { useState } from "react";
import ProductCard from "./productCard";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
} from "@material-tailwind/react";
import { Grid, IconButton } from "@mui/material";
import arrow from "../../assets/images/arrow.svg";

export default function ProductList({
  products,
  selectedProducts,
  selectProduct,
  selectedProduct,
  storeData,
  inCreaseAmount,
  decreaseAmount,
  addExtra,
  removeExtra,
  deselectProduct,
  pickSize,
  query,
  categories,
  selectedVariation,
  pickVariation,
  increaseVariationAmount,
  decreaseVariationAmount,
}) {
  const [open, setOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const selectCategory = (id) => {
    var selected = [...selectedCategories];
    if (selectedCategories.includes(id)) {
      selected.splice(selected.indexOf(id), 1);
    } else {
      selected.push(id);
    }
    setSelectedCategories(selected);
  };
  const isSelectedCategory = (id) => {
    return selectedCategories.includes(id);
  };

  const isCategoryIncluded = (category) => {
    const categoryNames = categories.map((_filteredCategory) => {
      if (selectedCategories.includes(_filteredCategory.id)) {
        return _filteredCategory.name;
      }
    });
    if (selectedCategories.length > 0) {
      return categoryNames.includes(category);
    } else {
      return true;
    }
  };
  const isSelected = (id) => {
    if (selectedProducts.find((item) => item.product_id === id)) return true;
    return false;
  };
  const isFocused = (id) => {
    return selectedProduct?.product_id === id;
  };
  const getAmount = (id) => {
    var product = selectedProducts.find((item) => item.product_id === id);
    return product?.amount;
  };
  const selectedProductData = (id) => {
    var product = selectedProducts.find((item) => item.product_id === id);
    return product;
  };
  return (
    <div className="flex flex-wrap gap-2 my-3">
      {query !== "" && products.length === 0 ? (
        <div className="w-full flex items-center justify-center mt-8">
          <p className="text-[#8492a6]">No products found</p>
        </div>
      ) : (
        <>
          <Accordion open={open}>
            <AccordionHeader
              onClick={() => setOpen(!open)}
              style={{ color: "#272727", width: "98%" }}
              className="accordion-header py-1"
            >
              <div className="flex flex-row items-center">
                <p className="text-base text-start font-normal">Categories</p>{" "}
              </div>
              <IconButton>
                <img
                  src={arrow}
                  style={{
                    transform: `rotate(${open ? 0 : 180}deg)`,
                    transition: "transform 400ms",
                  }}
                />
              </IconButton>
            </AccordionHeader>
            <AccordionBody>
              <Grid
                container
                sm={11}
                md={12}
                lg={12}
                spacing={3}
                style={{ marginLeft: "0px", marginTop: "0px" }}
              >
                {categories.map((category) => (
                  <Grid
                    onClick={() => selectCategory(category.id)}
                    key={category.id}
                    sm={4}
                    md={4}
                    lg={3}
                    style={{
                      paddingRight: "3px",
                      paddingTop: "3px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: !isSelectedCategory(category.id)
                          ? "1px solid lightgray"
                          : `1px solid rgb(97, 46, 242)`,
                        padding: "8px 5px 8px 5px",
                        borderRadius: "12px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        style={{
                          whiteSpace: "normal",
                          width: " 100%",
                          overflow: "hidden",
                          textAlign: "center",
                          color: !isSelectedCategory(category.id)
                            ? "black"
                            : "rgb(97, 46, 242)",
                        }}
                      >
                        {category.name}
                      </Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </AccordionBody>
          </Accordion>
          {products.map((el, idx) => {
            if (isCategoryIncluded(el.category)) {
              return (
                <div className={`w-[48%] gap-2 flex flex-col`}>
                  <ProductCard
                    key={idx + el.product_id}
                    product={el}
                    isSelected={isSelected(el.product_id)}
                    selectProduct={(e) => selectProduct(e)}
                    storeData={storeData}
                    inCreaseAmount={() => inCreaseAmount(el.product_id)}
                    decreaseAmount={() => decreaseAmount(el.product_id)}
                    focused={isFocused(el.product_id)}
                    amount={getAmount(el.product_id)}
                    selectedData={selectedProductData(el.product_id)}
                    addExtra={(extra) => addExtra(el.product_id, extra)}
                    removeExtra={(extra) => removeExtra(el.product_id, extra)}
                    deselectProduct={() => deselectProduct(el.product_id)}
                    pickSize={(size) => pickSize(el.product_id, size)}
                    selectedVariation={() => selectedVariation(el.product_id)}
                    increaseVariationAmount={(variation_id) => {
                      increaseVariationAmount(variation_id, el.product_id);
                    }}
                    decreaseVariationAmount={(variation_id) => {
                      decreaseVariationAmount(variation_id, el.product_id);
                    }}
                    pickVariation={(selected_variaiton) =>
                      pickVariation(selected_variaiton, el.product_id)
                    }
                  />
                </div>
              );
            }
            return null;
          })}
        </>
      )}
    </div>
  );
}
