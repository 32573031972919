import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Typography } from "@material-tailwind/react";
const ckEditorToolbarConfig = [
  "Heading",
  "|",
  "bold",
  "italic",
  "|",
  "BulletedList",
  "NumberedList",
  "|",
  "Indent",
  "|",
  "BlockQuote",
  "undo",
  "redo",
];
export default function Editor({ value, handleChange, placeholder }) {
  return (
    <>
      <Typography style={{ color: "#aaaaaa", marginBottom: "2px", fontWeight: 500 }}>{placeholder}</Typography>
      <CKEditor
        editor={ClassicEditor}
        config={{
          toolbar: ckEditorToolbarConfig,
        }}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleChange(data);
        }}
      />
    </>
  );
}
