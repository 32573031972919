import React, { useState } from "react";

import {
  Button,
  FormControl,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import moment from "moment";

import "react-phone-input-2/lib/material.css";
import "./styles.css";

// const errorMensages = {
//   required: 'This field is required'
// };

const dayNames = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const weekDays = [];

dayNames.map((day) => {
  weekDays.push({
    day: day,
    opened: moment("08:00", "HH:mm"),
    closed: moment("18:00", "HH:mm"),
    maxDateError: false,
    closedAllDAy: false,
  });
});

export default function CreateOpenHours(props) {
  const { handleSave, storeData } = props;

  const [days, setDays] = useState(weekDays);

  const handleChange = (_day, prop, time) => {
    setDays(
      days.map((item) => (item.day === _day ? { ...item, [prop]: time } : item))
    );
  };

  const submit = () => {
    handleSave(days);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <>
      <Grid
        style={{
          // border: '1px solid gray',
          borderRadius: "0.5vw",
          width: "40vw",
          padding: "1vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2vw",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ fontSize: "22px", marginBottom: "12px" }}>
            Set {storeData.name} opening hours
          </Typography>
        </div>

        <Typography
          style={{ fontFamily: "Work Sans, sans-serif", fontSize: "18px" }}
        >
          Set open hours
        </Typography>
        <div style={{}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {days.map((element, idx) => (
              <>
                <Typography
                  style={{
                    fontFamily: "Work Sans, sans-serif",
                    fontSize: "16px",
                  }}
                >
                  {capitalizeFirstLetter(element.day)}
                </Typography>

                <Grid
                  style={{ display: "flex", alignItems: "end" }}
                  container
                  spacing={2}
                >
                  {" "}
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: "15px" }}>
                      <TimePicker
                        value={dayjs(element.opened, "HH:mm")}
                        onChange={(value) =>
                          handleChange(
                            element.day,
                            "opened",
                            value.format("HH:mm")
                          )
                        }
                        disabled={element.closedAllDAy}
                        className="custom-datepicker-full custom-timepicker add-padding-to-input"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: "15px" }}>
                      <TimePicker
                        value={dayjs(element.closed, "HH:mm")}
                        onChange={(value) =>
                          handleChange(
                            element.day,
                            "opened",
                            value.format("HH:mm")
                          )
                        }
                        disabled={element.closedAllDAy}
                        className="custom-datepicker-full custom-timepicker add-padding-to-input"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} spacing={5} sm={4}>
                    <FormControl fullWidth style={{ marginBottom: "15px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={element.closedAllDAy}
                            onChange={(e) =>
                              handleChange(
                                element.day,
                                "closedAllDAy",
                                e.target.checked
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Closed all day"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ))}
          </LocalizationProvider>
          <Typography
            style={{
              fontFamily: "Work Sans, sans-serif",
              fontSize: "12px",
              marginBottom: "1rem",
            }}
          >
            These settings can be changed later.
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={(e) => submit()}
              className="text-white rounded-full border bg-[#612EF2] border-[#612EF2] flex justify-center items-center h-[28px] p-4"
            >
              <Typography style={{ marginTop: "0", color: "#F2F2F2" }}>
                Save and continue
              </Typography>
            </button>
          </div>
        </div>
      </Grid>
    </>
  );
}
