import { Typography } from "@mui/material";

export default function KycError({ profile }) {
    return (
        <div className=" h-screen w-full flex items-center justify-center">
            <div className="w-full md:w-1/2 bg-[#FF5D5D] p-[15px] p-[3rem] rounded-[10px]">
                <Typography style={{ color: "#FFDADA" }}>You need to finish your <a className="underline" target="_blank" href={profile.account_stripe_link}>Stripe configuration</a> to access this page.</Typography>
            </div>
        </div>
    )
}