import Select, { components, DropdownIndicatorProps } from "react-select";
import { useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import search from "./../../assets/images/search.svg";
import DropdownIcon from "../../assets/images/dropdown-icon3.svg";
export default function BottomFilters({
  selectedStatus,
  setStatus,
  query,
  setQuery,
  searchPlaceholder,
  start,
  setStart,
  end,
  setEnd,
  filter,
}) {
  useEffect(() => {
    var separator = document.getElementsByClassName(
      "select__indicator-separator"
    );
    for (var i = 0, len = separator.length; i < len; i++) {
      separator[i].style["display"] = "none";
    }
  }, []);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={DropdownIcon}
          alt="Arrow"
          style={{ width: "12px", marginRight: "0.5rem", marginLeft: "5.5rem" }}
        />
      </components.DropdownIndicator>
    );
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      color: "black",
      borderRadius: "1000px",
      height: "100%",
      border: "1px solid #612EF24D",
      ":focus": {
        border: "1px solid #612EF2",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
        color: "black",
      };
    },
    input: (styles) => ({ ...styles, color: "black" }),
    placeholder: (styles) => ({ ...styles, color: "black" }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#612EF2",
      margin: "2px 10px",
    }),
  };
  return (
    <div className="flex flex-row sm:justify-end py-1 fixed sm:bottom-2 md:bottom-5 sm:right-1 md:right-2 rounded-full sm:mr-1 md:mr-8 gap-1 flex-wrap">
      <Select
        // className={classes.selectInput}
        components={{ DropdownIndicator }}
        classNamePrefix="select"
        value={{ value: selectedStatus, label: selectedStatus }}
        isDisabled={false}
        placeholder="Select a store"
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        menuPlacement="top"
        name="color"
        options={["All status", "Paid", "Open"].map((el) => {
          return { value: el, label: el };
        })}
        onChange={(e) => {
          setStatus(e.value);
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "black",
          },
        })}
        styles={colourStyles}
      />
      <div className="flex flex-row rounded-full">
        <TextField
          value={start}
          label=""
          onChange={(e) => setStart(e.target.value)}
          type="date"
          variant="outlined"
          className="round-input-left"
        />

        <TextField
          value={end}
          label=""
          onChange={(e) => setEnd(e.target.value)}
          type="date"
          variant="outlined"
          className="round-input-right"
        />
      </div>
      <div className="border border-[#612EF24D] flex flex-row px-2 py-1 rounded-full bg-white gap-24">
        <input
          value={query}
          placeholder={searchPlaceholder}
          onChange={(e) => setQuery(e.target.value)}
          className="border-0 focus:border-0 text-[#612EF2] placeholder-[#612EF2] pl-2 outline-none w-[100px]"
        />
        <img src={search} className="mr-2"/>
      </div>
      <div
        className="rounded-full bg-[#612EF2] px-5 py-2 cursor-pointer"
        onClick={() => filter()}
      >
        <Typography className="text-white">Apply filter</Typography>
      </div>
    </div>
  );
}
