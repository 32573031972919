import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import InputBase from '@mui/material/InputBase';
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#612EF2",
  },
  "& label": {
    color: "#7A7A7A",
  },
  "& input": {
    color: "#ECECEC",
  },
  "& .MuiInputBase-root": {
    borderBottom: "1px solid #7A7A7A",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#612EF2",
  },
  "& .MuiSelect-nativeInput": {
    color: "#7A7A7A",
  },
  "& fieldset": {
    color: "#7A7A7A",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#612EF2",
    },
  },
});
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
export default CssTextField;
