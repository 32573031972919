import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
  createTheme,
  MenuItem,
  Select,
} from "@mui/material";
import "react-phone-input-2/lib/material.css";
import flogi from "./../../assets/images/flogi-logo.svg";
import Dropzone from "../../components/dropzone";
import "./styles.css";

export default function StoreContact(props) {
  const {
    userAddress,
    handleSave,
    uniquePhoneError,
    uniqueSlugError,
    uniqueEmailError,
  } = props;

  const [phone, setPhone] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  const [storeEmail, setStoreEmail] = useState("");
  const [storeEmailError, setStoreEmailError] = useState("");

  const [storeName, setStoreName] = useState("");
  const [storeNameError, setStoreNameError] = useState("");

  const [currency, setCurrency] = useState("");
  const [currencyError, setCurrencyError] = useState("");

  const [storeType, setStoreType] = useState("");
  const [storeTypeError, setStoreTypeError] = useState("");

  const [storePrefix, setStorePrefix] = useState("");
  const [storePrefixError, setStorePrefixError] = useState("");

  const [image, setImage] = useState("");

  const [sameContactsAsPersonal, setSameContactsAsPersonal] = useState(false);

  const { auth } = useSelector((state) => state.auth);
  const { network } = useSelector((state) => state.network);

  const generateURL = (file) => {
    if (file) {
      var url = URL.createObjectURL(file);
      return url;
    }
    return "";
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const checkForm = () => {
    let entered = false;
    if (storeEmail === "") {
      entered = true;
      setStoreEmailError("Please insert store email");
    }
    if (!isValidEmail(storeEmail)) {
      entered = true;
      setStoreEmailError("Please insert a valid email");
    }
    if (storeType === "") {
      entered = true;
      setStoreTypeError("Please insert type of store");
    }
    if (phone === "") {
      entered = true;
      setPhoneNumberError("Please insert a valid phone number");
    }
    if (storeName === "") {
      entered = true;
      setStoreNameError("Please insert a name");
    }
    if (storePrefix === "") {
      entered = true;
      setStorePrefixError("Please insert a store prefix");
    }
    if (currency === null) {
      entered = true;
      setCurrencyError("Please select a currency");
    }
    return entered;
  };

  const handlePhone = (event) => {
    setPhone(event.target.value);
    setPhoneNumberError("");
  };

  const handleStoreEmail = (event) => {
    setStoreEmail(event.target.value);
    setStoreEmailError("");
  };

  const handleStoreName = (event) => {
    setStoreName(event.target.value);
    setStoreNameError("");
  };

  const handleStorePrefix = (event) => {
    if (
      event.target.value.length <= 6 &&
      /^[^\W_]*$/.test(event.target.value)
    ) {
      setStorePrefix(event.target.value);
    }
    setStorePrefixError("");
  };

  const handleSameContact = (event) => {
    setSameContactsAsPersonal(event.target.checked);
    if (event.target.checked) {
      setStoreEmail(userAddress.email);
      setPhone(userAddress.phone);
    } else {
      setStoreEmail("");
      setPhone("");
    }
  };
  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };

  const submit = () => {
    if (!checkForm()) {
      handleSave({
        phone_number: phone,
        store_email: storeEmail,
        order_prefix: storePrefix,
        networks: network,
        name: storeName,
        currency: currency,
        image,
        classification: storeType,
      });
    }
  };

  return (
    <div className="h-full">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography className="title-onboarding" style={{ margin: "3rem 0" }}>
          Add store information
        </Typography>
        <div className="flex flex-col justify-center items-center w-full bg-black">
          <div className="flex flex-col justify-center items-center w-[90%] md:w-[30%]">
           {/*
            <FormControl
              className="form-onboarding"
              fullWidth
              style={{ marginBottom: ".8rem" }}
            >
              <FormControlLabel
                className="form-control-onboarding"
                control={
                  <Checkbox
                    className="form-control-onboarding"
                    checked={sameContactsAsPersonal}
                    onChange={handleSameContact}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Same as your contacts?"
              />
            </FormControl> */}
            <FormControl
              className="form-onboarding"
              style={{ marginBottom: ".8rem", width: "100%" }}
              error={storeEmailError !== ""}
            >
              <InputLabel htmlFor="name-input">Store email</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={storeEmail}
                onChange={handleStoreEmail}
              />
              <FormHelperText>
                {storeEmailError
                  ? storeEmailError
                  : uniqueEmailError
                  ? uniqueEmailError
                  : ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              className="form-onboarding"
              style={{ marginBottom: ".8rem", width: "100%" }}
              error={phoneNumberError !== "" || uniquePhoneError}
            >
              <InputLabel htmlFor="phone-input">Store Phone Number</InputLabel>
              <Input
                id="phone-input"
                type="string"
                value={phone}
                onChange={handlePhone}
              />
              <FormHelperText>
                {phoneNumberError
                  ? "This field is required"
                  : uniquePhoneError
                  ? "A store with this phone number already exists."
                  : ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              className="form-onboarding"
              style={{ marginBottom: ".8rem", width: "100%" }}
              error={storeNameError !== ""}
            >
              <InputLabel htmlFor="phone-input">Store name</InputLabel>
              <Input
                id="phone-input"
                type="string"
                value={storeName}
                onChange={handleStoreName}
              />
              <FormHelperText>
                {storeNameError && "This field is required"}
              </FormHelperText>
            </FormControl>
            <FormControl
              className="form-onboarding"
              fullWidth
              style={{ marginBottom: "20px" }}
            >
              <InputLabel htmlFor="type-store-input">Type of store</InputLabel>
              <Select
                variant="standard"
                id="type-store-input"
                value={storeType}
                onChange={(e) => setStoreType(e.target.value)}
                required={true}
                error={storeTypeError !== ""}
                MenuProps={{
                  className: "select-type-store-modal",
                }}
              >
                <MenuItem value={"Food"}>{"Food"}</MenuItem>
                <MenuItem value={"Fitness"}>{"Fitness"}</MenuItem>
                <MenuItem value={"Healthy"}>{"Healthy"}</MenuItem>
                <MenuItem value={"Vegan"}>{"Vegan"}</MenuItem>
                <MenuItem value={"Wine"}>{"Wine"}</MenuItem>
                <MenuItem value={"Supplement"}>{"Supplement"}</MenuItem>
                <MenuItem value={"Furniture"}>{"Furniture"}</MenuItem>
                <MenuItem value={"Grocery"}>{"Grocery"}</MenuItem>
                <MenuItem value={"Dessert"}>{"Dessert"}</MenuItem>
                <MenuItem value={"Clothes"}>{"Clothes"}</MenuItem>
                <MenuItem value={"Electronics"}>{"Electronics"}</MenuItem>
                <MenuItem value={"Coffee"}>{"Coffee"}</MenuItem>
              </Select>
              <FormHelperText error>
                {storeTypeError && "This field is required"}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              className="form-onboarding"
              style={{ marginBottom: ".8rem", width: "100%" }}
            >
              <InputLabel htmlFor="name-input">Currency</InputLabel>
              <Select
                variant="standard"
                id="category-input"
                value={currency}
                onChange={handleCurrency}
              >
                <MenuItem value={"USD"}>{"US Dollar (USD)"}</MenuItem>
                <MenuItem value={"EUR"}>{"Euro (EUR)"}</MenuItem>
                <MenuItem value={"GBP"}>{"British Pound (GBP)"}</MenuItem>
                <MenuItem value={"CHF"}>{"Swiss Franc (CHF)"}</MenuItem>
              </Select>
              <FormHelperText>
                {currencyError && "This field is required"}
              </FormHelperText>
            </FormControl>
            <FormControl
              className="form-onboarding"
              style={{ marginBottom: ".8rem", width: "100%" }}
              error={storePrefixError !== ""}
            >
              <InputLabel htmlFor="name-input">Store prefix</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={storePrefix}
                onChange={handleStorePrefix}
              />
              <FormHelperText>
                {storePrefixError && "This field is required"}
              </FormHelperText>
            </FormControl>

            <Typography className="text-white w-[100%] pt-2 pb-1">
              Store image
            </Typography>
            <div className="w-[100%] flex items-center gap-2 dropzone-onboarding">
              {image ? (
                <div className="flex items-center justify-center h-[8rem]">
                  <img
                    src={generateURL(image)}
                    className="h-full w-[12rem] object-cover"
                    alt=""
                  />
                </div>
              ) : null}
              <Dropzone
                accept={{
                  "image/jpeg": [],
                  "image/png": [],
                }}
                files={image}
                placeholder={"Click or drag and drop an image here"}
                setFiles={(files) => setImage(files[0])}
              />
            </div>
            <button
              onClick={(e) => submit()}
              className="text-white rounded-full border bg-[#612EF2] border-[#612EF2] flex  items-center w-[100%] h-[28px] p-4 my-8"
            >
              <Typography style={{ marginTop: "0", color: "white" }}>
                Save and continue
              </Typography>
            </button>
          </div>
        </div>

        <div className="flex items-center gap-1 justify-center bg-black w-full pb-2">
          <Typography className="text-[#7A7A7A]">Powered by</Typography>
          <img style={{ height: "12px" }} src={flogi} alt="" />
        </div>
      </div>
    </div>
  );
}
