import React from "react";
import {
  InputLabel,
  Input,
  Select,
  MenuItem,
  Container,
  Grid,
  FormControl,
  IconButton,
  FormHelperText,
  Typography,
  Modal,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { notify } from "../../../redux/features/alert";
import { createMenu } from "../../../services/menus";
import Dropzone from "./../../../components/dropzone";
import { Add, Delete, Close } from "@mui/icons-material";

export default function AddMenuModal(props) {
  const { onClose, open, store, categories, refresh } = props;
  const [bannerSource, setBannerSource] = React.useState(undefined);
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [typeOfMenu, setTypeOfMenu] = React.useState("Take Away");
  const [maxOfItems, setMaxOfItems] = React.useState(0);
  const [maxOfSameItem, setMaxOfSameItem] = React.useState(0);
  const [active, setActive] = React.useState(true);
  const [sections, setSections] = React.useState([
    {
      category: "",
      template: "",
      nameEn: "",
      namePt: "",
      id: 0,
    },
  ]);
  const dispatch = useDispatch();

  const handleClose = () => {
    setName("");
    setActive(true);
    setMaxOfItems(0);
    setMaxOfSameItem(0);
    setSections([
      {
        category: "",
        template: "",
        nameEn: "",
        namePt: "",
        id: 0,
      },
    ]);
    //  refresh();
    onClose();
  };

  const handleName = (event) => {
    setName(event.target.value);
    setNameError("");
  };

  const handleTypeOfMenu = (event) => {
    setTypeOfMenu(event.target.value);
  };

  const handleMaxOfItems = (event) => {
    setMaxOfItems(event.target.value);
  };

  const handleMaxOfSameItem = (event) => {
    setMaxOfSameItem(event.target.value);
  };

  const checkForm = () => {
    let entered = false;
    if (name === "") {
      setNameError("Please insert name");
      entered = true;
    }
    return entered;
  };

  const submitCreation = async () => {
    if (!checkForm()) {
      const sectionsToSubmit = [];
      for (let index = 0; index < sections.length; index++) {
        const element = sections[index];
        sectionsToSubmit.push({
          category: element.category,
          template: "product",
          nameEn: element.category,
          namePt: element.category,
        });
      }
      createMenu({
        banner: bannerSource,
        name,
        type_of_menu: typeOfMenu,
        max_quantity_of_items: maxOfItems,
        max_quantity_of_same_item: maxOfSameItem,
        store,
        active,
        sections: JSON.stringify(sectionsToSubmit),
      })
        .then((result) => {
          dispatch(
            notify({
              message: "Menu successfully created",
              severity: "success",
            })
          );
          refresh();
          handleClose();
        })
        .catch((e) => {
          dispatch(
            notify({
              message: e,
              severity: "error",
            })
          );
        });
    }
  };

  const listCategories = categories.map((category, index) => (
    <MenuItem key={index} value={category.name}>
      {category.name}
    </MenuItem>
  ));

  const handleChangeCategory = (index, value) => {
    setSections(
      sections.map((item) =>
        item.id === index
          ? {
              ...item,
              category: value,
            }
          : item
      )
    );
  };

  const handleChangeTemplate = (index, value) => {
    setSections(
      sections.map((item) =>
        item.id === index
          ? {
              ...item,
              template: value,
            }
          : item
      )
    );
  };

  const handleDelete = (id) => {
    const values = [...sections];
    values.splice(id, 1);
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      element.id = index;
    }
    setSections(values);
  };

  const listSections = sections.map((section, index) => (
    <>
      <div className="section-title-wrapper">
        <div className="flex items-center justify-between">
          <h3>Section {index + 1}</h3>
          {sections.length > 1 ? (
            <IconButton
              onClick={() => handleDelete(index)}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "25px",
                width: "25px",
              }}
            >
              <Close
                style={{
                  fill: "#DDDDDD",
                  height: "20px",
                  width: "20px",
                }}
              />{" "}
            </IconButton>
          ) : null}
        </div>
      </div>
      <Grid
        key={index + section.id}
        style={{ marginTop: "10px" }}
        item
        xs={12}
        spacing={5}
        sm={6}
      >
        <FormControl
          className="fix-label"
          fullWidth
          style={{ marginBottom: "15px" }}
        >
          <InputLabel htmlFor="category-input">Category</InputLabel>
          <Select
            variant="standard"
            labelId="label"
            id="select"
            value={section.category}
            onChange={(e) => handleChangeCategory(index, e.target.value)}
          >
            {listCategories}
          </Select>
        </FormControl>
      </Grid>
    </>
  ));
  return (
    <Modal 
      className="flex flex-row h-full  items-center justify-center"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[70%] md:w-[50%]  h-full sm:h-3/4 bg-white overflow-hidden relative">
        <div className="w-full  h-full overflow-y-auto px-10 pt-5 pb-[5.5rem]">
          <Container className="!mx-0 !px-0" spacing={5} maxWidth="md">
            <div className="w-full flex items-center justify-between">
              <Typography variant="p">Add new group</Typography>
              <IconButton
                onClick={() => handleClose()}
                style={{
                  backgroundColor: "#fff",
                  fill: "#fff",
                  border: "1px solid #DDDDDD",
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                }}
              >
                <Close style={{ fill: "#DDDDDD" }} />{" "}
              </IconButton>
            </div>
            <div className="mt-5">
              <Grid item xs={12} spacing={5} sm={6}>
                <FormControl
                  className="fix-label"
                  fullWidth
                  style={{ marginBottom: "15px" }}
                  required
                  error={nameError !== ""}
                  margin={"normal"}
                >
                  <InputLabel htmlFor="name-input">Name</InputLabel>
                  <Input
                    id="name-input"
                    type="string"
                    value={name}
                    onChange={handleName}
                  />
                  <FormHelperText>{nameError}</FormHelperText>
                </FormControl>
                <FormControl
                  className="fix-label"
                  fullWidth
                  style={{ marginBottom: "15px" }}
                  margin={"normal"}
                >
                  <InputLabel htmlFor="price-input">Group Type</InputLabel>
                  <Select
                    variant="standard"
                    labelId="label"
                    id="select"
                    value={typeOfMenu}
                    onChange={handleTypeOfMenu}
                  >
                    <MenuItem value="Take Away">Delivery & Pickup</MenuItem>
                    <MenuItem value="Mesa">Table or Tab</MenuItem>
                  </Select>
                </FormControl>
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: ".75rem",
                  }}
                >
                  <FormControl
                    className="fix-label"
                    style={{ marginBottom: "15px" }}
                    margin={"normal"}
                  >
                    <InputLabel htmlFor="name-input">
                      Maximum for group items
                    </InputLabel>
                    <Input
                      id="name-input"
                      type="number"
                      value={maxOfItems}
                      onChange={handleMaxOfItems}
                    />
                  </FormControl>
                  <FormControl
                    className="fix-label"
                    style={{ marginBottom: "15px" }}
                    margin={"normal"}
                  >
                    <InputLabel htmlFor="name-input">
                      Maximum for the same item
                    </InputLabel>
                    <Input
                      id="name-input"
                      type="number"
                      value={maxOfSameItem}
                      onChange={handleMaxOfSameItem}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {/* End of left column */}
              <div>
                {listSections}
                <IconButton
                  color="primary"
                  onClick={() => {
                    var data = {
                      category: "",
                      template: "",
                      nameEn: "",
                      namePt: "",
                      id: 0,
                    };
                    if (sections.length >= 1) {
                      data.id = sections[sections.length - 1].id + 1;
                    }
                    setSections(sections.concat(data));
                  }}
                >
                  <Add />
                </IconButton>
              </div>
            </div>
          </Container>
        </div>
        <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-60px] px-8 py-[.9rem] z-10">
          <button
            onClick={(e) => submitCreation()}
            className="text-white rounded-full border border-[#F2F2F2] flex justify-center items-center h-[28px] p-4 "
          >
            <Typography>Submit</Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
