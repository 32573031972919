import React from "react";
import {
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  Input,
  InputLabel,
} from "@mui/material";

export default function ThirdTab({
  onlyDelivery,
  handleOnlyDelivery,
  sameDayDelivery,
  handleSameDayDelivery,
  promote,
  handlePromote,
  pricesExcludeVat,
  handlePricesExcludeVat,
  ownDelivery,
  handleOwnDelivery,
  deliveryCost,
  handleDeliveryCost,
  fixedDeliveryCost,
  handleFixedDeliveryCost,
  noFilters,
  setNofilters,
}) {
  return (
    <>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          fullWidth
          style={{ marginBottom: "15px", borderBottom: "1px solid #DDDDDD" }}
        >
          <FormControlLabel
            className="form-control"
            control={
              <Checkbox
                checked={noFilters}
                onChange={(e) => setNofilters(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="No filters banner"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          fullWidth
          style={{ marginBottom: "15px", borderBottom: "1px solid #DDDDDD" }}
        >
          <FormControlLabel
            className="form-control"
            control={
              <Checkbox
                checked={onlyDelivery}
                onChange={(e) => handleOnlyDelivery(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Only delivery"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          fullWidth
          style={{ marginBottom: "15px", borderBottom: "1px solid #DDDDDD" }}
        >
          <FormControlLabel
            className="form-control"
            control={
              <Checkbox
                checked={sameDayDelivery}
                onChange={(e) => handleSameDayDelivery(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Same day delivery"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          fullWidth
          style={{ marginBottom: "15px", borderBottom: "1px solid #DDDDDD" }}
        >
          <FormControlLabel
            className="form-control"
            control={
              <Checkbox
                checked={ownDelivery}
                onChange={(e) => handleOwnDelivery(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Offer own delivery service"
          />
        </FormControl>
      </Grid>
      {ownDelivery && (
        <>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              className="fix-label"
              style={{ marginBottom: "20px" }}
            >
              <InputLabel htmlFor="delivery-cost-input">
                Delivery cost per km
              </InputLabel>
              <Input
                id="delivery-cost-input"
                type="string"
                value={deliveryCost}
                onChange={handleDeliveryCost}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={5} sm={12}>
            <FormControl
              fullWidth
              className="fix-label"
              style={{ marginBottom: "20px" }}
            >
              <InputLabel htmlFor="fixed-delivery-cost-input">
                Fixed delivery cost
              </InputLabel>
              <Input
                id="fixed-delivery-cost-input"
                type="string"
                value={fixedDeliveryCost}
                onChange={handleFixedDeliveryCost}
              />
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          fullWidth
          style={{ marginBottom: "15px", borderBottom: "1px solid #DDDDDD" }}
        >
          <FormControlLabel
            className="form-control"
            control={
              <Checkbox
                checked={pricesExcludeVat}
                onChange={(e) => handlePricesExcludeVat(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Price excludes VAT"
          />
        </FormControl>
      </Grid>
    </>
  );
}
