import React, { useState } from "react";
import whatsapp_icon from "./../../assets/images/whatsapp_icon.svg";

import { useSelector } from "react-redux";
import {
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
  createTheme,
} from "@mui/material";
import "react-phone-input-2/lib/material.css";
import flogi from "./../../assets/images/flogi-logo.svg";
import "./styles.css";
import Editor from "../../components/CKeditor";

export default function WhatsappContact({
  handleSave,
  whatsapp,
  setWhatsapp,
  whatsappError,
  whatsAppMessage,
  setWhatsappMessage,
  whatsAppMessageClosed,
  setWhatsappMessageClosed,
}) {
  const [isAutomaticMessageVisible, setIsAutomaticMessageVisible] =
    useState(false);
  const [isShopClosedMessageVisible, setIsShopClosedMessageVisible] =
    useState(false);

  const handleAutomaticMessageChange = (event) => {
    setIsAutomaticMessageVisible(event.target.checked);
  };
  const handleShopClosedMessageChange = (event) => {
    setIsShopClosedMessageVisible(event.target.checked);
  };

  const submit = () => {
    handleSave();
  };

  return (
    <div className="flex justify-center h-full">
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="flex justify-center items-center w-full gap-4">
          <Typography className="title-onboarding" style={{ margin: "3rem 0" }}>
            Add your whatsapp contact
          </Typography>
          <img src={whatsapp_icon} />
        </div>
        <div className="flex justify-center items-center w-full bg-black">
          <div className=" w-[90%] md:w-[30%] flex flex-col justify-center items-center">
            <FormControl
              className="form-onboarding"
              style={{ marginBottom: ".8rem", width: "75%" }}
              error={whatsappError}
            >
              <InputLabel htmlFor="name">Whatsapp number</InputLabel>
              <Input
                id="name"
                type="number"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
              />
              <FormHelperText>
                {whatsappError && "This field is required"}
              </FormHelperText>
            </FormControl>
          
  
            <button
              onClick={submit}
              className="text-white rounded-full border bg-[#612EF2] border-[#612EF2] flex  items-center w-[75%] h-[28px] p-4 my-8"
            >
              <Typography style={{ marginTop: "0", color: "white" }}>
                Save and continue
              </Typography>
            </button>
          </div>
        </div>

        <div className="flex items-center gap-1 justify-center bg-black w-screen pb-2">
          <Typography className="text-[#7A7A7A]">Powered by</Typography>
          <img style={{ height: "12px" }} src={flogi} alt="" />
        </div>
      </Grid>
    </div>
  );
}
