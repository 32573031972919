import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../redux/features/alert";
import { Button } from "@material-tailwind/react";
import { sendPassword } from "../../services/login";
import { IconButton, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Input from "./../../components/inputs";
import flogi from "./../../assets/images/flogi-logo.svg";

export default function Authentication() {
  const [email, setEmail] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const dispatch = useDispatch();
  const [showPassword, setShowPassWord] = useState(false);
  const [showPassword2, setShowPassWord2] = useState(false);

  const [network, setNetwork] = useState("");
  const [passwordError, SetPasswordError] = useState("");
  const [password2Error, SetPassword2Error] = useState("");
  const [forgotPassword, setForgotPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const indexStartUid = window.location.href.indexOf("=") + 1;
  const indexFinalUid = window.location.href.indexOf("&");

  const uid = window.location.href.slice(indexStartUid, indexFinalUid);

  const indexStartToken = window.location.href.indexOf("&") + 7;

  const token = window.location.href.slice(indexStartToken);

  const { auth } = useSelector((state) => state.auth);
  /*
  useEffect(() => {
    if (auth.authorized) {
      window.location.pathname = "dashboard/orders";
    }
  }, []);
  */
  const checkForm = () => {
    var entered = false;
    if (!password) {
      SetPasswordError("This field is required");
      entered = true;
    }
    if (!password2) {
      SetPassword2Error("This field is required");
      entered = true;
    }
    if (password !== password2) {
      SetPassword2Error("The passwords don't match");
      entered = true;
    }
    return entered;
  };

  const submitPassword = async (e) => {
    e.preventDefault();
    if (!checkForm()) {
      try {
        await sendPassword(uid, token, password, password2)
          .then(() => {
            dispatch(
              notify({
                message: "Your password has been reset",
                severity: "success",
              })
            );
            setTimeout(() => {
              window.location = "/";
            }, 2000);
          })
          .catch((e) => {
            dispatch(
              notify({
                message: e ? e : "Something went wrong",
                severity: "error",
              })
            );
          });
      } catch {
        dispatch(
          notify({
            message: "Something went wrong",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        notify({
          message: "Please check the fields below",
          severity: "error",
        })
      );
    }
  };

  return (
    <div className="w-full bg-black  pt-12 min-h-[100vh] flex justify-center">
      <div className="flex items-center w-full  sm:w-auto md:w-1/3 lg:w-1/4 justify-center mt-12 flex-col gap-3 px-5">
        <div className="items-start flex w-full">
          <Typography className="text-white">
            Please insert your new password
          </Typography>
        </div>
        <Input
          variant="standard"
          fullWidth
          name="password"
          required
          value={password}
          error={passwordError && !password}
          label="Password"
          id="password"
          type={showPassword ? "text" : "password"}
          icon={
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => setShowPassWord(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <Input
          variant="standard"
          fullWidth
          name="password"
          required
          value={password2}
          error={password2Error && !password2}
          label="Confirm your password"
          id="password"
          type={showPassword2 ? "text" : "password"}
          icon={
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => setShowPassWord2(!showPassword2)}
            >
              {showPassword2 ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
          onChange={(e) => setPassword2(e.target.value)}
          autoComplete="current-password"
        />
        {password2Error && password !== password2 ? (
          <Typography style={{ color: "#FF5D5D" }}>{password2Error}</Typography>
        ) : null}
        <Button
          className="w-full bg-[#612EF2] rounded-full text-start normal-case py-2 mt-5 shadow-grey hover:shadow-grey"
          disabled={password !== "" && password2 !== "" ? false : true}
          onClick={(e) => submitPassword(e)}
        >
          Submit
        </Button>
      </div>
      <div className="absolute left-0 right-0 bottom-5 flex items-center gap-1 justify-center">
        <Typography className="text-[#7A7A7A]">Powered by</Typography>
        <img style={{ height: "12px" }} src={flogi} alt="" />
      </div>
    </div>
  );
}
