import search from "./../../assets/images/search.svg";
import { useEffect, useState } from "react";
import { Typography, IconButton, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarIcon from "./../../assets/icons/CalendarIcon.svg";

export default function CompletedOrdersFilter({
  query,
  setQuery,
  searchPlaceholder,
  start,
  setStart,
  end,
  setEnd,
  filter,
}) {
  useEffect(() => {
    var separator = document.getElementsByClassName(
      "select__indicator-separator"
    );
    if (separator[0]) {
      separator[0].style["display"] = "none";
    }
  }, []);

  return (
    <div className="flex flex-row sm:px-1 md:px-2 py-1 fixed bottom-5 right-0 gap-1 sm:mr-1 md:mr-8 sm:max-w-[25rem] md:max-w-[50rem] flex-wrap justify-end">
      <div className="flex flex-row rounded-full">
        <TextField
          value={start}
          label=""
          onChange={(e) => setStart(e.target.value)}
          type="date"
          variant="outlined"
          className="round-input-left"
        />

        <TextField
          value={end}
          label=""
          onChange={(e) => setEnd(e.target.value)}
          type="date"
          variant="outlined"
          className="round-input-right"
        />
      </div>
      <div className="border border-[#612EF24D] flex flex-row px-2 py-1 rounded-full bg-white gap-24">
        <input
          value={query}
          placeholder={searchPlaceholder}
          onChange={(e) => setQuery(e.target.value)}
          className="border-0 focus:border-0 text-[#612EF2] placeholder-[#612EF2] pl-2 outline-none w-[100px]"
        />
        <img src={search} className="mr-2"/>
      </div>
      <div
        className="rounded-full bg-[#612EF2] px-5 py-2 cursor-pointer"
        onClick={() => filter()}
      >
        <Typography className="text-white">Apply filter</Typography>
      </div>
    </div>
  );
}
