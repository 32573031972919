import { fetch as api } from "./../utils/request";
import { mountQueryURL } from "../utils/query";
import { NETWORK } from "./../settings";
import moment from "moment";

export const fetchAnalytics = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const query = mountQueryURL(params);
      const response = await api({
        method: "GET",
        url: `/calculations/calculate_all/${query}`,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchAnalyticsDateRange = (initial, end, store) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api({
        method: "GET",
        url: `/calculations/calculate_all/?starts=${initial}&ends=${end}&store=${store}&network=${NETWORK}`,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
export const fetchSellersCommissions = (starts, ends) => {
  return new Promise(async (resolve, reject) => {
    try {
      starts = new Date(starts).toISOString().split("T")[0];
      ends = new Date(ends).toISOString().split("T")[0];
      const response = await api({
        method: "GET",
        url: `waiter/api/waiter/get_order_values_for_waiters_between_dates/?starts=${starts}&ends=${ends}&network=${NETWORK}`,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
