import { IconButton, Typography, TableRow, TableCell } from "@mui/material";
import CloseIconSvg from "../../../assets/icons/close_icon.svg";
import { Close } from "@mui/icons-material";


import { useState } from "react";
import DeleteExtraModal from "./deleteExtraModal";
import EditExtraModal from "./editExtraModal";
export default function ExtraRow({ extra, refresh }) {
  const [deleteExtra, setDeleteExtra] = useState(false);
  const [editExtra, setEditExtra] = useState(false);

  return (
    <TableRow className="py-4 border-gray border-b-2 table-cell-fix-border">
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
          width: "350px",
        }}
        className="table-cell text-[#272727]"
      >
        <Typography>{extra.name}</Typography>
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
        className="sm:w-1/7 text-[#272727] "
      >
        <Typography>{extra.stock}</Typography>
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
      >
        <Typography className="text-[#272727]"> {extra.measure}</Typography>
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
        className="table-cell "
      >
        <div className="flex items-center justify-end w-full gap-3 pr-24">
          <button
            onClick={() => setEditExtra(true)}
            className="bg-white mr-3 px-5 rounded-full h-fit text-black border border-[#DDDDDD]"
          >
            <Typography className="table-cell text-[#272727]">Edit</Typography>
          </button>
          <IconButton
            style={{
              backgroundColor: "#fff",
              fill: "#fff",
              border: "1px solid #DDDDDD",
              borderRadius: "50%",
              height: "30px",
              width: "30px",
            }}
            onClick={() => setDeleteExtra(true)}
          >
            <Close style={{ fill: "#DDDDDD" }} />{" "}
          </IconButton>
        </div>
      </TableCell>
      <DeleteExtraModal
        extra={extra}
        open={deleteExtra}
        handleClose={() => setDeleteExtra(false)}
        refresh={() => refresh()}
      />
      <EditExtraModal
        extra={extra}
        open={editExtra}
        onClose={() => setEditExtra(false)}
        refresh={() => refresh()}
      />
    </TableRow>
  );
}
