import { fetch as api } from "./../utils/request";
import { mountQueryURL } from "../utils/query";

export const getMenus = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query_url;
      let url;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";
      url = `products/api/menus/${query_url}`;
      let response;
      let results = [];
      do {
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      resolve(response.results);
    } catch (error) {
      reject(error);
    }
  });
};

export const createMenu = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response;
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === "banner") {
          if (data[key]) {
            body.append(key, data[key]);
          } else {
            return null;
          }
        } else {
          body.append(key, data[key]);
        }
      });
      response = await api({
        method: "POST",
        headers: { "content-type": "multipart/form-data" },
        url: "products/api/menus/",
        data: body,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editMenu = (menu_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response;
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === "banner") {
          if (data[key]) {
            body.append(key, data[key]);
          } else {
            return null;
          }
        } else {
          body.append(key, data[key]);
        }
      });
      response = await api({
        method: "PATCH",
        headers: { "content-type": "multipart/form-data" },
        url: `products/api/menus/${menu_id}/`,
        data: body,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
export const activateMenu = (menu_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response;
      response = await api({
        method: "POST",
        url: `products/api/menus/${menu_id}/handle_activation/`,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteMenu = (menu_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "DELETE",
        url: `products/api/menus/${menu_id}/`,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
