import React, { useEffect, useState } from "react";
import { Typography, IconButton, Modal, Container } from "@mui/material";
import { NETWORK } from "./../../settings";
import { getOrderItems } from "../../services/orders";
import { Close } from "@mui/icons-material";
export default function OrderDetails(props) {
  const { open, handleClose, order_id, type_of_order } = props;
  const [data, setData] = useState([]);
  const getData = async () => {
    try {
      getOrderItems(order_id).then((res) => {
        setData(res);
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  const onClose = () => {
    setData([]);
    handleClose();
  };
  useEffect(() => {
    if (order_id) getData();
  }, [order_id]);
  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center"
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[50%] md:w-[30%]  h-auto  bg-white p-5 overflow-y-auto overflow-x-hidden max-h-[80vh] relative">
        <div className="w-full  h-auto   overflow-y-auto">
          <div className="w-full flex flex-row items-center justify-between ">
            <Typography variant="p">
              {" "}
              #{type_of_order}
              {order_id} products
            </Typography>
            <IconButton
              onClick={() => onClose()}
              style={{ backgroundColor: "#fff", fill: "#fff", border:"1px solid #DDDDDD", borderRadius:"50%", height:"30px", width:"30px" }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <Container className="mb-12 mt-5 !mx-0 !px-0" maxWidth="md">
            <div style={{ marginTop: "15px" }}>
              {data.map((el, i) => (
                <div style={{ marginBottom: "10px" }} key={"order item " + i}>
                  <div>
                    <img
                      src={el.product.image}
                      style={{
                        height: "75px",
                        width: "75px",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <div>
                      <Typography>
                        {el.quantity}x {el.product.name}
                      </Typography>
                      {NETWORK === "6" && (
                        <Typography>{el?.size?.portion}</Typography>
                      )}
                    </div>
                    <Typography>
                      {" "}
                      <b>
                        {el.item_price?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "GBP",
                        })}
                      </b>
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </Container>
          {/*      <div className="w-0 sm:w-2/3" onClick={() => onClose()} />
           */}{" "}
        </div>
        <div className="flex justify-end absolute bg-white w-full ml-[0px] mt-[-30px] px-8 pb-3">
          <button
            onClick={(e) => onClose()}
            className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
          >
            <Typography>Close</Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
