import OrderCard from "./orderCard";
export default function OrderRow({
  status,
  orders,
  storeData,
  filterAbandoned,
}) {
  return (
    <div
      id={"order-row"}
      className="w-1/5 bg-gray min-w-[200px] w-[250px] md:w-1/5"
    >
      <div
        className={`border-r-gray h-full border-r-${
          status === "Delivered" ? "0" : "2"
        } border-l-0`}
      >
        {orders.map((order) => {
          if (filterAbandoned) {
            if (order.payment === "none") {
              return (
                <OrderCard
                  order={order}
                  status={status}
                  storeData={storeData}
                />
              );
            }
            return null;
          } else {
            return (
              <OrderCard order={order} status={status} storeData={storeData} />
            );
          }
        })}
        {orders.length < 1 ? <div className="w-[145px]" /> : null}
      </div>
    </div>
  );
}
