import React from "react";
import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";
import {
  CheckBoxOutlineBlank,
  CheckBox,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    width: "100%",
  },
  tableContainer: {
    padding: 0,
  },
});

export default function RelatedProductsTab({
  products,
  selectedProducts,
  pushRemove,
  handleProductOnPress,
  removedProducts,
  deleteRemove,
}) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ borderBottom: "1px solid #eeee" }}>
            <TableCell
              style={{
                padding: "0 5px 15px 5px ",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #eeee",
              }}
            >
              <div className="h-[24px]" />
            </TableCell>
            <TableCell
              style={{
                padding: "0 5px 15px 5px",
                borderBottom: "1px solid #eeee",
              }}
              className="table-cell-title"
            >
              Product Name
            </TableCell>
            <TableCell
              style={{
                padding: "0 5px 15px 5px",
                borderBottom: "1px solid #eeee",
              }}
              className="table-cell-title"
            >
              Category
            </TableCell>
            <TableCell
              style={{
                padding: "0 5px 15px 5px ",
                borderBottom: "1px solid #eeee",
              }}
              className="table-cell-title"
            >
              Price
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {products?.length > 0 ? (
            <>
              {products?.map((_product) => (
                <TableRow key={_product.product_id} hover>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      padding: "10px 5px 15px 5px",
                      width: "5%",
                      borderBottom: "1px solid #eeee",
                    }}
                    className="table-cell"
                  >
                    {selectedProducts.some((related) => {
                      return related?.product_id === _product?.product_id;
                    }) ? (
                      <CheckBox
                        onClick={() => {
                          pushRemove(_product.product_id);
                          handleProductOnPress(_product);
                        }}
                      />
                    ) : (
                      <CheckBoxOutlineBlank
                        onClick={() => {
                          if (removedProducts.includes(_product.product_id)) {
                            deleteRemove(_product.product_id);
                          }
                          handleProductOnPress(_product);
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "10px 5px 15px 5px",
                      borderBottom: "1px solid #eeee",
                    }}
                    className="table-cell"
                  >
                    {_product.name}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "10px 5px 15px 5px",
                      borderBottom: "1px solid #eeee",
                    }}
                    className="table-cell"
                  >
                    {_product.category}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "10px 5px 15px 5px",
                      borderBottom: "1px solid #eeee",
                    }}
                    className="table-cell"
                  >
                    {_product.price}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
