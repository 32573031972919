import { useDispatch } from "react-redux";
import { logout } from "./../../redux/features/auth";
import { useEffect } from "react";
export default function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
    window.location.pathname = "/";
  }, []);
  return <></>;
}
