import { Typography } from "@mui/material";

export default function Card({ value, title, type, count, currency, color }) {
  return (
    <div
      style={{ backgroundColor: color.secondary }}
      className={`px-4 py-4 w-[47%] h-60 rounded-2xl relative mx-1 md:w-1/5 sm:w-[48%] my-1`}
    >
      <div className="absolute top-4 right-4 left-4">
        <Typography style={{ color: "#3F3F3F" }}>{title}</Typography>
        {typeof count === "number" ? (
          <Typography style={{ color: color.primary }}>
            {count} orders
          </Typography>
        ) : null}
      </div>
      <div className="h-full flex flex-col items-center justify-center ">
        <Typography
          variant="h4" fontFamily="Inter !important"
          style={{
            color: title === "Collected in store" ? "#3F3F3F" : color.primary,
          }}
        >
          {value}
        </Typography>
        <Typography
          style={{
            color: title === "Collected in store" ? "#3F3F3F" : color.primary,
          }}
        >
          {currency}
        </Typography>
      </div>
    </div>
  );
}
