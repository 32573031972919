import { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, FormHelperText, Select, MenuItem, InputLabel } from "@mui/material";
import { Typography } from "@material-tailwind/react";
import { addTrackingNumber } from "../../services/orders";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/alert";

export default function TrackinNumberModal({ open, onClose, order }) {
    const dispatch = useDispatch();
    const [tracking, setTracking] = useState("")
    const [selectedCompany, setSelectedCompany] = useState("")
    const companies = ['OTHER', 'DHL', 'HERMESDE', 'UPS', 'FEDEX', 'ROYALMAIL']
    const [error, setError] = useState(false)
    const [companyError, setCompanyError] = useState(false)
    const handleClose = () => {
        setTracking("")
        setSelectedCompany("")
        onClose();
    };
    useEffect(() => {
        if (open && order.delivery_tracking_number) {
            setTracking(order.delivery_tracking_number)
        }
        if (open && order.delivery_company) {
            setSelectedCompany(order.delivery_company)
        }
    }, [open])
    const submit = async () => {
        try {
            if (tracking === "") {
                setError(true)
                return
            }
            if (selectedCompany === "") {
                setCompanyError(true)
                return
            }
            addTrackingNumber(order.order_id, { delivery_tracking_number: tracking, delivery_company: selectedCompany }).then(
                () => {
                    //generateOrderPDF({ ...order, delivery_tracking_number: tracking, delivery_company: selectedCompany })
                    dispatch(notify({ message: "Tracking number successfully added", severity: "success" }))
                    handleClose();
                }
            ).catch(e => {
                dispatch(notify({ message: "SOmething went wrong", severity: "error" }))

            })
        } catch (error) {

        }
    };
    return (
        <Dialog open={open} handler={handleClose} onClose={handleClose} PaperProps={{ style: { borderRadius: '0', width: '18rem' } }}>
            <DialogTitle>
                <Typography className="font-medium pt-2 text-[#272727]">
                    Add tracking number to order {order?.order_code}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-4">
                    <FormControl className="fix-label"
                        error={error && tracking === ""} fullWidth>
                        <InputLabel htmlFor="stock-input">Tracking number</InputLabel>
                        <Input
                            value={tracking}
                            onChange={(e) => setTracking(e.target.value)}
                            placeholder='Tracking number'
                        />
                        {error && tracking === "" && <FormHelperText>The tracking number is required</FormHelperText>}

                    </FormControl>
                    <FormControl className="fix-label"
                        error={error && tracking === ""} fullWidth>
                        <InputLabel htmlFor="stock-input">Delivery company</InputLabel>

                        <Select
                            labelId="label"
                            label="Delivery company"
                            id="select-template"
                            value={selectedCompany}
                            variant="standard"
                            onChange={(e) => setSelectedCompany(e.target.value)}
                        >
                            {companies.map((element) => {
                                return (
                                    <MenuItem key={element} value={element}>
                                        {element}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {companyError && selectedCompany === "" && <FormHelperText>The delivery company is required</FormHelperText>}

                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-between", padding: '16px 24px 24px 24px' }}>
                <button
                    className="bg-[#DDDDDD] rounded-[40px] w-20 py-1.5 px-4"
                    onClick={handleClose}
                >
                    <Typography style={{ color: "#FFFFFF" }}>Cancel</Typography>
                </button>
                <button
                    className="bg-[#612EF2] rounded-[40px] w-20 py-1.5 px-4"
                    onClick={submit}
                >
                    <Typography style={{ color: "#FFFFFF" }}>Save</Typography>
                </button>
            </DialogActions>
        </Dialog>
    );
}
