import {
  IconButton,
  Typography,
  Collapse,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import format from "../../../utils/formatValues";
import ModalEditProduct from "./modal/modalEditProduct";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { WEB_URL, NETWORK } from "../../../settings";
import { notify } from "../../../redux/features/alert";
import DeleteProductModal from "./modal/deleteProductModal";
import RelatedProductsModal from "./modal/relatedProductsModal";
import QrCodeModal from "./modal/qrCodeModal";
import SwitchButton from "./../../../components/switchButton";
import {
  handleOnline,
  getQrCode,
  duplicateProduct,
} from "../../../services/products";
import arrow from "./../../../assets/images/arrow.svg";
import ConfirmModal from "./../../../components/confirmModal";
import { Close, FileCopy, Add } from "@mui/icons-material";
import { QrCode } from "@mui/icons-material";
export default function ProductRow({
  product,
  products,
  categories,
  ingredients,
  store,
  refresh,
  artists,
  storeSlug,
  getCategories,
  loading,
  setLoading,
  storeUrl,
}) {
  const [editProduct, setEditProduct] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [qrCodeSrc, setQrCodeSrc] = useState("");
  const dispatch = useDispatch();
  const copyURL = (slug) => {
    switch (NETWORK) {
      case "4":
        return `${storeUrl}/shop/${storeSlug}/${slug}`;
      default:
        return storeUrl + `?product=${slug}`;
    }
  };
  const openQrCode = () => {
    getQrCode(product.product_id, store)
      .then((res) => {
        setQrCodeSrc(res.link);
      })
      .catch((e) => {
        setQrCodeSrc("");
      });
  };

  const handleCopyIcon = () => {
    setLoading(true);
    duplicateProduct(product.product_id, store)
      .then((res) => {
        refresh();
        dispatch(
          notify({
            message: "Product successfully duplicated",
            severity: "success",
          })
        );
      })
      .catch((e) => {
        dispatch(
          notify({
            message: e,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const productPrice = () => {
    if (product.variation.length > 0) {
      return product.variation[0].price;
    }
    return product.price;
  };
  var nameColumn = document.getElementById(`product-name-${product.product_id}`)?.getBoundingClientRect()?.width - 55 ?? "auto"
  var switchColumn = document.getElementById(`product-switch-${product.product_id}`)?.getBoundingClientRect()?.width ?? "auto"
  var relatedColumn = document.getElementById(`product-related-${product.product_id}`)?.getBoundingClientRect()?.width ?? "auto"
  var stockColumn = document.getElementById(`product-stock-${product.product_id}`)?.getBoundingClientRect()?.width ?? "auto"
  return (
    <>
      <TableRow className="py-4 table-cell-fix-border">
        <TableCell
          id={`product-name-${product.product_id}`}
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="table-cell min-w-[150px] md:min-w-[350px]"
        >
          <div className="flex items-center gap-2">
            {product.variation.length > 0 ? (
              <button onClick={() => setOpen(!open)}>
                <img
                  src={arrow}
                  style={{
                    transform: `rotate(${open ? 0 : 180}deg)`,
                    transition: "transform 400ms",
                  }}
                />
              </button>
            ) : (
              <div className="h-[24px] w-[24px]" />
            )}
            <Typography className="table-cell text-[#272727] pl-[12px]">
              {product.name}
            </Typography>
          </div>
        </TableCell>
        <TableCell
          id={`product-switch-${product.product_id}`}
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[120px]"
        >
          <SwitchButton
            id={"product row" + product.product_id}
            _checked={product.active}
            handleClick={(id, checked) => {
              handleOnline(product.product_id, checked);
            }}
          />
        </TableCell>
        <TableCell
          id={`product-related-${product.product_id}`}
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[115px]"
        >
          <IconButton onClick={() => setRelatedProducts(true)}>
            <Add />
          </IconButton>
        </TableCell>
        <TableCell
          id={`product-stock-${product.product_id}`}
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[115px]"
        >
          <Typography className="table-cell text-[#272727]">
            {product.stock}
          </Typography>
        </TableCell>
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="table-cell sm:w-1/7  md:w-[120px]"
        >
          <Typography className="table-cell text-[#272727]">
            {" "}
            {productPrice() ? format(productPrice()) : 0}
          </Typography>
        </TableCell>
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="table-cell sm:w-1/7  md:w-[115px]"
        >
          <Typography className="table-cell text-[#272727]">
            {" "}
            {product.sold}
          </Typography>
        </TableCell>
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="table-cell sm:w-1/7  md:w-[115px] "
        >
          <Typography className="table-cell text-[#272727]">
            {" "}
            {product.reserved}
          </Typography>
        </TableCell>

        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="w-min"
        >
          <div className="flex items-center justify-end w-full gap-2 pr-24 align-top">
            <button
              onClick={() => setEditProduct(true)}
              className="bg-white mr-3 px-5 rounded-full h-fit text-black border border-[#DDDDDD]"
            >
              <Typography className="table-cell text-[#272727]">
                Edit
              </Typography>
            </button>
            <button
              onClick={() => {
                navigator.clipboard.writeText(copyURL(product.slug));
                dispatch(
                  notify({
                    message: "Product URL copied to clipboard",
                    severity: "success",
                  })
                );
              }}
              className="bg-white mr-3 px-5 rounded-full h-fit text-black border border-[#DDDDDD]"
            >
              <Typography className="table-cell text-[#272727]">
                Share
              </Typography>
            </button>
            <IconButton
              style={{
                height: "30px",
                width: "30px",
              }}
              onClick={() => openQrCode()}
            >
              <QrCode style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
            <Tooltip title="Copy product">
              <IconButton
                style={{
                  height: "30px",
                  width: "30px",
                }}
                onClick={() => handleCopyIcon()}
              >
                <FileCopy style={{ fill: "#DDDDDD" }} />{" "}
              </IconButton>
            </Tooltip>
            <IconButton
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
              onClick={() => setDeleteProduct(true)}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
        </TableCell>
        <DeleteProductModal
          product={product}
          open={deleteProduct}
          handleClose={() => setDeleteProduct(false)}
          refresh={() => refresh()}
        />
        <QrCodeModal
          open={qrCodeSrc !== ""}
          handleClose={() => setQrCodeSrc("")}
          qrCode={qrCodeSrc}
        />
        <RelatedProductsModal
          product={product}
          open={relatedProducts}
          onClose={() => setRelatedProducts(false)}
          reset={() => refresh()}
          products={products}
        />
        <ModalEditProduct
          artists={artists}
          open={editProduct}
          handleClose={() => setEditProduct(false)}
          attemptClose={() => setConfirmModal(true)}
          categories={categories}
          ingredients={ingredients}
          products={products}
          store={store}
          refresh={() => refresh()}
          product={product}
          getCategories={() => getCategories()}
        />
        <ConfirmModal
          open={confirmModal}
          goBack={() => setConfirmModal(false)}
          handleClose={() => {
            setConfirmModal(false);
            setEditProduct(false);
          }}
        />
      </TableRow>
      <TableRow style={{ display: open ? "" : "none" }}>
        <TableCell
          className="fix-mui-table !border-b-0 px-0"
          colSpan={8}
          style={{ paddingLeft: "0", paddingBottom: "0" }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table className="ml-[3.5rem]">
              <TableHead>
                <TableCell
                  className="table-cell table-cell-title"
                  style={{ padding: "10px 16px 16px 0px" }}
                >
                  Variation name
                </TableCell>
                <TableCell className="table-cell-title">Price</TableCell>
                <TableCell className="table-cell-title">Stock</TableCell>
                <TableCell className="table-cell-title">SKU</TableCell>
                <TableCell className="table-cell-title">Bar code</TableCell>
              </TableHead>
              <TableBody>
                {product.variation.map((variation) => (
                  <TableRow
                    className="table-cell-fix-border"
                    key={variation.id + "-variation"}
                  >
                    <TableCell
                      style={{
                        padding: "10px 16px 16px 0px",
                        width: `${nameColumn}px`,
                      }}
                      align="left"
                    >
                      <Typography className="table-cell">
                        {variation.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 16px 16px 16px",
                        width: `${switchColumn}px`,
                      }}
                      align="left"
                    >
                      <Typography className="table-cell">
                        {variation.price}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 16px 16px 16px",
                        width: `${relatedColumn}px`,
                      }}
                      align="left"
                    >
                      <Typography className="table-cell">
                        {variation.stock}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 16px 16px 16px",
                        width: `${stockColumn}px`,
                      }}
                      align="left"
                    >
                      <Typography className="table-cell">
                        {variation.sku}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "10px 16px 16px 16px",
                      }}
                      align="left"
                    >
                      <Typography className="table-cell">
                        {variation.barcode}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
