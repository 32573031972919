import React, { useEffect } from "react";
import {
  InputLabel,
  Input,
  // Select,
  // MenuItem,
  Container,
  Grid,
  FormControl,
  FormHelperText,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import { Button } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { editIngredient } from "./../../../services/ingredients";
import { Close } from "@mui/icons-material";
import { notify } from "../../../redux/features/alert";
export default function ModalEditExtra(props) {
  const { onClose, open, refresh, extra } = props;
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [quantity, setQuantity] = React.useState(0);
  const [quantityError, setQuantityError] = React.useState("");
  const [measure, setMeasure] = React.useState("Unit");
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const fillData = () => {
    setName(extra.name);
    setQuantity(extra.quantity);
    setMeasure(extra.measure);
  };
  useEffect(() => {
    fillData();
  }, []);
  const handleClose = () => {
    setName("");
    setNameError("");
    setMeasure("Unit");
    setQuantity(0);
    setQuantityError("");
    refresh();
    // setCost(0);
    onClose();
  };

  const handleName = (event) => {
    setName(event.target.value);
    setNameError("");
  };
  // const handleCost = (event: any) => {
  //   setCost(event.target.value);
  // };
  const handleQuantity = (event) => {
    setQuantity(event.target.value);
    setQuantityError("");
  };
  const handleMeasure = (event) => {
    setMeasure(event.target.value);
  };

  const checkForm = () => {
    let entered = false;

    if (name === "") {
      setNameError("Please insert a name");
      entered = true;
    }

    if (quantity <= 0) {
      setQuantityError("Please insert a valid stock");
      entered = true;
    }

    return entered;
  };

  const submitCreation = async () => {
    if (!checkForm()) {
      setLoading(true);
      editIngredient(
        {
          name,
          measure,
          quantity,
        },
        extra.ingredient_id
      )
        .then(() => {
          setLoading(false);
          dispatch(
            notify({
              message: "Extra successfully edited",
              severity: "success",
            })
          );
          handleClose();
        })
        .catch((e) => {
          setLoading(false);
          dispatch(notify({ message: e, severity: "error" }));
        });
    } else {
      dispatch(
        notify({ message: "Please check the errors below", severity: "error" })
      );
    }
  };

  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[70%] md:w-[50%]  h-auto  bg-white py-5 overflow-hidden relative">
        <div className="w-full  h-auto overflow-y-auto px-10">
          <div className="w-full flex flex-row items-center justify-between ">
            <Typography variant="p">Edit extra</Typography>
            <IconButton
              onClick={() => handleClose()}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <Container className="mb-8 mt-5 !px-0 !mx-0" maxWidth="md">
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl
                className="fix-label"
                fullWidth
                style={{ marginBottom: "15px" }}
                required
                error={nameError !== ""}
              >
                <InputLabel htmlFor="name-input">Name</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={name}
                  onChange={handleName}
                />
                <FormHelperText>{nameError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl
                className="fix-label"
                fullWidth
                style={{ marginBottom: "15px" }}
                required
                error={quantityError !== ""}
              >
                <InputLabel htmlFor="name-input">Stock</InputLabel>
                <Input
                  id="name-input"
                  type="number"
                  value={quantity}
                  onChange={handleQuantity}
                />
                <FormHelperText>{quantityError}</FormHelperText>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} spacing={5} sm={12}>
            <FormControl fullWidth style={{ marginBottom: '15px' }}>
              <InputLabel htmlFor="name-input">Cost</InputLabel>
              <Input
                id="name-input"
                type="number"
                value={cost}
                onChange={handleCost}
              />
            </FormControl>
          </Grid> */}
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl
                className="fix-label"
                fullWidth
                style={{ marginBottom: "40px" }}
              >
                <InputLabel htmlFor="name-input">Measure</InputLabel>
                <Input
                  id="name-input"
                  value="Unit"
                  disabled={true}
                  onChange={handleMeasure}
                />
              </FormControl>
            </Grid>
          </Container>
          {/*      <div className="w-0 sm:w-2/3" onClick={() => onClose()} />
           */}{" "}
        </div>
        <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-45px] px-8 py-[.9rem] z-10">
          <button
            onClick={(e) => submitCreation()}
            disabled={loading}
            className="text-white rounded-full border border-[#F2F2F2] flex justify-center items-center h-[28px] p-4 disabled:opacity-50"
          >
            <Typography>Submit</Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
