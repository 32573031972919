import format from "./../../utils/formatValues";
import {
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { Close, Print } from "@mui/icons-material";
import { Button } from "@material-tailwind/react";
import Receipt from "../../components/receipt";

export default function Summary({
  items,
  delivery_fee,
  delivery_fee_calculated,
  calculateDeliveryFee,
  submit,
  typeOfOrder,
  orderComplete,
  reset,
  orderData,
  storeData,
  deliveryFee,
  paymentMethod,
  isPaid,
  giftcard,
  setGiftCard,
  giftCardError,
  giftcardValue,
  checkGiftcard,
  editOrderView,
  getStoreURL
}) {
  const minimumOrderValue = deliveryFee && deliveryFee["min-value"];

  const total = () => {
    var total = 0;
    items.map((product) => {
      total += itemTotal(product);
    });
    if (typeOfOrder === "Delivery" && deliveryFee) {
      total += deliveryFee.delivery_price;
    }
    return total - giftcardValue <= 0 ? 0 : total - giftcardValue;
  };

  const itemTotal = (item) => {
    if (item.selected_variation && item.variation.length > 0) {
      var sum = 0;
      item?.selected_variation.map((el) => {
        sum += el.price * el.amount;
      });
      var totalExtras = 0;
      item.extras.map((extra) => {
        totalExtras += extra.quantity  * extra.price;
      });
      return sum + totalExtras;
    }
    return item.amount * item.price;
  };

  const displayOrderItems = () => {
    return (
      <>
        {items.map((product) => {
          if (product.selected_variation.length > 0) {
            return (
              <>
                {product.selected_variation.map((variation) => (
                  <>
                    <div className="flex row items-center justify-between border-b-2 border-b-grey mt-2 pt-2 pb-1">
                      <div
                        style={{
                          maxWidth: "12rem",
                          maxHeight: "40px",
                          overflow: "hidden",
                        }}
                      >
                        <p
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            color: "#272727",
                          }}
                        >
                          {variation.amount}× {variation.name}
                        </p>
                      </div>
                      <p className="w-2/6 text-end whitespace-nowrap h-6 text-[#272727]">
                        {format(variation.amount * variation.price)}{" "}
                        {storeData.currency}
                      </p>
                    </div>
                  </>
                ))}
              </>
            );
          } else {
            return (
              <>
                <div className="flex row items-center justify-between border-b-2 border-b-grey mt-2 pt-2 pb-1">
                  <div
                    style={{
                      maxWidth: "12rem",
                      maxHeight: "40px",
                      overflow: "hidden",
                    }}
                  >
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: "#272727",
                      }}
                    >
                      {product.amount}× {product.name}
                    </p>
                  </div>
                  <p className="w-2/6 text-end whitespace-nowrap h-6 text-[#272727]">
                    {format(itemTotal(product))} {storeData.currency}
                  </p>
                </div>
                <div>
                  {product.extras.map((extra) => (
                    <div
                      key={extra.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingLeft: "15px",
                      }}
                    >
                      {extra.quantity > 0 && (
                        <Typography className="text-[#272727]">
                          {extra.quantity}× {extra.ingredient}
                        </Typography>
                      )}
                      {extra.quantity > 0 && (
                        <Typography className="text-[#272727]">
                          {new Intl.NumberFormat("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(extra.price * extra.quantity)}{" "}
                          {storeData.currency}
                        </Typography>
                      )}
                    </div>
                  ))}
                </div>
              </>
            );
          }
        })}
      </>
    );
  };

  const items_length = () => {
    var length = 0;
    items.map((item) => {
      if (item.selected_variation && item.variation.length > 0) {
        length += 1;
      }
      if (item.variation.length === 0) length += 1;
    });
    return length;
  };
  return (
    <div className="sticky top-[4.5rem] w-full">
      {!orderComplete ? (
        <>
          {items_length() > 0 ? (
            <>
              <Typography className="text-[#272727]">Order summary</Typography>
              <div className="pt-3 flex-col pr-3 gap-2">
                <div className="flex flex-row items-start">
                  {/*
                  <FormControl
                    className={"form-control"}
                    style={{ width: "90%" }}
                    error={giftCardError !== ""}
                  >
                    <TextField
                      style={{ color: "#c7c7c7" }}
                      value={giftcard}
                      onChange={(e) => setGiftCard(e.target.value)}
                      fullWidth
                      variant="standard"
                      label="Giftcard code"
                    />
                    {giftCardError !== "" && (
                      <FormHelperText style={{ marginLeft: "0px" }}>
                        {giftCardError}
                      </FormHelperText>
                    )}
                  </FormControl>
                  */}
                  {giftcard !== "" ? (
                    <button
                      className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
                      onClick={() => checkGiftcard()}
                    >
                      <Typography>Check</Typography>
                    </button>
                  ) : null}
                </div>

                {displayOrderItems()}
                {/*items.map((item) => showItem(item))*/}
                {delivery_fee_calculated && !minimumOrderValue && (
                  <div className="flex row items-center justify-between border-b-2 border-b-grey mt-5 pt-2 pb-1">
                    <p className="w-4/6 text-left whitespace-normal pr-1">
                      Delivery fee
                    </p>
                    <p className="w-2/6 text-end whitespace-nowrap">
                      {deliveryFee?.delivery_price
                        ? format(deliveryFee?.delivery_price) +
                          " " +
                          storeData.currency
                        : "Free"}
                    </p>
                  </div>
                )}
                {delivery_fee_calculated && !minimumOrderValue && (
                  <div className="flex row items-center justify-between border-b-2 border-b-grey mt-2 pt-2 pb-1">
                    <p className="w-4/6 text-left whitespace-normal pr-1">
                      Service fee
                    </p>
                    <p className="w-2/6 text-end whitespace-nowrap">
                      {deliveryFee?.service_price
                        ? format(deliveryFee?.service_price) +
                          " " +
                          storeData.currency
                        : "Free"}
                    </p>
                  </div>
                )}
                {giftcardValue > 0 && (
                  <div className="flex row items-center justify-between border-b-2 border-b-grey mt-2 pt-2 pb-1">
                    <p className="w-4/6 text-left whitespace-normal pr-1">
                      Giftcard
                    </p>
                    <p className="w-2/6 text-end whitespace-nowrap">
                      - {format(giftcardValue) + " " + storeData.currency}
                    </p>
                  </div>
                )}
                {items_length() > 0 && (
                  <div className="flex row items-center justify-between border-b-2 border-b-grey mt-5 pt-2 pb-1">
                    <p className="w-4/6 text-left whitespace-normal pr-1 text-[#272727]">
                      {delivery_fee_calculated ? "Total" : "Subtotal"}
                    </p>
                    <p className="w-2/6 text-end whitespace-nowrap text-[#272727]">
                      {delivery_fee_calculated &&
                      deliveryFee?.service_price &&
                      deliveryFee?.delivery_price
                        ? format(
                            total() +
                              deliveryFee?.service_price +
                              deliveryFee?.delivery_price
                          ) +
                          " " +
                          storeData.currency
                        : format(total()) + " " + storeData.currency}
                    </p>
                  </div>
                )}
              </div>

              <div className="flex flex-col items-center justify-center mt-3">
                {minimumOrderValue &&
                  (delivery_fee_calculated || typeOfOrder === "Collection") && (
                    <Typography className="text-red-500 pb-3">
                      {deliveryFee?.message}
                    </Typography>
                  )}
                {delivery_fee_calculated || typeOfOrder === "Collection" ? (
                  <button
                    className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
                    onClick={(e) => submit(e)}
                    disabled={minimumOrderValue}
                  >
                    <Typography>
                      {editOrderView ? "Edit" : "Create"} order
                    </Typography>
                  </button>
                ) : (
                  <button
                    className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
                    onClick={() => calculateDeliveryFee()}
                  >
                    {" "}
                    <Typography>Calculate delivery fee</Typography>
                  </button>
                )}
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          {items_length() > 0 ? (
            <>
              <div className="flex flex-row items-center gap-3 mt-7">
                <IconButton
                  style={{
                    height: "30px",
                    width: "30px",
                  }}
                  onClick={() => reset()}
                >
                  <Close />
                </IconButton>
                <Typography>
                  Order {editOrderView ? "edited" : "created"} successfully!
                </Typography>
              </div>

              <div className="pt-3 flex-col pr-3 gap-2">
                {displayOrderItems()}
                {/*items.map((item) => showItem(item))*/}
                {delivery_fee_calculated && !minimumOrderValue && (
                  <div className="flex row items-center justify-between border-b-2 border-b-grey mt-2 pt-2 pb-1">
                    <p className="w-4/6 text-left whitespace-normal pr-1">
                      Delivery fee
                    </p>
                    <p className="w-2/6 text-end whitespace-nowrap">
                      {deliveryFee?.delivery_price
                        ? format(deliveryFee.delivery_price) +
                          " " +
                          storeData.currency
                        : "Free"}
                    </p>
                  </div>
                )}
                {delivery_fee_calculated && (
                  <div className="flex row items-center justify-between border-b-2 border-b-grey mt-2 pt-2 pb-1">
                    <p className="w-4/6 text-left whitespace-normal pr-1">
                      Service fee
                    </p>
                    <p className="w-2/6 text-end whitespace-nowrap">
                      {deliveryFee?.service_price
                        ? format(deliveryFee?.service_price) +
                          " " +
                          storeData.currency
                        : "Free"}
                    </p>
                  </div>
                )}
                {items_length() > 0 && (
                  <div className="flex row items-center justify-between border-b-2 border-b-grey mt-2 pt-2 pb-1">
                    <p className="w-4/6 text-left whitespace-normal pr-1">
                      Total
                    </p>
                    <p className="w-2/6 text-end whitespace-nowrap">
                      {delivery_fee_calculated &&
                      deliveryFee?.service_price &&
                      deliveryFee?.delivery_price
                        ? format(
                            total() +
                              deliveryFee?.service_price +
                              deliveryFee?.delivery_price
                          ) +
                          " " +
                          storeData.currency
                        : format(total()) + " " + storeData.currency}
                    </p>
                  </div>
                )}
              </div>
              {isPaid === "Not paid" && paymentMethod.id === "Card" ? (
                <div>
                  <p style={{ marginTop: "15px" }}>
                    Your order has been created! We have sent an sms to the
                    client to proceed with the payment. You can access the
                    payment page by clicking this{" "}
                    <a
                      target="_blank"
                      className="text-[blue] underline"
                      href={`https://${getStoreURL}/payment/${orderData.order_id}`}
                    >
                      link
                    </a>
                    .
                  </p>
                </div>
              ) : null}

              <div className="mt-3 flex items-center justify-center gap-4">
                <Receipt
                  storeData={storeData}
                  order={orderData}
                  trigger={
                    <IconButton size="medium" title="Print">
                      <Print />
                    </IconButton>
                  }
                />

                <Button
                  className="w-fit bg-black shadow-grey hover:shadow-grey"
                  onClick={(e) => reset(e)}
                >
                  Make new order
                </Button>
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
}
