import { getTransactions } from "../../services/orders";
import { useState, useEffect } from "react";
import StoreFilter from "../../components/storeFilter";
import { getStores } from "../../services/stores";
import { NETWORK } from "./../../settings";
import BottomFilters from "../../components/bottomFilters";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";
import moment from "moment";
import TransactionRow from "./transactionsRow";
import ProgressBar from "../../components/loader/progressBar";
import { useSelector } from "react-redux";
import KycError from "../../components/kycError";
export default function () {
  const { auth } = useSelector(store => store.auth)
  const [transactions, setTransactions] = useState([]);
  const [stores, setStores] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All status");
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialDate, setInitialDate] = useState(
    moment(new Date(new Date().setDate(new Date().getDate() - 30))).format(
      "YYYY-MM-DD"
    )
  );
  const [search, setSearch] = useState("");
  const [finalDate, setFinalDate] = useState(moment().format("YYYY-MM-DD"));
  const [activeStoreFilter, setActiveStoreFilter] = useState({
    value: 0,
    label: "",
  });
  const fetchStores = () => {
    getStores()
      .then((res) => {
        setActiveStoreFilter({ value: res[0].id, label: res[0].name });
        setStores(res);
      })
      .catch((e) => {
        console.log("err ", e);
      });
  };
  const fetchTransactions = () => {
    setLoading(true);
    const _initialDate = initialDate
      ? moment(initialDate).format("YYYY-MM-DD")
      : null;
    const _finalDate = finalDate
      ? moment(finalDate).format("YYYY-MM-DD")
      : null;
    getTransactions({
      starts: _initialDate,
      ends: _finalDate,
      network: NETWORK,
      store: activeStoreFilter.value,
    }).then((res) => {
      filterData(res);
      setTransactions(res);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchStores();
  }, []);
  const filter = (order) => {
    var included = true;
    const date = new Date(order.date_ordered);

    const start = new Date(initialDate);
    const end = new Date(finalDate);
    switch (selectedStatus) {
      case "Open":
        if (isPaid(order)) {
          included = false;
        } else {
          console.log("order not paid ", order.order_id);
        }
        break;
      case "Paid":
        if (!isPaid(order)) included = false;

        break;

      default:
        break;
    }
    if (included)
      if (date > start && date < end) {
      } else {
        console.log(
          "order excluded in time ",
          order.order_id,
          order.date_ordered
        );

        included = false;
      }
    if (
      search !== "" &&
      !order.order_code.toLowerCase().includes(search.toLowerCase()) &&
      !order?.owner?.name.toLowerCase().includes(search.toLowerCase())
    ) {
      included = false;
    }
    if (included) {
      // console.log("order returned ", order.order_id);
    }
    return included;
  };
  const filterData = (data) => {
    setFiltered(data.filter((order) => filter(order)));
  };
  useEffect(() => {
    fetchTransactions();
  }, [activeStoreFilter.value]);
  const isPaid = (order) => {
    return order.is_paid;
  };

  function getCurrency(stores, activeStoreFilter) {
    const store = stores.find((store) => store.id === activeStoreFilter.value);
    return store.currency;
  }

  const getStoreSelected = stores.find(
    (_store) => _store.id === activeStoreFilter.value
  );

  return (
    <div className="mb-24">
      {auth.stripe_profiles.account_stripe_KYC_status ?
        <>
          <div className="w-[250px] mx-4 flex-wrap my-[43px]">
            <StoreFilter
              stores={stores}
              activeStoreFilter={activeStoreFilter}
              setActiveStoreFilter={(value) => setActiveStoreFilter(value)}
            />
          </div>
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <ProgressBar />
            </div>
          ) : (
            <div className="w-full flex items-center pb-14">
              <TableContainer style={{ overflowX: "initial", maxHeight: "100vh" }}>
                <Table stickyHeader size="small" aria-label="simple table">
                  <TableHead className="border-gray border-b-2">
                    <TableRow>
                      <TableCell
                        className="table-cell-border"
                      >
                        <Typography className="borderless-title">
                          Order ID
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="table-cell-border"
                      >
                        <Typography className="borderless-title">Client</Typography>
                      </TableCell>
                      <TableCell
                        className="table-cell-border"
                      >
                        <Typography className="borderless-title">
                          Payment method
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="table-cell-border"
                      >
                        <Typography className="borderless-title">Value</Typography>
                      </TableCell>
                      <TableCell
                        className="table-cell-border"
                      >
                        <Typography className="borderless-title">
                          Schedule
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="table-cell-border"
                      >
                        <Typography className="borderless-title">
                          Payment status
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="table-cell-border"
                      >
                        <Typography className="borderless-title">
                          Order status
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtered.map((order) => (
                      <TransactionRow
                        key={order.order_id}
                        order={order}
                        store={order.store_id}
                        currency={getCurrency(stores, activeStoreFilter)}
                        storeData={getStoreSelected}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          <BottomFilters
            selectedStatus={selectedStatus}
            setStatus={(e) => setSelectedStatus(e)}
            query={search}
            setQuery={(e) => setSearch(e)}
            searchPlaceholder={"Search"}
            start={initialDate}
            setStart={(e) => setInitialDate(e)}
            end={finalDate}
            setEnd={(e) => setFinalDate(e)}
            filter={() => filterData(transactions)}
          />
        </> : <KycError profile={auth.stripe_profiles} />}
    </div>
  );
}
