import { Typography } from "@mui/material";
import moment from "moment";

export default function Timeline({ order }) {
  const colors = {
    neworders: "#FFDADA",
    doing: "#FFE5CE",
    done: "#FFFBA7",
    ontheway: "#E8FFC3",
    delivered: "#C3FFD0",
  };
  const status = [
    {
      title: "Request",
      color: colors.neworders,
      date: order.date_ordered,
    },
    {
      title: "Doing",
      color: colors.doing,
      date: order.doing_at,
    },
    {
      title: "Done",
      color: colors.done,
      date: order.done_at,
    },
    {
      title: "On the way",
      color: colors.ontheway,
      date: order.depot_at,
    },
    {
      title: "Delivered",
      color: colors.delivered,
      date: order.finished_at,
    },
  ];
  return (
    <>
      <div className=" flex items-center py-3 flex-wrap md:flex-nowrap w-fit">
        {status.map((el) => (
          <div className="flex flex-col">
            <Typography className="">
              {el.title}
            </Typography>
            <div
              style={{ borderColor: el.color, backgroundColor: el.color }}
              className="border-solid border-2 px-3 py-2 flex flex-col items-start justify-center rounded-[10px] mr-2 w-60 h-16 my-1"
              key={el.title + order.order_id}
            >
              <Typography>
                {el.date
                  ? `${new Date(el.date)
                      .toLocaleString("en-GB", { weekday: "long" })
                      .replace(/^\w/, (c) => c.toUpperCase())} ${new Date(
                      el.date
                    ).getDate()} ${new Date(el.date)
                      .toLocaleString("en-GB", { month: "long" })
                      .replace(/^\w/, (c) => c.toUpperCase())}`
                  : "-"}
              </Typography>
              <Typography>
                {el.date ? `${moment(new Date(el.date)).format("hh:mm")}` : ""}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
