import { Modal, IconButton, Typography } from "@mui/material";
import { createProduct } from "../../../../services/products";
import { Close } from "@mui/icons-material";
import { NETWORK } from "../../../../settings";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { notify } from "../../../../redux/features/alert";
import ReturnTabHeaders from "./modal-tabs/modalTabHeaders";
import FirstTab from "./modal-tabs/firstTab";
import SecondTab from "./modal-tabs/secondTab";
import ExtrasTab from "./modal-tabs/extrasTab";
import SizesTab from "./modal-tabs/sizesTab";
import RelatedProductsTab from "./modal-tabs/relatedProductsTab";
import { makeStyles } from "@mui/styles";
import {
  addRelatedProduct,
  removeRelatedProduct,
} from "../../../../services/products";
import { useEffect } from "react";
import CreateCategoryModal from "../../categories/createCategoryModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
const useStyles = makeStyles({
  activeButton: {
    margin: "0 5px",
    color: "#fff",
    border: "none",
    backgroundColor: "#124c4d",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

export default function ModalAddProduct({
  open,
  handleClose,
  artists,
  categories,
  ingredients,
  products,
  refresh,
  store,
  attemptClose,
  getCategories
}) {
  const dispatch = useDispatch();

  const onClose = () => {
    setName("");
    setNameError("");
    setPrice(0);
    setStock(0);
    // setCost(0.0);
    setMeasureError("");
    setSlugError("");
    // setCostError('');
    setDescription("");
    setAllergyNotes("");
    setMeasure("");
    setCategory("");
    setImageSource("");
    setVideo("");
    setExtras([]);
    setCategoryError("");
    setSizes([]);
    setProductImages([]);
    setProductSheet(null);
    setOrigin("");
    setHexCode("");
    refresh();
    setActiveTab(0);
    setSlug("");
    setArtistName("");
    setMaterial("");
    setActiveTab(0);
    setActive(true);
    setNewCategory(false);
    setChanged(false);
    setFiscalCategory("PRODUCT")
    handleClose();
  };
  const options = categories.map((_category) => {
    return _category.name;
  });
  const [activeTab, setActiveTab] = useState(0);
  const [imageSource, setImageSource] = React.useState("");
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [slugError, setSlugError] = React.useState("");
  const [measure, setMeasure] = React.useState("Unit");
  const [measureError, setMeasureError] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [categoryError, setCategoryError] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [fiscalCategory, setFiscalCategory] = useState("PRODUCT")
  const [slug, setSlug] = React.useState("");
  const [video, setVideo] = React.useState();
  const [extras, setExtras] = React.useState([]);
  const [extrasError, setextrasError] = React.useState("");
  const [stock, setStock] = React.useState(0);
  const [description, setDescription] = React.useState("");
  const [allergyNotes, setAllergyNotes] = React.useState("");
  const [sizes, setSizes] = React.useState([]);
  const [sizesError, setSizesError] = React.useState(false);
  const [artistName, setArtistName] = React.useState("");
  const [artistNameError, setArtistNameError] = React.useState("");
  const [origin, setOrigin] = React.useState("");
  const [originError, setOriginError] = React.useState("");
  const [productImages, setProductImages] = React.useState([]);
  const [hexCode, setHexCode] = useState("");
  const [hexCodeError, setHexCodeError] = React.useState("");
  const [productSheet, setProductSheet] = React.useState(null);
  const [material, setMaterial] = React.useState("");
  const [materialError, setMaterialError] = React.useState("");
  const [duplicateSlugError, setDuplicateSlugError] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [removedProducts, setRemovedProducts] = useState([]);
  const [sku, setSku] = useState("");
  const [barcode, setBarcode] = useState("");
  const [cleanedState, setCleanedState] = useState(false);
  const [active, setActive] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);

  function slugify(text) {
    return text
      .toString()
      .normalize("NFD")
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
  const generateURL = (file) => {
    if (file) {
      var url = URL.createObjectURL(file);
      return url;
    }
    return "";
  };
  const checkDuplicateSlug = () => {
    for (let index = 0; index < products.length; index++) {
      const product_el = products[index];
      if (product_el.slug === slug) {
        return true;
      }
    }
    return false;
  };
  const checkForm = () => {
    let entered = false;
    if (name === "") {
      setNameError("Please insert a name");
      entered = true;
    }
    if (category === "" || category === null || !category) {
      setCategoryError("Please insert a category");
      entered = true;
    }
    if (checkDuplicateSlug()) {
      setDuplicateSlugError(
        "You already have a product with this friendly URL"
      );
      entered = true;
    }
    if (slug === "") {
      setSlugError("Please insert the friendly URL");
      entered = true;
    }
    if (measure === "" || measure === null) {
      setMeasureError("Please insert a unit");
      entered = true;
    }
    sizes.map((element) => {
      if (element.portion === "") {
        setSizesError(true);
        entered = true;
      }
    });

    if (NETWORK !== "6") {
      extras.map((element) => {
        if (element.ingredient === 0 || element.price === "") {
          setextrasError("Please fill all fields in extras");
          entered = true;
        }
      });
    }
    if (NETWORK === "6") {
      if (material === "" || material === null) {
        setMaterialError("Please insert the material");
        entered = true;
      }
      if (hexCode === "" || hexCode === null) {
        setHexCodeError("Please insert the hex code");
        entered = true;
      }
      if (artistName === "" || artistName === null) {
        setArtistNameError("Please insert the artist");
        entered = true;
      }
      if (origin === "" || origin === null) {
        setOriginError("Please insert the origin");
        entered = true;
      }
    }

    if (entered) {
      dispatch(
        notify({ message: "Please insert all the names", severity: "error" })
      );
    }
    return entered;
  };
  const showMeasure = (id) => {
    var measure = "";
    for (let index = 0; index < ingredients.length; index++) {
      const element = ingredients[index];
      if (element.ingredient_id === id) {
        measure = element.measure;
      }
    }
    return measure;
  };
  const handleRelatedProducts = (product_id) => {
    return new Promise((resolve, reject) => {
      if (removedProducts.length === 0 && selectedProducts.length === 0) {
        resolve();
      } else {
        Promise.all([
          handleRemovedProducts(product_id),
          handleAddedProducts(product_id),
        ])
          .then(() => {
            resolve();
          })
          .catch((e) => {
            console.log("error related", e);
            reject();
          });
      }
    });
  };
  const submitCreation = async (e) => {
    e.preventDefault();
    if (!checkForm()) {
      setLoading(true);
      var extrasToSubmit = [];
      for (let index = 0; index < extras.length; index++) {
        const element = extras[index];

        extrasToSubmit.push({
          ingredient_id: element.ingredient,
          extra: {
            limit: element.limit,
            price: element.price,
            is_sauce: element.is_sauce === 0 ? false : true,
            measure: showMeasure(element.ingredient),
            quantity: (element.quantity = 1),
          },
        });
      }

      var ingredientsToSubmit = [];
      for (let index = 0; index < extras.length; index++) {
        const element = ingredients[index];
        ingredientsToSubmit.push({
          ingredient_id: element.ingredient_id,
          ingredient_stock: {
            measure: showMeasure(element.ingredient_id),
            quantity: element.quantity * 1,
          },
        });
      }

      var payload = {
        image: imageSource,
        name: name,
        price: price,
        stock: typeof stock === "number" ? stock.toFixed(2) : stock,
        // cost: cost? cost.toFixed(2) : 0.0,
        description: description,
        allergy_notes: allergyNotes,
        category: category,
        measure: measure,
        slug: slug,
        video: video,
        store: store,
        ingredients: ingredientsToSubmit,
        extras: extrasToSubmit,
        size: sizes,
        artist: artistName,
        origin: origin,
        product_sheet: productSheet,
        colour_hex: hexCode,
        network: parseInt(NETWORK),
        product_images: productImages,
        material,
        active,
        sku,
        barcode,
        fiscal_category: fiscalCategory
      };
      if (NETWORK !== "6") {
        delete payload.product_sheet;
        delete payload.artist;
      }
      if (!video) {
        delete payload.video;
      }
      if (!payload.product_sheet) {
        delete payload.product_sheet;
      }
      createProduct(payload, selectedProducts, removedProducts)
        .then(() => {
          setLoading(false);

          dispatch(
            notify({
              message: "Product successfully created",
              severity: "success",
            })
          );

          onClose();
        })
        .catch((error) => {
          setLoading(false);
          dispatch(notify({ message: error, severity: "error" }));
        });
    }
  };
  useEffect(() => {
    if (!open && !cleanedState) {
      onClose();
      setCleanedState(true);
    }
    if (open) {
      setCleanedState(false);
    }
  }, [open]);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const renderFirstTab = () => (
    <FirstTab
      options={options}
      name={name}
      setName={(e) => {
        setName(e);
        setChanged(true);
      }}
      nameError={nameError}
      slug={slug}
      slugError={slugError}
      category={category}
      setCategory={(e) => {
        setCategory(e);
        setChanged(true);
      }}
      categoryError={categoryError}
      price={price}
      setPrice={(e) => {
        setPrice(e);
        setChanged(true);
      }}
      stock={stock}
      setStock={(e) => {
        setStock(e);
        setChanged(true);
      }}
      measure={measure}
      setMeasure={(e) => {
        setMeasure(e);
        setChanged(true);
      }}
      measureError={measureError}
      artistName={artistName}
      artistNameError={artistNameError}
      setArtistName={(e) => {
        setArtistName(e);
        setChanged(true);
      }}
      setActive={(e) => {
        setActive(e);
        setChanged(true);
      }}
      active={active}
      origin={origin}
      originError={originError}
      setOrigin={(e) => {
        setOrigin(e);
        setChanged(true);
      }}
      hexCode={hexCode}
      hexCodeError={hexCodeError}
      setHexCode={(e) => {
        setHexCode(e);
        setChanged(true);
      }}
      material={material}
      materialError={materialError}
      setMaterial={(e) => {
        setMaterial(e);
        setChanged(true);
      }}
      imageSource={imageSource}
      setImageSource={(e) => {
        setImageSource(e);
        setChanged(true);
      }}
      video={video}
      setVideo={(e) => {
        setVideo(e);
        setChanged(true);
      }}
      productSheet={productSheet}
      setProductSheet={(e) => {
        setProductSheet(e);
        setChanged(true);
      }}
      description={description}
      setDescription={(e) => {
        setDescription(e);
        setChanged(true);
      }}
      generateURL={generateURL}
      categories={categories}
      artists={artists}
      setSlug={(e) => {
        setSlug(e);
        setChanged(true);
      }}
      duplicateSlugError={duplicateSlugError}
      setDuplicateSlugError={setDuplicateSlugError}
      variations={sizes.filter((size) => !size.deleted).length}
      setNewCategory={() => setNewCategory(true)}
      fiscalCategory={fiscalCategory}
      setFiscalCategory={(e) => setFiscalCategory(e)}
    />
  );
  const renderSecondTab = () => {
    return (
      <SecondTab
        productImages={productImages}
        setProductImages={(images) => {
          setChanged(true);
          setProductImages((oldArray) => [...oldArray, ...images]);
        }}
        removeImage={(idx) => {
          setChanged(true);
          var _images = [...productImages];
          if (!_images[idx].image) {
            _images.splice(idx, 1);
          } else {
            _images[idx].deleted = true;
          }
          setProductImages(_images);
        }}
        generateURL={generateURL}
        imageSource={imageSource}
        setImageSource={(e) => {
          setImageSource(e);
          setChanged(true);
        }}
        video={video}
        setVideo={(e) => {
          setVideo(e);
          setChanged(true);
        }}
        productSheet={productSheet}
        setProductSheet={(e) => {
          setProductSheet(e);
          setChanged(true);
        }}
      />
    );
  };

  const renderExtrasTab = () => {
    return (
      <ExtrasTab
        extras={extras}
        setExtras={(e) => {
          setExtras(e);
          setChanged(true);
        }}
        handleChange={(prop, index, value) => {
          setChanged(true);
          setExtras(
            extras.map((item) =>
              item.extra_id === index
                ? {
                  ...item,
                  [prop]: value,
                }
                : item
            )
          );
        }}
        extrasError={extrasError}
        ingredients={ingredients}
      />
    );
  };

  const renderSizesTab = () => {
    return (
      <SizesTab
        sizes={sizes}
        setSizes={(newValue) => {
          setChanged(true);
          var stockSum = 0;
          newValue.map((el) => {
            if (!el.deleted) stockSum += Number(el.stock);
          });
          setStock(stockSum);
          setSizes(newValue);
        }}
        sizesError={sizesError}
      />
    );
  };
  const pushRemove = (product_id) => {
    var removed = [];
    removedProducts.map((el) => {
      if (!removed.includes(el)) {
        removed.push(el);
      }
    });
    if (!removed.includes(product_id)) {
      removed.push(product_id);
    }
    setRemovedProducts(removed);
  };
  const deleteRemove = (product_id) => {
    var removed = [];
    removedProducts.map((el) => {
      if (el !== product_id) {
        removed.push(el);
      }
    });
    setRemovedProducts(removed);
  };
  const handleProductUnPressAll = () => {
    setRemovedProducts(
      selectedProducts.map((_product) => {
        return _product.product_id;
      })
    );
    setSelectedProducts([]);
  };
  const handleProductOnPress = (selectedProduct) => {
    if (
      selectedProducts
        .map((productToAdd) => productToAdd.product_id)
        .includes(selectedProduct.product_id)
    ) {
      return setSelectedProducts(
        selectedProducts.filter(
          (prod) => prod.product_id !== selectedProduct.product_id
        )
      );
    }
    return setSelectedProducts([selectedProduct, ...selectedProducts]);
  };
  const handleRemovedProducts = async (product_id) => {
    return new Promise((resolve, reject) => {
      try {
        if (removedProducts.length === 0) {
          resolve();
        }
        removedProducts.map((el, idx) => {
          removeRelatedProduct(el, product_id);
          if (idx === removeRelatedProduct.length - 1) {
            resolve();
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleAddedProducts = async (product_id) => {
    return new Promise((resolve, reject) => {
      try {
        if (selectedProducts.length === 0) {
          resolve();
        }
        selectedProducts.map((el, idx) => {
          addRelatedProduct(el.product_id, product_id);
          if (idx === selectedProducts.length - 1) {
            resolve();
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  const returnTabsBody = () => {
    switch (NETWORK) {
      case "6":
        return (
          <>
            <TabPanel value={activeTab} index={0} className="px-10">
              {renderFirstTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={1} className="px-10">
              {renderSecondTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              {renderSizesTab()}
            </TabPanel>
          </>
        );

      default:
        return (
          <>
            <TabPanel value={activeTab} index={0} className="px-10">
              {renderFirstTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={1} className="px-10">
              {renderSecondTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={2} className="px-10">
              {renderExtrasTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <Typography className="table-cell px-10">Variation</Typography>
              {renderSizesTab()}
            </TabPanel>
            <TabPanel value={activeTab} index={4} className="px-10">
              <RelatedProductsTab
                products={products}
                selectedProducts={selectedProducts}
                pushRemove={pushRemove}
                handleProductOnPress={handleProductOnPress}
                removedProducts={removedProducts}
                deleteRemove={deleteRemove}
              />
            </TabPanel>
          </>
        );
    }
  };
  const buttonTitle = () => {
    switch (NETWORK) {
      case "6":
        if (activeTab > 2) {
          return "Submit";
        }
        return "Next";

      default:
        if (activeTab > 3) {
          return "Submit";
        }
        return "Next";
    }
  };
  const handleNavigation = (e) => {
    switch (NETWORK) {
      case "6":
        if (activeTab <= 1) {
          setActiveTab(activeTab + 1);
        } else {
          submitCreation(e);
        }
      default:
        if (activeTab <= 3) {
          setActiveTab(activeTab + 1);
        } else {
          submitCreation(e);
        }
    }
  };
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    var wrapper = document.getElementById("modal-wrapper");
    if (wrapper) wrapper.scrollTo(0, 0);
  }, [activeTab]);

  return (
    <>
      <CreateCategoryModal
        open={newCategory}
        handleClose={() => setNewCategory(false)}
        refresh={() => getCategories()}
        ingredientsToFilterBy={[]}
        embedModal={true}
        store={store}
        getNewCategory={(e) => setCategory(e)}
      />
      <Modal
        className="flex flex-row h-full  items-center justify-center"
        open={open}
        ModalProps={{
          onBackdropClick: () => {
            if (changed) attemptClose();
            else onClose();
          },
        }}
        onClose={() => {
          if (changed) attemptClose();
          else onClose();
        }}
      >
        <div className="w-full sm:w-[70%] md:w-[50%]  h-full sm:h-3/4 bg-white overflow-hidden relative">
          <div
            id="modal-wrapper"
            className="w-full h-full overflow-y-auto pt-5 pb-10"
          >
            <div className="px-10">
              <div className="w-full flex flex-row items-center justify-between">
                <Typography className="borderless-title">
                  Add new product
                </Typography>
                <IconButton
                  onClick={() => {
                    if (changed) attemptClose();
                    else onClose();
                  }}
                  style={{
                    backgroundColor: "#fff",
                    fill: "#fff",
                    border: "1px solid #DDDDDD",
                    borderRadius: "50%",
                    height: "30px",
                    width: "30px",
                  }}
                >
                  <Close style={{ fill: "#DDDDDD" }} />{" "}
                </IconButton>
              </div>
              <div className="mt-5 bg-gray w-full mb-5 overflow-x-hidden">
                <ReturnTabHeaders
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
            <div className="">{returnTabsBody()}</div>
            {/*      <div className="w-0 sm:w-2/3" onClick={() => onClose()} />
             */}{" "}
          </div>
          <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-60px] px-8 py-[.9rem] z-10">
            <button
              disabled={loading}
              onClick={(e) => handleNavigation(e)}
              className="text-white rounded-full border disabled:opacity-50 flex justify-center items-center h-[28px] p-4 "
            >
              <Typography style={{ marginTop: "0", color: "#F2F2F2" }}>
                {buttonTitle()}
              </Typography>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
