import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Button } from "@material-tailwind/react";

import "./styles.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";
export default function StripeKYC(props) {
  const { auth } = useSelector((state) => state.auth);

  const [stripeLink, setStripeLink] = useState("");
  const fillStripe = () => {
    setStripeLink(auth.stripe_profiles.account_stripe_link);
  };
  useEffect(() => {
    if (auth.stripe_profiles) {
      fillStripe();
    }
  }, [auth]);

  return (
    <div
      className="bg-black w-screen h-screen flex items-center justify-center"
    >
      <div className="md:w-1/3 w-[85%]">
        <div>
          <Typography style={{ fontSize: "22px", marginBottom: "10px", color: "white" }}>
            You are logged in!
          </Typography>
        </div>
        <div style={{ marginBottom: "20px" }}>
          {" "}
          <Typography style={{ fontSize: "15px", color: "white" }}>
            User, you must complete your KYC with Stripe, we sent and email. This
            is required to be live on the market place and start earning money.
          </Typography>
        </div>
        <div className="w-full bg-[#612EF2] rounded-full text-start normal-case py-[2px] px-[8px] shadow-grey hover:shadow-grey" style={{ marginTop: "20px" }}>
          {" "}
          <a
            className=""
            href={stripeLink}
          >
            <Typography className="py-[2px] pl-[0.67rem] ml-[2px]" style={{ color: "white" }}>Complete your KYC</Typography>

          </a>
        </div>
      </div>
    </div>
  );
}
