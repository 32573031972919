import { createSlice } from "@reduxjs/toolkit";

export const network = createSlice({
  name: "network",
  initialState: {
    network: 2,
    email: "",
    networks: [],
  },
  reducers: {
    setNetwork: (state, action) => {
      state.network = action.payload.network;
      state.email = action.payload.email;
    },
    setNetworkList: (state, action) => {

      state.networks = action.payload.networks;
    },
    reset: (state) => {
      state.network = 2;
      state.email = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNetwork, setNetworkList, reset } = network.actions;

export default network.reducer;
