import { fetch as api } from "./../utils/request";
import { NETWORK } from "./../settings";

export const getStores = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let url = `/products/api/stores/?networks=${NETWORK}`;
      let response;
      let results = [];
      do {
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      resolve(response.results);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const createStore = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        body.append(key, data[key]);
      });
      await api({
        method: "POST",
        headers: { "content-type": "multipart/form-data" },
        url: `products/api/stores/`,
        data: body,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editStore = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = new FormData();
      const { address } = data;
      delete data.address;
      Object.keys(data).forEach((key) => {
        body.append(key, data[key]);
      });
      await api({
        method: "PATCH",
        url: `products/api/stores/${id}/`,
        data: body,
      });
      await api({
        method: "PATCH",
        url: `products/api/stores/${id}/`,
        data: { address: address },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editWhatsApp = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        body.append(key, data[key]);
      });
      await api({
        method: "PATCH",
        url: `products/api/stores/${id}/`,
        data: { whatsapp: data },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editOpenHours = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/orders/api/open_hours/`,
        data: data,
      }).then(async (response) => {
        await api({
          method: "POST",
          url: `/products/api/stores/${id}/store_open_hours/`,
          data: { open_hour_id: response.id },
        }).then((res) => {
          resolve();
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const forceClose = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = new FormData();
      Object.keys(data).forEach((key) => {
        body.append(key, data[key]);
      });
      await api({
        method: "PATCH",
        url: `products/api/stores/${id}/`,
        data: body,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
