import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import flogi from "./../../assets/images/flogi-logo.svg";
import { countries } from "./../../utils/countries";

import "react-phone-input-2/lib/material.css";
import "./styles.css";

const errorMensages = {
  required: "This field is required",
};

export default function CreateStoreAddress(props) {
  const { userAddress, handleSave } = props;

  const [hasSubmitedOnce, setHasSubmitedOnce] = useState(false);
  const [sameAddressAsPersonal, setSameAddressAsPersonal] = useState(false);
  const [addressFormData, setAddressFormData] = useState({
    street: "",
    neighborhood: "",
    postalCode: "",
    city: "",
    state: "",
    street_number: "",
    country: { code: "", label: "" },
  });

  const [addressFormDataErrors, setAddressFormDataErrors] = useState({
    street: false,
    neighborhood: false,
    postalCode: false,
    city: false,
    state: false,
    street_number: false,
    country: false,
  });

  useEffect(() => {
    if (sameAddressAsPersonal) {
      if (
        userAddress.billing_address.country &&
        typeof userAddress.billing_address.country === "string"
      ) {
        userAddress.billing_address.country = countries.find(
          (country) =>
            country.code.toLowerCase() ===
            userAddress.billing_address.country.toLowerCase()
        );
      }
      setAddressFormData({
        street: userAddress.billing_address.street
          ? userAddress.billing_address.street
          : "",
        neighborhood: userAddress.billing_address.neighborhood
          ? userAddress.billing_address.neighborhood
          : "",
        postalCode: userAddress.billing_address.zipcode
          ? userAddress.billing_address.zipcode
          : "",
        city: userAddress.billing_address.city
          ? userAddress.billing_address.city
          : "",
        country: userAddress.billing_address.country
          ? userAddress.billing_address.country
          : "",
        state: userAddress.billing_address.state
          ? userAddress.billing_address.state
          : "",
        street_number: userAddress.billing_address.street_number
          ? userAddress.billing_address.street_number
          : "",
      });
    } else {
      setAddressFormData({
        street: "",
        neighborhood: "",
        postalCode: "",
        city: "",
        state: "",
        street_number: "",
      });
    }
  }, [sameAddressAsPersonal]);

  const handleSameAddress = () => {
    setSameAddressAsPersonal(!sameAddressAsPersonal);
  };

  const handleFormData = (event) => {
    const input = event.target.id;
    const value = event.target.value;

    setSameAddressAsPersonal(false);
    switch (input) {
      case "street":
        setAddressFormData((currentData) => ({
          ...currentData,
          street: value,
        }));
        break;
      case "neighborhood":
        setAddressFormData((currentData) => ({
          ...currentData,
          neighborhood: value,
        }));
        break;
      case "postal-code":
        setAddressFormData((currentData) => ({
          ...currentData,
          postalCode: value,
        }));
        break;
      case "city":
        setAddressFormData((currentData) => ({
          ...currentData,
          city: value,
        }));
        break;
      case "state":
        setAddressFormData((currentData) => ({
          ...currentData,
          state: value,
        }));
        break;
      case "street_number":
        setAddressFormData((currentData) => ({
          ...currentData,
          street_number: value,
        }));
        break;
      default:
        break;
    }
  };
  const validadeAddressFields = () => {
    if (!hasSubmitedOnce) return;

    for (const [key, value] of Object.entries(addressFormData)) {
      setAddressFormDataErrors((currentData) => ({
        ...currentData,
        [key]: value ? false : true,
      }));
    }
  };

  const submit = () => {
    let proceed = true;
    setHasSubmitedOnce(true);

    validadeAddressFields();

    for (const [_key, value] of Object.entries(addressFormData)) {
      if (value === "") {
        proceed = false;
      }
    }

    if (proceed) {
      handleSave({
        address: {
          street: addressFormData.street,
          street_number: "",
          neighborhood: addressFormData.neighborhood,
          city: addressFormData.city,
          zipcode: addressFormData.postalCode,
          country: "GB",
        },
      });
    }
  };
  useEffect(() => {
    getAddressOptions();
  }, [addressFormData.street]);

  const getAddressOptions = () => {
    if (addressFormData.street) {
      const input = document.getElementById("street");
      // eslint-disable-next-line no-undef
      var autocomplete = new google.maps.places.Autocomplete(input, {
        fields: ["address_components", "geometry"],
        types: ["address"],
      });
      autocomplete.addListener("place_changed", () => {
        fillInAddress(autocomplete);
      });
    }
  };
  function fillInAddress(autoComplete) {
    const place = autoComplete.getPlace();
    var emptyPostalCode = true;
    var emptyNeighborhood = true;
    var filledAddress = {
      street: "",
      neighborhood: "",
      postalCode: "",
      city: "",
      state: "",
      street_number: "",
      country: { code: "", label: "" },
    };
    if (place && place.address_components) {
      for (const component of place.address_components) {
        // Process the address components as needed
        const type = component.types[0];
        if (type === "route") {
          filledAddress.street = component.long_name;
        }
        if (type === "country") {
          filledAddress.country = {
            label: component.long_name,
            code: component.short_name,
          };
        }
        if (type === "postal_code") {
          filledAddress.postalCode = component.long_name;

          emptyPostalCode = false;
        }
        if (type === "sublocality_level_1") {
          filledAddress.neighborhood = component.long_name;

          emptyNeighborhood = false;
        }

        if (type === "administrative_area_level_2") {
          filledAddress.city = component.long_name;
        }
      }
      if (emptyPostalCode) {
        filledAddress.postalCode = "";
      }
      if (emptyNeighborhood) {
        filledAddress.neighborhood = "";
      }
      setAddressFormData(filledAddress);
    }
  }
  return (
    <div className="flex justify-center h-full">
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className="title-onboarding" style={{ margin: "3rem 0" }}>
          Add Store Address
        </Typography>
        <div className="flex justify-center items-center w-full bg-black">
          <div className=" w-[90%] md:w-[30%] flex flex-col justify-center items-center pb-[3rem]">
            <div className="flex flex-col justify-center items-center">
              {" "}
              {userAddress?.billing_address ? (
                <FormControl
                  fullWidth
                  style={{
                    marginBottom: ".8rem",
                    marginTop: "1rem",
                    borderBottom: "1px solid gray",
                    "& span": { color: "white" },
                  }}
                >
                  <FormControlLabel
                    className="form-control-onboarding"
                    control={
                      <Checkbox
                        className="form-control-onboarding"
                        checked={sameAddressAsPersonal}
                        onChange={handleSameAddress}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Same as your address?"
                  />
                </FormControl>
              ) : null}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <FormControl
                    fullWidth
                    className="form-onboarding"
                    style={{ marginBottom: ".8rem" }}
                    error={addressFormDataErrors.street}
                  >
                    <InputLabel htmlFor="street">Store address</InputLabel>
                    <Input
                      id="street"
                      type="string"
                      value={addressFormData.street}
                      onChange={handleFormData}
                      onBlur={validadeAddressFields}
                    />
                    <FormHelperText>
                      {addressFormDataErrors.street && errorMensages.required}
                    </FormHelperText>
                  </FormControl>{" "}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    fullWidth
                    className="form-onboarding"
                    style={{ marginBottom: ".8rem" }}
                    error={addressFormDataErrors.street_number}
                  >
                    <InputLabel htmlFor="street">Number</InputLabel>
                    <Input
                      id="street_number"
                      type="string"
                      value={addressFormData.street_number}
                      onChange={handleFormData}
                      onBlur={validadeAddressFields}
                    />
                    <FormHelperText>
                      {addressFormDataErrors.number && errorMensages.required}
                    </FormHelperText>
                  </FormControl>{" "}
                </Grid>
              </Grid>
              <FormControl
                fullWidth
                className="form-onboarding"
                style={{ marginBottom: ".8rem" }}
                error={addressFormDataErrors.neighborhood}
              >
                <InputLabel htmlFor="neighborhood">Town</InputLabel>
                <Input
                  id="neighborhood"
                  type="string"
                  value={addressFormData.neighborhood}
                  onChange={handleFormData}
                  onBlur={validadeAddressFields}
                />
                <FormHelperText>
                  {addressFormDataErrors.neighborhood && errorMensages.required}
                </FormHelperText>
              </FormControl>{" "}
              <FormControl
                fullWidth
                className="form-onboarding"
                style={{ marginBottom: ".8rem" }}
                error={addressFormDataErrors.city}
              >
                <InputLabel htmlFor="city">City</InputLabel>
                <Input
                  id="city"
                  type="string"
                  value={addressFormData.city}
                  onChange={handleFormData}
                  onBlur={validadeAddressFields}
                />
                <FormHelperText>
                  {addressFormDataErrors.city && errorMensages.required}
                </FormHelperText>
              </FormControl>{" "}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <FormControl
                    fullWidth
                    className="form-onboarding"
                    style={{ marginBottom: ".8rem" }}
                    error={addressFormDataErrors.state}
                  >
                    <InputLabel htmlFor="postal-code">County/State</InputLabel>
                    <Input
                      id="state"
                      type="string"
                      value={addressFormData.state}
                      onChange={handleFormData}
                      onBlur={validadeAddressFields}
                    />
                    <FormHelperText>
                      {addressFormDataErrors.state && errorMensages.required}
                    </FormHelperText>
                  </FormControl>{" "}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    fullWidth
                    className="form-onboarding"
                    style={{ marginBottom: ".8rem" }}
                    error={addressFormDataErrors.postalCode}
                  >
                    <InputLabel htmlFor="postal-code">Postal Code</InputLabel>
                    <Input
                      id="postal-code"
                      type="string"
                      value={addressFormData.postalCode}
                      onChange={handleFormData}
                      onBlur={validadeAddressFields}
                    />
                    <FormHelperText>
                      {addressFormDataErrors.postalCode &&
                        errorMensages.required}
                    </FormHelperText>
                  </FormControl>{" "}
                </Grid>
              </Grid>
              <FormControl
                className="form-onboarding-country"
                style={{ width: "100%" }}
                required
                error={
                  addressFormDataErrors.zipcode &&
                  (addressFormData.country == "" ||
                    addressFormData.country == null)
                }
              >
                <Autocomplete
                  id="country-select-demo"
                  options={countries}
                  error={
                    addressFormDataErrors.country &&
                    (addressFormData.country == "" ||
                      addressFormData.country == null)
                  }
                  value={addressFormData.country}
                  onChange={(e, newValue) => {
                    setAddressFormData((currentData) => ({
                      ...currentData,
                      country: newValue,
                    }));
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="country-input"
                      variant="standard"
                      label="Country"
                    />
                  )}
                />{" "}
                {addressFormDataErrors.country &&
                  (addressFormData.country == "" ||
                    addressFormData.country == null) && (
                    <FormHelperText style={{ marginLeft: "0px" }}>
                      This field is required
                    </FormHelperText>
                  )}{" "}
              </FormControl>
              <button
                onClick={(e) => submit()}
                className="text-white rounded-full border bg-[#612EF2] border-[#612EF2] flex  items-center w-full h-[28px] p-4 my-[1.2rem]"
              >
                <Typography style={{ marginTop: "0", color: "white" }}>
                  Save and continue
                </Typography>
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-1 justify-center bg-black w-screen pb-2">
          <Typography className="text-[#7A7A7A]">Powered by</Typography>
          <img style={{ height: "12px" }} src={flogi} alt="" />
        </div>
      </Grid>
    </div>
  );
}
