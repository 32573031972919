import { Typography } from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from "recharts";
const COLORS = ["#20C680", "#FF5D5D"];

export default function Card({
  open,
  title,
  type,
  closed,
  currency,
  count,
  color,
}) {
  const data = [
    { name: "Closed", value: closed },
    { name: "Open", value: open },
  ];
  return (
    <div
      style={{ backgroundColor: color.secondary }}
      className={`p-2 w-full h-60 rounded-2xl relative mx-1 md:w-2/6 sm:w-full relative`}
    >
      <div className="absolute top-4 right-4 left-4">
        <Typography style={{ color: "#3F3F3F" }}>{title}</Typography>
        {typeof count === "number" ? (
          <Typography style={{ color: "#7A7A7A" }}>
            Total {count} orders
          </Typography>
        ) : null}
      </div>
      <div className="h-full flex flex-col items-center justify-center">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              innerRadius={50}
              outerRadius={70}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <>
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                </>
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="flex flex-row items-center gap-4">
          <div className="flex flex-row items-center gap-1">
            <div className="bg-[#FF5D5D] h-[10px] w-[10px] rounded-full" />
            <Typography style={{ color: "#3F3F3F" }}>
              {" "}
              {data[1].value} Not paid
            </Typography>
          </div>
          <div className="flex flex-row items-center gap-1">
            <div className="bg-[#20C680] h-[10px] w-[10px] rounded-full" />
            <Typography style={{ color: "#3F3F3F" }}>
              {" "}
              {data[0].value} Paid
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
