import {
  fetchAnalyticsDateRange,
  fetchSellersCommissions,
} from "./../../services/analytics";
import { getStores } from "../../services/stores";
import StoreFilter from "../../components/storeFilter";

import { MutatingDots } from "react-loader-spinner";
import { useEffect, useState } from "react";
import moment from "moment";
import Filters from "./../../components/analyticsFilters";
import Card from "./charts/card";
import Pie from "./charts/pie";
import { Typography } from "@mui/material";
import format from "./../../utils/formatValues";
import Line from "./charts/line";
import TableChart from "./charts/table";
import ProgressBar from "../../components/loader/progressBar";
import { useSelector } from "react-redux";
import KycError from "../../components/kycError";
export default function Analytics() {
  const { auth } = useSelector(store => store.auth)
  const [stores, setStores] = useState([]);
  const [analyticsData, setAnalyticsData] = useState({
    orders: {},
    ranks: {},
    balance: {},
    users: {},
  });
  const [loading, setLoading] = useState(false);
  const [activeStoreFilter, setActiveStoreFilter] = useState({
    label: "",
    value: 0,
  });
  const [initialDate, setInitialDate] = useState(
    moment(new Date(new Date().setDate(new Date().getDate() - 30))).format(
      "YYYY-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  useEffect(() => {
    setLoading(true);
    getStores()
      .then((res) => {
        setStores(res);
        setActiveStoreFilter({ value: res[0].id, label: res[0].name });
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);
  const fetchData = () => {
    setLoading(true);
    try {
      fetchAnalyticsDateRange(
        moment(initialDate).format("YYYY-MM-DD HH:MM"),
        moment(endDate).format("YYYY-MM-DD HH:MM"),
        activeStoreFilter.value
      ).then((res) => {
        setAnalyticsData(res);
        setLoading(false);
      });
      fetchSellersCommissions(
        moment(initialDate).format("YYYY-MM-DD HH:MM"),
        moment(endDate).format("YYYY-MM-DD HH:MM")
      ).then((res) => {
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const getCurrencyStore = () => {
    const store = stores.find(
      (_store) => _store.id === activeStoreFilter.value
    );
    return store?.currency;
  };

  useEffect(() => {
    if (activeStoreFilter.value !== 0) {
      fetchData();
    }
  }, [initialDate, endDate, activeStoreFilter.value]);
  const totalCost = analyticsData.balance.cost
    ? Object.values(analyticsData?.balance?.cost).reduce(
      (value, sum) => value + sum,
      0
    ) - analyticsData.balance.cost.discount
    : "0.00";
  return (
    <div className="mb-10">
      {auth.stripe_profiles.account_stripe_KYC_status ?
        <>
          <div className="w-[250px] mx-4 flex-wrap mt-[43px] mb-[55px]">
            <StoreFilter
              stores={stores}
              activeStoreFilter={activeStoreFilter}
              setActiveStoreFilter={(value) => setActiveStoreFilter(value)}
            />
          </div>
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <ProgressBar />
            </div>
          ) : (
            <div className="mt-[55px] pt-3 sm:px-5 md:pl-5 md:pr-0 bt-solid border-t-2 border-t-grey">
              {" "}
              <div>
                <Typography variant="p" className="text-[#3F3F3F] pl-[.3rem]">
                  Orders
                </Typography>
                <div className="mt-4 flex flex-row flex-wrap w-full">
                  {Object.keys(analyticsData.balance).length > 0 && (
                    <>
                      <Card
                        count={analyticsData.balance.revenue_count.card}
                        title="Orders total"
                        value={format(
                          analyticsData.balance.revenue.card +
                          analyticsData.balance.where_go.collection +
                          analyticsData.balance.where_go.delivery,
                          getCurrencyStore()
                        )}
                        currency={getCurrencyStore()}
                        type={"profit"}
                        color={{ primary: "#20C680", secondary: "#C3FFD0" }}
                        style={{ boxShadow: "none" }}
                      />
                      <Card
                        count={analyticsData.balance.where_go_count.delivery}
                        title="Delivery"
                        value={format(analyticsData.balance.where_go.delivery)}
                        currency={getCurrencyStore()}
                        type={"profit"}
                        color={{ primary: "#20C680", secondary: "#C3FFD0" }}
                        style={{ boxShadow: "none" }}
                      />
                      <Card
                        count={analyticsData.balance.where_go_count.collection}
                        title="Collected in store"
                        currency={getCurrencyStore()}
                        value={format(analyticsData.balance.where_go.collection)}
                        color={{ primary: "#7A7A7A", secondary: "#FAFAFA" }}
                        style={{ boxShadow: "none" }}
                      />
                      <Pie
                        open={analyticsData.orders.open}
                        count={analyticsData.balance.revenue_count.card}
                        title="Payment status"
                        currency={getCurrencyStore()}
                        closed={analyticsData.orders.closed}
                        color={{ primary: "#000", secondary: "#FAFAFA" }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="mt-9">
                <Typography variant="p" className="text-[#3F3F3F] pl-[.3rem]">
                  Costs
                </Typography>
                <div className="mt-4 flex flex-row flex-wrap">
                  {Object.keys(analyticsData.balance).length > 0 && (
                    <>
                      <Card
                        count={analyticsData.balance.revenue_count.card}
                        title="Costs total"
                        value={format(totalCost)}
                        currency={getCurrencyStore()}
                        type={"cost"}
                        color={{ primary: "#FF5D5D", secondary: "#FFDADA" }}
                        style={{ boxShadow: "none" }}
                      />
                      <Card
                        count={analyticsData.balance.where_go_count.card}
                        title="Delivery"
                        value={format(
                          analyticsData.balance.cost.drivers,
                          getCurrencyStore()
                        )}
                        currency={getCurrencyStore()}
                        type={"cost"}
                        color={{ primary: "#FF5D5D", secondary: "#FFDADA" }}
                        style={{ boxShadow: "none" }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="mt-9">
                <Typography variant="p" className="text-[#3F3F3F] pl-[.3rem]">
                  Average time
                </Typography>
                <div className="mt-4 flex sm:flex-col md:flex-row flex-wrap ">
                  {Object.keys(analyticsData.orders).length > 0 && (
                    <>
                      <Line
                        title="New orders"
                        data={analyticsData.orders.news}
                        color={{ primary: "#000", secondary: "#FAFAFA" }}
                      />
                      <Line
                        title="Average value per order"
                        data={analyticsData.orders.average}
                        floatNumbers
                        total_average={analyticsData.orders.total_average}
                        color={{ primary: "#000", secondary: "#FAFAFA" }}
                        currency={getCurrencyStore()}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="mt-9">
                <Typography variant="p" className="text-[#3F3F3F] pl-[.3rem]">
                  Product ranking
                </Typography>
                <div className="mt-4 flex flex-row gap-3">
                  {Object.keys(analyticsData.ranks).length > 0 && (
                    <>
                      <TableChart
                        label1="Product"
                        label2="Quant."
                        data={analyticsData.ranks.products}
                        total_count={analyticsData.ranks.total_products}
                        total_value={analyticsData.ranks.total_products_value}
                        currency={getCurrencyStore()}
                        color={{ primary: "#000", secondary: "#FAFAFA" }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <Filters
            start={initialDate}
            setStart={(e) => setInitialDate(e)}
            end={endDate}
            setEnd={(e) => setEndDate(e)}
          />
        </>
        : <KycError profile={auth.stripe_profiles} />}

    </div>
  );
}
