import React from "react";

import Print from "./print";
import { Typography, Grid, IconButton } from "@mui/material";
import Logo from "./../../helpers/logo";

//import Print from 'components/Common/Print';
import moment from "moment";

export default function Invoice(props) {
  const { order, trigger } = props;
  return (
    <Print trigger={<div>{trigger}</div>}>
      <div style={{ width: "100%", WebkitPrintColorAdjust: "exact" }}>
        <div
          style={{
            width: "100%",
            padding: "3% 5%",
          }}
        >
          <table>
            <tr style={{ width: "100%" }}>
              <td style={{ marginTop: "19px", paddingLeft: "30px" }}>
                <Logo styling={{ width: "180px", height: "100px" }} />
              </td>
              <td style={{ textAlign: "end" }}>
                <Typography variant="h4" style={{ color: "#3a3a3a" }}>
                  INVOICE
                </Typography>
                <Typography variant="h6" style={{ color: "gray" }}>
                  {order?.order_code}
                </Typography>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  paddingTop: "65px",
                  paddingLeft: "30px",
                  display: "grid",
                }}
              >
                <td style={{ marginBottom: "15px" }}>
                  <Typography style={{ fontWeight: "bold" }}>ARBID</Typography>
                  <Typography>
                    86-90 Paul Street, London, England, United Kingdom,EC2A 4NE
                  </Typography>
                </td>
                <td style={{ marginBottom: "15px" }}>
                  <Typography style={{ color: "gray" }}>Bill to:</Typography>
                  <Typography style={{ fontWeight: "bold" }}>
                    {order?.owner?.name}
                  </Typography>
                  <Typography>{order?.billing_address}</Typography>
                </td>
              </td>
              <td style={{ width: "50%" }}>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      padding: "7px 10px",
                      display: "flex",
                      alignItems: "center",
                      flex: "12",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: "6",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography style={{ color: "gray" }}>Date:</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: "6",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography>
                        {moment(order.date_ordered).format("MMM d, yyyy")}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "7px 10px",
                      display: "flex",
                      alignItems: "center",
                      flex: "12",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: "6",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography style={{ color: "gray" }}>
                        Payment terms:
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: "6",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography>{order.payment}</Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "7px 10px",
                      display: "flex",
                      alignItems: "center",
                      flex: "12",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: "6",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography style={{ color: "gray" }}>
                        Due date:
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: "6",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography>
                        {moment(order.date_ordered).format("MMM d, yyyy")}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: "12",
                      backgroundColor: "#f5f5f5",
                      background: "#f5f5f5",
                      padding: "7px 10px",
                      borderRadius: "5px",
                      WebkitPrintColorAdjust: "exact",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: "6",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        Balance due:
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flex: "6",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        £ {order.total_order}
                      </Typography>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div style={{ marginTop: "60px", width: "100%" }}>
            <table cellSpacing={"0px"} style={{ width: "100%" }}>
              <thead style={{ borderRadius: "5px", color: "white" }}>
                <th
                  style={{
                    textAlign: "start",
                    width: "55%",
                    padding: "10px 20px",
                    backgroundColor: "#3a3a3a",
                    borderTopLeftRadius: "5px",
                    borderBottomLeftRadius: "5px",
                    WebkitPrintColorAdjust: "exact",
                  }}
                >
                  <Typography>Item</Typography>
                </th>
                <th
                  style={{
                    textAlign: "start",
                    width: "15%",
                    padding: "10px 20px",
                    backgroundColor: "#3a3a3a",
                    borderLeft: "1px solid gray",
                    WebkitPrintColorAdjust: "exact",
                  }}
                >
                  <Typography>Quantity</Typography>
                </th>
                <th
                  style={{
                    textAlign: "end",
                    width: "15%",
                    padding: "10px 20px",
                    backgroundColor: "#3a3a3a",
                    borderLeft: "1px solid gray",
                    WebkitPrintColorAdjust: "exact",
                  }}
                >
                  <Typography>Rate</Typography>
                </th>
                <th
                  style={{
                    textAlign: "end",
                    width: "15%",
                    padding: "10px 20px",
                    backgroundColor: "#3a3a3a",
                    borderTopRightRadius: "5px",
                    borderBottomRightRadius: "5px",
                    borderLeft: "1px solid gray",
                    WebkitPrintColorAdjust: "exact",
                  }}
                >
                  <Typography>Amount</Typography>
                </th>
              </thead>
              {order.items.map((item, key) => (
                <tr key={key + "item"}>
                  <td style={{ padding: "10px 20px" }}>
                    <Typography style={{ fontWeight: "bold" }}>
                      {item.product.name}
                    </Typography>
                  </td>
                  <td style={{ padding: "10px 20px" }}>
                    <Typography>{item.quantity}</Typography>
                  </td>
                  <td style={{ padding: "10px 20px", textAlign: "end" }}>
                    <Typography style={{ whiteSpace: "nowrap" }}>
                      £{" "}
                      {new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(item.product.price)}
                    </Typography>
                  </td>
                  <td
                    style={{
                      padding: "10px 20px",
                      textAlign: "end",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Typography>
                      £{" "}
                      {new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(item.product.price * item.quantity)}
                    </Typography>
                  </td>
                </tr>
              ))}
            </table>
          </div>
          <Grid
            lg={12}
            md={12}
            sm={12}
            style={{
              marginTop: "50px",
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              paddingRight: "10px",
            }}
          >
            <div style={{ width: "40%" }}>
              <div
                style={{
                  padding: "7px 10px",
                  display: "flex",
                  alignItems: "center",
                  flex: "12",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: "6",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography style={{ color: "gray" }}>Subtotal:</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: "6",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography>
                    £{" "}
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order.sub_total)}
                  </Typography>
                </div>
              </div>
              {order.delivery_fee > 0 && (
                <div
                  style={{
                    padding: "7px 10px",
                    display: "flex",
                    alignItems: "center",
                    flex: "12",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: "6",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography style={{ color: "gray" }}>
                      Delivery fee:
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flex: "6",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography>
                      £{" "}
                      {new Intl.NumberFormat("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(order.delivery_fee)}
                    </Typography>
                  </div>
                </div>
              )}
              <div
                style={{
                  padding: "7px 10px",
                  display: "flex",
                  alignItems: "center",
                  flex: "12",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: "6",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography style={{ color: "gray" }}>Tax (20%):</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: "6",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography>
                    £{" "}
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order.vat_total)}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  padding: "7px 10px",
                  display: "flex",
                  alignItems: "center",
                  flex: "12",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: "6",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography style={{ color: "gray" }}>Total:</Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: "6",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography>
                    £{" "}
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order.total_order)}
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid lg={12} md={12} sm={12} style={{ marginTop: "50px" }}>
            {order.final_comment && (
              <>
                <div>
                  <Typography style={{ color: "gray" }}>Notes:</Typography>
                  <Typography>{order.final_comment}</Typography>
                </div>
                <br />
              </>
            )}
            <div>
              <Typography style={{ color: "gray" }}>Terms:</Typography>
              <Typography>
                Goods Received, 7 (seven) day payment terms.
              </Typography>
            </div>
            <br />
            <div>
              <Typography>
                Transfers made payable to: <br />
                ARBID TECHNOLOGY AND COMMERCIAL SERVICES LTD
                <br />
                Sort Code: 20-42-76
                <br />
                Account Number: 43705412
              </Typography>
            </div>
          </Grid>
        </div>
      </div>
    </Print>
  );
}

Invoice.defaultProps = {
  disabled: false,
};
