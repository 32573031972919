import { makeStyles } from "@mui/styles";
import {
  Tooltip,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@mui/material";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
  },
  activeButton: {
    margin: "0 5px",
    color: "#fff",
    border: "none",
    backgroundColor: "#124c4d",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});

export default function EditProductsTable(props) {
  const classes = useStyles();
  const { products, selectedProducts, setSelectedProducts } = props;

  const handleProductOnPress = (selectedProduct) => {
    if (
      selectedProducts
        .map((productToAdd) => productToAdd.product_id)
        .includes(selectedProduct.product_id)
    ) {
      return setSelectedProducts(
        selectedProducts.filter(
          (prod) => prod.product_id !== selectedProduct.product_id
        )
      );
    }
    return setSelectedProducts([selectedProduct, ...selectedProducts]);
  };
  const handleProductPressAll = () => {
    setSelectedProducts(products);
  };
  const handleProductUnPressAll = () => {
    setSelectedProducts([]);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{ position: "sticky", top: 0 }}>
            <Tooltip title="Select All" arrow placement="top">
              <TableCell
                style={{
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
                title="Select all"
              >
                {selectedProducts.length === products.length ? (
                  <CheckBox onClick={() => handleProductUnPressAll()} />
                ) : (
                  <CheckBoxOutlineBlank
                    onClick={() => handleProductPressAll()}
                  />
                )}
              </TableCell>
            </Tooltip>
            <TableCell style={{ padding: "5px", position: "relative" }}>
              Product Name
            </TableCell>
            <TableCell style={{ padding: "5px", position: "relative" }}>
              Category
            </TableCell>
            <TableCell style={{ padding: "5px", position: "relative" }}>
              Price
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products?.map((product) => (
            <TableRow key={product.product_id} hover>
              <TableCell
                component="th"
                scope="row"
                style={{ padding: "5px", width: "5%" }}
              >
                {selectedProducts.some(
                  (menu_product) =>
                    menu_product.product_id === product.product_id
                ) ? (
                  <CheckBox onClick={() => handleProductOnPress(product)} />
                ) : (
                  <CheckBoxOutlineBlank
                    onClick={() => handleProductOnPress(product)}
                  />
                )}
              </TableCell>
              <TableCell style={{ padding: "5px" }}>{product.name}</TableCell>
              <TableCell style={{ padding: "5px" }}>
                {product.category}
              </TableCell>
              <TableCell style={{ padding: "5px" }}>{product.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
