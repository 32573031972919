import React from "react";
import { Delete, Close } from "@mui/icons-material";
import { IconButton, Grid, Select, FormControl, MenuItem } from "@mui/material";
import Input from "../../../../../components/textInput";
import AddIcon from "../../../../../components/addIcon";

export default function ExtrasTab({
  extras,
  setExtras,
  extrasError,
  ingredients,
  handleChange,
}) {
  const handleChangeExtra = (prop, index, value) => {
    handleChange(prop, index, value);
  };
  const handleDeleteExtras = (id) => {
    const values = [...extras];
    values[id].deleted = true;
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
    }
    setExtras(values);
  };
  const listIngredientsItems = ingredients.map((value, index) => (
    <MenuItem key={value.name + index} value={value.ingredient_id}>
      {value.name}
    </MenuItem>
  ));
  const listExtras = extras.map((item, index) => (
    <>
      {!item.deleted && (
        <tr>
          <td
            style={{
              marginTop: 10,
              marginLeft: 15,
              paddingRight: "20px",
              paddingTop: "10px",
            }}
          >
            <FormControl
              className="normalize-select"
              style={{
                marginTop: "0px",
                marginBottom: "0px",
              }}
              fullWidth={true}
              margin={"normal"}
              variant="standard"
            >
              <Select
                id="ingredient-input"
                value={item.ingredient}
                onChange={(e) => {
                  handleChangeExtra(
                    "ingredient",
                    item.extra_id,
                    e.target.value
                  );
                }}
              >
                {listIngredientsItems}
              </Select>
            </FormControl>
          </td>
          <td
            style={{
              paddingRight: "20px",
            }}
          >
            <FormControl className="fix-label" fullWidth={true} required={true}>
              <Input
                className="fix-label-input"
                id="price-input"
                type="number"
                value={item.price}
                onChange={(e) => {
                  handleChangeExtra("price", item.extra_id, e.target.value);
                }}
              />
            </FormControl>
          </td>
          <td style={{ paddingLeft: "15px" }}>
            <IconButton
              onClick={() => handleDeleteExtras(index)}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "25px",
                width: "25px",
              }}
            >
              <Close style={{
                fill: "#DDDDDD", height: "20px",
                width: "20px"
              }} />{" "}
            </IconButton>
          </td>
        </tr>
      )}
    </>
  ));

  return (
    <>
      <table className="ingredients-table-container w-full table-auto gap-10">
        <thead className="w-full">
          <th className="font-normal w-3/6">Extra</th>
          <th className="font-normal w-2/6">Price per serving</th>
          <th></th>
        </thead>
        <tbody>{listExtras}</tbody>
      </table>
      <div style={{ fontSize: "0.8vw" }}>{extrasError}</div>
      <div className="mt-4">
        <AddIcon
          onClick={() => {
            var data = {
              extra_id: 0,
              id: 0,
              ingredient: 0,
              limit: 10,
              price: "",
              is_sauce: 0,
              quantity: 0,
            };
            if (extras.length >= 1) {
              data.id = extras[extras.length - 1].id + 1;
              data.extra_id = extras.length;
            }
            setExtras(extras.concat(data));
          }}
        />
      </div>
    </>
  );
}
