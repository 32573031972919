import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Typography } from "@material-tailwind/react";

export default function ConfirmTracking({ open, handleClose, submit }) {
    const handleSubmit = () => {
        submit()
    };
    return (
        <Dialog open={open} handler={handleClose} onClose={handleClose} PaperProps={{ style: { borderRadius: '0', width: '18rem' } }}>
            <DialogTitle>
                <Typography className="font-medium pt-2 text-[#272727]">
                    Are you sure you want to proceed without a tracking number?
                </Typography>
            </DialogTitle>
            <DialogActions style={{ display: "flex", justifyContent: "space-between", padding: '16px 24px 24px 24px' }}>
                <button
                    className="bg-[#DDDDDD] rounded-[40px] w-20 py-1.5 px-4"
                    onClick={handleClose}
                >
                    <Typography style={{ color: "#FFFFFF" }}>NO</Typography>
                </button>
                <button
                    className="bg-[#612EF2] rounded-[40px] w-20 py-1.5 px-4"
                    onClick={handleSubmit}
                >
                    <Typography style={{ color: "#FFFFFF" }}>YES</Typography>
                </button>
            </DialogActions>
        </Dialog>
    );
}
