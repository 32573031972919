import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Dropzone from "../../../../components/dropzone";

export default function SecondTab({
  image,
  generateURL,
  setImage,
  video,
  setVideo,
  darkLogo,
  setDarkLogo,
  lightLogo,
  setLightLogo,
}) {
  return (
    <>
      <Grid item xs={12} sm={12}>
        <div className="mb-4">
          <Typography>Store banner</Typography>
          <div className="w-full flex items-center gap-2">
            {image ? (
              <div className="flex items-center justify-center h-[8rem]">
                <img
                  src={generateURL(image)}
                  className="h-full w-[12rem] object-cover"
                  alt=""
                />
              </div>
            ) : null}
            <Dropzone
              accept={{
                "image/jpeg": [],
                "image/png": [],
              }}
              files={image}
              placeholder={"Click or drag and drop an image here"}
              setFiles={(files) => setImage(files[0])}
            />
          </div>
        </div>
      </Grid>{" "}
      <Grid item xs={12} sm={12}>
        <div className="mb-4">
          <Typography>Store video</Typography>
          <div className="w-full flex items-center gap-2">
            {video ? (
              <div className="flex items-center justify-center relative">
                <video
                  className="h-[8rem] w-[12rem] object-cover"
                  alt=""
                  controls
                >
                  <source src={generateURL(video)} />
                </video>
                <IconButton
                  onClick={() => setVideo("")}
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    backgroundColor: "white",
                    top: "0.2rem",
                    right: "0.2rem",
                    height: "30px",
                    width: "30px",
                  }}
                >
                  <Close />
                </IconButton>
              </div>
            ) : null}
            <Dropzone
              accept={{
                "video/*": [],
              }}
              files={video}
              placeholder={"Click or drag and drop a video here"}
              setFiles={(files) => setVideo(files[0])}
            />
          </div>
        </div>
      </Grid>
      <div className="mb-4">
        <Grid item xs={6} sm={6}>
          <Typography>Dark store logo</Typography>
          <div className="w-full flex items-center gap-2">
            {image ? (
              <div className="flex items-center justify-center">
                <img
                  src={generateURL(darkLogo)}
                  className="h-[8rem] w-[12rem] object-contain bg-black"
                  alt=""
                />
              </div>
            ) : null}
            <Dropzone
              accept={{
                "image/jpeg": [],
                "image/png": [],
              }}
              files={darkLogo}
              placeholder={"Click or drag and drop an image here"}
              setFiles={(files) => setDarkLogo(files[0])}
            />
          </div>
        </Grid>
      </div>
      <div className="mb-4">
        <Grid item xs={6} sm={6}>
          <Typography>Light store logo</Typography>
          <div className="w-full flex items-center gap-2">
            {image ? (
              <div className="flex items-center justify-center">
              <img
                  src={generateURL(lightLogo)}
                  className="h-[8rem] w-[12rem] border object-contain"
                  alt=""
                />
              </div>
            ) : null}
            <Dropzone
              accept={{
                "image/jpeg": [],
                "image/png": [],
              }}
              files={lightLogo}
              placeholder={"Click or drag and drop an image here"}
              setFiles={(files) => setLightLogo(files[0])}
            />
          </div>
        </Grid>
      </div>
    </>
  );
}
