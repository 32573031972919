import Summary from "./summary";
import ProductSearch from "../../components/productSearch";
import ProductList from "./productList";
export default function ProductAndSummary({
  products,
  storeData,
  selectedProducts,
  selectProduct,
  selectedProduct,
  inCreaseAmount,
  decreaseAmount,
  deliveryFee,
  delivery_fee_calculated,
  calculateDeliveryFee,
  search,
  setSearch,
  addExtra,
  removeExtra,
  deselectProduct,
  pickSize,
  submit,
  orderComplete,
  typeOfOrder,
  reset,
  orderData,
  query,
  categories,
  selectedVariation,
  pickVariation,
  increaseVariationAmount,
  decreaseVariationAmount,
  isPaid,
  paymentMethod,
  giftcard,
  setGiftCard,
  giftCardError,
  giftcardValue,
  checkGiftcard,
  editOrderView,
  getStoreURL
}) {
  return (
    <div className="flex flex-col w-full md:flex-row">
      <div className="mx-2 mb-8 mt-2 w-full md:w-2/3">
        <ProductSearch
          value={search}
          handleChange={(e) => setSearch(e)}
          placeholder={"Search products"}
        />

        <ProductList
          products={products}
          selectedProducts={selectedProducts}
          selectProduct={(product) => selectProduct(product)}
          selectedProduct={selectedProduct}
          storeData={storeData}
          inCreaseAmount={(product) => inCreaseAmount(product)}
          decreaseAmount={(product) => decreaseAmount(product)}
          addExtra={(product, extra) => addExtra(product, extra)}
          removeExtra={(product, extra) => removeExtra(product, extra)}
          deselectProduct={(product) => deselectProduct(product)}
          pickSize={(product, size) => pickSize(product, size)}
          query={query}
          categories={categories}
          selectedVariation={(product_id) => selectedVariation(product_id)}
          pickVariation={(selected_variaiton, product_id) =>
            pickVariation(selected_variaiton, product_id)
          }
          increaseVariationAmount={(variation_id, product_id) => {
            increaseVariationAmount(variation_id, product_id);
          }}
          decreaseVariationAmount={(variation_id, product_id) => {
            decreaseVariationAmount(variation_id, product_id);
          }}
        />
      </div>
      <div className="w-full md:w-1/3 mx-2 my-8 pr-3">
        <Summary
          items={selectedProducts}
          typeOfOrder={typeOfOrder}
          deliveryFee={deliveryFee}
          delivery_fee_calculated={delivery_fee_calculated}
          calculateDeliveryFee={() => calculateDeliveryFee()}
          submit={(e) => submit(e)}
          orderComplete={orderComplete}
          reset={() => reset()}
          orderData={orderData}
          storeData={storeData}
          isPaid={isPaid}
          paymentMethod={paymentMethod}
          giftcard={giftcard}
          setGiftCard={(e) => setGiftCard(e)}
          giftCardError={giftCardError}
          giftcardValue={giftcardValue}
          checkGiftcard={() => checkGiftcard()}
          editOrderView={editOrderView}
          getStoreURL={getStoreURL}
        />
      </div>
    </div>
  );
}
