import moment from "moment";
import format from "../../utils/formatValues";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/alert";
import { IconButton, Button, Tooltip } from "@mui/material";
import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import CanelOrderModal from "./cancelOrderModal";
import CanelAndRefundModal from "./cancelAndRefundModal";
import {
  Print,
  MapOutlined,
  EventAvailable,
  NavigateNext,
  GpsFixed
} from "@mui/icons-material";
import Receipt from "../../components/receipt";
import ModalScheduleDate from "./modalScheduleDate";
import { nextStatus, refundOrder } from "../../services/orders";
import { useState } from "react";
import refundIcon from "./../../assets/icons/refund.svg";
import TrackinNumberModal from "./addTrackingNumber";
import ConfirmTracking from "./confirmTracking";
export default function OrderCard({ order, status, storeData }) {
  const colors = {
    neworders: "#FFDADA",
    neworders_dark: "#FF5D5D",
    doing: "#FFE5CE",
    doing_dark: "#FF8A00",
    done: "#FFFBA7",
    done_dark: "#E6C200",
    ontheway: "#E8FFC3",
    ontheway_dark: "#ABD500",
    delivered: "#C3FFD0",
    delivered_dark: "#20C680",
    text: "#3F3F3F",
  };

  const [cancelModal, setCancelModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [modalScheduleDate, setModalScheduleDate] = useState(false);
  const [addTracking, setAddTracking] = useState(false)
  const [confirmTracking, setConfirmTracking] = useState(false)
  const dispatch = useDispatch();
  const statusColor = () => {
    var _status = status.replaceAll(/\s/g, "").toLowerCase();
    return colors[_status];
  };
  const statusColor_dark = () => {
    var _status = status.replaceAll(/\s/g, "").toLowerCase();
    return colors[_status + "_dark"];
  };
  var date = new Date(order?.date_delivery);
  const next = () => {
    if (status === "doing" && !order.delivery_tracking_number && order.type_of_order.includes("D")) {
      setConfirmTracking(true)
    }
    nextStatus(order.order_id)
      .then(() => { })
      .catch((e) => {
        dispatch(notify({ message: e, severity: "error" }));
      });
  };

  const handleConfirmTracking = () => {
    nextStatus(order.order_id)
      .then(() => { })
      .catch((e) => {
        setConfirmTracking(false)
        dispatch(notify({ message: e, severity: "error" }));
      });
  }
  const refund = () => {
    refundOrder(order.order_id)
      .then(() => { })
      .catch((e) => {
        dispatch(notify({ message: e, severity: "error" }));
      });
  };
  return (
    <div className="h-auto pt-1 px-1">
      <Card
        className={`h-full w-full rounded-md p-0 relative`}
        style={{ backgroundColor: statusColor(), boxShadow: "none" }}
      >
        <CardBody className="p-0 relative" style={{ boxShadow: "none" }}>
          <div className={`p-3`}>
            <p style={{ color: colors.text }}>
              {date
                .toLocaleString("default", { weekday: "long" })
                .replace(/^\w/, (c) => c.toUpperCase())}{" "}
              {date.getDate()}{" "}
              {date.toLocaleString("default", { month: "long" })}
            </p>
            <p style={{ color: colors.text }}>{moment(date).format("hh:mm")}</p>
            <p style={{ color: order.is_paid ? "#20C680" : "#FF5D5D" }}>
              {order.is_paid
                ? "Paid"
                : order.payment === "Card"
                  ? "Requires capture"
                  : "Not paid"}
            </p>
            <p style={{ color: statusColor_dark() }}>
              {order?.owner?.name.split(" ")[0]}
            </p>
            {order.payment === "none" ? (
              <p style={{ color: "red" }}>Abandoned cart</p>
            ) : null}
          </div>
          <div
            className="flex flex-col p-3 gap-0.5"
            style={{ backgroundColor: statusColor_dark() }}
          >
            {order.items.map((item) => (
              <>
                {item.quantity > 0 && (
                  <div className="flex justify-between w-full">
                    <p className="w-4/5" style={{ color: statusColor() }}>
                      {item?.product?.name}
                    </p>
                    <p
                      className="w-1/5 text-right whitespace-nowrap"
                      style={{ color: statusColor() }}
                    >
                      × {item?.quantity}
                    </p>
                  </div>
                )}
                {item.quantity > 0 &&
                  item.extra_orders.length > 0 &&
                  item.extra_orders.map((extra) => (
                    <div className="flex justify-between ml-3">
                      <p className="w-4/5" style={{ color: statusColor() }}>
                        {extra.quantity}× {extra.ingredient}
                      </p>
                    </div>
                  ))}
              </>
            ))}
          </div>
          <div className={`p-3`}>
            <p style={{ color: statusColor_dark() }}>
              Total: {format(order.total_order)} {storeData.currency}
            </p>
            <p style={{ color: statusColor_dark() }}>
              Type:{" "}
              {order.type_of_order.includes("-D") ? "Delivery" : "Collection"}
            </p>
            <p style={{ color: statusColor_dark() }}>
              Order: {order.order_code}
            </p>
          </div>
        </CardBody>
        <CardFooter
          className={`p-3 flex flex-row flex-wrap justify-between items-center mt-8 w-full`}
          style={{ boxShadow: "none" }}
        >
          {status !== "New orders" ? (
            <>
              {order.is_paid === true && order.payment === "Card" ? (
                <Tooltip title="Cancel and refund">
                  <Button
                    size="small"
                    onClick={() => setRefundModal(true)}
                    style={{ minWidth: "0px", color: "#ff5a23" }}
                  >
                    <img src={refundIcon} alt="" style={{ height: "25px" }} />
                  </Button>
                </Tooltip>
              ) : (
                <IconButton
                  onClick={() => setCancelModal(true)}
                  style={{
                    backgroundColor: "#FF5D5D",
                    height: "30px",
                    width: "30px",
                  }}
                  className="h-fit"
                >
                  <CloseIcon style={{ fill: "white", width: "16px" }} />
                </IconButton>
              )}
              {status !== "New orders" && order?.type_of_order.includes('D') ? <IconButton onClick={() => setAddTracking(true)}><GpsFixed /></IconButton> : null}
            </>
          ) : null}
          {order.stuart_delivery_status === "cancelled" && status === "Done" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                // onClick={() => reorder_stuart()}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Reorder delivery
              </Button>
            </div>
          ) : (
            <>
              {status === "New orders" ? (
                <>
                  <IconButton
                    onClick={() => setCancelModal(true)}
                    style={{
                      backgroundColor: "#FF5D5D",
                      height: "30px",
                      width: "30px",
                    }}
                    className="h-fit"
                  >
                    <CloseIcon style={{ fill: "white", width: "16px" }} />
                  </IconButton>
                  <button
                    onClick={() => next()}
                    style={{ backgroundColor: "#20C680" }}
                    className="rounded-full py-1 px-3 text-white h-fit"
                  >
                    Accept
                  </button>
                </>
              ) : (
                <Receipt
                  storeData={storeData}
                  order={order}
                  trigger={
                    <IconButton size="medium" title="Print">
                      <Print />
                    </IconButton>
                  }
                />
              )}
              {status === "Doing" && (
                <IconButton
                  onClick={() => setModalScheduleDate(true)}
                  title="Schedule an alternative delivery time and date"
                >
                  <EventAvailable />
                </IconButton>
              )}
              {status === "Done" && order.stuart_tracking_url && (
                <IconButton title="Track delivery by Stuart">
                  <MapOutlined />
                </IconButton>
              )}
              {status === "Doing" && (
                <>
                  {order?.type_of_order.includes("-C") ? (
                    <IconButton
                      size="small"
                      onClick={() => next()}
                      style={{ minWidth: "0px" }}
                      title="Next"
                    >
                      <NavigateNext />
                    </IconButton>
                  ) : (
                    moment().diff(order?.date_delivery, "days") < 1 &&
                    order?.type_of_order.includes("-D") && (
                      <IconButton
                        onClick={() => next()}
                        size="small"
                        //onClick={setKitchenStatus}
                        style={{ minWidth: "0px" }}
                        title="Request Driver"
                      >
                        <NavigateNext />
                      </IconButton>
                    )
                  )}
                </>
              )}
              {status === "Done" && order.stuart_tracking_url
                ? null
                : status !== "Doing" &&
                status !== "New orders" && (
                  <IconButton
                    size="small"
                    onClick={() => next()}
                    style={{ minWidth: "0px" }}
                    title="Next"
                  >
                    <NavigateNext />
                  </IconButton>
                )}
            </>
          )}
        </CardFooter>
      </Card>
      <CanelOrderModal
        open={cancelModal}
        handleClose={() => setCancelModal(false)}
        order={order}
      />
      <CanelAndRefundModal
        open={refundModal}
        handleClose={() => setRefundModal(false)}
        order={order}
      />
      <ModalScheduleDate
        open={modalScheduleDate}
        onClose={() => setModalScheduleDate(false)}
        order={order}
      />
      <TrackinNumberModal
        open={addTracking}
        onClose={() => setAddTracking(false)}
        order={order}
      />
      <ConfirmTracking
        open={confirmTracking}
        onClose={() => setConfirmTracking(false)}
        submit={handleConfirmTracking}
      />
    </div>
  );
}
