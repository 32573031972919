import { Collapse, IconButton, Typography, Grid } from "@mui/material";
import { useState } from "react";
import format from "./../../utils/formatValues";
import Timeline from "./timeline";
import arrow from "../../assets/images/arrow.svg"

export default function Order({ order, currencyStore }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col w-full h-auto bb-solid border-b-2 border-b-grey pl-[1.5rem]">
      <div
        onClick={() => setOpen(!open)}
        className="flex flex-row items-center cursor-pointer pt-[.2rem] pb-2"
      >
        <Typography className="text-[#272727] w-28 pb-2">
          {order.order_code}
        </Typography>
        <div className="pl-32">
          <IconButton >
          <img src={arrow} style={{
                transform: `rotate(${open ? 0 : 180}deg)`,
                transition: "transform 400ms",
              }}/>

          </IconButton>
        </div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="pt-3">
          <div className="w-full gap-2 flex flex-row">
            <div className="w-60">
              <Typography>Information</Typography>
              {order.store_name && (
                <Typography  className="text-[#2727274D]">
                  Store: {order.store_name}
                </Typography>
              )}
              {order?.owner?.name && (
                <Typography  className="text-[#2727274D]">
                  Client: {order?.owner?.name === "guest last_name" ? "Guest" : order.owner.name}
                </Typography>
              )}
              {order.order_code && (
                <Typography  className="text-[#2727274D]">
                  Type:{" "}
                  {order.order_code.includes("-D") ? "Delivery" : "Collection"}
                </Typography>
              )}
              {["Canceled", "Canceld and Refunded"].includes(order.status) && (
                <Typography  variant="overline" style={{ color: "red" }}>
                  CANCELED
                </Typography>
              )}
            </div>

            <div className="w-60">
              <Typography >Order summary</Typography>
              {order?.items.length > 0 &&
                order.items.map((item, idx) => (
                  <>
                    {item.quantity > 0 && (
                      <Grid key={item.name + idx}>
                        <Typography  className="text-[#2727274D]">
                          {`${item?.quantity} x ${item?.product?.name}`}
                        </Typography>
                        {item.comment && (
                          <Typography 
                            className="text-[#2727274D]"
                            style={{
                              fontStyle: "italic",
                              marginLeft: "12px",
                            }}
                          >
                            "{item.comment}"
                          </Typography>
                        )}
                        {item?.extra_orders.map((extra) => (
                          <Grid key={extra.id}>
                            <Typography
                              className="text-[#2727274D] "
                              style={{ marginLeft: 15 }}
                            >
                              {`${extra?.quantity}x ${extra?.ingredient}`}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </>
                ))}
            </div>

            <div className="w-60">
              <Typography >Payment details</Typography>
              <Typography  className="text-[#2727274D]">
                Subtotal:{" "}
                {format(
                  (
                    Number(order?.total_order?.toFixed(2)) -
                    Number(order?.delivery_fee)
                  ).toFixed(2)
                )}{" "}
                {currencyStore}
              </Typography>
              <Typography  className="text-[#2727274D]">
                Total: {format(order?.total_order?.toFixed(2))} {currencyStore}
              </Typography>
              {order.payment && (
                <Typography  className="text-[#2727274D]">
                  Payment: {order.payment}
                </Typography>
              )}{" "}
              <br />
            </div>

            <div className="w-60">
              <Typography >Delivery</Typography>
              {order.order_code && (
                <Typography  className="text-[#2727274D]">
                  Type:{" "}
                  {order.order_code.includes("-D") ? "Delivery" : "Collection"}
                </Typography>
              )}
              <Typography  className="text-[#2727274D] w-[13rem]">
                Address:{" "}
                {order.delivery_address ? `${order.delivery_address}` : "-"}
              </Typography>
            </div>
          </div>
          <Timeline order={order} />
        </div>
      </Collapse>
    </div>
  );
}
