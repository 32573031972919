import { Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
function CustomChart(props) {
  const { data, floatNumbers } = props;
  return (
    <div className="center-line-chart" style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey="name" tick={{ fontSize: 14 }} />
          <YAxis />
          <Tooltip />
          <Line
            type="monotone"
            dataKey={floatNumbers ? "average" : "quantity"}
            stroke="#124c4d"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
export default function LineChartCard({
  data,
  title,
  color,
  floatNumbers,
  total_average,
  currency
}) {
  let dataFormated;

  if (floatNumbers) {
    dataFormated = data.map((record) => ({
      name: record.name,
      média: record.amount.toFixed(2),
    }));
  } else {
    dataFormated = data.map((record) => ({
      name: record.name,
      quantidade: record.amount,
    }));
  }
  return (
    <div
      style={{ backgroundColor: color.secondary }}
      className={`px-2 py-4 h-[24rem] rounded-2xl relative mx-1 my-1 md:w-[48%] sm:w-full`}
    >
      <div className="top-2 right-2 left-2 flex flex-row items-center justify-between">
        <Typography style={{ color: "#3F3F3F", paddingLeft: ".8rem" }}>
          {title}
        </Typography>
        {total_average ? (
          <Typography className="!mr-4" style={{ color: "#20C680" }}>
           {total_average.toFixed(2)} {currency} 
          </Typography>
        ) : null}
      </div>
      <div className="h-full flex flex-col items-center justify-center py-6">
        <CustomChart
          data={dataFormated}
          floatNumbers={floatNumbers ? true : false}
        />
      </div>
    </div>
  );
}
