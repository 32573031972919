import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import fileIcon from "./../../assets/images/file-icon.png";
export default function MyDropzone({
  setFiles,
  placeholder,
  accept,
  files,
  max,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
    maxFiles: max,
  });
  return (
    <div
      className="p-10 border-dashed border-[#eeeeee] border-2 cursor-pointer h-[9rem] w-full"
      {...getRootProps()}
    >
      <input type="file" {...getInputProps()} />
      <div className="flex items-center justify-center h-full">
        {isDragActive ? (
          <p>Drop the files here ...</p>
        )  : (
          <p>{placeholder}</p>
        )}
      </div>
    </div>
  );
}
