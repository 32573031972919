import { useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CalendarIcon from "../../assets/icons/Calendarcon2.svg";

export default function SelectDatePicker({ date, setDate, dateError, time, setTime }) {

  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setDate(formattedDate);
  };

  const DatePickerWithIcon = ({ min, value, onChange, className }) => {
    const [open, setOpen] = useState(false);

    const handleIconClick = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div className={className} style={{ position: "relative" }}>
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={handleClose}
          value={value}
          onChange={onChange}
          min={min}
        />

        <IconButton
          onClick={handleIconClick}
          style={{ position: "absolute", right: "1.7rem" }}
        >
          <img src={CalendarIcon} alt="Calendar Icon" />
        </IconButton>
      </div>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="flex gap-3 items-center">
        <div className="mt-4 w-1/2">
          <DatePicker
            value={dayjs(date)}
            min={new Date()}
            onChange={handleDateChange}
            className="custom-datepicker-full custom-timepicker add-padding-to-input"
          />
          {dateError && !date ? (
            <p className="text-[red] mt-1 ml-1">This field cannot be empty</p>
          ) : null}
        </div>
        <div className="mt-4 w-1/2">
          <TimePicker
            value={dayjs(time, "HH:mm")}
            onChange={(value) => setTime(value.format("HH:mm"))}
            className="custom-datepicker-full custom-timepicker add-padding-to-input"
          />
        </div>
      </div>
    </LocalizationProvider>
  );
}