import React, { useState, useEffect } from "react";

import {
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
  IconButton,
  DialogActions,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { makeStyles } from "@mui/styles";
// import Typography from '@material-ui/core/Typography';
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import {
  addRelatedProduct,
  removeRelatedProduct,
} from "../../../../services/products";
import { notify } from "../../../../redux/features/alert";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  activeButton: {
    margin: "0 5px",
    color: "#fff",
    border: "none",
    backgroundColor: "#124c4d",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
});
export default function RelatedProductsModal(props) {
  const dispatch = useDispatch();
  const { onClose, open, product, products, reset } = props;
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [removedProducts, setRemovedProducts] = useState([]);
  const handleClose = () => {
    onClose();
  };
  const classes = useStyles();
  const handleProductPressAll = () => {
    setRemovedProducts([]);
    setSelectedProducts(
      products.filter((_product) => _product.product_id !== product.product_id)
    );
  };
  const pushRemove = (product_id) => {
    var removed = [];
    removedProducts.map((el) => {
      if (!removed.includes(el)) {
        removed.push(el);
      }
    });
    if (!removed.includes(product_id)) {
      removed.push(product_id);
    }
    setRemovedProducts(removed);
  };
  const deleteRemove = (product_id) => {
    var removed = [];
    removedProducts.map((el) => {
      if (el !== product_id) {
        removed.push(el);
      }
    });
    setRemovedProducts(removed);
  };
  const handleProductUnPressAll = () => {
    setRemovedProducts(
      selectedProducts.map((_product) => {
        return _product.product_id;
      })
    );
    setSelectedProducts([]);
  };
  const handleProductOnPress = (selectedProduct) => {
    if (
      selectedProducts
        .map((productToAdd) => productToAdd.product_id)
        .includes(selectedProduct.product_id)
    ) {
      return setSelectedProducts(
        selectedProducts.filter(
          (prod) => prod.product_id !== selectedProduct.product_id
        )
      );
    }
    return setSelectedProducts([selectedProduct, ...selectedProducts]);
  };
  const handleRemovedProducts = async () => {
    return new Promise((resolve, reject) => {
      try {
        if (removedProducts.length === 0) {
          resolve();
        }
        removedProducts.map((el, idx) => {
          removeRelatedProduct(el, product.product_id);
          if (idx === removedProducts.length - 1) {
            resolve();
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleAddedProducts = async () => {
    return new Promise((resolve, reject) => {
      try {
        if (selectedProducts.length === 0) {
          resolve();
        }
        selectedProducts.map((el, idx) => {
          addRelatedProduct(el.product_id, product.product_id);
          if (idx === selectedProducts.length - 1) {
            resolve();
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  };
  const submit = async () => {
    if (removedProducts.length === 0 && selectedProducts.length === 0) {
      handleClose();
    } else {
      Promise.all([handleRemovedProducts(), handleAddedProducts()])
        .then(() => {
          dispatch(
            notify({
              message: "Related products successfully edited",
              severity: "success",
            })
          );
          setTimeout(() => {
            reset();
            handleClose();
          }, 500);
        })
        .catch((e) => {
          console.log("error related", e);
          dispatch(
            notify({
              message: e,
              severity: "error",
            })
          );
        });
    }
  };
  useEffect(() => {
    if (product?.product_id) {
      var filteredProducts = [];
      product.related_products.forEach((related) => {
        var selectedRelated = products.filter(
          (element) => element.product_id === related.related_product.product_id
        );
        filteredProducts.push(selectedRelated[0]);
      });
      setSelectedProducts(filteredProducts);
    }
  }, [product]);
  return (
    <Dialog
      onClose={handleClose}
      className="relative"
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogContent className="!px-10 !pt-0">
        <div className="pt-5 pb-10 ">
          <div className="w-full flex flex-row items-center justify-between gap-5">
            <Typography variant="p">
              Related products of {product?.product_id ? product.name : null}
            </Typography>
            <IconButton
              onClick={() => handleClose()}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <TableContainer>
            <Table
              stickyHeader
              className={classes.table}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <Tooltip title="Select All" arrow placement="top">
                    <TableCell
                      style={{
                        padding: "5px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {selectedProducts?.length === products?.length - 1 ? (
                        <CheckBox onClick={() => handleProductUnPressAll()} />
                      ) : (
                        <CheckBoxOutlineBlank
                          onClick={() => handleProductPressAll()}
                        />
                      )}
                    </TableCell>
                  </Tooltip>
                  <TableCell style={{ padding: "5px" }}>Product Name</TableCell>
                  <TableCell style={{ padding: "5px" }}>Category</TableCell>
                  <TableCell style={{ padding: "5px" }}>Price</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {product?.product_id && products?.length > 0 ? (
                  <>
                    {products?.map(
                      (_product) =>
                        _product.product_id !== product.product_id && (
                          <TableRow key={_product.product_id} hover>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ padding: "5px", width: "5%" }}
                            >
                              {selectedProducts.some((related) => {
                                return (
                                  related?.product_id === _product?.product_id
                                );
                              }) ? (
                                <CheckBox
                                  onClick={() => {
                                    pushRemove(_product.product_id);
                                    handleProductOnPress(_product);
                                  }}
                                />
                              ) : (
                                <CheckBoxOutlineBlank
                                  onClick={() => {
                                    if (
                                      removedProducts.includes(
                                        _product.product_id
                                      )
                                    ) {
                                      deleteRemove(_product.product_id);
                                    }
                                    handleProductOnPress(_product);
                                  }}
                                />
                              )}
                            </TableCell>
                            <TableCell style={{ padding: "5px" }}>
                              {_product.name}
                            </TableCell>
                            <TableCell style={{ padding: "5px" }}>
                              {_product.category}
                            </TableCell>
                            <TableCell style={{ padding: "5px" }}>
                              {_product.price}
                            </TableCell>
                          </TableRow>
                        )
                    )}
                  </>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-end  bg-white w-full ml-[-30px] mt-[-20px] px-8 pb-3">
          <button
            onClick={(e) => submit()}
            className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
          >
            <Typography>Submit</Typography>
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
