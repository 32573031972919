import React from "react";
import { Grid } from "@mui/material";
import Editor from "../../../../components/CKeditor";

export default function FifthTab({ privacy, setPrivacy }) {
  return (
    <>
      <Grid item xs={12} spacing={5} sm={12} style={{ marginBottom: "35px" }}>
        <Editor
          placeholder={"Privacy policy"}
          value={privacy}
          handleChange={(e) => setPrivacy(e)}
        />
      </Grid>
    </>
  );
}
