import search from "./../../assets/images/search.svg";
export default function BottomSearch({ value, handleChange, placeholder }) {
  return (
    <div className="border h-[43px] border-[#612EF24D] rounded-full items-center flex flex-row fixed bottom-[1.5rem] right-0 sm:mr-1 md:mr-[2.5rem] sm:max-w-[25rem] md:max-w-[50rem] bg-white rounded-full">
      <img src={search} className="pl-2 w-[33.4px] h-[16px]" />
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        className="border-0 focus:border-0 text-[#612EF2] placeholder-[#612EF2] pl-2 outline-none w-[85%]"
      />
    </div>
  );
}
