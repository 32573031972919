import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Container,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import SwitchButton from "../../../components/switchButton";
import { editGiftCard } from "../../../services/products";
import { useDispatch } from "react-redux";
import { notify } from "../../../redux/features/alert";
import { useState, useEffect } from "react";
export default function EditGiftcardModal({
  open,
  handleClose,
  attemptClose,
  refresh,
  store,
  giftcard,
}) {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [active, setActive] = useState(false);
  const [codeError, setCodeError] = useState("");
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const fillData = () => {
    setCode(giftcard.code);
    setActive(giftcard.active);
  };
  useEffect(() => {
    fillData();
  }, []);
  const checkForm = () => {
    if (!code) {
      setCodeError(true);
      return true;
    }
    return false;
  };
  const submit = () => {
    if (!checkForm()) {
      setLoading(true);
      editGiftCard(
        {
          code,
          status: active,
        },
        giftcard.id
      )
        .then(() => {
          setLoading(false);

          dispatch(
            notify({
              message: "Gift card successfully edited",
              severity: "success",
            })
          );

          setCode("");
          refresh();
          handleClose();
        })
        .catch((e) => {
          setLoading(false);
          dispatch(notify({ message: e, severity: "error" }));
        });
    } else {
      dispatch(
        notify({ message: "Please check the errors below", severity: "error" })
      );
    }
  };
  const onClose = () => {
    if (changed) attemptClose();
    else {
      handleClose();
    }
  };
  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center shadow-none"
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[50%] md:w-[30%]  h-auto  bg-white p-5 overflow-hidden relative">
        <div className="w-full  h-auto   overflow-y-auto">
          <div className="w-full flex flex-row items-center justify-between ">
            <Typography variant="p">Edit gift card</Typography>
            <IconButton
              onClick={() => onClose()}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <Container className="mb-7 mt-5 !mx-0 !px-0" maxWidth="md">
            <div className="flex flex-col">
              <p>Active/Inactive</p>
              <SwitchButton
                id={"giftcard edit"}
                _checked={active}
                handleClick={(id, checked) => {
                  setChanged(true);
                  setActive(checked);
                }}
              />
            </div>
            <FormControl
              className="fix-label"
              fullWidth
              style={{
                marginBottom: "40px",
                width: "100%",
                marginTop: "10px",
              }}
              required
              error={codeError !== "" && code === ""}
            >
              <InputLabel htmlFor="name-input">Code</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={code}
                onChange={(e) => {
                  setChanged(true);
                  setCode(e.target.value);
                }}
              />
              <FormHelperText>{codeError}</FormHelperText>
            </FormControl>
          </Container>
          {/*      <div className="w-0 sm:w-2/3" onClick={() => onClose()} />
           */}{" "}
        </div>
        <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-45px] ml-[-1.25rem] px-8 py-[.9rem] z-10">
          <button
            disabled={loading}
            onClick={(e) => submit()}
            className="text-white rounded-full border border-[#F2F2F2] disabled:opacity-50 flex justify-center items-center h-[28px] p-4"
          >
            <Typography>Submit</Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
