import { login } from "./../redux/features/auth";
import { notify } from "./../redux/features/alert";
import { setNetwork, setNetworkList } from "./../redux/features/network";
//import { fetch as api } from "./../utils/request";
import axios from "axios";
import { fetchAuth, fetchAuthWaiter } from "../utils/request";
import jwtDecode from "jwt-decode";
import { API_ENDPOINT, NETWORK } from "./../settings";
import { saveState } from "../helpers/state";
import { NETWORKS_LIST } from "./../settings";
export const checkAuthentication = (credentials) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetchAuth(credentials);
      const { token, user } = response;
      const claims = jwtDecode(token);
      const { exp } = claims;
      const { id, email, is_superuser, profile, stores } = user;

      var profileObject;
      profileObject = profile.stripe_profiles[0];
      var payload = {
        token,
        id,
        name: (profile !== null && profile.name) || "",
        phone: (profile !== null && profile.phone_number) || "",
        is_superuser,
        authorized: true,
        is_manager: profile.is_stripe_owner,
        is_store_owner: false,
        is_waiter: false,
        is_waiter_manager: false,
        new_user: profile !== null && profile.is_onboarding,
        email,
        exp,
        stripe_profiles: profileObject ? profileObject : null,
        account_stripe_KYC_status: is_superuser
          ? true
          : profileObject
            ? profileObject.account_stripe_KYC_status
            : false,
      };
      if (is_superuser) {
        payload.stripe_profiles = {
          network_id: parseInt(NETWORK),
          account_stripe_link: profileObject ? profileObject.account_stripe_link : "",
          account_stripe_KYC_status: true,
          account_connect_status: profileObject ? profileObject.account_stripe_link : "",
          stripe_login_link: profileObject ? profileObject.account_stripe_link : "",
        };
      }
      var networks = [];
      stores.map((store) => {
        if (
          !networks.some((e) => e.network === store.networks[0]) &&
          NETWORKS_LIST.some((e) => e.id === store.networks[0])
        ) {
          var selectedNetwork = NETWORKS_LIST.find(
            (e) => e.id === store.networks[0]
          );
          networks.push({
            network: store.networks[0],
            email: store.store_email,
            name: selectedNetwork.name,
          });
        }
      });
      payload.networks = networks
      console.log("user -> ",profile)
      //payload.new_user = true
      if (payload.new_user) {
       window.location.pathname = "/on-boarding";
      } else {
        {/*
       if (!payload.account_stripe_KYC_status) {
          window.location.pathname = "/kyc";
        }
      */}
        if (networks.length < 2) {
          const _network = networks.find(
            (el) => el.network === Number(networks[0].network)
          );
          dispatch(
            setNetwork({
              network: networks[0].network,
              email: _network.email,
            })
          );
        }
        if (networks.length >= 2) {
          dispatch(setNetworkList({ networks: networks }));
          window.location = "/network";
        } else {
          localStorage.setItem("network", networks[0].network);
          window.location.pathname = "dashboard/orders";
        }
      }
      //window.location.reload();
      dispatch(login(payload));
      saveState(payload);

      resolve();
    } catch (error) {
      var _error;
      switch (error) {
        case "Não reconhecemos seu usuário ou senha":
          _error = "We don't recognize your user or password";
          break;
        default:
          break;
      }
      dispatch(notify({ message: _error ? _error : error, severity: "error" }));
      reject(error);
    }
  });
};
export const checkAuthenticationWaiter = (credentials) => async (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { token, user } = await fetchAuthWaiter(credentials);

      const claims = jwtDecode(token);
      const { exp } = claims;
      const { id, email, is_manager, first_name, phone } = user;

      const payload = {
        token,
        id,
        name: first_name,
        phone: phone,
        is_superuser: false,
        authorized: true,
        is_manager: false,
        is_store_owner: false,
        is_waiter: !is_manager,
        is_waiter_manager: is_manager,
        new_user: false,
        email,
        exp,
        stripe_profiles: {
          network_id: 0,
          account_stripe_link: "",
          account_connect_status: "Enabled",
          stripe_login_link: "",
        },
        account_stripe_KYC_status: false,
      };
      saveState(payload);
      dispatch(login(payload));
      resolve();
    } catch (error) {
      dispatch(notify(error, "error"));
      reject();
    }
  });
};

export const sendForgotPassword = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      const request_url = `${API_ENDPOINT}users/api/clients/forgot_password/?network=${NETWORK}`;
      await axios.post(request_url, { email });
      resolve();
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const sendPassword = (uid, token, password, confirmPassword) => {
  return new Promise(async (resolve, reject) => {
    try {
      const request_url = `${API_ENDPOINT}/users/api/clients/realtime_password_reset/`;
      await axios.post(request_url, {
        uid,
        token,
        password,
        confirm_password: confirmPassword,
      });
      resolve();
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const getNetworks = async (token) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .get(`${API_ENDPOINT}products/api/networks/`, { token })
        .then((response) => {
          resolve(response.data.results);
        });
    } catch (error) {
      reject(error);
    }
  });
};
