import React from "react";

import formatDate from "date-fns/format";
import { v4 as uuidv4 } from "uuid";

import Logo from "./../../helpers/logo";
import { Typography, IconButton, Grid, Card } from "@mui/material";

import Print from "./print";
import { useStyles } from "./styles";

export default function Receipt(props) {
  const { order, trigger, storeData } = props;
  const { order_id, date_ordered, items } = order;
  const classes = useStyles();
  return (
    <Print trigger={<div>{trigger}</div>}>
      <Card
        style={{
          width: "100%",
        }}
      >
        <Grid container justify="center" className={classes.receiptHeader}>
          <Grid style={{ width: "100%" }}>
            {storeData.light_logo === null || storeData.dark_logo === null ? (
              <Logo
                styling={{
                  marginBottom: "-50px",
                  marginLeft: "40px",
                  width: "250px",
                  height: "250px",
                }}
              />
            ) : (
              <div style={{ padding: "4rem" }}>
                <img
                  src={
                    storeData.light_logo
                      ? `https://res.cloudinary.com/arbid/${storeData.light_logo}`
                      : `https://res.cloudinary.com/arbid/${storeData.dark_logo}`
                  }
                />
              </div>
            )}

            <div className={classes.receiptBorder}>
              <Typography
                style={{
                  fontSize: 22,
                  marginBottom: 25,
                }}
              >
                ORDER DETAILS
              </Typography>

              <div className={classes.receiptTextContainer}>
                <Typography className={classes.receiptTextBorder}>
                  Date
                </Typography>
                <Typography>
                  {formatDate(
                    new Date(date_ordered.slice(0, 10)),
                    "dd.MM.yyyy"
                  )}
                </Typography>
              </div>

              <div className={classes.receiptTextContainer}>
                <Typography className={classes.receiptTextBorder}>
                  Order-number
                </Typography>
                <Typography
                  style={{
                    fontSize: 22,
                  }}
                >
                  {order_id}
                </Typography>
              </div>
              <div className={classes.receiptTextContainer}>
                <div className={classes.receiptTextBorder}>
                  {items.map((item) =>
                    item.quantity > 0 ? (
                      <span key={uuidv4()}>
                        <Typography key={item?.product?.product_id}>
                          {`${item?.product?.name}`}
                        </Typography>
                        <Typography key={item?.product?.product_id}>
                          {`${item?.quantity}`}x {`${item?.product?.price}`}{" "}
                          {storeData.currency}
                        </Typography>

                        {item?.extra_orders.length > 0 ? (
                          <Typography>
                            Extras:
                            {item?.extra_orders.map((extra) => (
                              <Typography key={extra.id}>
                                <span> {extra?.quantity}x</span>
                                {` ${extra?.ingredient}`};
                              </Typography>
                            ))}
                          </Typography>
                        ) : null}
                      </span>
                    ) : null
                  )}
                </div>
              </div>

              <div className={classes.receiptTextContainer}>
                <Typography className={classes.receiptTextBorder}>
                  Service fee
                </Typography>
                <Typography>
                  {/* {Number(order?.customer_service_fee).toFixed(2)} */}
                  {new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(order?.customer_service_fee)}{" "}
                  {storeData.currency}
                </Typography>
              </div>

              <div className={classes.receiptTextContainer}>
                <Typography className={classes.receiptTextBorder}>
                  Delivery fee
                </Typography>
                {order?.type_of_order.includes("D") ? (
                  <Typography>
                    {/* {Number(order?.delivery_fee).toFixed(2)} */}
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order?.delivery_fee)}{" "}
                    {storeData.currency}
                  </Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </div>

              <div className={classes.receiptTextContainer}>
                <Typography className={classes.receiptTextBorder}>
                  Subtotal
                </Typography>
                <Typography>
                  {(
                    Number(order?.total_order?.toFixed(2)) -
                    Number(order?.delivery_fee) -
                    Number(order?.customer_service_fee)
                  ).toFixed(2)}{" "}
                  {storeData.currency}
                </Typography>
              </div>

              <div className={classes.receiptTextContainer}>
                <Typography className={classes.receiptTextBorder}>
                  VAT
                </Typography>
                <Typography>
                  {storeData.price_plus_vat ? "Included" : "Excluded"}
                </Typography>
              </div>

              <div className={classes.receiptTextContainer}>
                <Typography className={classes.receiptTextBorder}>
                  Total
                </Typography>
                <Typography>
                  {new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(order?.total_order)}{" "}
                  {storeData.currency}
                </Typography>
              </div>

              <div style={{ marginTop: 40 }}>
                <Typography>Address</Typography>
                <Typography style={{ marginTop: 2 }}>
                  {order?.owner?.name ? order?.owner?.name : null}
                </Typography>
              </div>

              {order?.type_of_order.includes("D") ? (
                <>
                  <Typography style={{ maxWidth: 200 }}>
                    {`${order?.delivery_address}`}
                  </Typography>
                </>
              ) : (
                <Typography>-</Typography>
              )}
              {order?.owner?.email || order?.owner?.user ? (
                <div style={{ marginTop: 2 }}>
                  <Typography>Email:</Typography>
                  <Typography style={{ marginTop: 2 }}>
                    {order?.owner?.user.email ? order.owner?.user.email : "-"}
                  </Typography>
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Card>
    </Print>
  );
}

Receipt.defaultProps = {
  disabled: false,
};
