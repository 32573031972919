import { fetch as api } from "./../utils/request";

export const getArtists = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = `products/api/artist/`;
      let response;
      let results = [];
      do {
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      resolve(response.results);
    } catch (error) {
      reject();
    }
  });
};
