import React from "react";
import DefaultReceipt from "./receipt";
import PetshopReceipt from "./petshopReceipt";
import { NETWORK } from "./../../settings";
import Invoice from "./NSreceipt";

export default function Receipt(props) {
  const { order, trigger, storeData } = props;

  return (
    <>
      {order !== undefined && (
        <>
          {NETWORK === "6" ? (
            <PetshopReceipt trigger={trigger} order={order} storeData={storeData} />
          ) : order.payment === "Invoice" ? (
            <Invoice trigger={trigger} order={order} storeData={storeData}/>
          ) : (
            <DefaultReceipt trigger={trigger} order={order} storeData={storeData}/>
          )}
        </>
      )}
    </>
  );
}

Receipt.defaultProps = {
  disabled: false,
};
