import { useState, useEffect } from "react";
import OrderRow from "./orderRow";

export default function OrderTable({ orders, storeData }) {
  const [sidebarTop, setSidebarTop] = useState(undefined);
  const [filterAbandoned, setFilterAbandoned] = useState(false);
  var headers = [
    {
      title: "New orders",
      status: "A fazer",
    },
    {
      title: "Doing",
      status: "Fazendo",
    },
    {
      title: "Done",
      status: "Feito",
    },
    {
      title: "On the way",
      status: "Transit",
    },
    {
      title: "Delivered",
      status: "Delivered",
    },
  ];
  const filterOrders = (status) => {
    const statuses = headers.map((element) => {
      return element.status;
    });
    var filtered = [];
    orders.map((order) => {
      if (statuses.includes(order.status)) {
        if (order.status === status) filtered.push(order);
      }
    });
    return filtered;
  };
  const incomingOrders = () => {
    return orders.filter((el) => el.payment === "none").length;
  };
  const isSticky = (e) => {
    const ordersWrapperel = document.querySelector(`.headers-wrapper`);
    const scrollTop = window.scrollY;
    if (scrollTop >= sidebarTop - 10) {
      ordersWrapperel.classList.add("is-sticky");
      resizeBar();
    } else {
      ordersWrapperel.classList.remove("is-sticky");
      resizeBar();
    }
  };

  const resizeBar = () => {
    const orderHeaderWrapper = document.getElementById("headers-wrapper");
    const scrollTop = window.scrollY;
    if (scrollTop >= sidebarTop - 10) {
      const rowsWrapper = document.getElementById("order-row").offsetWidth * 5;
      if (rowsWrapper) {
        const newWidth = rowsWrapper + 3.2 * 16;
        orderHeaderWrapper.style.width = newWidth + "px";
      }
    } else {
      orderHeaderWrapper.style.width = "auto";
    }
  };
  useEffect(() => {
    const orderHeader = document
      .querySelector(`.headers-wrapper`)
      ?.getBoundingClientRect();
    setSidebarTop(orderHeader?.top + 10);
  }, []);
  useEffect(() => {
    if (sidebarTop) {
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    } else {
      return undefined;
    }
  }, [sidebarTop]);
  useEffect(() => {
    window.addEventListener("resize", resizeBar);
    return () => {
      window.removeEventListener("resize", resizeBar);
    };
  }, []);
  var initialOffset = undefined;
  const trackHorizontalScroll = (e) => {
    const orderHeaderWrapper = document.getElementById("headers-wrapper");
    if (initialOffset === undefined) {
      initialOffset = orderHeaderWrapper.offsetLeft;
    }
    const ordersRowWrapper = document.getElementById("content-wrapper");
    orderHeaderWrapper.style.left =
      initialOffset - ordersRowWrapper.scrollLeft + "px";
  };
  useEffect(() => {
    const ordersRowWrapper = document.getElementById("content-wrapper");
    if (ordersRowWrapper) {
      ordersRowWrapper.addEventListener("scroll", trackHorizontalScroll);
      return () => {
        ordersRowWrapper.removeEventListener("scroll", trackHorizontalScroll);
      };
    } else {
      return undefined;
    }
  }, [sidebarTop]);
  return (
    <div id="test-col" className="w-full flex flex-col">
      <div
        id="headers-wrapper"
        className="headers-wrapper mb-2 flex flex-row z-20 handle-order-header !px-7 box-border"
      >
        {headers.map((head, idx) => (
          <>
            <div
              id={"order-header" + idx}
              className="w-1/5 bg-gray min-w-[200px] w-[250px] md:w-1/5 flex items-center"
            >
              <div className={`flex items-center gap-2 z-10`}>
                <p style={{ color: "#3F3F3F" }}>{head.title}</p>{" "}
                {head.title === "New orders" && (
                  <div
                  onClick={() => { {setFilterAbandoned(!filterAbandoned)}}}
                    className={`rounded-full bg-[#f44336] flex items-center justify-center cursor-pointer ${
                      incomingOrders() > 99 ? "w-7 h-7" : "w-6 h-6"
                    }`}
                  >
                    <p className="w-min text-white !text-[14px]">
                      {incomingOrders()}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        ))}
      </div>
      <div
        id={"orders-row-wrapper"}
        className="flex flex-row order-row-wrapper px-6 border-t-gray border-t-2 min-h-screen"
      >
        {headers.map((head, index) => (
          <>
            <OrderRow
              key={head.title + "header"}
              status={head.title}
              orders={filterOrders(head.status)}
              storeData={storeData}
              filterAbandoned={filterAbandoned}
            />
          </>
        ))}
      </div>
    </div>
  );
}
