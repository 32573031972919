import { fetch as api } from "./../utils/request";
import { mountQueryURL } from "../utils/query";
import { NETWORK } from "./../settings";
export const getOrders = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";

      let url = `/orders/api/orders_resumed/${query_url}`;
      let response;
      let results = [];
      do {
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      response.results = response.results.reverse();
      resolve(response.results);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const nextStatus = async (order_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/orders/api/orders/${order_id}/change_status/`,
      }).then(() => {
        resolve();
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const refundOrder = async (order_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/orders/api/orders/${order_id}/refund_order/`,
      });
      resolve(1);
    } catch (error) {
      console.log("error => ", error);
      reject(0);
    }
  });
};

export const previousStatus = async (order_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/orders/api/orders/${order_id}/revert_status/`,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const cancelStatus = async (order_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/orders/api/orders/${order_id}/cancel_order/`,
        data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const getTimeSlots = async (order) => {
  return new Promise(async (resolve, reject) => {
    try {
      var res = await api({
        method: "GET",
        url: `/orders/api/orders/${order}/get_timeslots/`,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const scheduleDeliveryDate = async (orderId, date) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/orders/api/orders/${orderId}/create_or_update_date_delivery/`,
        data: { date_delivery: date },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export function returnAddressAsString(addressObject) {
  var { street_number, street, zipcode } = addressObject;
  if (!street_number) {
    street_number = "";
  } else {
    street_number = street_number + ", ";
  }
  if (!street) {
    street = "";
  } else {
    street = street + ", ";
  }
  if (!zipcode) {
    zipcode = "";
  }
  return street_number + street + zipcode;
}

export const getdeliveryFee = (_pickup, _delivery, store, value) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api({
        method: "POST",
        url: `/orders/api/orders/get_service_delivery_estimate/`,
        data: {
          store: store,
          order_items_value: value,
          pickup_address: returnAddressAsString(_pickup),
          delivery_address: returnAddressAsString(_delivery),
          network: Number(NETWORK),
        },
      });
      resolve(JSON.parse(response));
    } catch (error) {
      reject(error);
    }
  });
};

export const createOrder = (data, edit, editId) => {
  return new Promise(async (resolve, reject) => {
    try {
      var response;
      if (edit) {
        response = await api({
          method: "PATCH",
          url: `orders/api/orders/${editId}/`,
          data: {
            ...data,
          },
        });
      } else {
        response = await api({
          method: "POST",
          url: `orders/api/orders/`,
          data: {
            ...data,
          },
        });
      }

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const checkGiftCard = async (giftcard) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (giftcard === "") {
        resolve({ status: "success", message: "", value: 0 });
      } else {
        var response = await api({
          method: "POST",
          url: `/orders/api/giftcard/get_gift_card_info/`,
          data: {
            code: giftcard,
          },
        });
        if (response.active && !response["in use"]) {
          resolve({ status: "success", value: response.balance, message: "" });
        } else {
          const errorMessage = !response.active
            ? "This gift card is not active"
            : response["in use"]
              ? "This gift card is already in use"
              : "This gift card is not valid";
          resolve({ status: "error", value: 0, message: errorMessage });
        }
      }
    } catch (error) {
      reject({
        status: "error",
        value: 0,
        message: "This giftcard does not exist",
      });
    }
  });
};
export const getTransactions = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";

      let url = `/waiter/api/waiter/get_orders_network/${query_url}`;
      await api({
        method: "GET",
        url: url,
      }).then((res) => {
        resolve(res);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const getOrderItems = (order) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api({
        method: "GET",
        url: `orders/api/orders/${order}/get_order/`,
      });
      resolve(response.items);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBalconyOrders = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query_url;
      var search = params.query;
      delete params.query;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";

      let url = `/orders/api/orders_balcony_resumed/${query_url}`;
      let response;
      let results = [];
      do {
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      if (search) {
        response.results = results.filter(
          (res) =>
            res.status === "Completed" &&
            (res.order_code.toLowerCase().includes(search.toLowerCase()) ||
              res.owner.name.toLowerCase().includes(search.toLowerCase()))
        );
      } else {
        response.results = results.filter((res) => res.status === "Completed");
      }
      // response.results = response.results.reverse();
      resolve(response.results);
    } catch (error) {
      reject(error);
    }
  });
};

export const addTrackingNumber = async (order_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/orders/api/orders/${order_id}/delivery_tracking_number/`,
        data
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  })
}