import {
  Typography,
  TableRow,
  TableCell,
  Switch,
  IconButton,
} from "@mui/material";
import EditStoreModal from "./modal/editStoreModal";
import { Edit, AccessTimeFilled } from "@mui/icons-material";
import { useState } from "react";
import ModalOpenHours from "./modal/openHoursModal";
import { forceClose } from "../../services/stores";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/alert";
import SwitchButton from "./../../components/switchButton";
import ConfirmModal from "./../../components/confirmModal";
export default function StoreRow({ store, reset, stores }) {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [openHours, setOpenHours] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleForceClose = (id, action) => {
    forceClose(
      {
        force_close: !action,
      },
      id
    )
      .then(() => {
        reset();
        dispatch(
          notify({
            message: `Store successfully ${action ? "opened" : "closed"}`,
            severity: "success",
          })
        );
      })
      .catch((e) => {
        dispatch(
          notify({
            message: e,
            severity: "error",
          })
        );
      });

    // setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <TableRow className="table-cell-fix-border" key={store.id}>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
          width: "300px",
         
        }}
      >
        <Typography className="text-[#272727]">{store.name}</Typography>
      </TableCell>
      <TableCell
        scope="row"
        style={{
          // padding: 0,
          width: "150px",
        }}
      >
        <SwitchButton
          id={store.id}
          _checked={store.force_close}
          handleClick={(id, checked) => handleForceClose(id, checked)}
        />
      </TableCell>
      <TableCell
        style={{
          width: "150px",
        }}
      >
        {" "}
        <button
          onClick={() => setEdit(true)}
          className="bg-white mr-3 px-5 rounded-full h-fit text-black border border-[#DDDDDD]"
        >
          <Typography className="text-[#272727]">Edit</Typography>
        </button>
      </TableCell>
      <TableCell
       
      >
        <button
          onClick={() => setOpenHours(true)}
          className="bg-white mr-3 px-5 rounded-full h-fit text-black border border-[#DDDDDD]"
        >
          <Typography className="text-[#272727]">Edit</Typography>
        </button>
      </TableCell>
      <EditStoreModal
        open={edit}
        onClose={() => setEdit(false)}
        stores={stores}
        reset={() => reset()}
        storeData={store}
        attemptClose={() => setConfirmModal(true)}
      />
      <ModalOpenHours
        open={openHours}
        onClose={() => setOpenHours(false)}
        store={store}
        reset={() => reset()}
        attemptClose={() => setConfirmModal(true)}
      />
      <ConfirmModal
        open={confirmModal}
        goBack={() => setConfirmModal(false)}
        handleClose={() => {
          setConfirmModal(false);
          setEdit(false);
          setOpenHours(false);
        }}
      />
    </TableRow>
  );
}
