import React from "react";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import "react-phone-input-2/lib/material.css";
import flogi from "./../../assets/images/flogi-logo.svg";
import "./styles.css";

export default function CreateWebAddress({
  handleSave,
  slug,
  setSlug,
  newUrl, setNewUrl
}) {

  const submit = () => {
    handleSave();
  };

  return (
    <div className="flex justify-center h-full">
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className="title-onboarding" style={{ margin: "3rem 0" }}>
          Add or create your web address
        </Typography>
        <div className="flex justify-center items-center w-full bg-black">
          <div className=" w-[90%] md:w-[30%] flex flex-col justify-center items-center">
            <FormControl
              className="form-onboarding"
              style={{ marginBottom: ".8rem", width: "75%" }}
            >
              <InputLabel htmlFor="name-input">Add Existing URL</InputLabel>
              <Input value={newUrl}
                disabled={slug !== ""}
                onChange={(e) => setNewUrl(e.target.value)}
                id="name-input"
                type="string" />
            </FormControl>
            <FormControl
              className="form-onboarding"
              style={{ marginBottom: ".8rem", width: "75%" }}
            >
              <InputLabel htmlFor="phone-input">Create URL</InputLabel>
              <Input
                endAdornment={
                  <Typography className="text-white">.flogi.app</Typography>
                }
                disabled={newUrl !== ""}
                id="name"
                type="string"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
              />
            </FormControl>
            <button
              onClick={submit}
              className="text-white rounded-full border bg-[#612EF2] border-[#612EF2] flex  items-center w-[75%] h-[28px] p-4 my-8"
            >
              <Typography style={{ marginTop: "0", color: "white" }}>
                Save and continue
              </Typography>
            </button>
          </div>
        </div>

        <div className="flex items-center gap-1 justify-center bg-black w-screen pb-2">
          <Typography className="text-[#7A7A7A]">Powered by</Typography>
          <img style={{ height: "12px" }} src={flogi} alt="" />
        </div>
      </Grid>
    </div>
  );
}
