import { fetch as api } from "./../utils/request";
import { mountQueryURL } from "../utils/query";

export function getIngredients(params) {
  return new Promise(async (resolve, reject) => {
    try {
      let response;
      let results = [];
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";
      var url = `products/api/ingredients/${query_url}`;
      do {
        url = url + query_url;
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      resolve(response.results);
    } catch (error) {
      reject(error);
    }
  });
}

export const createIngredient = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      var store;
      Object.keys(data).forEach((key) => {
        if (key === "store") store = data[key];
      });
      await api({
        method: "POST",
        url: "products/api/ingredients/",
        data: data,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editIngredient = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "PATCH",
        url: `products/api/ingredients/${id}/`,
        data: data,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
export const deleteIngredient = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "DELETE",
        url: `products/api/ingredients/${id}/`,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editMenuProducts = async (menu_id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response;
      response = await api({
        method: "POST",
        url: `products/api/menus/${menu_id}/add_products/`,
        data: data,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
