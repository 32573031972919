import { formatCellPhone } from "../../utils/formatValues";
import { Typography, TableRow, TableCell, Button } from "@mui/material";
import CustomerModal from "./customerOrdersModal";
import { useState } from "react";
export default function ClientRow({ client, storeCurrency }) {
  const [customerModal, setCustomerModal] = useState(false);
  return (
    <TableRow
    className="border-gray border-b-2 table-cell-fix-border"
      key={client.id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0},
      }}
    >
      <TableCell style={{padding: '10px 16px 16px 16px'}}>
        <Typography className="text-[#272727]">{client.name}</Typography>
      </TableCell>
      <TableCell scope="row">
        {" "}
        <Typography>
          {client.email ? (
            <button style={{ textTransform: "inherit" }}>
              <Typography
                style={{
                  textDecoration: "none",
                  color: "#272727",
                }}
                href={`mailto:${client.email}`}
              >
                {client.email}
              </Typography>
            </button>
          ) : null}
        </Typography>
      </TableCell>
      <TableCell>
        {" "}
        <Typography className="text-[#272727]">
          {" "}
          {client.phone_number ? formatCellPhone(client.phone_number) : ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className="text-[#2727274D]">
          {" "}
          {client.stripe_account ? client.stripe_account : ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          onClick={() => setCustomerModal(true)}
          className="text-[#2727274D] cursor-pointer"
        >
          {client.orders.length}
        </Typography>
      </TableCell>
      <CustomerModal
        open={customerModal}
        handleClose={() => setCustomerModal(false)}
        data={client.orders}
        customer={client.name}
        currency={storeCurrency}
      />
    </TableRow>
  );
}
