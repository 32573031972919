import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Button, Typography } from "@material-tailwind/react";

export default function ConfirmModal({ open, goBack, handleClose }) {
  return (
    <Dialog
      open={open}
      handler={goBack}
      PaperProps={{ style: { borderRadius: "0", width: "18rem" } }}
    >
      {" "}
      <DialogTitle>
        <Typography className="pt-2 text-[#272727]">
          Are you sure to leave without saving?
        </Typography>
      </DialogTitle>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 24px 24px 24px",
        }}
      >
        <button
          className="bg-[#DDDDDD] rounded-[40px] w-20 py-1.5 px-4"
          onClick={goBack}
        >
          <Typography style={{ color: "#FFFFFF" }}>NO</Typography>
        </button>
        <button
          className="bg-[#612EF2] rounded-[40px] w-20 py-1.5 px-4"
          onClick={handleClose}
        >
          <Typography style={{ color: "#FFFFFF" }}>YES</Typography>
        </button>
      </DialogActions>
    </Dialog>
  );
}
