import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Typography } from "@material-tailwind/react";
import { Close } from "@mui/icons-material";
export default function QrCodeModl({ open, handleClose, qrCode }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { borderRadius: "0", width: "18rem", height: "18rem" } }}
    >
      {" "}
      {/*<DialogTitle>
        <IconButton
          onClick={() => {
            handleClose();
          }}
          style={{
            backgroundColor: "#fff",
            fill: "#fff",
            border: "1px solid #DDDDDD",
            borderRadius: "50%",
            height: "30px",
            width: "30px",
          }}
        >
          <Close style={{ fill: "#DDDDDD" }} />{" "}
        </IconButton>
      </DialogTitle> */}
      <DialogContent>
        <div className="flex items-center justify-center h-full w-full">
          <img className="h-full w-auto" src={qrCode} />
        </div>
      </DialogContent>
      {/*
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 24px 24px 24px",
        }}
      >
        <button
          className="bg-[#DDDDDD] rounded-[40px] w-20 py-1.5 px-4"
          onClick={handleClose}
        >
          <Typography style={{ color: "#FFFFFF" }}>Close</Typography>
        </button>
      </DialogActions>
      */}
    </Dialog>
  );
}
