import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Container,
  IconButton,
  Typography,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { Close, Add } from "@mui/icons-material";

import { createGroup } from "../../../services/products";
import { useDispatch } from "react-redux";
import { notify } from "../../../redux/features/alert";
import { useState } from "react";
import { NETWORK } from "../../../settings";
export default function CreateGroupModal({
  open,
  handleClose,
  refresh,
  store,
  categories,
  attemptClose,
}) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(false);
  const [maximumGroup, setMaximumGroup] = useState(0);
  const [maximumSame, setMaximumSame] = useState(0);
  const [changed, setChanged] = useState(false);
  const [sections, setSections] = useState([
    {
      category: "",
      template: "",
      nameEn: "",
      namePt: "",
      id: 0,
    },
  ]);
  const checkForm = () => {
    if (!name) {
      setNameError(true);
      return true;
    }
    return false;
  };
  const handleChangeCategory = (index, value) => {
    setChanged(true);
    setSections(
      sections.map((item) =>
        item.id === index
          ? {
              ...item,
              category: value,
            }
          : item
      )
    );
  };

  const handleDelete = (id) => {
    setChanged(true);
    const values = [...sections];
    values.splice(id, 1);
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      element.id = index;
    }
    setSections(values);
  };

  const submit = () => {
    if (!checkForm()) {
      setLoading(true);
      var formatedSections = sections.map((element) => ({
        category: element.category,
        template: "product",
        nameEn: element.category,
        namePt: element.category,
      }));
      createGroup({
        banner: null,
        name,
        type_of_menu: "Take Away",
        max_quantity_of_items: maximumGroup,
        max_quantity_of_same_item: maximumSame,
        store,
        active: true,
        sections: JSON.stringify(formatedSections),
      })
        .then(() => {
          setLoading(false);

          dispatch(
            notify({
              message: "Group successfully created",
              severity: "success",
            })
          );
          refresh();
          handleClose();
        })
        .catch((e) => {
          setLoading(false);
          dispatch(notify({ message: e, severity: "error" }));
        });
    } else {
      dispatch(
        notify({ message: "Please check the errors below", severity: "error" })
      );
    }
  };
  const onClose = () => {
    if (changed) attemptClose();
    else {
      setName("");
      setMaximumGroup(0);
      setMaximumSame(0);
      setSections([
        {
          category: "",
          template: "",
          nameEn: "",
          namePt: "",
          id: 0,
        },
      ]);
      handleClose();
    }
  };
  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center shadow-none"
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[50%] md:w-[30%] max-h-[70vh]  bg-white p-5 overflow-x-hidden overflow-y-auto ">
        <div className="w-full  h-auto   overflow-y-auto">
          <div className="w-full flex flex-row items-center justify-between relative">
            <Typography variant="p">
              Add new {NETWORK === "6" ? "group" : "menu"}
            </Typography>
            <IconButton
              onClick={() => onClose()}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <Container className="mb-7 mt-5 !mx-0 !px-0 pb-7" maxWidth="md">
            <FormControl
              className="fix-label"
              fullWidth
              style={{
                marginBottom: "40px",
                width: "100%",
                marginTop: "10px",
              }}
              required
              error={nameError !== "" && name === ""}
            >
              <InputLabel htmlFor="name-input">Name</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={name}
                onChange={(e) => {
                  setChanged(true);
                  setName(e.target.value);
                }}
              />
              <FormHelperText>{nameError}</FormHelperText>
            </FormControl>
            <div className="flex flex-row items-center justify-between">
              <div className="w-[47%]">
                <FormControl
                  className="fix-label"
                  fullWidth
                  style={{
                    marginBottom: "40px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                  required
                >
                  <InputLabel htmlFor="name-input">
                    Maximum {NETWORK === "6" ? "group" : "menu"} items
                  </InputLabel>
                  <Input
                    id="name-input"
                    type="number"
                    value={maximumGroup}
                    onChange={(e) => {
                      setChanged(true);
                      setMaximumGroup(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
              <div className="w-[47%]">
                <FormControl
                  className="fix-label"
                  fullWidth
                  style={{
                    marginBottom: "40px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                  required
                >
                  <InputLabel htmlFor="name-input">
                    Maximum of the same item
                  </InputLabel>
                  <Input
                    id="name-input"
                    type="number"
                    value={maximumSame}
                    onChange={(e) => {
                      setChanged(true);
                      setMaximumSame(e.target.value);
                    }}
                  />
                </FormControl>
              </div>
            </div>
            {sections.map((section, idx) => (
              <div key={"section loop " + idx} className="flex flex-col">
                <div className="flex flex-row justify-between items-center">
                  <p>Section {idx + 1}</p>
                  <IconButton onClick={() => handleDelete(idx)}>
                    <Close />
                  </IconButton>
                </div>
                <FormControl
                  variant="standard"
                  fullWidth
                  style={{ marginBottom: "15px", marginTop: "10px" }}
                >
                  <InputLabel htmlFor="category-input">Category</InputLabel>
                  <Select
                    value={section.category}
                    onChange={(e) => handleChangeCategory(idx, e.target.value)}
                  >
                    {categories.map((el) => (
                      <MenuItem value={el.name} key={el.id + " category loop"}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ))}
            <IconButton
              onClick={() => {
                setChanged(true);
                var data = {
                  category: "",
                  template: "",
                  nameEn: "",
                  namePt: "",
                  id: 0,
                };
                if (sections.length >= 1) {
                  data.id = sections[sections.length - 1].id + 1;
                }
                setSections(sections.concat(data));
              }}
            >
              <Add />
            </IconButton>
          </Container>
          {/*      <div className="w-0 sm:w-2/3" onClick={() => onClose()} />
           */}{" "}
        </div>
        <div className="flex justify-end fixed bg-[#612EF2] w-full h-auto mt-[-43px] ml-[-1.25rem] px-8 py-[.9rem] z-10">
          <button
            disabled={loading}
            onClick={(e) => submit()}
            className="text-white rounded-full border border-[#F2F2F2] disabled:opacity-50 flex justify-center items-center h-[28px] p-4"
          >
            <Typography>Submit</Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
