import React, { useEffect, useState } from "react";
import {
  Container,
  DialogTitle,
  FormControl,
  IconButton,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Modal,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/alert";
// import moment from 'moment';
import { getTimeSlots, scheduleDeliveryDate } from "../../services/orders";
import formatDate from "date-fns/format";
import "./styles.css";
import moment from "moment";

export default function ModalScheduleDate(props) {
  const { onClose, open, order } = props;

  const handleClose = () => {
    setTimeSlots([]);
    setSelectedTime("");
    setAllValidTimeslots([]);
    setTimeSlotNotFound(false);
    setShowSubmitButton(false);
    setLoading(false);
    setSelectedTimeslot("");
    onClose();
  };

  useEffect(() => {
    if (open && !loading) {
      getOrderTimeSlots();
    }
  }, [open]);
  const dispatch = useDispatch();
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeslot] = useState("");
  const [allValidTimeslots, setAllValidTimeslots] = useState([]);

  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);

  const [timeslotNotFound, setTimeSlotNotFound] = useState(false);
  const [timeSlotsNonSelectedError, setTimeSlotsNonSelectedError] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const getOrderTimeSlots = async () => {
    setLoading(true);
    getTimeSlots(order.order_id).then((res) => {
      setLoading(false);
      if (!res.error) {
        //Set only the 5 slots starting after the order.date_delivery
        const filteredTimeslots = res.slots?.filter(
          (time) =>
            new Date(time.start_time).getTime() >
            new Date(order.date_delivery).getTime()
        );
        setAllValidTimeslots(filteredTimeslots);
        setTimeSlots(filteredTimeslots.slice(0, 5));
        setShowSubmitButton(true);
      } else {
        setTimeSlotNotFound(true);
        setShowSubmitButton(false);
      }
    });

    return null;
  };

  const addMoreTimeslots = () => {
    const currentLengthPlus = timeSlots.length + 5;
    setTimeSlots(allValidTimeslots.slice(0, currentLengthPlus));

    controlAddMoreButtonVisibility();

    return;
  };

  const controlAddMoreButtonVisibility = () => {
    if (timeSlots.length < allValidTimeslots.length) {
      setShowLoadMoreButton(true);
    } else {
      setShowLoadMoreButton(false);
    }

    return;
  };

  const handleChange = (event) => {
    setSelectedTimeslot(event.target.value);
  };

  const TimeSlotRadioGroup = () => {
    return !timeslotNotFound ? (
      <div style={{ marginBottom: "50px" }}>
        <FormControl component="fieldset" style={{ margin: "24px 12px" }}>
          <FormLabel component="legend">
            {loading ? "Loading available time slots..." : "Please select one"}
          </FormLabel>
          <RadioGroup
            aria-label="Timeslot"
            name="timeslot"
            value={selectedTimeSlot}
            onChange={handleChange}
          >
            {timeSlots.map((slot, index) => (
              <FormControlLabel
                key={`timeslotradio${index}`}
                value={slot.end_time}
                control={<Radio />}
                label={`${formatDate(
                  new Date(slot.start_time),
                  "EEE  dd/MMM HH:mm"
                )} to ${formatDate(
                  new Date(slot.end_time),
                  "EEE  dd/MMM HH:mm"
                )}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {showLoadMoreButton && !loading && (
          <div className="flex justify-center bg-white w-full pb-3">
            <button
              onClick={() => addMoreTimeslots()}
              className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
            >
              <Typography>Load more</Typography>
            </button>
          </div>
        )}
      </div>
    ) : (
      <DatePickerContent />
    );
  };

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedTime, setSelectedTime] = useState("");

  const DatePickerContent = () => {
    return (
      <div className="mt-4">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="flex justify-between items-center">
            <div className="flex gap-4">
              <FormControl
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
                margin={"normal"}
              >
                <TextField
                  className="custom-datepicker-full custom-timepicker add-padding-to-input"
                  label="Date"
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </FormControl>
              <FormControl
                style={{
                  marginTop: "0px",
                  marginBottom: "0px",
                  width: "10rem",
                }}
                margin={"normal"}
              >
                <TimePicker
                  className="custom-datepicker-full custom-timepicker add-padding-to-input"
                  label="Time"
                  value={selectedTime}
                  variant="standard"
                  onChange={(e) => {
                    setSelectedTime(e);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                />
              </FormControl>
            </div>

            <button
              disabled={selectedTime === ""}
              className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
              onClick={() => updateDeliveryDate()}
            >
              <Typography> Check availability </Typography>
            </button>
          </div>
        </LocalizationProvider>
      </div>
    );
  };

  async function submit() {
    if (!selectedTimeSlot) {
      setTimeSlotsNonSelectedError(true);
      return;
    }

    await scheduleDeliveryDate(order.order_id, selectedTimeSlot).then(() => {
      handleClose();
    });
    return;
  }
  async function updateDeliveryDate() {
    const formatedSelectedDate =
      selectedDate + " " + moment(selectedTime).format("HH:mm");
    order.date_delivery = String(
      selectedDate + " " + moment(selectedTime).format("HH:mm")
    );
    await scheduleDeliveryDate(order.order_id, formatedSelectedDate)
      .then(() => {
        dispatch(
          notify({
            message: "Date scucessfully scheduled",
            severity: "success",
          })
        );
        handleClose();
      })
      .catch((e) => {
        dispatch(
          notify({
            message: e,
            severity: "error",
          })
        );
      });
  }

  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center"
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[70%] md:w-[50%]  h-full sm:h-3/4 bg-white p-5 overflow-hidden relative">
        <div className="w-full  h-full   overflow-y-auto  p-5">
          <div className="w-full flex flex-row items-center justify-between">
            <Typography>
              {" "}
              Schedule date for order {order?.order_code}
            </Typography>
            <IconButton
              onClick={() => handleClose()}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <div className="py-2 px-0">
            <div className="mt-2">
              <Typography>
                Current scheduled date:{" "}
                {moment(order.date_delivery).format("DD/MMM HH:mm")}
              </Typography>
              <TimeSlotRadioGroup />
            </div>
          </div>
        </div>
        {showSubmitButton && (
          <div className="flex justify-end absolute bg-white w-full ml-[-30px] mt-[-35px] px-8">
            <button
              onClick={() => submit()}
              className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
              disabled={timeSlotsNonSelectedError}
            >
              <Typography>Submit</Typography>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}
