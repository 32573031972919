import { Modal, Typography, Container, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import formatDate from "date-fns/format";

export default function OrderStatusDialog(props) {
  const { order, showDialog, handleCloseDialog } = props;

  const getStatusOrder = (order) => {
    if (order.status === "Fazendo") {
      return "Doing";
    } else if (order.status === "Feito") {
      return "Done";
    } else if (order.status === "Transit") {
      return "On the way";
    } else if (order.status === "Delivered") {
      return "Delivered";
    } else if (order.status === "Canceled") {
      return "Canceled";
    } else {
      return "To do";
    }
  };

  return (
    <Modal
      open={showDialog}
      onClose={handleCloseDialog}
      className="flex flex-row h-full  items-center justify-center"
    >
      <div className="w-full sm:w-[70%] md:w-[50%]  h-full sm:h-3/4 bg-white p-5 overflow-hidden relative">
        <div className="w-full">
          <div className="w-full flex flex-row items-center justify-between ">
            <Typography variant="p">
              Order Status: {getStatusOrder(order)}
            </Typography>
            <IconButton
              onClick={() => handleCloseDialog()}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <Container className="mb-10 mt-10 !mx-0 !px-0" maxWidth="md">
            <div className="flex flex-col gap-2">
              <div className="py-2 border-b border-b-[#eeeeee]">
                <Typography>
                  To do:{" "}
                  {order?.date_ordered
                    ? formatDate(
                        new Date(order?.date_ordered),
                        "dd/MM/yyyy - hh:mm:ss"
                      )
                    : null}
                </Typography>
              </div>
              <div className="py-2 border-b border-b-[#eeeeee]">
                <Typography>
                  Doing:{" "}
                  {order?.doing_at
                    ? formatDate(
                        new Date(order?.doing_at),
                        "dd/MM/yyyy - hh:mm:ss"
                      )
                    : null}
                </Typography>
              </div>
              <div className="py-2 border-b border-b-[#eeeeee]">
                <Typography>
                  Done:{" "}
                  {order?.done_at
                    ? formatDate(
                        new Date(order?.done_at),
                        "dd/MM/yyyy - hh:mm:ss"
                      )
                    : null}
                </Typography>
              </div>
              <div className="py-2 border-b border-b-[#eeeeee]">
                <Typography>
                  On the way: {" "}
                  {order?.transit_at
                    ? formatDate(
                        new Date(order?.transit_at),
                        "dd/MM/yyyy - hh:mm:ss"
                      )
                    : null}
                </Typography>
              </div>
              <div className="py-2 border-b border-b-[#eeeeee]">
                <Typography>
                  Delivered:{" "}
                  {order?.delivered_at
                    ? formatDate(
                        new Date(order?.delivered_at),
                        "dd/MM/yyyy - hh:mm:ss"
                      )
                    : null}
                </Typography>
              </div>
              <div className="py-2 border-b border-b-[#eeeeee]">
                <Typography>
                  Complete:{" "}
                  {order?.finished_at
                    ? formatDate(
                        new Date(order?.finished_at),
                        "dd/MM/yyyy - hh:mm:ss"
                      )
                    : null}
                </Typography>
              </div>

              {order?.canceled_at && (
                <div className="py-2 border-b border-b-[#eeeeee]">
                  <Typography>
                    Canceled:{" "}
                    {order?.canceled_at
                      ? formatDate(
                          new Date(order?.canceled_at),
                          "dd/MM/yyyy - hh:mm:ss"
                        )
                      : null}
                  </Typography>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
    </Modal>
  );
}
