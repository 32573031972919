import React, { useState, useEffect } from "react";
import EditProductsTable from "./editProductsTable";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent
} from "@mui/material";
import { editMenuProducts } from "../../../services/ingredients";
import { notify } from "../../../redux/features/alert";
import { Close } from "@mui/icons-material";
export default function ModalEditMenu(props) {
  const dispatch = useDispatch();
  const { onClose, open, menu, products, refresh } = props;

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleClose = () => {
    onClose(selectedProducts);
  };

  useEffect(() => {
    setSelectedProducts(menu?.products);
  }, [menu]);

  const submitEdit = async () => {
    if (checkSaveAvailability()) {
      editMenuProducts(menu?.menu_id, {
        products: selectedProducts,
      }).then((result) => {
        handleClose();
        refresh();
      });
    } else {
      dispatch(
        notify({
          message: "You must choose at least one product.",
          severity: "warning",
        })
      );
    }
  };
  const checkSaveAvailability = () => {
    if (selectedProducts.length === 0) {
      return false;
    }
    return true;
  };

  return (
    <Dialog
      onClose={handleClose}
      className="relative"
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogContent className="!px-10 !pt-0">
        <div className="pt-5 pb-10 ">
          <div className="flex items-center justify-between">
            <p> Products of menu: {menu?.name}</p>
            <IconButton
              onClick={() => handleClose()}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          {menu ? (
            <EditProductsTable
              products={products}
              menu_products={menu?.products}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          ) : null}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-end  bg-white w-full ml-[-30px] mt-[-20px] px-8 pb-3">
          <button
            onClick={(e) => submitEdit()}
            className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
          >
            <Typography>Submit</Typography>
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
