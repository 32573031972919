import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import store from "./redux/store";
import { APP_NAME } from "./settings";
import Authentication from "./pages/Authentication/";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "./settings";
import Alert from "./components/alert";
import { ThemeProvider as TailwindThemeProvider } from "@material-tailwind/react";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import Protected from "./components/Router/PrivateRoute";
import Orders from "./pages/Orders";
import CommercialInvoice from "./pages/CommercialInvoice";
import Inventory from "./pages/Inventory";
import Transactions from "./pages/Transactions";
import Clients from "./pages/Clients";
import Stores from "./pages/Stores";
import Analytics from "./pages/Analytics";
import Completed from "./pages/Completed";
import Logout from "./pages/Logout";
import SetPassword from "./pages/SetPassword";
import Content from "./components/sidebar";
import Network from "./pages/Network";
import Onboarding from "./pages/Onboarding";
import StripePlans from "./pages/StripePlans";
import StripeKYC from "./pages/Onboarding/stripeKYC";
import FinishOnboarding from "./pages/FinishOnboarding";
let persistor = persistStore(store);

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 400,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: PRIMARY_COLOR(),
    },
    secondary: {
      main: SECONDARY_COLOR(),
    },
  },
});

const App = () => {
  useEffect(() => {
    document.title = APP_NAME;
  }, []);

  var authorized = store.getState().auth.authorized;
  useEffect(() => {
    if (
      !store.getState().auth.auth.authorized &&
      (window.location.pathname.includes("dashboard") ||
        window.location.pathname.includes("on-boarding"))
    ) {
      window.location.pathname = "/";
    }
  }, [authorized]);
  return (
    <ThemeProvider theme={theme}>
      <TailwindThemeProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Alert />
            <Router>
              <Content>
                <Routes>
                  <Route path="/" exact element={<Authentication />} />
                  <Route
                    path="/dashboard/orders"
                    element={
                      <Protected>
                        <Orders />
                      </Protected>
                    }
                  />
                  <Route
                    path="/dashboard/commercialInvoice"
                    element={
                      <Protected>
                        <CommercialInvoice />
                      </Protected>
                    }
                  />
                  <Route
                    path="/dashboard/inventory"
                    element={
                      <Protected>
                        <Inventory />
                      </Protected>
                    }
                  />
                  <Route
                    path="/dashboard/transactions"
                    element={
                      <Protected>
                        <Transactions />
                      </Protected>
                    }
                  />
                  <Route
                    path="/dashboard/clients"
                    element={
                      <Protected>
                        <Clients />
                      </Protected>
                    }
                  />
                  <Route
                    path="/dashboard/stores"
                    element={
                      <Protected>
                        <Stores />
                      </Protected>
                    }
                  />
                  <Route
                    path="/dashboard/analytics"
                    element={
                      <Protected>
                        <Analytics />
                      </Protected>
                    }
                  />
                  <Route
                    path="/dashboard/completed"
                    element={
                      <Protected>
                        <Completed />
                      </Protected>
                    }
                  />
                  <Route
                    path="/on-boarding"
                    element={
                      <Protected>
                        <Onboarding />
                      </Protected>
                    }
                  />
                  <Route
                    path="/network"
                    element={
                      <Protected>
                        <Network />
                      </Protected>
                    }
                  />
                  <Route
                    path="/plans"
                    element={
                      <Protected>
                        <StripePlans />
                      </Protected>
                    }
                  />
                  <Route
                    path="/finish-onboarding"
                    element={
                      <Protected>
                        <FinishOnboarding />
                      </Protected>
                    }
                  />
                  <Route
                    path="/kyc"
                    element={
                      <Protected>
                        <StripeKYC />
                      </Protected>
                    }
                  />
                  <Route path="/set-password" element={<SetPassword />} />
                  <Route path="/dashboard/logout" element={<Logout />} />
                  <Route
                    render={() => (
                      <>
                        <h3 className="text-center">Page Not Found</h3>
                      </>
                    )}
                  />
                  {/* </LanguageProvider> */}
                </Routes>
              </Content>
            </Router>
          </PersistGate>
        </Provider>
      </TailwindThemeProvider>
    </ThemeProvider>
  );
};

export default App;
