import { useState } from "react";
import { IconButton, TableRow, TableCell, Typography } from "@mui/material";
import CloseIconSvg from "../../../assets/icons/close_icon.svg";
import { Close } from "@mui/icons-material";

import DeleteCategoryModal from "./deleteCategoryModal";
export default function MenuRow({ category, refresh }) {
  const [deleteCategory, setDeleteCategory] = useState(false);
  return (
    <TableRow className="py-4 table-cell-fix-border">
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
          width: "350px",
        }}
        className="table-cell "
      >
        <Typography className="text-[#272727]"> {category.id}</Typography>
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
        className="sm:w-1/7 "
      >
        <Typography className="text-[#272727]">{category.name}</Typography>
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
      >
        <div className="flex items-center justify-end w-full gap-3 pr-24">
          <IconButton
            style={{
              backgroundColor: "#fff",
              fill: "#fff",
              border: "1px solid #DDDDDD",
              borderRadius: "50%",
              height: "30px",
              width: "30px",
            }}
            onClick={() => setDeleteCategory(true)}
          >
            <Close style={{ fill: "#DDDDDD" }} />{" "}
          </IconButton>
        </div>
      </TableCell>
      <DeleteCategoryModal
        category={category}
        open={deleteCategory}
        handleClose={() => setDeleteCategory(false)}
        refresh={() => refresh()}
      />
    </TableRow>
  );
}
