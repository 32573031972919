import React from "react";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import Editor from "../../../../components/CKeditor";

export default function SixthTab({
  whatsapp,
  setWhatsapp,
  whatsappError,
  handleWhatsapp
}) {
  return (
    <>
      <Grid item xs={12} spacing={5} sm={12}>
        <FormControl
          fullWidth
          className="fix-label"
          style={{ marginBottom: "20px" }}
          required={true}
          error={whatsappError !== ""}
        >
          <InputLabel htmlFor="name-input">WhatsApp Number</InputLabel>
          <Input
            id="name-input"
            type="string"
            value={whatsapp}
            onChange={handleWhatsapp}
          />
          <FormHelperText>{whatsappError}</FormHelperText>
        </FormControl>
      </Grid>

    </>
  );
}
