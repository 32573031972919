import { makeStyles } from "@mui/styles";
import { Grid, MobileStepper } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "./../../redux/features/alert";
import { fetch } from "./../../utils/request";
import { getStores } from "../../services/stores";
import { getClientData } from "../../services/clients";
import StoreContact from "./storeContact";
import CreateWebAddress from "./CreateWebAddress";
import WhatsappContact from "./WhatsappContact";
import ProgressBar from "../../components/loader/progressBar";

import moment from "moment";
import CreateUserAddress from "./CreateUserAddress";
import CreateOpenHours from "./CreateOpenHours";
import CreateStoreAddress from "./CreateStoreAddress";
import { editOpenHours } from "./../../services/onboarding";
//import AddressPage from './addAddress';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    "& .MuiMobileStepper-dotActive": {
      backgroundColor: "#612EF2",
    },
    marginTop: "-85px",
  },
});

export default function Onboarding() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  if (!auth) {
    window.location.href = "/";
  }
  const [welcomePage, setWelcomePage] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [store, setStore] = useState(0);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState("");
  const [uniqueSlugError, setUniqueSlugError] = useState(false);
  const [uniquePhoneError, setUniquePhoneError] = useState(false);
  const [uniqueEmailError, setUniqueEmailError] = useState(false);
  const [products, setProducts] = useState([]);
  const [whatsapp, setWhatsapp] = useState("");
  const [whatsappError, setWhatsappError] = useState(false);
  const [whatsAppMessage, setWhatsappMessage] = useState("");
  const [whatsAppMessageClosed, setWhatsappMessageClosed] = useState("");
  const [slug, setSlug] = useState("");
  const [newUrl, setNewUrl] = useState("");

  const [menu, setMenu] = useState({
    menu_id: 0,
    name: "",
    active: true,
    products: [],
  });
  const [storeData, setStoreData] = useState({
    name: "",
    store_email: "",
    network: 0,
  });
  const [userAddress, setUserAddress] = useState();

  const fetchStores = () => {
    setLoading(false);
    getStores().then((res) => {
      setStores(res);
      setLoading(false);
    });
  };
  const fetchUserData = () => {
    getClientData().then((res) => {
      if (res.billing_address) {
        var billing = {
          name: res.billing_address.name ?? "",
          street: res.billing_address.street ?? "",
          street_number: res.billing_address.street_number ?? "",
          neighborhood: res.billing_address.neighborhood ?? "",
          city: res.billing_address.city ?? "",
          state: res.billing_address.state ?? "",
          country: res.billing_address.country ?? "",
          active: true,
          zipcode: res.billing_address.zipcode ?? "",
          update_address: true,
          complement: res.billing_address.complement ?? "",
        };
        setUserAddress({ billing_address: billing });
      }
    });
  };
  useEffect(() => {
    fetchStores();
    fetchUserData();
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSaveStore = async (data) => {
    try {
      setLoading(true);

      const body = new FormData();
      Object.keys(data).map((key) => {
        body.append(key, data[key]);
      });
      const response = await fetch({
        method: "POST",
        headers: { "content-type": "multipart/form-data" },
        url: `products/api/stores/`,
        data: body,
      });

      setStore(response.id);

      dispatch(
        notify({
          message: "Your store has been created successfully.",
          severity: "success",
        })
      );

      handleNext();
      setLoading(false);
    } catch (error) {
      console.log("error - ", error);
      if (typeof error === "object") {
        Object.keys(error).forEach((field) => {
          switch (field) {
            case "slug":
              setUniqueSlugError(true);
            case "phone_number":
              setUniquePhoneError(true);
            case "email":
              setUniqueEmailError(true);
            default:
          }
        });
        dispatch(
          notify({
            message: "Please check the errors below",
            severity: "error",
          })
        );
      } else {
        dispatch(
          notify({
            message: error,
            severity: "error",
          })
        );
      }
      setLoading(false);
    }
  };

  const handleSaveStoreAddress = async (data) => {
    try {
      setLoading(true);
      await fetch({
        method: "PATCH",
        headers: { "content-type": "application/json" },
        url: `products/api/stores/${store}/`,
        data: data,
      });

      dispatch(
        notify({
          message: "Your store has been successfully created",
          severity: "success",
        })
      );

      handleNext();
      setLoading(false);
    } catch (error) {
      dispatch(
        notify({
          message: error,
          severity: "error",
        })
      );
      setLoading(false);
    }
  };

  const handleSaveUserAddress = async (data) => {
    setUserAddress(data);
    data.is_stripe_owner = true;
    try {
      setLoading(true);

      await fetch({
        method: "POST",
        url: `users/api/address/`,
        data,
      });

      /*
      await fetch({
        method: "GET",
        url: `users/api/clients/check_stripe_accounts?network=${NETWORK}&user_id=${auth.id}`,
      }); */

      dispatch(
        notify({
          message: "Your address was successfully created",
          severity: "success",
        })
      );
      setLoading(false);

      handleNext();
    } catch (error) {
      dispatch(
        notify({
          message: error,
          severity: "error",
        })
      );
      setLoading(false);
    }
  };

  const handleSaveOpenHours = async (days) => {
    var daysOfTheWeek = {
      id: store,
      monday: {
        opened: days[0].closedAllDAy
          ? "00:00"
          : moment(days[0].opened).format("HH:mm"),
        closed: days[0].closedAllDAy
          ? "00:00"
          : moment(days[0].closed).format("HH:mm"),
        closed_all_day: days[0].closed_all_day,
      },
      tuesday: {
        opened: days[1].closedAllDAy
          ? "00:00"
          : moment(days[1].opened).format("HH:mm"),
        closed: days[1].closedAllDAy
          ? "00:00"
          : moment(days[1].closed).format("HH:mm"),
        closed_all_day: days[1].closed_all_day,
      },
      wednesday: {
        opened: days[2].closedAllDAy
          ? "00:00"
          : moment(days[2].opened).format("HH:mm"),
        closed: days[2].closedAllDAy
          ? "00:00"
          : moment(days[2].closed).format("HH:mm"),
        closed_all_day: days[2].closed_all_day,
      },
      thursday: {
        opened: days[3].closedAllDAy
          ? "00:00"
          : moment(days[3].opened).format("HH:mm"),
        closed: days[3].closedAllDAy
          ? "00:00"
          : moment(days[3].closed).format("HH:mm"),
        closed_all_day: days[3].closed_all_day,
      },
      friday: {
        opened: days[4].closedAllDAy
          ? "00:00"
          : moment(days[4].opened).format("HH:mm"),
        closed: days[4].closedAllDAy
          ? "00:00"
          : moment(days[4].closed).format("HH:mm"),
        closed_all_day: days[4].closed_all_day,
      },
      saturday: {
        opened: days[5].closedAllDAy
          ? "00:00"
          : moment(days[5].opened).format("HH:mm"),
        closed: days[5].closedAllDAy
          ? "00:00"
          : moment(days[5].closed).format("HH:mm"),
        closed_all_day: days[5].closed_all_day,
      },
      sunday: {
        opened: days[6].closedAllDAy
          ? "00:00"
          : moment(days[6].opened).format("HH:mm"),
        closed: days[6].closedAllDAy
          ? "00:00"
          : moment(days[6].closed).format("HH:mm"),
        closed_all_day: days[6].closed_all_day,
      },
    };

    await editOpenHours(daysOfTheWeek, store).then(() => {
      finish();
    });
  };

  const handleSaveCategory = async (data) => {
    try {
      setLoading(true);

      await fetch({
        method: "POST",
        url: `products/api/categories/`,
        data,
      });
      // setCategoryId(response.id);
      dispatch(
        notify({
          message: "Your categories were successfully created",
          severity: "success",
        })
      );
      setLoading(false);

      handleNext();
    } catch (error) {
      dispatch(
        notify({
          message: error,
          severity: "error",
        })
      );
      setLoading(false);
    }
  };
  const handleSaveProduct = async (data) => {
    try {
      setLoading(true);
      const body = new FormData();
      Object.keys(data).map((key) => {
        body.append(key, data[key]);
      });
      const response = await fetch({
        method: "POST",
        headers: { "content-type": "multipart/form-data" },
        url: `products/api/products/`,
        data: body,
      });
      dispatch(
        notify({
          message: "Your product was successfully created",
          severity: "success",
        })
      );
      const x = [...products];
      x.push(response);
      setProducts(x);
    } catch (error) {
      dispatch(
        notify({
          message: error,
          severity: "error",
        })
      );
      setLoading(false);
    }
  };

  const updateWhatsApp = async () => {
    if (whatsapp === "") {
      setWhatsappError(true);
      dispatch(
        notify({
          message: "Please check the errors below",
          severity: "error",
        })
      );
      return;
    }
    const response = await fetch({
      method: "PATCH",
      headers: { "content-type": "multipart/form-data" },
      url: `/products/api/stores/${store}/`,
      data: { whatsapp: whatsapp },
    }).then(() => {
      finish();
    });
  };

  const updateSlug = async () => {
    setLoading(true);
    var data = {
      slug: slug ? slug : "",
      subdomain: slug ? slug.toLowerCase() + ".flogi.app" : newUrl
    }
    await fetch({
      method: "PATCH",
      headers: { "content-type": "multipart/form-data" },
      url: `/products/api/stores/${store}/`,
      data,
    });

    dispatch(
      notify({
        message: "Your slug has been successfully created",
        severity: "success",
      })
    );

    handleNext();
    setLoading(false);
  };

  const finish = async () => {
    setLoading(true);
    const {
      is_superuser,
      is_manager,
      is_waiter_manager,
      is_waiter,
      is_store_owner,
      token,
      id,
      email,
      exp,
      name,
      phone,
      profile,
      account_stripe_KYC_status,
    } = auth;

    const payload = {
      token,
      name,
      phone,
      id,
      is_superuser,
      is_manager,
      is_store_owner,
      is_waiter,
      is_waiter_manager,
      new_user: false,
      email,
      exp,
      stripe_profiles: {
        network_id: 0,
        account_stripe_link: "",
        account_connect_status: "Enabled",
        stripe_login_link: "",
      },
      account_stripe_KYC_status: account_stripe_KYC_status,
    };
    if (profile) {
      payload.stripe_profiles = profile.stripe_profiles;
    }
    await handleUpdateClient();
    setLoading(false);
    // dispatch(successAuthentication(payload));
    localStorage.setItem("firstAccess", "true");
    window.location.href = "/finish-onboarding";
  };

  const handleUpdateClient = async () => {
    await fetch({
      method: "PATCH",
      url: `users/api/clients/${auth.id}/`,
      data: {
        profile: {
          is_onboarding: false,
        },
      },
    });
  };
  return (
    <div className="h-screen overflow-x-hidden">
      {loading ? (
        <Grid
          style={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            backgroundColor: "black",
            height: "100%",
          }}
        >
          <div className="w-full flex items-center justify-center">
            <ProgressBar />
          </div>
        </Grid>
      ) : (
        <>
          <Grid
            style={{
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              backgroundColor: "black",
              height: "100%",
              width: "100%",
            }}
          >
            {/*
            {activeStep === 0 && (
              <CreateUserAddress
                handleSave={(data) => handleSaveUserAddress(data)}
              />
            )}

            */}
            {activeStep === 0 && (
              <StoreContact
                userAddress={userAddress}
                uniquePhoneError={uniquePhoneError}
                uniqueSlugError={uniqueSlugError}
                uniqueEmailError={uniqueEmailError}
                handleSave={(data) => {
                  setStoreData(data);
                  handleSaveStore(data);
                }}
              />
            )}
            {activeStep === 1 && (
              <CreateStoreAddress
                handleSave={(data) => handleSaveStoreAddress(data)}
                userAddress={userAddress}
              />
            )}
            {/* {activeStep === 2 && (
              <CreateOpenHours
                storeData={storeData}
                handleSave={(data) => handleSaveOpenHours(data)}
              />
            )} */}
            {activeStep === 2 && (
              <CreateWebAddress
                handleSave={() => updateSlug()}
                slug={slug}
                setSlug={(e) => {
                  setSlug(e);
                }}
                newUrl={newUrl}
                setNewUrl={(e) => {
                  setNewUrl(e);
                }}
              />
            )}

            {activeStep === 3 && (
              <WhatsappContact
                whatsapp={whatsapp}
                setWhatsapp={(e) => {
                  setWhatsapp(e);
                  setWhatsappError(false);
                }}
                handleSave={() => updateWhatsApp()}
                whatsappError={whatsappError}
                whatsAppMessage={whatsAppMessage}
                setWhatsappMessage={(e) => setWhatsappMessage(e)}
                whatsAppMessageClosed={whatsAppMessageClosed}
                setWhatsappMessageClosed={(e) => setWhatsappMessageClosed}
              />
            )}
          </Grid>
        </>
      )}
    </div>
  );
}
