import React, { useEffect, useState } from "react";
import {
  InputLabel,
  Input,
  Container,
  Grid,
  FormControl,
  IconButton,
  FormHelperText,
  Typography,
  MenuItem,
  Select,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import FirstTab from "./modal-tabs/firstTab";
import SecondTab from "./modal-tabs/secondTab";
import ThirdTab from "./modal-tabs/thirdTab";
import FourthTab from "./modal-tabs/fourthTab";
import FifthTab from "./modal-tabs/fifthTab";
import SixthTab from "./modal-tabs/sixthTab";

import { useDispatch } from "react-redux";
import { createStore } from "./../../../services/stores";
import { notify } from "./../../../redux/features/alert";
import { trigger } from "../../../redux/features/fetchStore";
import { Close } from "@mui/icons-material";
// import Typography from '@material-ui/core/Typography';
import { NETWORK } from "./../../../settings";

export default function AddStoreModal(props) {
  const { onClose, open, stores, reset, attemptClose } = props;
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [image, setImage] = React.useState("");
  const [darkLogo, setDarkLogo] = React.useState("");
  const [lightLogo, setLightLogo] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [store, setStore] = React.useState("");
  const [stripeKey, setStripeKey] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState("");
  const [whatsapp, setWhatsapp] = React.useState("");
  const [whatsappError, setWhatsappError] = React.useState("");
  const [slug, setSlug] = React.useState("");
  const [slugError, setSlugError] = React.useState("");
  const [ownerEmail, setOwnerEmail] = React.useState("");
  const [ownerEmailError, setOwnerEmailError] = React.useState("");
  const [orderPrefix, setOrderPrefix] = React.useState("");
  const [addressUrl, setAddressUrl] = React.useState("");
  const [merchantOrderId, setMerchantOrderId] = React.useState("");
  const [sameDayDelivery, setSameDayDelivery] = React.useState(false);
  const [promote, setPromote] = React.useState(false);
  const [ownDelivery, setOwnDelivery] = React.useState(false);
  const [deliveryCost, setDeliveryCost] = React.useState("");
  const [fixedDeliveryCost, setFixedDeliveryCost] = React.useState("");
  const [storeType, setStoreType] = React.useState("");
  const [storeTypeError, setStoreTypeError] = React.useState("");
  const [onlyDelivery, setOnlyDelivery] = React.useState(false);
  const [pricesExcludeVat, setPricesExcludeVat] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [terms, setTerms] = React.useState("");
  const [privacy, setPrivacy] = React.useState("");
  const [contacts, setContacts] = React.useState("");
  const [video, setVideo] = React.useState("");
  const [activeTab, setActiveTab] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [noFilters, setNofilters] = useState(false);
  const [address, setAddress] = useState({
    street: "",
    street_number: "",
    neighborhood: "",
    city: "",
    zipcode: "",
    country: "",
    state: "",
  });
  const [addressError, setAddressError] = useState({
    street: false,
    street_number: false,
    neighborhood: false,
    city: false,
    zipcode: false,
    country: false,
    state: false,
  });
  const dispatch = useDispatch();

  const handleClose = () => {
    setName("");
    setDescription("");
    setTerms("");
    setPrivacy("");
    setContacts("");
    setNameError("");
    setImage("");
    setLightLogo("");
    setDarkLogo("");
    setStore("");
    setPhoneNumber("");
    setWhatsapp("");
    setVideo("");
    setPhoneNumberError("");
    setWhatsappError("");
    setSlug("");
    setStripeKey("");
    setSlugError("");
    setOwnerEmail("");
    setOwnerEmailError("");
    setOrderPrefix("");
    setAddressUrl("");
    setMerchantOrderId("");
    setSameDayDelivery(false);
    setPromote(false);
    setOwnDelivery(false);
    setDeliveryCost("");
    setFixedDeliveryCost("");
    setStoreType("");
    setOnlyDelivery(false);
    setPricesExcludeVat(false);
    setActiveTab(0);
    setChanged(false);
    onClose();
  };

  const handleName = (event) => {
    if (
      /^[^\W_]*$/.test(event.target.value) ||
      event.target.value.includes(" ")
    ) {
      setName(event.target.value);
      setNameError("");
    }
  };
  const handleStore = (event) => {
    setStore(event.target.value);
  };
  const handleSameDayDelivery = (event) => {
    setSameDayDelivery(event);
  };
  const handlePromote = (event) => {
    setPromote(event);
  };
  const handleOnlyDelivery = (event) => {
    setOnlyDelivery(event);
  };
  const handlePricesExcludeVat = (event) => {
    setPricesExcludeVat(event);
  };
  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };
  const handleStoreType = (event) => {
    setStoreType(event.target.value);
    setStoreTypeError("");
  };
  const handleMerchantOrderId = (event) => {
    setMerchantOrderId(event.target.value);
  };
  const handlePhoneNumber = (event) => {
    if (/(^[0-9]*[-]*[0-9]*$)/.test(event.target.value)) {
      setPhoneNumber(event.target.value);
      setPhoneNumberError("");
    }
  };
  const handleWhatsapp = (event) => {
    if (/(^[0-9]*[-]*[0-9]*$)/.test(event.target.value)) {
      setWhatsapp(event.target.value);
      setWhatsappError("");
    }
  };
  const handleOwnDelivery = (event) => {
    setOwnDelivery(event);
  };
  const handleDeliveryCost = (event) => {
    if (/(^[0-9]*[-]*[0-9]*$)/.test(event.target.value)) {
      setDeliveryCost(event.target.value);
    }
  };
  const handleFixedDeliveryCost = (event) => {
    if (/(^[0-9]*[-]*[0-9]*$)/.test(event.target.value)) {
      setFixedDeliveryCost(event.target.value);
    }
  };
  const handleSlug = (event) => {
    setSlug(event.target.value);
    setSlugError("");
  };
  const handleAddressUrl = (event) => {
    setAddressUrl(event.target.value);
  };
  const handlePrefix = (event) => {
    if (event.target.value.length <= 6 && /^[^\W_]*$/.test(event.target.value))
      setOrderPrefix(event.target.value);
  };
  const handleOwnerEmail = (event) => {
    setOwnerEmail(event.target.value.trim());
    setOwnerEmailError("");
  };
  const handleAddress = (prop, value) => {
    setAddress({ ...address, [prop]: value });
  };
  const handleStripeKey = (event) => {
    setStripeKey(event.target.value);
  };
  const checkForm = () => {
    let entered = false;
    if (name === "") {
      entered = true;
      setNameError("Please insert the name");
    }
    if (phoneNumber === "") {
      entered = true;
      setPhoneNumberError("Please insert phone");
    }
    if (whatsapp === "") {
      entered = true;
      setWhatsappError("Please insert phone");
    }
    if (ownerEmail === "") {
      entered = true;
      setOwnerEmailError("Please insert email");
    }
    if (slug === "") {
      entered = true;
      setSlugError("Please insert slug");
    }
    if (storeType === "") {
      entered = true;
      setStoreTypeError("Please insert type of store");
    }
    const _addressError = {
      street: false,
      street_number: false,
      city: false,
      zipcode: false,
      country: false,
    };
    Object.keys(address).map((key) => {
      if (address[key] === "") {
        console.log("didn't pass ", key, addressError[key]);
        _addressError[key] = true;
        entered = true;
      } else {
        console.log("passed ", key, addressError[key]);
      }
    });
    setAddressError(_addressError);
    return entered;
  };
  const submitCreation = async () => {
    if (!checkForm()) {
      setLoading(true);
      createStore({
        name,
        order_prefix: orderPrefix,
        currency,
        store,
        stripe_key: stripeKey,
        phone_number: phoneNumber,
        whatsapp: whatsapp,
        owner_email: ownerEmail,
        image,
        light_logo: lightLogo,
        dark_logo: darkLogo,
        slug,
        subdomain: addressUrl,
        merchant_ifood_id: merchantOrderId,
        sameday_delivery: sameDayDelivery,
        prices_exclude_vat: pricesExcludeVat,
        description: description,
        terms_conditions: terms,
        privacy_policy: privacy,
        contacts: contacts,
        own_delivery: ownDelivery,
        delivery_cost_per_km: deliveryCost,
        fixed_delivery_cost: fixedDeliveryCost,
        classification: storeType,
        networks: [NETWORK],
        video: video ? video : "",
        is_own_store: true,
        promote: promote,
        address: address,
      })
        .then((result) => {
          dispatch(trigger());
          setLoading(false);
          reset();
          dispatch(
            notify({
              message: "Store successfullt created",
              severity: "success",
            })
          );
          handleClose();
        })
        .catch((e) => {
          setLoading(false);
          notify({
            message: e,
            severity: "error",
          });
        });
    } else {
      dispatch(
        notify({
          message: "Please check the errors in the fields below",
          severity: "error",
        })
      );
    }
  };
  const generateURL = (file) => {
    if (file) {
      var url = URL.createObjectURL(file);
      return url;
    }
    return "";
  };
  const handleNavigation = () => {
    if (activeTab <= tabs.length - 2) {
      setActiveTab(activeTab + 1);
    } else {
      submitCreation();
    }
  };
  const tabs = ["Information", "Style", "Details", "T&C", "Privacy", "Chat"];
  const displayTabs = () => {
    switch (activeTab) {
      case 0:
        return (
          <FirstTab
            name={name}
            nameError={nameError}
            handleName={(e) => {
              handleName(e);
              setChanged(true);
            }}
            ownerEmail={ownerEmail}
            ownerEmailError={ownerEmailError}
            handleOwnerEmail={(e) => {
              handleOwnerEmail(e);
              setChanged(true);
            }}
            phoneNumber={phoneNumber}
            phoneNumberError={phoneNumberError}
            handlePhoneNumber={(e) => {
              handlePhoneNumber(e);
              setChanged(true);
            }}
            slug={slug}
            slugError={slugError}
            handleSlug={(e) => {
              handleSlug(e);
              setChanged(true);
            }}
            currency={currency}
            handleCurrency={(e) => {
              handleCurrency(e);
              setChanged(true);
            }}
            storeType={storeType}
            storeTypeError={storeTypeError}
            handleStoreType={(e) => {
              handleStoreType(e);
              setChanged(true);
            }}
            addressUrl={addressUrl}
            handleAddressUrl={(e) => {
              handleAddressUrl(e);
              setChanged(true);
            }}
            stripeKey={stripeKey}
            handleStripeKey={(e) => {
              handleStripeKey(e);
              setChanged(true);
            }}
            stores={stores}
            store={store}
            handleStore={(e) => {
              handleStore(e);
              setChanged(true);
            }}
            orderPrefix={orderPrefix}
            handlePrefix={(e) => {
              handlePrefix(e);
              setChanged(true);
            }}
            description={description}
            terms={terms}
            privacy={privacy}
            contacts={contacts}
            setDescription={(e) => {
              setDescription(e);
              setChanged(true);
            }}
            setTerms={(e) => {
              setTerms(e);
              setChanged(true);
            }}
            setPrivacy={(e) => {
              setPrivacy(e);
              setChanged(true);
            }}
            setContacts={(e) => {
              setContacts(e);
              setChanged(true);
            }}
            address={address}
            setAddress={(prop, value) => handleAddress(prop, value)}
            setAddressObj={(value) => {
              setAddress(value);
            }}
            addressError={addressError}
          />
        );
      case 1:
        return (
          <SecondTab
            image={image}
            generateURL={generateURL}
            setImage={(e) => {
              setImage(e);
              setChanged(true);
            }}
            video={video}
            setVideo={(e) => {
              setVideo(e);
              setChanged(true);
            }}
            darkLogo={darkLogo}
            setDarkLogo={(e) => {
              setDarkLogo(e);
              setChanged(true);
            }}
            lightLogo={lightLogo}
            setLightLogo={(e) => {
              setLightLogo(e);
              setChanged(true);
            }}
          />
        );
      case 2:
        return (
          <ThirdTab
            onlyDelivery={onlyDelivery}
            handleOnlyDelivery={(e) => {
              handleOnlyDelivery(e);
              setChanged(true);
            }}
            sameDayDelivery={sameDayDelivery}
            handleSameDayDelivery={(e) => {
              handleSameDayDelivery(e);
              setChanged(true);
            }}
            promote={promote}
            handlePromote={(e) => {
              handlePromote(e);
              setChanged(true);
            }}
            ownDelivery={ownDelivery}
            handleOwnDelivery={(e) => {
              handleOwnDelivery(e);
              setChanged(true);
            }}
            deliveryCost={deliveryCost}
            handleDeliveryCost={(e) => {
              handleDeliveryCost(e);
              setChanged(true);
            }}
            fixedDeliveryCost={fixedDeliveryCost}
            handleFixedDeliveryCost={(e) => {
              handleFixedDeliveryCost(e);
              setChanged(true);
            }}
            pricesExcludeVat={pricesExcludeVat}
            handlePricesExcludeVat={(e) => {
              handlePricesExcludeVat(e);
              setChanged(true);
            }}
            noFilters={noFilters}
            setNofilters={(e) =>{
              setChanged(true)
              setNofilters(e)}}
          />
        );
      case 3:
        return (
          <FourthTab
            terms={terms}
            setTerms={(e) => {
              setTerms(e);
              setChanged(true);
            }}
          />
        );
      case 4:
        return (
          <FifthTab
            privacy={privacy}
            setPrivacy={(e) => {
              setPrivacy(e);
              setChanged(true);
            }}
          />
        );
      case 5:
        return (
          <SixthTab
            whatsapp={whatsapp}
            setWhatsapp={(e) => {
              setWhatsapp(e);
              setChanged(true);
            }}
            whatsappError={whatsappError}
            handleWhatsapp={(e) => {
              handleWhatsapp(e);
              setChanged(true);
            }}
          />
        );
      default:
        break;
    }
  };
  const buttonLabel = () => {
    if (activeTab <= tabs.length - 2) {
      return "Next";
    } else {
      return "Submit";
    }
  };
  useEffect(() => {
    var wrapper = document.getElementById("modal-wrapper");
    if (wrapper) wrapper.scrollTo(0, 0);
  }, [activeTab]);
  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center"
      open={open}
      ModalProps={{
        onBackdropClick: () => {
          if (changed) attemptClose();
          else handleClose();
        },
      }}
      onClose={() => {
        if (changed) attemptClose();
        else handleClose();
      }}
    >
      <div className="w-full sm:w-[70%] md:w-[50%]  h-full sm:h-3/4 bg-white overflow-hidden relative">
        <div
          id="modal-wrapper"
          className="w-full  h-full overflow-y-auto px-10 pt-5 pb-10"
        >
          <div className="w-full flex flex-row items-center justify-between">
            <Typography variant="p">Store</Typography>
            <div className="">
              <IconButton
                onClick={() => {
                  if (changed) attemptClose();
                  else handleClose();
                }}
                style={{
                  backgroundColor: "#fff",
                  fill: "#fff",
                  border: "1px solid #DDDDDD",
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                }}
              >
                <Close style={{ fill: "#DDDDDD" }} />{" "}
              </IconButton>
            </div>
          </div>
          <Container className="mb-10 mt-3 !mx-0 !px-0" maxWidth="md">
            <div className="w-full flex items-center gap-2 mb-6">
              {tabs.map((tab, idx) => (
                <div
                  className={`px-5 py-1 rounded-full ease-linear transition-colors ${
                    idx === activeTab ? "text-white bg-black border-black" : ""
                  } border-gray-light border-2 cursor-pointer`}
                  onClick={() => setActiveTab(idx)}
                  key={tab}
                >
                  <Typography>{tab}</Typography>
                </div>
              ))}
            </div>
            {displayTabs()}
          </Container>
        </div>

        <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-60px] px-8 py-[.9rem] z-10">
          <button
            onClick={(e) => handleNavigation()}
            disabled={loading}
            className="text-white rounded-full border border-[#F2F2F2] flex justify-center items-center h-[28px] p-4 disabled:opacity-50"
          >
            <Typography style={{ marginTop: "0", color: "#F2F2F2" }}>
              {buttonLabel()}
            </Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
