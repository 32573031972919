import { useEffect } from "react";
import { countries } from "../../utils/countries";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
} from "@material-tailwind/react";
import arrow from "../../assets/images/arrow.svg";
import {
  TextField,
  FormControl,
  FormHelperText,
  Button,
  IconButton,
} from "@mui/material";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
export default function AddressForm({
  title,
  handleChange,
  address,
  errors,
  open,
  typeOfOrder,
  setOpen,
  handleCopyBillingChange,
  copyBilling,
  ignoreTypeOfOrder,
  billingData,
  inputId,
}) {
  useEffect(() => {
    getAddressOptions();
  }, [address.street]);

  const getAddressOptions = () => {
    if (address.street) {
      const input = document.getElementById(inputId);
      // eslint-disable-next-line no-undef
      var autocomplete = new google.maps.places.Autocomplete(input, {
        fields: ["address_components", "geometry"],
        types: ["address"],
      });
      autocomplete.addListener("place_changed", () => {
        fillInAddress(autocomplete);
      });
    }
  };
  function fillInAddress(autoComplete) {
    const place = autoComplete.getPlace();
    var emptyZip = true;
    var emptyNeighborhood = true;
    if (place && place.address_components) {
      for (const component of place.address_components) {
        // Process the address components as needed
        const type = component.types[0];
        console.log("component =>", component);
        if (type === "route") {
          handleChange("street", component.long_name);
        }
        //if (type === "street_number") {
        //  handleChange("street_number", component.long_name);
        //}
        if (type === "postal_code") {
          emptyZip = false;
          handleChange("zipcode", component.long_name);
        }
        if (type === "country") {
          handleChange("country", {
            label: component.long_name,
            code: component.short_name,
          });
        }
        if (type === "sublocality_level_1") {
          emptyNeighborhood = false;
          handleChange("neighborhood", component.long_name);
        }
        if (type === "administrative_area_level_1") {
          handleChange("state", component.long_name);
        }
        if (type === "administrative_area_level_2") {
          handleChange("city", component.long_name);
        }
      }
      handleChange("street_number", "");
      if (emptyNeighborhood) {
        handleChange("neighborhood", "");
      }
      if (emptyZip) {
        handleChange("zipcode", "");
      }
    }
  }
  return (
    <div
      className={`mt-5 address-border-bottom ${
        typeOfOrder === "Delivery" || ignoreTypeOfOrder ? "block" : "hidden"
      }`}
    >
      <Accordion open={open}>
        <AccordionHeader
          onClick={() => setOpen(!open)}
          style={{ color: "#272727" }}
          className="accordion-header py-1"
        >
          <div className="flex flex-row items-center">
            <p className="text-base text-start font-normal">{title}</p>{" "}
          </div>
          <IconButton>
            <img
              src={arrow}
              style={{
                transform: `rotate(${open ? 0 : 180}deg)`,
                transition: "transform 400ms",
              }}
            />
          </IconButton>
        </AccordionHeader>
        <AccordionBody>
          <div className="flex-col gap-3 flex">
            {title === "Delivery Address" &&
              billingData.street &&
              billingData.street_number &&
              billingData.neighborhood &&
              billingData.city &&
              billingData.state &&
              billingData.country &&
              billingData.zipcode && (
                <div className="flex gap-2 items-center">
                  <div
                    className="cursor-pointer hover:bg-[#f7f7f7] rounded-full"
                    onClick={() => handleCopyBillingChange(!copyBilling)}
                  >
                    {copyBilling ? (
                      <RadioButtonCheckedIcon className="text-[#272727]" />
                    ) : (
                      <RadioButtonUncheckedIcon className="text-[#272727]" />
                    )}
                  </div>
                  <Typography className="text-[#272727]">
                    Same as Billing Address
                  </Typography>
                </div>
              )}
            <div className="w-full flex gap-2 ">
              <div className="w-2/3">
                <FormControl
                  className={
                    address.street ? "form-control" : "form-control-incompleted"
                  }
                  required
                  style={{ width: "100%" }}
                  error={
                    errors.street !== "" &&
                    (address.street == "" || address.street == null)
                  }
                >
                  <TextField
                    style={{ color: "#c7c7c7" }}
                    value={address.street}
                    onChange={(e) => handleChange("street", e.target.value)}
                    fullWidth
                    id={inputId}
                    variant="standard"
                    label="Street"
                  />
                  {errors.street !== "" &&
                    (address.street == "" || address.street == null) && (
                      <FormHelperText style={{ marginLeft: "0px" }}>
                        This field is required
                      </FormHelperText>
                    )}
                </FormControl>
              </div>
              <div className="w-1/3">
                <FormControl
                  className={
                    address.street_number
                      ? "form-control"
                      : "form-control-incompleted"
                  }
                  required
                  style={{ width: "100%" }}
                  error={
                    errors.street_number !== "" &&
                    (address.street_number == "" ||
                      address.street_number == null)
                  }
                >
                  <TextField
                    value={address.street_number}
                    onChange={(e) =>
                      handleChange("street_number", e.target.value)
                    }
                    fullWidth
                    variant="standard"
                    label="Number"
                  />
                  {errors.street_number !== "" &&
                    (address.street_number == "" ||
                      address.street_number == null) && (
                      <FormHelperText style={{ marginLeft: "0px" }}>
                        This field is required
                      </FormHelperText>
                    )}{" "}
                </FormControl>
              </div>
            </div>
            <div className="w-full flex gap-2">
              <FormControl
                className={
                  address.city ? "form-control" : "form-control-incompleted"
                }
                required
                style={{ width: "100%" }}
                error={
                  errors.city !== "" &&
                  (address.city == "" || address.city == null)
                }
              >
                <TextField
                  value={address.city}
                  onChange={(e) => handleChange("city", e.target.value)}
                  fullWidth
                  variant="standard"
                  label="City"
                />{" "}
                {errors.city !== "" &&
                  (address.city == "" || address.city == null) && (
                    <FormHelperText style={{ marginLeft: "0px" }}>
                      This field is required
                    </FormHelperText>
                  )}{" "}
              </FormControl>
            </div>
            <div className="w-full flex gap-2">
              <FormControl
                className={
                  address.neighborhood
                    ? "form-control"
                    : "form-control-incompleted"
                }
                required
                style={{ width: "100%" }}
                error={
                  errors.neighborhood !== "" &&
                  (address.neighborhood == "" || address.neighborhood == null)
                }
              >
                <TextField
                  value={address.neighborhood}
                  onChange={(e) => handleChange("neighborhood", e.target.value)}
                  fullWidth
                  variant="standard"
                  label="Neighbourhood"
                />{" "}
                {errors.neighborhood !== "" &&
                  (address.neighborhood == "" ||
                    address.neighborhood == null) && (
                    <FormHelperText style={{ marginLeft: "0px" }}>
                      This field is required
                    </FormHelperText>
                  )}{" "}
              </FormControl>
            </div>
            <div className="w-full flex gap-2">
              <div className="w-2/3">
                <FormControl
                  className={
                    address.state ? "form-control" : "form-control-incompleted"
                  }
                  style={{ width: "100%" }}
                  required
                  error={
                    errors.state !== "" &&
                    (address.state == "" || address.state == null)
                  }
                >
                  <TextField
                    value={address.state}
                    onChange={(e) => handleChange("state", e.target.value)}
                    fullWidth
                    variant="standard"
                    label="County/State"
                  />{" "}
                  {errors.state !== "" &&
                    (address.state == "" || address.state == null) && (
                      <FormHelperText style={{ marginLeft: "0px" }}>
                        This field is required
                      </FormHelperText>
                    )}{" "}
                </FormControl>
              </div>
              <div className="w-1/3">
                <FormControl
                  className={
                    address.zipcode
                      ? "form-control"
                      : "form-control-incompleted"
                  }
                  style={{ width: "100%" }}
                  required
                  error={
                    errors.zipcode !== "" &&
                    (address.zipcode == "" || address.zipcode == null)
                  }
                >
                  <TextField
                    value={address.zipcode}
                    onChange={(e) => handleChange("zipcode", e.target.value)}
                    fullWidth
                    variant="standard"
                    label="Zipcode"
                  />{" "}
                  {errors.zipcode !== "" &&
                    (address.zipcode == "" || address.zipcode == null) && (
                      <FormHelperText style={{ marginLeft: "0px" }}>
                        This field is required
                      </FormHelperText>
                    )}{" "}
                </FormControl>
              </div>{" "}
            </div>
            <div className="w-full flex gap-2"></div>
            <FormControl
              className={
                address.country?.code
                  ? "form-control"
                  : "form-control-incompleted"
              }
              style={{ width: "100%" }}
              required
              error={
                errors.zipcode !== "" &&
                (address.country == "" || address.country == null)
              }
            >
              <Autocomplete
                id="country-select-demo"
                options={countries}
                error={
                  errors.country !== "" &&
                  (address.country == "" || address.country == null)
                }
                value={address.country}
                onChange={(e, newValue) => {
                  handleChange("country", newValue);
                }}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Country"
                    style={{ borderRadius: "10000px" }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    className="country-input"
                  />
                )}
              />{" "}
              {errors.country !== "" &&
                (address.country == "" || address.country == null) && (
                  <FormHelperText style={{ marginLeft: "0px" }}>
                    This field is required
                  </FormHelperText>
                )}{" "}
            </FormControl>
          </div>
        </AccordionBody>
      </Accordion>
    </div>
  );
}
