import { TextField } from "@mui/material";
export default function BottomFilters({
  start,
  setStart,
  end,
  setEnd,
}) { 
  
  return (
    <div className="flex flex-row fixed bottom-5 right-2 rounded-full sm:mr-1 md:mr-8 gap-1 flex-wrap md:pb-1">
      <div className="flex flex-row rounded-full">
        <TextField
          value={start}
          label=""
          onChange={(e) => setStart(e.target.value)}
          type="date"
          variant="outlined"
          className="round-input-left"
        />

        <TextField
          value={end}
          label=""
          onChange={(e) => setEnd(e.target.value)}
          type="date"
          variant="outlined"
          className="round-input-right"
        />
      </div>
      
    </div>
  );
}
