import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import Menu from "./menu";
import { Typography } from "@material-tailwind/react";
import questionMark from "./../../assets/images/question-mark.svg";
import { useSelector } from "react-redux";
import flogi from "./../../assets/images/flogi-logo.svg";
import WhatsAppModal from "./whatsappModal";
import ConfirmModal from "./../../components/confirmModal";
import { getStores } from "../../services/stores";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    backgroundColor: "black",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children }) {
  const [whatsapp, setWhatsapp] = useState(false);
  const [attemptClose, setAttemptClose] = useState(false);
  const [stores, setStores] = useState("");

  const [open, setOpen] = React.useState(false);
  const { email } = useSelector((state) => state.network);
  const  { count }  = useSelector((state) => state.fetchStore);
  const [fullScreen, setFullScreen] = React.useState(false);
  console.log(count)
  const handleClick = () => {
    setOpen(!open);
  };
  const fetchStores = () => {
    getStores().then((res) => {
      setStores(res);
    });
  };
  useEffect(() => {
    fetchStores();
  }, [count]);
  useEffect(() => {
    var closedDrawerpages = ["comercialInvoice", "order"];
    var location = window.location.pathname.split("/");
    const lastPage = location[location.length - 1];
    if (closedDrawerpages.includes(lastPage)) {
      setOpen(false);
    }
  }, []);
  const display = () => {
    var type = "none";
    if (window.location.pathname.includes("dashboard")) type = "block";
    return type;
  };
  const toggleFullScreen = () => {
    try {
      if (fullScreen) {
        setFullScreen(false);
        document.exitFullscreen();
      } else {
        document.documentElement
          .requestFullscreen()
          .then(function () {
            setFullScreen(true);
          })
          .catch(function (error) {
            setFullScreen(false);
          });
      }
    } catch (error) {
      console.log("error ", error);
    }
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <WhatsAppModal
        onClose={() =>setWhatsapp(false)}
        open={whatsapp}
        reset={() => fetchStores()}
        attemptClose={() => setAttemptClose(true)}
        stores={stores}
      />
      <ConfirmModal
        open={attemptClose}
        goBack={() => setAttemptClose(false)}
        handleClose={() => {
          setAttemptClose(false);
          setWhatsapp(false);
        }}
      />
      <Drawer
        style={{ display: display(), backgroundColor: "black" }}
        variant="permanent"
        open={open}
      >
        <DrawerHeader
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "black",
            position: "relative",
          }}
        >
          <div style={{ position: "relative" }}>
            <IconButton aria-label="open drawer" onClick={handleClick}>
              <MenuIcon style={{ fill: "#7A7A7A" }} />
            </IconButton>
          </div>
        </DrawerHeader>
        <Divider />
        <div className="flex flex-col justify-between h-screen mb-4">
          <Menu openWhatsApp={() => setWhatsapp(true)} open={open} />
          <div
            className="flex items-center gap-1 justify-center"
            style={{ opacity: open ? 1 : 0 }}
          >
            <Typography className="text-[#7A7A7A]">Powered by</Typography>
            <img style={{ height: "12px" }} src={flogi} alt="" />
          </div>
        </div>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 2, padding: "0px" }}>
        {children}
      </Box>
      <div
        style={{
          display: window.location.pathname.includes("dashboard")
            ? "block"
            : "none",
        }}
      >
        <div className={`absolute top-1 right-3 flex items-center gap-1 z-20`}>
          <a
            target="_blank"
            rel="noreferrer"
            href={`mailto:${email}`}
            style={{ cursor: "pointer" }}
            className="text-[#3F3F3F] no-underline mr-1 visited:text-[#3F3F3F]"
          >
            <Typography className="text-[#3F3F3F]" color="secondary">
              Contact us
            </Typography>
          </a>
          {fullScreen ? (
            <IconButton
              style={{ fill: "#3F3F3F" }}
              onClick={() => {
                toggleFullScreen();
              }}
            >
              <FullscreenExit style={{ fill: "#3F3F3F" }} />
            </IconButton>
          ) : (
            <IconButton
              style={{ fill: "#3F3F3F" }}
              onClick={() => {
                toggleFullScreen();
              }}
            >
              <Fullscreen style={{ fill: "#3F3F3F" }} />
            </IconButton>
          )}

          <IconButton>
            <div
              //onClick={() => tour.start()}
              style={{ cursor: "pointer" }}
              className="restart-tour-button"
            >
              <img
                alt=""
                style={{ height: "25px", width: "25px" }}
                src={questionMark}
              />
            </div>
          </IconButton>
        </div>
      </div>
    </Box>
  );
}
