import React from "react";
import { NETWORK } from "../../../../../settings";
import {
  IconButton,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  Tooltip,
  MenuItem,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Input from "../../../../../components/textInput";
import Editor from "../../../../../components/CKeditor";
import SwitchButton from "../../../../../components/switchButton";
import { Add, Close } from "@mui/icons-material";
export default function FirstTab({
  options,
  name,
  setName,
  nameError,
  active,
  setActive,
  slug,
  setSlug,
  slugError,
  duplicateSlugError,
  category,
  categoryError,
  price,
  setPrice,
  stock,
  setStock,
  measure,
  setMeasure,
  artistName,
  artistNameError,
  setArtistName,
  origin,
  originError,
  setOrigin,
  hexCode,
  hexCodeError,
  setHexCode,
  material,
  materialError,
  setMaterial,
  description,
  setDescription,
  categories,
  setCategory,
  measureError,
  artists,
  setDuplicateSlugError,
  sku,
  setSku,
  barcode,
  setBarcode,
  variations,
  setNewCategory,
  fiscalCategory,
  setFiscalCategory
}) {
  function slugify(text) {
    return text
      .toString()
      .normalize("NFD")
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
  const handleActiveChange = (event) => {
    setActive(event.target.checked);
  };

  return (
    <div className="flex flex-row">
      <div className="w-full">
        <div className="fix-label mb-2">
          <InputLabel
            style={{ marginLeft: "0px", fontSize: "14px" }}
            htmlFor={1 + "Edit product"}
            className="text-[#aaaaaa] font-normal font-[16px]"
          >
            Online
          </InputLabel>
          <div className="mt-1 ml-3">
            <SwitchButton
              id={1 + "add product"}
              _checked={active}
              handleClick={(id, checked) => {
                setActive(checked);
              }}
            />
          </div>
        </div>
        <FormControl
          fullWidth={true}
          required={true}
          margin={"normal"}
          className="fix-label"
          error={nameError !== "" && name === ""}
        >
          <InputLabel>Name</InputLabel>
          <Input
            type="string"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setDuplicateSlugError("");
              setSlug(slugify(e.target.value));
            }}
          />
          <FormHelperText>{nameError}</FormHelperText>
        </FormControl>
        <Tooltip title="The friendly URL is the url that will appear when the client opens the product page">
          <FormControl
            className="fix-label"
            margin={"normal"}
            fullWidth={true}
            required={true}
            error={
              (slugError !== "" && slug === "") || duplicateSlugError !== ""
            }
          >
            <InputLabel htmlFor="name-input">Friendly URL</InputLabel>
            <Input
              id="name-input"
              onChange={(e) => {
                setDuplicateSlugError("");
                setSlug(e.target.value);
              }}
              type="string"
              value={slug}
              onBlur={() => setSlug(slugify(slug))}
            />
            <FormHelperText>
              {slugError ? slugError : duplicateSlugError}
            </FormHelperText>
          </FormControl>
        </Tooltip>
        <div className="w-full flex items-center gap-4">
          <FormControl
            className="normalize-select"
            variant="standard"
            fullWidth={true}
            margin={"normal"}
            error={categoryError !== "" && category === ""}
            required
          >
            <Autocomplete
              options={options}
              className="w-full"
              value={category}
              onChange={(e, newValue) => {
                setCategory(newValue);
              }}
              autoHighlight
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="w-full"
                  variant="standard"
                  label="Category"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />

            <FormHelperText>{categoryError}</FormHelperText>
          </FormControl>

          <IconButton
            style={{
              backgroundColor: "#612EF2",
              borderRadius: "50%",
              height: "28px",
              width: "28px",
            }}
            onClick={() => setNewCategory()}
          >
            <Tooltip title="Create a new category">
              <Add style={{ fill: "white", padding: "2px" }} />
            </Tooltip>
          </IconButton>
        </div>
        <div className="flex flex-row justify-between gap-3">
          <div className="w-1/2">
            <FormControl
              className="fix-label"
              fullWidth={true}
              margin={"normal"}
            >
              <InputLabel htmlFor="price-input">Price</InputLabel>
              <Input
                id="price-input"
                type="number"
                value={price}
                disabled={variations != 0}
                onChange={(e) => setPrice(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="w-1/2">
            <FormControl
              className="fix-label"
              fullWidth={true}
              margin={"normal"}
            >
              <InputLabel htmlFor="stock-input">Stock</InputLabel>
              <Input
                id="stock-input"
                type="number"
                value={stock}
                disabled={variations != 0}
                onChange={(e) => setStock(e.target.value)}
              />
              {/* <p>{variations}</p> */}
            </FormControl>
          </div>
        </div>
        <div className="w-full flex items-center gap-3">
          <div className="w-1/2">
            <FormControl
              className="normalize-select"
              fullWidth={true}
              margin={"normal"}
              required
              variant="standard"
              error={measureError !== "" && measure === ""}
            >
              <InputLabel htmlFor="measure-input">Measure</InputLabel>
              <Select
                id="measure-input"
                value={measure}
                variant="standard"
                onChange={(e) => setMeasure(e.target.value)}
              >
                <MenuItem value={"Unit"}>Unit</MenuItem>
                <MenuItem value={"kg"}>Kilogram, kg</MenuItem>
                <MenuItem value={"l"}>Litre, l</MenuItem>
                <MenuItem value={"m"}>Metre, m</MenuItem>
              </Select>
              <FormHelperText>{measureError}</FormHelperText>
            </FormControl>
          </div>
          <div className="w-1/2">
            <FormControl
              className="fix-label"
              fullWidth={true}
              margin={"normal"}
            >
              <InputLabel htmlFor="stock-input">Fiscal category</InputLabel>
              <Select
                id="measure-input"
                value={fiscalCategory}
                variant="standard"
                onChange={(e) => setFiscalCategory(e.target.value)}
              >
                <MenuItem value={"PRODUCT"}>Product</MenuItem>
                <MenuItem value={"SERVICE"}>Service</MenuItem>

              </Select>
            </FormControl>
          </div>
        </div>

        {NETWORK == "6" ? (
          <>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className="normalize-select"
                  fullWidth={true}
                  variant="standard"
                  margin={"normal"}
                  error={artistNameError !== "" && artistName === ""}
                >
                  <InputLabel htmlFor="price-input">Artist name</InputLabel>

                  <Select
                    value={artistName}
                    variant="standard"
                    placeholder="Artist"
                    onChange={(e) => {
                      setArtistName(e.target.value);
                    }}
                  >
                    {artists.map((el) => (
                      <MenuItem value={el.artist_id}>{el.name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{artistNameError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className="fix-label"
                  fullWidth={true}
                  margin={"normal"}
                  error={originError !== "" && origin === ""}
                >
                  <InputLabel htmlFor="stock-input">Origin</InputLabel>
                  <Input
                    id="stock-input"
                    type="text"
                    value={origin}
                    onChange={(e) => setOrigin(e.target.value)}
                  />
                  <FormHelperText>{originError}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid style={{ marginTop: "-35px" }} container spacing={5}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className="fix-label"
                  fullWidth={true}
                  margin={"normal"}
                  error={hexCodeError !== "" && hexCode === ""}
                >
                  <InputLabel htmlFor="stock-input">Hex code</InputLabel>
                  <Input
                    id="stock-input"
                    type="text"
                    value={hexCode}
                    onChange={(e) => setHexCode(e.target.value)}
                  />
                  <FormHelperText>{hexCodeError}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className="fix-label"
                  fullWidth={true}
                  margin={"normal"}
                  error={materialError !== "" && material === ""}
                >
                  <InputLabel htmlFor="stock-input">Material</InputLabel>
                  <Input
                    id="stock-input"
                    type="text"
                    value={material}
                    onChange={(e) => setMaterial(e.target.value)}
                  />
                  <FormHelperText>{materialError}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid style={{ marginTop: "-35px" }} container spacing={5}>
            <Grid item xs={12} sm={6}>
              <FormControl
                className="fix-label"
                fullWidth={true}
                margin={"normal"}
                error={materialError !== "" && material === ""}
              >
                <InputLabel htmlFor="stock-input">Material</InputLabel>
                <Input
                  id="stock-input"
                  type="text"
                  value={material}
                  onChange={(e) => setMaterial(e.target.value)}
                />
                <FormHelperText>{materialError}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                className="fix-label"
                fullWidth={true}
                margin={"normal"}
              >
                <InputLabel htmlFor="stock-input">Product color</InputLabel>
                <Input
                  id="stock-input"
                  type="text"
                  value={hexCode}
                  onChange={(e) => setHexCode(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <div className="flex flex-row justify-between gap-3">
          <div className="w-1/2">
            <FormControl
              className="fix-label"
              fullWidth={true}
              margin={"normal"}
            >
              <InputLabel htmlFor="price-input">SKU</InputLabel>
              <Input
                id="price-input"
                type="string"
                value={sku}
                onChange={(e) => setSku(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="w-1/2">
            <FormControl
              className="fix-label"
              fullWidth={true}
              margin={"normal"}
            >
              <InputLabel htmlFor="stock-input">Bar code</InputLabel>
              <Input
                id="stock-input"
                type="string"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
              />
            </FormControl>
          </div>
        </div>
        <FormControl
          className="fix-label"
          fullWidth={true}
          style={{ margin: "32px 0 42px 0" }}
        >
          <Editor
            placeholder={"Description"}
            value={description}
            handleChange={(e) => setDescription(e)}
          />
        </FormControl>
        {/* {NETWORK !== "6" && (
            <FormControl
              className="fix-label"
              fullWidth={true}
              margin={"normal"}
            >
              <InputLabel htmlFor="alergy-notes-input">
                Allergy Notes
              </InputLabel>
              <Input
                id="alergy-notes-input"
                type="string"
                value={allergyNotes}
                onChange={(e) => setAllergyNotes(e.target.value)}
              />
            </FormControl>
          )} */}
      </div>
    </div>
  );
}
