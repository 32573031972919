import { Close } from "@mui/icons-material";
import { Add } from "@mui/icons-material";
import { IconButton } from "@mui/material";
export default function AddIcon({ onClick }) {
  return (
    <IconButton
      onClick={() => onClick()}
      style={{
        backgroundColor: "#612EF2",
        borderRadius: "50%",
        height: "28px",
        width: "28px",
      }}
    >
      <Add style={{ fill: "white", padding: "2px" }} />{" "}
    </IconButton>
  );
}
