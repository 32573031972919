import { getStores } from "../../services/stores";
import { getProducts, getGiftCards, getTags } from "../../services/products";
import { getArtists } from "../../services/artists";
import { getIngredients } from "../../services/ingredients";
import { getCategories } from "../../services/categories";
import { getMenus } from "../../services/menus";
import StoreFilter from "../../components/storeFilter";
import { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import Search from "./../../components/bottomSearch";
import { NETWORK } from "./../../settings";
import ProductsTable from "./product/productsTable";
import CategoriesTable from "./categories/categoriesTable";
import ModalAddProduct from "./product/modal/modalAddProduct";
import AddMenuModal from "./menus/addMenuModal";
import CreateCategoryModal from "./categories/createCategoryModal";
import CreateTagModal from "./tags/createTag";
import AddExtraModal from "./extras/addExtraModal";
import ExtrasTable from "./extras/extrasTable";
import ProgressBar from "../../components/loader/progressBar";
import ConfirmModal from "./../../components/confirmModal";
import TagsTable from "./tags/tagsTable";
import GiftcardsTable from "./giftcards/giftcardTable";
import CreateGroupModal from "./groups/createGroup";
import MenusTable from "./menus/menusTable";
export default function Inventory() {
  const { auth } = useSelector((store) => store.auth);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState({
    label: "",
    value: 0,
  });
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [addProduct, setAddProduct] = useState(false);
  const [addMenu, setAddMenu] = useState(false);
  const [addExtra, setAddExtra] = useState(false);
  const [addCategory, setAddCategory] = useState(false);
  const [addTags, setAddTags] = useState(false);
  const [addGroups, setAddGroups] = useState(false);
  const [confirm, setCOnfirm] = useState(false);
  const [artists, setArtists] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [menus, setMenus] = useState([]);
  const [tags, setTags] = useState([]);
  const [giftcards, setGiftcards] = useState([]);
  const [activeTable, setActiveTable] = useState("Products");
  useEffect(() => {
    getStores().then((res) => {
      setSelectedStore({ label: res[0].name, value: res[0].id });
      setStores(res);
    });
  }, []);
  useEffect(() => {
    if (selectedStore.value > 0) {
      getArtists().then((res) => {
        setArtists(res);
      });
      searchIngredients();
      searchCategories();
      searchMenus();
      searchProducts();
      searchTags();
      searchGiftcards();
    }
  }, [selectedStore]);
  const searchIngredients = () => {
    if (activeTable === "Extras") {
      setLoading(true);
    }
    getIngredients({
      store: selectedStore.value,
      name: "",
    }).then((res) => {
      if (activeTable === "Extras") {
        setLoading(false);
      }
      setIngredients(res);
    });
  };
  const searchMenus = () => {
    if (activeTable === "Groups") {
      setLoading(true);
    }
    var menuParams = { store: selectedStore.value, network: NETWORK };
    if (!auth.is_superuser) {
      delete menuParams.network;
    }
    getMenus(menuParams).then((res) => {
      setMenus(res);
      if (activeTable === "Groups") {
        setLoading(false);
      }
    });
  };
  const searchTags = () => {
    if (activeTable === "Tags") {
      setLoading(true);
    }
    getTags({
      store: selectedStore.value,
      network: NETWORK,
    }).then((res) => {
      if (activeTable === "Tags") {
        setLoading(false);
      }
      setTags(res);
    });
  };

  const searchGiftcards = () => {
    if (activeTable === "Gift cards") {
      setLoading(true);
    }
    getGiftCards({
      store: selectedStore.value,
      network: NETWORK,
    }).then((res) => {
      if (activeTable === "Gift cards") {
        setLoading(false);
      }
      setGiftcards(res);
    });
  };
  const getCurrencyStore = () => {
    const store = stores.find((_store) => _store.id === selectedStore.value);
    return store?.currency;
  };

  const searchCategories = () => {
    if (activeTable === "Categories") {
      setLoading(true);
    }
    var params = { store: selectedStore.value, network: NETWORK };
    if (!auth.is_superuser) {
      delete params.network;
    }
    getCategories(params).then((res) => {
      setCategories(res);
      if (activeTable === "Categories") {
        setLoading(false);
      }
    });
  };
  const searchProducts = () => {
    if (activeTable === "Products") {
      setLoading(true);
    }
    var params = {
      store: selectedStore.value,
      network: NETWORK,
    };
    if (auth?.is_superuser) {
      params.network = NETWORK;
    }
    if (selectedStore === 0) {
      delete params.store;
    }
    getProducts(params).then((res) => {
      setProducts(res);
      if (activeTable === "Products") {
        setLoading(false);
      }
    });
  };
  const returnStoreSlug = () => {
    var storeData = stores.find(
      (storeElement) => storeElement.id === selectedStore.value
    );
    return storeData?.slug ? storeData?.slug : storeData?.id;
  };

  const getStoreURL = () => {
    var storeObj = stores.find(
      (storeElement) => storeElement.id === selectedStore.value
    );
    return storeObj?.subdomain ? storeObj.subdomain : "";
  };

  const returnFilters = () => {
    switch (NETWORK) {
      case "6":
        return ["Products", "Categories", "Gift cards", "Groups"];

      default:
        return ["Products", "Extras", "Gift cards", "Categories", "Groups"];
    }
  };

  const handleButtonTitle = () => {
    switch (activeTable) {
      case "Products":
        return "Add new products";
      case "Extras":
        return "Add new extras";
      case "Categories":
        return "Add new categories";
      case "Menus":
        return "Add new menus";
      case "Tags":
        return "Add new tags";
      case "Gift cards":
        return "Add new gift cards";
      case "Groups":
        return "Add new groups";
      default:
        return "";
    }
  };
  const handleButtonSubmit = () => {
    switch (activeTable) {
      case "Products":
        setAddProduct(true);
        break;
      case "Extras":
        setAddExtra(true);
        break;
      case "Categories":
        setAddCategory(true);
        break;
      case "Tags":
        setAddTags(true);
        break;
      case "Groups":
        setAddMenu(true);
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full h-screen">
      <div className="mx-4 flex items-center flex-row flex-wrap max-w-[90vw] mt-10 md:mt-[43px]">
        <div className="w-[250px]">
          <StoreFilter
            setActiveStoreFilter={(e) => setSelectedStore(e)}
            stores={stores}
            activeStoreFilter={selectedStore}
          />
        </div>
      </div>
      <div className="flex flex-row items-center gap-2 mt-4 mx-4 flex-wrap max-w-[90vw] ">
        {returnFilters().map((filter) => (
          <div
            className={`px-5 py-1 rounded-full ease-linear transition-colors ${
              filter === activeTable ? "bg-black border-black" : "bg-white"
            } border-gray-light border cursor-pointer`}
            onClick={() => setActiveTable(filter)}
            key={filter}
          >
            <Typography
              className={`${
                filter === activeTable ? "text-white" : "text-[#272727]"
              }`}
            >
              {filter}
            </Typography>
          </div>
        ))}
      </div>
      <div className="mt-4 mx-4">
        {activeTable === "Gift cards" ? null : (
          <button
            onClick={() => handleButtonSubmit()}
            className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px]"
          >
            <Typography>{handleButtonTitle()}</Typography>
          </button>
        )}
      </div>

      <div>
        {loading ? (
          <div className="w-full flex items-center justify-center">
            <ProgressBar />
          </div>
        ) : (
          <div className="mt-[50px]">
            {activeTable === "Products" && (
              <ProductsTable
                products={
                  search
                    ? products.filter((product) =>
                        product.name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                    : products
                }
                categories={categories}
                ingredients={ingredients}
                store={selectedStore.value}
                refresh={() => searchProducts()}
                artists={artists}
                storeSlug={returnStoreSlug()}
                storeCurrency={getCurrencyStore()}
                getCategories={() => searchCategories()}
                loading={loading}
                setLoading={(e) => setLoading(e)}
                storeUrl={getStoreURL()}
              />
            )}
            {activeTable === "Categories" && (
              <CategoriesTable
                categories={
                  search
                    ? categories.filter((category) =>
                        category.name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                    : categories
                }
                store={selectedStore.value}
                refresh={() => searchCategories()}
                storeSlug={returnStoreSlug()}
                ingredientsToFilterBy={ingredients}
              />
            )}
            {activeTable === "Extras" && (
              <ExtrasTable
                extras={
                  search
                    ? ingredients.filter((extra) =>
                        extra.name.toLowerCase().includes(search.toLowerCase())
                      )
                    : ingredients
                }
                store={selectedStore.value}
                refresh={() => searchIngredients()}
                storeSlug={returnStoreSlug()}
              />
            )}
            {activeTable === "Tags" && (
              <TagsTable
                tags={tags}
                store={selectedStore.value}
                refresh={() => searchTags()}
                storeSlug={returnStoreSlug()}
              />
            )}
            {activeTable === "Gift cards" && (
              <GiftcardsTable
                giftcards={giftcards}
                store={selectedStore.value}
                refresh={() => searchGiftcards()}
              />
            )}
            {activeTable === "Groups" && (
              <MenusTable
                menus={menus}
                store={selectedStore.value}
                refresh={() => searchMenus()}
                categories={categories}
                storeSlug={returnStoreSlug()}
                products={products}
              />
            )}
          </div>
        )}
      </div>
      <Search
        value={search}
        handleChange={(e) => setSearch(e)}
        placeholder={`Search ${activeTable.toLowerCase()}`}
      />
      <ModalAddProduct
        artists={artists}
        open={addProduct}
        handleClose={() => setAddProduct(false)}
        categories={categories}
        ingredients={ingredients}
        products={products}
        store={selectedStore.value}
        refresh={() => searchProducts()}
        attemptClose={() => setCOnfirm(true)}
        getCategories={() => searchCategories()}
      />
      <ConfirmModal
        open={confirm}
        goBack={() => setCOnfirm(false)}
        handleClose={() => {
          setCOnfirm(false);
          setAddProduct(false);
          setAddCategory(false);
          setAddExtra(false);
          setAddGroups(false);
          setAddTags(false);
          setAddMenu(false);
        }}
      />
      <AddMenuModal
        onClose={() => setAddMenu(false)}
        open={addMenu}
        store={selectedStore.value}
        categories={categories}
        refresh={() => searchMenus()}
      />
      <CreateCategoryModal
        open={addCategory}
        handleClose={() => setAddCategory(false)}
        store={selectedStore.value}
        ingredientsToFilterBy={ingredients}
        refresh={() => searchCategories()}
      />
      <AddExtraModal
        onClose={() => setAddExtra(false)}
        open={addExtra}
        store={selectedStore.value}
        refresh={() => searchIngredients()}
      />
      <CreateTagModal
        handleClose={() => setAddTags(false)}
        open={addTags}
        store={selectedStore.value}
        refresh={() => searchTags()}
      />
      <CreateGroupModal
        open={addGroups}
        handleClose={() => setAddGroups(false)}
        store={selectedStore.value}
        refresh={() => searchMenus()}
        categories={categories}
        attemptClose={() => setCOnfirm(true)}
      />
    </div>
  );
}
