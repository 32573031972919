import { Typography, TableRow, TableCell } from "@mui/material";
import ModalEditGiftcard from "./editGiftcard";
import SwitchButton from "../../../components/switchButton";
import { useState } from "react";
import ConfirmModal from "../../../components/confirmModal";
import { togglegiftCard } from "../../../services/products";
import { useDispatch } from "react-redux";
import { notify } from "../../../redux/features/alert";
export default function GiftcardRow({ giftcard, store, refresh }) {
  const [editGiftcard, setEditGiftcard] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const handleStatus = (checked) => {
    togglegiftCard(checked, giftcard.id)
      .then(() => {
        dispatch(
          notify({
            message: `Gift card successfully ${
              checked ? "activated" : "deactivated"
            }`,
            severity: "success",
          })
        );
      })
      .catch((e) => {

        dispatch(
          notify({
            message: e,
            severity: "error",
          })
        );
      });
  };
  return (
    <>
      <TableRow className="py-4 table-cell-fix-border">
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[120px]"
        >
          <p>{giftcard.id}</p>
        </TableCell>
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[120px]"
        >
          <SwitchButton
            id={"giftcard row" + giftcard.id}
            _checked={giftcard.active}
            handleClick={(id, checked) => {
              handleStatus(checked);
            }}
          />
        </TableCell>{" "}
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[120px]"
        >
          <p>{giftcard.value}</p>
        </TableCell>
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[120px]"
        >
          <p>{giftcard.balance}</p>
        </TableCell>
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="sm:w-1/7 md:w-[120px]"
        >
          <p>{giftcard.code}</p>
        </TableCell>
        <TableCell
          resizable={false}
          style={{
            padding: "10px 16px 16px 16px",
          }}
          className="w-min"
        >
          <div className="flex items-center justify-end w-full gap-2 pr-24 align-top">
            <button
              onClick={() => setEditGiftcard(true)}
              className="bg-white mr-3 px-5 rounded-full h-fit text-black border border-[#DDDDDD]"
            >
              <Typography className="table-cell text-[#272727]">
                Edit
              </Typography>
            </button>
          </div>
        </TableCell>
        <ModalEditGiftcard
          open={editGiftcard}
          handleClose={() => setEditGiftcard(false)}
          attemptClose={() => setConfirmModal(true)}
          store={store}
          refresh={() => refresh()}
          giftcard={giftcard}
        />
        <ConfirmModal
          open={confirmModal}
          goBack={() => setConfirmModal(false)}
          handleClose={() => {
            setConfirmModal(false);
            setEditGiftcard(false);
          }}
        />
      </TableRow>
    </>
  );
}
