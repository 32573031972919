import { useSelector, useDispatch } from "react-redux";
import { logout } from "./../../redux/features/auth";

import { Navigate } from "react-router-dom";
function Protected({ children }) {
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.auth);
  const { authorized } = authentication.auth;
  if (authorized) {
    const { exp } = authentication.auth;
    if (exp < new Date().getTime() / 1000) {
      dispatch(logout());
      return <Navigate to="/" replace />;
    }
  }
  return children;
}
export default Protected;
