import { createSlice } from "@reduxjs/toolkit";

export const alert = createSlice({
  name: "alert",
  initialState: {
    show: false,
    message: "",
    severity: "",
    action: () =>{}
  },
  reducers: {
    notify: (state, action) => {
      state.show = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    hide: (state) => {
      state.show = false;
      state.action = () =>{}
    },
  },
});

// Action creators are generated for each case reducer function
export const { notify, hide } = alert.actions;

export default alert.reducer;
