import React from "react";
import Print from "./print";
import { Typography, Grid, IconButton, Card } from "@mui/material";
import thankYou from "./../../assets/images/petshop/thank-you.svg";
import logo from "./../../assets/images/petshop/logo.svg";
import fly from "./../../assets/images/petshop/fly.svg";

import { useStyles } from "./receiptStyles";
import moment from "moment";

export default function PetshopReceipt(props) {
  const { order, trigger } = props;
  const classes = useStyles();
  return (
    <Print trigger={<div>{trigger}</div>}>
      <Card>
        <Grid container justify="center" className={classes.receiptHeader}>
          <Grid style={{ width: "100%", position: "relative" }}>
            <img
              style={{ position: "absolute", top: "88px", left: "40px" }}
              src={fly}
            />
            <div
              style={{ display: "flex", flexDirection: "column", width: "85%" }}
            >
              <img src={thankYou} />
              <img style={{ marginTop: "15px" }} src={logo} />
            </div>
            <div
              style={{
                marginTop: "70px",
                borderTop: "1px solid black",
                paddingTop: "5px",
                paddingBottom: "40px",
              }}
            >
              <Typography>
                Date: {moment(order.date_ordered).format("DD.MM.YYYY")}
              </Typography>
              <Typography>
                Time: {moment(order.date_ordered).format("hh:mm:ss")}
              </Typography>
              <Typography>Receipt Nr.: {order.order_id}</Typography>
              <Typography>Employee: Peter Potter</Typography>
            </div>
            {order.items.map((item) => (
              <div
                style={{
                  borderTop: "1px solid black",
                  paddingTop: "5px",
                  paddingBottom: "40px",
                }}
              >
                <Typography>{item.product.name}</Typography>
                <Typography>{item.product.artist}</Typography>
                <Typography>
                  {item?.size?.portion}, {item?.product?.colour_hex}
                </Typography>
                <Typography>
                  {item.quantity} x{" "}
                  {new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(item.product.price)}{" "}
                  EUR
                </Typography>
              </div>
            ))}
            <div
              style={{
                borderTop: "1px solid black",
                paddingTop: "5px",
                paddingBottom: "10px",
              }}
            >
              <div
                style={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography>Subtotal:</Typography>
                <Typography>
                  {new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(order.sub_total)}{" "}
                  EUR
                </Typography>
              </div>
            </div>
            {order.vat_total > 0 ? (
              <div
                style={{
                  borderTop: "1px solid black",
                  paddingTop: "5px",
                  paddingBottom: "10px",
                }}
              >
                <div
                  style={{
                    gap: "10px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Typography>MWSt 19%:</Typography>
                  <Typography>
                    {new Intl.NumberFormat("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(order.vat_total)}{" "}
                    EUR
                  </Typography>
                </div>
              </div>
            ) : null}

            <div
              style={{
                borderTop: "1px solid black",
                paddingTop: "5px",
                paddingBottom: "40px",
              }}
            >
              <div
                style={{
                  gap: "10px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography>Total:</Typography>
                <Typography>
                  {new Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(order.total_order)}{" "}
                  EUR
                </Typography>
              </div>
            </div>
            {order.payment === "Card" ? (
              <div
                style={{
                  borderTop: "1px solid black",
                  paddingTop: "5px",
                  paddingBottom: "15px",
                }}
              >
                <Typography>Payment method: Credit card</Typography>
                <Typography>
                  Date: {moment(order.date_ordered).format("DD.MM.YYYY")}
                </Typography>
                <Typography>Cardnr: ******1234</Typography>
              </div>
            ) : null}
            <div
              style={{
                borderTop: "1px solid black",
                paddingTop: "5px",
                paddingBottom: "40px",
              }}
            >
              <Typography>Kassen-ID: 1111111</Typography>
              <Typography>Ustld: DE1234567</Typography>
            </div>
            <div
              style={{
                borderTop: "1px solid black",
                paddingTop: "5px",
                paddingBottom: "40px",
              }}
            >
              <Typography>Petshop by karhard®</Typography>
              <br />
              <Typography>
                Oberbaumstrasse 7<br />
                10997 Berlin
                <br />
                Deustchland
              </Typography>
              <br />
              <Typography>
                petshop@karhard.com
                <br />
                +41 79 176 83 66
              </Typography>
              <br />
            </div>
          </Grid>
        </Grid>
      </Card>
    </Print>
  );
}

PetshopReceipt.defaultProps = {
  disabled: false,
};
