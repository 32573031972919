import { useState } from "react";
import { notify } from "../../redux/features/alert";
import { NETWORK_NAME } from "../../settings";
import { Button, Typography } from "@material-tailwind/react";
import { MenuItem } from "@mui/material";
import flogi from "./../../assets/images/flogi-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setNetwork as setNetworkRedux,
  setNetworkList as setNetworkListRedux,
} from "./../../redux/features/network";
import Input from "./../../components/inputs";

export default function Network() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state).auth;
  const { networks } = useSelector((state) => state).network;

  const [network, setNetwork] = useState("");
  const [networkError, setNetworkError] = useState("");

  const checkForm = () => {
    var entered = false;
    if (!network && NETWORK_NAME === "Network") {
      setNetworkError("This field is required");
      entered = true;
    }
    return entered;
  };
  const submit = (e) => {
    e.preventDefault();
    if (!checkForm()) {
      const _network = networks.find((el) => el.name === network);
      dispatch(
        setNetworkRedux({ network: _network.network, email: _network.email })
      );
      localStorage.setItem("network", _network.network);
      setNetworkListRedux({ networks: [] });
      if (auth.new_user) {
        window.location.pathname = "/on-boarding";
      } else {
        if (
          auth.auth.stripe_profiles.account_connect_status === "Deauthorized"
        ) {
          window.location.pathname = "/kyc";
        } else {
          window.location.pathname = "dashboard/orders";
        }
      }
    } else {
      dispatch(
        notify({
          message: "Please chose a network to continue",
          severity: "error",
        })
      );
    }
  };
  return (
    <div className="w-full min-h-[100vh] pt-12 flex justify-center bg-black">
      <div className="flex items-center w-full  sm:w-auto md:w-1/3 lg:w-1/4 justify-center flex-col px-5">
        <div className="items-start flex w-full pb-6">
          <Typography className="text-white">
            Select the network you want to access
          </Typography>
        </div>
        <div className="w-full flex flex-col gap-1 pb-8">
          <div className="relative w-full">
            <Input
              value={network}
              select
              size="lg"
              error={networkError && !network}
              aria-required
              variant="standard"
              fullWidth
              onChange={(e) => {
                setNetwork(e.target.value);
              }}
              label="Portal Name"
            >
              {networks.map((option) => (
                <MenuItem value={option.name}>{option.name}</MenuItem>
              ))}
            </Input>
            <Typography className="text-[#ECECEC] absolute top-5 left-0.5">
              {network}
            </Typography>
          </div>
        </div>
        <Button
          className="w-full bg-[#612EF2] rounded-full text-start normal-case py-2 px-4 shadow-grey hover:shadow-grey"
          onClick={(e) => submit(e)}
        >
          Submit
        </Button>
      </div>
      <div className="absolute left-0 right-0 bottom-5 flex items-center gap-1 justify-center">
        <Typography className="text-[#7A7A7A]">Powered by</Typography>
        <img style={{ height: "12px" }} src={flogi} alt="" />
      </div>
    </div>
  );
}
