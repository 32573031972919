import { IconButton, Typography, TableRow, TableCell } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { notify } from "../../../redux/features/alert";
import CustomSwitch from "./customSwitchButton";
import { activateMenu } from "./../../../services/menus";
import DeleteMenuModal from "./deleteMenuModal";
import EditMenuModal from "./editMenuModal";
import EditProductsTable from "./selectProductsModal";
import CloseIconSvg from "../../../assets/icons/close_icon.svg";
import { Close, Add } from "@mui/icons-material";

export default function MenuRow({
  menu,
  refresh,
  store,
  categories,
  products,
}) {
  const [editMenu, setEditMenu] = useState(false);
  const [active, setActive] = useState(menu.active);
  const [deleteMenu, setDeleteMenu] = useState(false);
  const [selectProducts, setSelectProducts] = useState(false);
  const dispatch = useDispatch();
  const toggleMenu = async () => {
    activateMenu(menu.menu_id)
      .then(async () => {
        await refresh();
        dispatch(
          notify({
            message: `Menu successfully ${
              active ? "deactivated" : "activated"
            }`,
            severity: "success",
          })
        );
      })
      .catch((e) => {
        dispatch(
          notify({
            message: e,
            severity: "error",
          })
        );
      });
  };
  return (
    <TableRow className="py-4 border-gray border-b-2 table-cell-fix-border">
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
          width: "350px",
        }}
        className="table-cell w-[150px]"
      >
        {menu.menu_id}
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
        className="sm:w-1/7 w-[150px] md:w-[120px] table-cell"
      >
        {menu.name}
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
        className="sm:w-1/7 md:w-[115px] w-[150px]"
      >
        <Typography className="table-cell">
          <IconButton onClick={() => setSelectProducts(true)}>
            <Add/>
          </IconButton>
         
        </Typography>
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
        className="table-cell sm:w-1/7  md:w-[120px]"
      >
        <CustomSwitch
          checked={active}
          onChange={(newChecked) => {
            setActive(newChecked);
            toggleMenu();
          }}
        />
      </TableCell>
      <TableCell
        style={{
          padding: "10px 16px 16px 16px",
        }}
        className="table-cell w-min"
      >
        <div className="flex items-center justify-end w-full gap-3 pr-24">
          <button
            onClick={() => setEditMenu(true)}
            className="table-cell bg-white mr-3 px-5 rounded-full h-fit text-black border border-[#DDDDDD]"
          >
            <Typography className="table-cell">Edit</Typography>
          </button>
          {/* <IconButton
            onClick={() => setDeleteMenu(true)}
            // style={{
            //   backgroundColor: "#DDDDDD",
            //   height: "30px",
            //   width: "30px",
            // }}
          >
            <img src={CloseIconSvg} />
          </IconButton> */}
          <IconButton
            style={{
              backgroundColor: "#fff",
              fill: "#fff",
              border: "1px solid #DDDDDD",
              borderRadius: "50%",
              height: "30px",
              width: "30px",
            }}
            onClick={() => setDeleteMenu(true)}
          >
            <Close style={{ fill: "#DDDDDD" }} />{" "}
          </IconButton>
        </div>
      </TableCell>
      <DeleteMenuModal
        menu={menu}
        open={deleteMenu}
        handleClose={() => setDeleteMenu(false)}
        refresh={() => refresh()}
      />
      <EditMenuModal
        menu={menu}
        open={editMenu}
        store={store}
        onClose={() => setEditMenu(false)}
        refresh={() => refresh()}
        categories={categories}
      />
      <EditProductsTable
        menu={menu}
        open={selectProducts}
        onClose={() => setSelectProducts(false)}
        refresh={() => refresh()}
        products={products}
      />
    </TableRow>
  );
}
