import search from "./../../assets/images/search-grey.svg";
export default function ProductSearch({ value, handleChange, placeholder }) {
  return (
    <div className="border-2 border-grey flex flex-row px-2 py-1 rounded-full bg-white md:w-[48%] w-[95%] md:mt-0 mt-4 justify-between focus:border-black">
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        className="border-0 focus:border-0 text-grey placeholder-grey pl-2 outline-none w-full"
      />
      <img src={search} className="mr-2"/>
    </div>
  );
}
