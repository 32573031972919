import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import format from "./../../../utils/formatValues";
const useStyles = makeStyles({
  table: {
    overflowY: "auto",
    height: "100%",
    width: "100%",
  },
});

export default function TableChart({
  title,
  label1,
  label2,
  data,
  total_count,
  total_value,
  color,
  currency
}) {
  const classes = useStyles();

  return (
    <div
      style={{ backgroundColor: color.secondary }}
      className={`py-4 w-full h-auto rounded-2xl relative md:w-[97%] mb-14`}
    >
      <div className="top-2 right-2 left-2">
        <Typography style={{ color: "#3F3F3F" }}>{title}</Typography>
      </div>
      <div className={classes.table}>
        <Table stickyHeader aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" className="table-cell-title !bg-[#fafafa]">
                <Typography className="borderless-title">
                  {" "}
                  {label1}
                </Typography>
              </TableCell>
              <TableCell align="left" className="table-cell-title !bg-[#fafafa]">
                <Typography className="borderless-title">
                  {" "}
                  {label2}
                </Typography>
              </TableCell>
              {total_count ? (
                <TableCell align="left" className="table-cell-title !bg-[#fafafa]">
                  <Typography
                    className="borderless-title"
                  >
                    {" "}
                    % Q
                  </Typography>
                </TableCell>
              ) : null}
              <TableCell align="left" className="table-cell-title !bg-[#fafafa]">
                <Typography className="borderless-title">
                  {" "}
                  Value
                </Typography>
              </TableCell>
              {total_value ? (
                <TableCell align="left" className="table-cell-title !bg-[#fafafa]">
                  <Typography
                    
                    className="borderless-title"
                  >
                    % V
                  </Typography>
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((d) => (
              <TableRow key={d.name}>
                <TableCell className="table-cell-title" align="left">
                  <Typography
                    className="table-cell"
                  >
                    {d.name}
                  </Typography>
                </TableCell>
                <TableCell className="table-cell-title" align="left">
                  <Typography
                    className="table-cell"
                  >
                    {d.amount}
                  </Typography>
                </TableCell>
                {total_count ? (
                  <TableCell className="table-cell-title" align="left">
                    <Typography
                      className="table-cell"
                    >
                      {" "}
                      {((d.amount / total_count) * 100).toFixed(2)}%
                    </Typography>
                  </TableCell>
                ) : null}
                <TableCell className="table-cell-title" align="left">
                  <Typography
                    className="table-cell"
                  >
                    {d.total_sold
                      ? format(d.total_sold.toFixed(2)) + " " +currency
                      : 0.0}
                  </Typography>
                </TableCell>
                {total_value ? (
                  <TableCell className="table-cell-title" align="left">
                    <Typography
                      className="table-cell"
                    >
                      {" "}
                      {((d.total_sold / total_value) * 100).toFixed(2)}%
                    </Typography>
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
