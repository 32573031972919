import { useState, useEffect } from "react";
import StoreFilter from "../../components/storeFilter";
import { getStores } from "../../services/stores";
import { getOrders } from "../../services/orders";
import { useDispatch, useSelector } from "react-redux";
import { NETWORK, SOCKET_NOTIFICATION } from "../../settings";
import OrderTable from "./orderTable";
import { connect } from "../../utils/socket";
import UIfx from "uifx";
import NotificationSound from "../../assets/sounds/to-the-point.mp3";
import ProgressBar from "../../components/loader/progressBar";
import { notify } from "../../redux/features/alert";
import KycError from "../../components/kycError";
export default function Orders() {
  const { auth } = useSelector((store) => store.auth);
  const dispatch = useDispatch()
  const [stores, setStores] = useState([]);
  const [orders, setOrders] = useState([]);
  const [online, setOnline] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStoreFilter, setActiveStoreFilter] = useState({
    value: 0,
    label: "",
  });
  const fetchStores = () => {
    getStores()
      .then((res) => {
        setActiveStoreFilter({ value: res[0].id, label: res[0].name });
        setStores(res);
      })
      .catch((e) => {
        console.log("err ", e);
      });
  };
  const fetchOrders = () => {
    setLoading(true);
    var params = {
      date_delivery: "ALL",
      a_fazer: true,
      fazendo: true,
      feito: true,
      depot: true,
      transit: true,
      delivered: true,
      baking: true,
      store: activeStoreFilter.value,
      network: NETWORK,
    };
    if (activeStoreFilter.value !== 0) {
      delete params.network;
    }

    if (auth?.is_superuser) {
      params.network = NETWORK;
    }
    if (activeStoreFilter.value === 0) {
      delete params.store;
    }
    getOrders(params)
      .then((res) => {
        old_orders = res;
        setOrders(res);
        setLoading(false);
      })
      .catch((e) => {
        console.log("err ", e);
      });
  };
  let old_orders = [];

  const handleFirstAccess = () => {
    var firstAccess = localStorage.getItem("firstAccess")
    const { stripe_profiles } = auth
    if (firstAccess == "true" && stripe_profiles.account_stripe_link) {
      dispatch(notify({ message: `<a target='_blank' style={{textDecoration:"underline"}} href=${stripe_profiles.account_stripe_link} >Complete your Stripe Setup to get paid</a>`, severity: "error" }))
    }
    localStorage.setItem("firstAccess", "false")
  }
  useEffect(() => {
    fetchStores();
    handleFirstAccess()
  }, []);
  useEffect(() => {
    if (activeStoreFilter.value !== 0) {
      fetchOrders();
    }
  }, [activeStoreFilter.value]);
  const updateOrders = (newOrder) => {
    const _orders = [...old_orders];
    var updadatedOrders = [];
    var orderFound = false;
    _orders.map((order, idx) => {
      if (order.order_id === newOrder.order_id) {
        _orders[idx] = newOrder;
        orderFound = true;
      }
    });
    if (!orderFound) {
      updadatedOrders.push(newOrder);
      updadatedOrders.push(..._orders);
    } else updadatedOrders = _orders;
    if (!newOrder.last_status && newOrder.status === "A fazer") {
      const beep = new UIfx(NotificationSound);
      beep.play();
    }
    old_orders = updadatedOrders;
    setOrders(updadatedOrders);
  };
  const isStoreIncluded = (store) => {
    var included = false;
    stores.map((el) => {
      if (el.name === store) included = true;
    });
    return included;
  };

  useEffect(() => {
    let kitchenSocket = connect(SOCKET_NOTIFICATION);

    kitchenSocket.onopen = () => {
      setOnline(true);
    };

    kitchenSocket.onmessage = (event) => {
      let new_data = JSON.parse(event.data);
      if (new_data.message.order_id) {
        if (isStoreIncluded(new_data.message.store.name)) {
          if (activeStoreFilter.value !== 0) {
            if (new_data.message.store_id === activeStoreFilter.value) {
              updateOrders(new_data.message);
            }
          } else if (activeStoreFilter.value === 0) {
            updateOrders(new_data.message);
          }
          /*
          if (new_data.message.status === "Fazendo") {
            generategiftCardPdf(new_data.message.bought_giftcards)
            setTimeout(() => {
              if (!new_data.message.invoice_pdf)
                generateOrderPDF(new_data.message)
            }, 30000);
          }*/
        }
      }
    };

    kitchenSocket.onerror = (e) => {
      console.log("error on the websocket ", e);
      kitchenSocket.close();
    };

    kitchenSocket.onclose = () => {
      kitchenSocket.close();
    };

    const heartbeat = setInterval(() => {
      if (kitchenSocket.readyState === 1) setOnline(true);

      if (kitchenSocket.readyState !== 1) setOnline(false);

      if (!online) kitchenSocket = connect(SOCKET_NOTIFICATION);
    }, 5000);

    return () => {
      kitchenSocket.close();
      clearInterval(heartbeat);
    };
  }, [online, activeStoreFilter.value]);

  const store = stores.find((store) => store.name === activeStoreFilter.label);
  console.log("auth -> ", auth)
  return (
    <div className="">
      {auth.stripe_profiles.account_stripe_KYC_status ?
        <>
          <div className="w-[250px] mx-4 flex-wrap mt-[43px] z-40 relative">
            <StoreFilter
              stores={stores}
              activeStoreFilter={activeStoreFilter}
              setActiveStoreFilter={(value) => setActiveStoreFilter(value)}
            />
          </div>
          <div className="w-full flex justify-center">
            {loading ? (
              <ProgressBar />
            ) : (
              <div
                id="content-wrapper"
                className={`mt-[43px] relative w-full ${orders.length > 0 ? "overflow-x-auto" : "overflow-x-hidden"
                  } overflow-y-hidden`}
              >
                <OrderTable orders={orders} storeData={store} />
              </div>
            )}
          </div>
        </> : <KycError profile={auth.stripe_profiles} />}
    </div>
  );
}
