import ExtraRow from "./extrasRow";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
export default function ExtrasTable({
  extras,
  store,
  refresh,
  storeSlug,
  products,
}) {
  return (
    <>
      <TableContainer style={{ overflowX: 'initial', maxHeight: '100vh' }}>
        <Table stickyHeader className="table-auto min-w-[800px] w-full pb-24">
          <TableHead className="text-left border-gray border-b-2 sticky z-[50]">
            <TableCell className="table-cell-title font-normal pl-[16px] pb-[10px] text-[#272727]">
              Name
            </TableCell>
            <TableCell className="table-cell-title font-normal pb-[10px] text-[#272727]">
              Stock
            </TableCell>
            <TableCell className="table-cell-title font-normal pb-[10px] text-[#272727]">
              Unit
            </TableCell>
            <TableCell className="table-cell-title"></TableCell>
          </TableHead>
          <TableBody>
            {extras.map((extra) => (
              <ExtraRow
                extra={extra}
                key={extra.id}
                store={store}
                refresh={() => refresh()}
                storeSlug={storeSlug}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
