import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  receiptHeader: {
    width: "100%",
    margin: "50px 10px",
    padding: "3% 30%",
  },
  receiptBody: {
    marginTop: 15,
    marginBottom: 10,
  },
}));
