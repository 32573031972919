import React from "react";
import {
  Typography,
  IconButton,
  Button,
  Modal,
  Container,
} from "@mui/material";
import format from "./../../utils/formatValues";
import { Close, OpenInBrowser } from "@mui/icons-material";
import moment from "moment";
import OrderDetails from "./orderDetails";

export default function CustomerModal(props) {
  const { open, handleClose, data, customer, currency } = props;
  const [selected, setSelected] = React.useState("");
  const [typeOfOrder, setTypeOfOrder] = React.useState("");
  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[70%] md:w-[50%]  max-h-full sm:max-h-[60vh] bg-white p-5 overflow-auto relative">
        <div className="w-full flex flex-row items-center justify-between ">
          <Typography variant="p">{customer}</Typography>
          <IconButton
            onClick={() => handleClose()}
            style={{
              backgroundColor: "#fff",
              fill: "#fff",
              border: "1px solid #DDDDDD",
              borderRadius: "50%",
              height: "30px",
              width: "30px",
            }}
          >
            <Close style={{ fill: "#DDDDDD" }} />{" "}
          </IconButton>
        </div>
        <Container className="mb-7 mt-5 !mx-0 !px-0" maxWidth="md">
          <>
            {data.map((order, idx) => (
              <>
                {order && (
                  <div className="mb-7">
                    <div className="w-full gap-2 flex flex-row">
                      <div className="w-60">
                        <Typography>Information</Typography>
                        <Typography className="text-[#2727274D]">
                          #{order.type_of_order}
                          {order.order_id}
                        </Typography>

                        {order.store_name && (
                          <Typography className="text-[#2727274D]">
                            Store: {order.store_name}
                          </Typography>
                        )}
                        <Typography className="text-[#2727274D]">
                          Total: {format(order?.total_order?.toFixed(2))} {currency}
                        </Typography>
                        <Typography className="text-[#2727274D]">
                          Type:{" "}
                          {order.type_of_order.includes("-D")
                            ? "Delivery"
                            : "Collection"}
                        </Typography>
                        {["Canceled", "Canceld and Refunded"].includes(
                          order.status
                        ) && (
                          <Typography
                            variant="overline"
                            style={{ color: "red" }}
                          >
                            CANCELED
                          </Typography>
                        )}
                        <Button
                          onClick={() => {
                            setTypeOfOrder(order.type_of_order);
                            setSelected(order.order_id);
                          }}
                          variant="text"
                          style={{
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Typography>Order details </Typography>
                          <OpenInBrowser
                            style={{ marginLeft: "7px", fill: "#2727274D" }}
                          />
                        </Button>
                      </div>

                      {/*<div className="w-60">
                      <Typography >Order summary</Typography>
                      {order?.items.length > 0 &&
                        order.items.map((item, idx) => (
                          <>
                            {item.quantity > 0 && (
                              <Grid key={item.name + idx}>
                                <Typography  className="text-[#2727274D]">
                                  {`${item?.quantity} x ${item?.product?.name}`}
                                </Typography>
                                {item.comment && (
                                  <Typography 
                                    className="text-[#2727274D]"
                                    style={{
                                      fontStyle: "italic",
                                      marginLeft: "12px",
                                    }}
                                  >
                                    "{item.comment}"
                                  </Typography>
                                )}
                                {item?.extra_orders.map((extra) => (
                                  <Grid key={extra.id}>
                                    <Typography
                                      className="text-[#2727274D] "
                                      style={{ marginLeft: 15 }}
                                    >
                                      {`${extra?.quantity}x ${extra?.ingredient}`}
                                    </Typography>
                                  </Grid>
                                ))}
                              </Grid>
                            )}
                          </>
                        ))}
                    </div> */}

                      <div className="w-60">
                        <Typography>Address</Typography>
                        {order.type_of_order.includes("-D") ? (
                          <Typography className="text-[#2727274D] w-[13rem] !mb-2">
                            Delivery:{" "}
                            {order.order_address
                              ? `${order.order_address}`
                              : "-"}
                          </Typography>
                        ) : null}

                        <Typography className="text-[#2727274D] w-[13rem]">
                          Billing:{" "}
                          {order.billing_address
                            ? `${order.billing_address}`
                            : "-"}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ))}
          </>
          <OrderDetails
            open={!(selected === "")}
            handleClose={() => {
              setTypeOfOrder("");
              setSelected("");
            }}
            order_id={selected}
            type_of_order={typeOfOrder}
          />
        </Container>
      </div>
    </Modal>
  );
}
