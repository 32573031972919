import Select, { components, DropdownIndicatorProps } from "react-select";
import { useEffect } from "react";
import DropdownIcon from "../../assets/images/dropdown-icon2.svg";
import DropdownIconWhite from "../../assets/images/dropdown-icon.svg";

export default function StoreFilter({ options, value, onChange, placeholder, disabled }) {
  useEffect(() => {
    var separator = document.getElementsByClassName(
      "select__indicator-separator"
    );
    for (var i = 0, len = separator.length; i < len; i++) {
      separator[i].style["display"] = "none";
    }
  }, []);

  const statusPayment = value.id === "Paid" || value.id === "Not paid";
  const colorIsPaid = value.id === "Paid" ? "#20C680" : "#FF5D5D";

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={statusPayment ? DropdownIconWhite : DropdownIcon}
          alt="Arrow"
          style={{ width: "12px", marginRight: "0.5rem" }}
        />
      </components.DropdownIndicator>
    );
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: statusPayment ? colorIsPaid : "white",
      borderRadius: "1000px",
      borderColor: statusPayment ? colorIsPaid : "#D7D7D7",
      ":focus": {
        borderColor: "#D7D7D7",
      },
      ":hover": {
        borderColor: statusPayment ? colorIsPaid : "#D7D7D7",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
        color: "#272727",
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, paddingLeft: "0.67rem", color: value.id === undefined ? "#C7C7C7" : "#272727"}),
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: statusPayment ? "white" : "#272727",
        paddingLeft: "0.67rem",
      };
    },
  };
  return (
    <div className="select-wrapper mt-4">
      <Select
        // className={classes.selectInput}
        components={{ DropdownIndicator }}
        classNamePrefix="select"
        value={value}
        placeholder={placeholder}
        isDisabled={disabled ? disabled : false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        options={options}
        onChange={(e) => {
          onChange(e);
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
          },
        })}
        styles={colourStyles}
      />
    </div>
  );
}
