import { createSlice } from "@reduxjs/toolkit";

export const authState = createSlice({
  name: "authState",
  initialState: {
    auth: {
      token: "",
      email: "",
      name: "",
      phone: "",
      is_superuser: false,
      is_manager: false,
      is_store_owner: false,
      is_waiter_manager: false,
      is_waiter: false,
      new_user: false,
      exp: 0,
      id: 0,
      authorized: false,
      error: "",
      stripe_profiles: {
        network_id: 0,
        account_stripe_link: "",
        account_stripe_KYC_status: false,
        account_connect_status: "",
        stripe_login_link: "",
      },
      account_stripe_KYC_status: true,
    },
  },
  reducers: {
    login: (state, action) => {
      state.auth = action.payload;
    },
    logout: (state) => {
      state = {
        auth: {
          token: "",
          email: "",
          name: "",
          phone: "",
          is_superuser: false,
          is_manager: false,
          is_store_owner: false,
          is_waiter_manager: false,
          is_waiter: false,
          new_user: false,
          exp: 0,
          id: 0,
          authorized: false,
          error: "",
          stripe_profiles: {
            network_id: 0,
            account_stripe_link: "",
            account_stripe_KYC_status: false,
            account_connect_status: "",
            stripe_login_link: "",
          },
          account_stripe_KYC_status: true,
        },
      };
      localStorage.clear("state:persist");
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = authState.actions;

export default authState.reducer;
