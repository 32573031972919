export default function Toggle({ value, handleChange }) {
  return (
    <div className="switchbox">
      <input
        onChange={(e) => {
          handleChange();
        }}
        type="checkbox"
        id="switch"
        name="switch"
      />
      <label htmlFor="switch" data-on="ON" data-off="OFF">
        <span
          value={1}
          style={{
            color: value ? "white" : "black"
          }}
          className="on"
        >
          Monthly
        </span>
        <span
          style={{
            color: !value ? "white" : "black"
          }}
          value={2}
          className="off"
        >
          Yearly
        </span>
      </label>
    </div>
  );
}
