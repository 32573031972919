import { fetch as api } from "./../utils/request";
import { mountQueryURL } from "../utils/query";
import { NETWORK } from "./../settings";
import { createCategory } from "./categories";
export const getProducts = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";
      let url = `/products/api/products/calculate_stock_products/${query_url}`;
      let response;
      let results = [];
      do {
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      resolve(response.results);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};
const handleRemovedProducts = async (product_id, removedProducts) => {
  return new Promise((resolve, reject) => {
    try {
      if (removedProducts.length === 0) {
        resolve();
      }
      removedProducts.map((el, idx) => {
        removeRelatedProduct(el, product_id);
        if (idx === removeRelatedProduct.length - 1) {
          resolve();
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};
const handleAddedProducts = async (product_id, selectedProducts) => {
  return new Promise((resolve, reject) => {
    try {
      if (selectedProducts.length === 0) {
        resolve();
      }
      selectedProducts.map((el, idx) => {
        addRelatedProduct(el.product_id, product_id);
        if (idx === selectedProducts.length - 1) {
          resolve();
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};
const handleRelatedProducts = (
  product_id,
  selectedProducts,
  removedProducts
) => {
  return new Promise((resolve, reject) => {
    if (removedProducts.length === 0 && selectedProducts.length === 0) {
      resolve();
    } else {
      Promise.all([
        handleRemovedProducts(product_id, removedProducts),
        handleAddedProducts(product_id, selectedProducts),
      ])
        .then(() => {
          resolve();
        })
        .catch((e) => {
          console.log("error related", e);
          reject();
        });
    }
  });
};
export const createProduct = (data, selectedProducts, removedProducts) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = new FormData();
      const productImages = data["product_images"];
      delete data["product_images"];
      if (data["newCategory"]) {
        await createCategory({ name: data["category"], store: data["store"] });
      }
      var ingredientsAarray = [];
      var extras = [];
      var relatedProductsArray = [];
      Object.keys(data).forEach((key) => {
        if (key === "image") {
          if (data[key]) {
            body.append(key, data[key]);
          } else {
            return null;
          }
        } else {
          if (key === "extras") {
            extras = data[key];
          } else {
            if (key !== "ingredients") {
              //   if (key === 'tags') {
              //   tags = data[key];
              // } else {

              if (key === "related_products") {
                relatedProductsArray = data[key];
              } else body.append(key, data[key]);
              //}
            } else {
              ingredientsAarray = data[key];
            }
          }
        }
      });
      await api({
        method: "POST",
        headers: { "content-type": "multipart/form-data" },
        url: `products/api/products/?network=${NETWORK}`,
        data: body,
      }).then(async (response) => {
        const sizes = data["size"];
        if (data["size"]) {
        }
        Promise.all([
          sizes.map((size) => {
            handleSize(response.product_id, size);
          }),
          await handleImagesMap(response.product_id, productImages),
          await handleRelatedProducts(
            response.product_id,
            selectedProducts,
            removedProducts
          ),
        ]).then(async () => {
          if (NETWORK !== "6") {
            for (let index = 0; index < ingredientsAarray.length; index++) {
              const element = ingredientsAarray[index];
              var ingredientBody = {};
              Object.keys(element).forEach((key) => {
                if (key !== "id") {
                  ingredientBody[key] = element[key];
                }
              });

              await api({
                method: "POST",
                headers: {
                  "content-type": "application/json",
                },
                url: `products/api/products/${response.product_id}/add_ingredient_to_product/`,
                data: ingredientBody,
              }).catch((e) => {
                reject(e);
              });
            }
            for (let index = 0; index < relatedProductsArray.length; index++) {
              const element = relatedProductsArray[index];
              var relatedProductBody = {};
              Object.keys(element).forEach((key) => {
                if (key !== "id") {
                  relatedProductBody[key] = element[key];
                }
              });

              await api({
                method: "POST",
                headers: {
                  "content-type": "application/json",
                },
                url: `products/api/products/${response.product_id}/add_related_product_to_product/`,
                data: relatedProductBody,
              }).catch((e) => {
                reject(e);
              });
            }
            for (let index = 0; index < extras.length; index++) {
              const element = extras[index];
              await api({
                method: "POST",
                headers: { "content-type": "application/json" },
                url: `products/api/products/${response.product_id}/add_extra_to_product/`,
                data: element,
              }).catch((e) => {
                reject(e);
              });
            }
          }
          resolve();
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};
const handleImagesMap = async (id, images) => {
  return new Promise((resolve, reject) => {
    try {
      if (images.length === 0) {
        resolve();
      }
      images.map(async (image, sort_key) => {
        var imageToSend = image;
        if (Object.keys(image).includes("image")) {
          imageToSend = image.image;
        }
        await addImages(id, imageToSend, sort_key + 1);
        if (sort_key === images.length - 1) {
          resolve();
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};
const addImages = async (id, image, sort_key) => {
  return new Promise(async (resolve, reject) => {
    const body = new FormData();
    body.append("sort_key", sort_key);
    body.append("image", image);
    body.append("product", id);
    try {
      await api({
        method: "POST",
        headers: { "content-type": "multipart/form-data" },
        url: `/products/api/products/${id}/add_image_to_product/`,
        data: body,
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};
const handleSize = async (id, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      data.product = id;
      data.price = data.price === "" ? 0 : parseFloat(data.price);
      delete data.portion;
      if (!data.id && !data.deleted) {
        await api({
          method: "POST",
          url: `/products/api/variations/`,
          data: data,
        });
      }
      if (data.changed && !data.deleted && data.id) {
        await api({
          method: "PATCH",
          url: `/products/api/variations/${data.id}/`,
          data: data,
        });
      }
      if (data.deleted && data.id) {
        await api({
          method: "DELETE",
          url: `/products/api/variations/${data.id}/`,
          data: data,
        });
      }

      resolve();
    } catch (error) {
      reject();
    }
  });
};
const updateSizes = (sizes, product_id) => {
  return new Promise((resolve, reject) => {
    if (sizes) {
      sizes.map((size) => {
        handleSize(product_id, size);
      });
      resolve();
    } else {
      resolve();
    }
  });
};

const getAssetId = (image) => {
  var components = image.split("/");
  components = components[components.length - 1];
  return components.split(".")[0];
};
const deleteImage = async (id, image) => {
  var assetId = getAssetId(image);
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "DELETE",
        url: `/products/api/products/${id}/delete_image_from_product/`,
        data: {
          asset_id: assetId,
        },
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};
const patchImage = async (id, sort_key) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "PATCH",
        url: `/products/api/productimages/${id}/`,
        data: { sort_key: sort_key },
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};
const lastValue = (array) => {
  var value = 0;
  array.map((el) => {
    if (el > 0) {
      value = el;
    }
  });
  return value;
};
const addImagesMap = (images, product_id) => {
  return new Promise(async (resolve, reject) => {
    const sort_keys = [];
    images.map((image, idx) => {
      if (!image.image) {
        sort_keys.push(lastValue(sort_keys) + 1);
      } else {
        if (!image.deleted) {
          sort_keys.push(lastValue(sort_keys) + 1);
        } else {
          sort_keys.push(0);
        }
      }
    });
    if (images.length > 0) {
      images.map(async (image, idx) => {
        if (!image?.image && typeof image.image !== "string") {
          await addImages(product_id, image, sort_keys[idx]);
        } else {
          if (image["deleted"]) {
            await deleteImage(product_id, image.image["image"]);
          } else {
            await patchImage(image?.image["id"], sort_keys[idx]);
          }
        }

        if (idx === images.length - 1) {
          resolve();
        }
      });
    } else {
      resolve();
    }
  });
};
export const editProduct = async (data, product_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = new FormData();
      var oldExtras = [];
      var oldIngredients = [];
      var newExtras = [];
      var store;
      var newIngredients = [];
      const images = data["product_images"];
      Object.keys(data).forEach((key) => {
        if (key === "image") {
          if (data[key]) {
            body.append(key, data[key]);
          } else {
            return null;
          }
        } else {
          switch (key) {
            case "oldExtras":
              oldExtras = data[key];
              break;
            case "oldIngredients":
              oldIngredients = data[key];
              break;
            case "newExtras":
              newExtras = data[key];
              break;
            case "newIngredients":
              newIngredients = data[key];
              break;
            case "store":
              store = data[key];
              break;
            default:
              body.append(key, data[key]);
              break;
          }
        }
      });
      if (data["newCategory"]) {
        await createCategory({ name: data["category"], store: data["store"] });
      }
      await api({
        method: "PATCH",
        headers: { "content-type": "multipart/form-data" },
        url: `products/api/products/${product_id}/?store=${store}`,
        data: body,
      });
      Promise.all([
        await updateSizes(data["size"], product_id),
        await addImagesMap(images, product_id),
      ])
        .then(async () => {
          for (let index = 0; index < oldIngredients.length; index++) {
            const element = oldIngredients[index];
            await api({
              method: "PATCH",
              url: `/products/api/stocks/${element.stock}/`,
              data: {
                quantity: element.quantity,
                measure: element.measure,
                ingredient_id: element.ingredient,
              },
            }).catch((e) => {
              reject(e);
            });
          }
          for (let index = 0; index < newIngredients.length; index++) {
            const element = newIngredients[index];
            await api({
              method: "POST",
              url: `products/api/products/${product_id}/add_ingredient_to_product/`,
              data: element,
            }).catch((e) => {
              reject(e);
            });
          }
          for (let index = 0; index < oldExtras.length; index++) {
            const element = oldExtras[index];
            if (element.deleted) {
              await api({
                method: "DELETE",
                headers: {
                  "content-type": "application/json",
                },
                url: `/products/api/extras/${element.id}/`,
              }).catch((e) => {
                reject(e);
              });
            } else {

              await api({
                method: "PATCH",
                headers: {
                  "content-type": "application/json",
                },
                url: `/products/api/extras/${element.id}/`,
                data: element,
              }).catch((e) => {
                reject(e);
              });
            }
          }
          for (let index = 0; index < newExtras.length; index++) {
            const element = newExtras[index];

            await api({
              method: "POST",
              headers: { "content-type": "application/json" },
              url: `products/api/products/${product_id}/add_extra_to_product/`,
              data: element,
            }).catch((e) => {
              reject(e);
            });
          }
          resolve();
        })
        .catch((e) => {
          console.log("error caught 2");
          reject(e);
        });
    } catch (error) {
      console.log("error caught");
      reject(error);
    }
  });
};

export const deleteProduct = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "DELETE",
        url: `products/api/products/${id}/`,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const removeRelatedProduct = async (id, related_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "DELETE",
        headers: {
          "content-type": "application/json",
        },
        url: `/products/api/products/${related_id}/remove_related_product_from_product/`,
        data: {
          related_product_id: id,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const addRelatedProduct = async (id, related_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        url: `products/api/products/${related_id}/add_related_product_to_product/`,
        data: {
          product_id: id,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const handleOnline = async (id, checked) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "PATCH",
        url: `/products/api/products/${id}/`,
        data: { active: checked },
      });
      resolve();
    } catch (error) {
      reject();
    }
  });
};

export const getQrCode = async (id, store) => {
  return new Promise(async (resolve, reject) => {
    try {
      var res = await api({
        url: `/products/api/products/${id}/generate_qr_to_product/?network_id=${NETWORK}&store_id=${store}`,
        method: "POST",
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const duplicateProduct = (product, store) => {
  return new Promise(async (resolve, reject) => {
    try {
      var res = await api({
        url: `/products/api/products/${product}/duplicate_product/`,
        method: "POST",
        data: {
          store,
        },
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getTags = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";
      let url = `/products/api/tags/${query_url}`;
      let response;
      let results = [];
      do {
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      resolve(response.results);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const getGiftCards = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";
      let url = `/orders/api/giftcard/${query_url}`;
      let response;
      let results = [];
      do {
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      resolve(response.results);
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

export const createTag = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/products/api/tags/`,
        data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editTag = async (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "PATCH",
        url: `/products/api/tags/${id}/`,
        data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteTag = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "DELETE",
        url: `/products/api/tags/${id}/`,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const togglegiftCard = async (active, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "PATCH",
        url: `/orders/api/giftcard/${id}/`,
        data: { active: active },
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editGiftCard = async (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "PATCH",
        url: `/orders/api/giftcard/${id}/`,
        data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteGroup = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "DELETE",
        url: `/products/api/menus/${id}/`,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const createGroup = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/products/api/menus/`,
        data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editGroup = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/products/api/menus/${id}/`,
        data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const togglegroup = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `products/api/menus/${id}/handle_activation/`,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
