import { NETWORK } from "../../../../../settings";
import { Typography } from "@mui/material";

export default function ReturnTabHeaders({ activeTab, setActiveTab }) {
  var tabs = [];

  switch (NETWORK) {
    case "6":
      tabs = ["Information", "Images", "Sizes"];
      break;
    default:
      tabs = [
        "Information",
        "Media",
        "Extras",
        "Variation",
        "Related Products",
      ];
      break;
  }

  const scrollToTab = (index) => {
    const tabElement = document.getElementById(`tab-${index}`);
    if (tabElement) {
      tabElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className="w-full flex items-center gap-2">
      {tabs.map((tab, idx) => (
        <div
          id={`tab-${idx}`}
          className={`px-5 py-1 rounded-full ease-linear transition-colors min-w-fit ${
            idx === activeTab ? "text-white bg-black border-black" : "text-[#3F3F3F]"
          } border-gray-light border-2 cursor-pointer`}
          onClick={() => {
            setActiveTab(idx);
            scrollToTab(idx);
          }}
          key={tab}
        >
          <Typography>{tab}</Typography>
        </div>
      ))}
    </div>
  );
}
