import { NETWORK } from "../../../../../settings";

import { IconButton, Grid, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import Dropzone from "../../../../../components/dropzone";

export default function SecondTab({
  productImages,
  setProductImages,
  generateURL,
  imageSource,
  setImageSource,
  video,
  setVideo,
  productSheet,
  setProductSheet,
  removeImage,
}) {
  const videoDoesNotExist = () => {
    if (video !== "video/upload/null" && video !== "video/upload/undefined") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div className="flex flex-col gap-2 mb-14">
        <div className="mb-4">
          <Typography>Product main image</Typography>
          <div className="w-full flex items-center gap-2">
            {imageSource ? (
              <div className="flex items-center justify-center h-[8rem]">
                <img
                  src={generateURL(imageSource)}
                  className="h-full w-[12rem] object-cover"
                  alt=""
                />
              </div>
            ) : null}
            <Dropzone
              accept={{
                "image/jpeg": [],
                "image/png": [],
              }}
              files={imageSource}
              placeholder={"Click or drag and drop an image here"}
              setFiles={(files) => setImageSource(files[0])}
            />
          </div>
        </div>
        <div className="mb-4">
          <Typography>Product video</Typography>
          <div className="w-full flex items-center gap-2">
            {video && videoDoesNotExist() ? (
              <div className="flex items-center justify-center h-[8rem]">
                <video
                  className="h-full w-[12rem] object-cover"
                  alt=""
                  controls
                >
                  <source src={generateURL(video)} />
                </video>
              </div>
            ) : null}
            <Dropzone
              accept={{
                "video/*": [],
              }}
              files={videoDoesNotExist() ? video : null}
              placeholder={"Drag and drop a video here or click"}
              setFiles={(files) => setVideo(files[0])}
              filesLimit={1}
            />
          </div>
        </div>
        {NETWORK === "6" && (
          <div className="mb-4">
            <Dropzone
              accept={{
                "image/*": [],
                "application/pdf": [],
              }}
              placeholder={"Click or drag and drop the product sheet here"}
              setFiles={(files) => setProductSheet(files[0])}
              files={productSheet}
              filesLimit={1}
            />
            {productSheet ? (
              productSheet instanceof File ? (
                <a href={generateURL(productSheet)} target="_blank">
                  {productSheet.name}
                </a>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>{productSheet}</div>
                  <IconButton
                    style={{ height: "30px", width: "30px" }}
                    onClick={() => setProductSheet(undefined)}
                  >
                    <Close />
                  </IconButton>
                </div>
              )
            ) : (
              ""
            )}
          </div>
        )}
        <div>
          <Typography>More images</Typography>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ marginBottom: "10px", paddingTop: "3.5px" }}
          >
            <Dropzone
              accept={{
                "image/*": [],
              }}
              filesLimit={20}
              files={productImages}
              placeholder={"Click or drag and drop the product images here"}
              setFiles={(files) => {
                setProductImages(files);
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
                flexWrap: "wrap",
              }}
            >
              {productImages && (
                <>
                  {productImages.map((image, idx) => (
                    <>
                      {!image.deleted ? (
                        <div
                          key={idx}
                          style={{
                            height: "100px",
                            width: "100px",
                            marginRight: "10px",
                          }}
                        >
                          <div className="w-full flex justify-end">
                            <IconButton
                              style={{
                                border: "1px solid #DDDDDD",
                                marginBottom: "5px",
                                height: "25px",
                                width: "25px",
                              }}
                              onClick={() => removeImage(idx)}
                            >
                              <Close
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  fill: "#DDDDDD",
                                }}
                              />
                            </IconButton>
                          </div>
                          <img
                            src={generateURL(image)}
                            alt=""
                            style={{
                              height: "100px",
                              width: "100px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      ) : null}
                    </>
                  ))}
                </>
              )}
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
}
