import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Typography,
} from "@mui/material";
import ProductRow from "./productRow";
export default function ProductsTable({
  products,
  categories,
  ingredients,
  store,
  refresh,
  artists,
  storeSlug,
  storeCurrency,
  getCategories,
  loading,
  setLoading,
  storeUrl
}) {
  return (
    <TableContainer
      style={{ overflowX: "initial", maxWidth: "100% !important" }}
    >
      <Table
        disableColumnResize={true}
        stickyHeader
        className="table-auto min-w-[95vh] w-full pb-24"
      >
        <TableHead className="text-left border-gray border-b-2 sticky z-[50]">
          <TableCell className="table-cell-title font-normal pl-[16px] pb-[10px]">
            Name
          </TableCell>
          <TableCell className="table-cell-title font-normal pb-[10px]">
            Online
          </TableCell>
          <TableCell className="table-cell-title font-normal pb-[10px]">
            Related Products
          </TableCell>
          <TableCell className="table-cell-title font-normal pb-[10px]">
            Stock
          </TableCell>
          <TableCell className="table-cell-title font-normal !pr-0 !min-w-[7rem]">
            Price ({storeCurrency})
          </TableCell>
          <TableCell className="table-cell-title font-normal pb-[10px] !min-w-[8rem]">
            Sold today
          </TableCell>
          <TableCell className="table-cell-title font-normal pb-[10px]">
            Reserved
          </TableCell>
          <TableCell className="table-cell-title"></TableCell>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <ProductRow
              products={products}
              key={product.product_id}
              product={product}
              categories={categories}
              ingredients={ingredients}
              store={store}
              refresh={() => refresh()}
              artists={artists}
              storeSlug={storeSlug}
              storeCurrency={storeCurrency}
              getCategories={() => getCategories()}
              loading={loading}
              setLoading={(e) => setLoading(e)}
              storeUrl={storeUrl}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
