import React from "react";
import {
  IconButton,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { useDispatch } from "react-redux";
import { createClient } from "./../../services/clients";
import { NETWORK } from "./../../settings";
import { notify } from "./../../redux/features/alert";

export default function NewClientModal(props) {
  const { onClose, open, refresh } = props;
  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");

  const dispatch = useDispatch();

  const handleClose = (newId) => {
    if (newId) {
      refresh({ id: newId, label: firstName + " " + lastName });
    }
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    onClose();
  };

  const checkForm = () => {
    let entered = false;
    if (firstName === "") {
      setFirstNameError("Please Enter first name");
      entered = true;
    }
    if (lastName === "") {
      setLastNameError("Please Enter last name");
      entered = true;
    }

    return entered;
  };

  const submitCreate = async () => {
    if (!checkForm()) {
      createClient({
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phone,
        network: NETWORK,
      })
        .then((newId) => {
          dispatch(
            notify({
              message: "Client successfully created",
              severity: "success",
            })
          );
          handleClose(newId);
        })
        .catch((e) => {
          dispatch(notify({ message: e, severity: "error" }));
        });
    }
  };

  return (
    <Modal
      className="flex flex-row h-full items-center justify-center"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[70%] md:w-[50%]  h-auto  bg-white py-5 overflow-hidden relative">
        <div className="w-full  h-auto overflow-y-auto px-10">
          <div className="my-3 w-full flex items-center justify-between">
            <Typography variant="p">Create Client</Typography>
            <IconButton
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
              onClick={() => handleClose(0)}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <Container className="mb-20 mt-5 !px-0 !mx-0">
            <FormControl
              className="fix-label"
              style={{
                marginTop: "0px",
              }}
              fullWidth={true}
              margin={"normal"}
              error={firstNameError !== ""}
              required
            >
              <InputLabel htmlFor="name-input">First Name</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstNameError("");
                }}
              />
              <FormHelperText>{firstNameError}</FormHelperText>
            </FormControl>
            <FormControl
              className="fix-label"
              fullWidth={true}
              error={lastNameError !== ""}
              required
              margin={"normal"}
            >
              <InputLabel htmlFor="name-input">Last Name</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setLastNameError("");
                }}
              />
              <FormHelperText>{lastNameError}</FormHelperText>
            </FormControl>
            <FormControl
              className="fix-label"
              fullWidth={true}
              margin={"normal"}
              error={emailError !== ""}
            >
              <InputLabel htmlFor="name-input">Email</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError("");
                }}
              />
              <FormHelperText>{emailError}</FormHelperText>
            </FormControl>
            <FormControl
              className="fix-label"
              fullWidth={true}
              margin={"normal"}
              error={phoneError !== ""}
            >
              <InputLabel htmlFor="name-input">Phone Number</InputLabel>
              <Input
                id="name-input"
                type="text"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setPhoneError("");
                }}
              />
              <FormHelperText>{phoneError}</FormHelperText>
            </FormControl>
          </Container>
        </div>
        <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-45px] px-8 py-[.9rem] z-10">
          <button
            onClick={submitCreate}
            className="text-white rounded-full border border-[#F2F2F2] flex justify-center items-center h-[28px] p-4"
          >
            <Typography style={{ marginTop: "0", color: "#F2F2F2" }}>
              Create
            </Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
