import Select, { components } from "react-select";
import { useEffect } from "react";
import DropdownIcon from "../../assets/images/dropdown-icon.svg";

export default function StoreFilter({
  stores,
  activeStoreFilter,
  setActiveStoreFilter,
}) {
  useEffect(() => {
    var separator = document.getElementsByClassName(
      "select__indicator-separator"
    );
    if (separator[1]) {
      separator[1].style["display"] = "none";
    }
    if (separator[0]) {
      separator[0].style["display"] = "none";
    }
  }, []);
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "black",
      color: "white",
      borderRadius: "1000px",
      border: "none",
      fontSize: "16px",
      ":focus": {
        borderColor: "black",
        border: "none",
        fontSize: "16px",
        paddingLeft: "0.67rem",
      },
    }),
     
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
        color: "black",
        fontSize: "16px",
      };
    },
    input: (styles) => ({ ...styles, color: "white", fontSize: "16px"}),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "16px",
      color: "white",
      paddingLeft: "0.67rem",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: "16px",
      color: "white",
      paddingLeft: "0.67rem",
    }),
  };
  const DropdownIconIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          alt=""
          style={{ width: "12px", marginRight: "0.5rem" }}
          src={DropdownIcon}
        />
      </components.DropdownIndicator>
    );
  };

  const { DropdownIndicator } = components;
  const shouldDisable = () => {
    if (stores.length > 1) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="select-wrapper">
      <Select
        className="z-[80]"
        classNamePrefix="select"
        value={activeStoreFilter}
        displayEmpty={true}
        isDisabled={shouldDisable()}
        placeholder="Select a store"
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={false}
        name="color"
        
        components={{
          DropdownIndicator:
            stores.length > 1 ? DropdownIconIndicator : () => null
        }}
        options={stores.map((store) => {
          return { value: store.id, label: store.name };
        })}
        onChange={(e) => {
          setActiveStoreFilter(e);
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: 'black'
          }
        })}
        styles={colourStyles}
      />
    </div>
  );
}
