import React, { useState } from "react";
import {
  InputLabel,
  Input,
  Container,
  Grid,
  FormControl,
  IconButton,
  FormHelperText,
  Typography,
  Modal,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { editWhatsApp } from "./../../services/stores";
import { notify } from "./../../redux/features/alert";
import { trigger } from "./../../redux/features/fetchStore";
import { Close } from "@mui/icons-material";
import { useEffect } from "react";
import StoreFilter from "../storeFilter";
export default function WhatsAppModal(props) {
  const { onClose, open, reset, attemptClose, stores } = props;
  const [whatsapp, setWhatsapp] = useState("");
  const [whatsappError, setWhatsappError] = useState("");
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [selectedStore, setSelectedStore] = useState({
    label: "",
    value: 0,
  });
  useEffect(() => {
    if (stores.length > 0 && open) {
      setSelectedStore({ label: stores[0].name, value: stores[0].id });
      setWhatsapp(stores[0].whatsapp);
    }
  }, [stores.length, open]);
  const dispatch = useDispatch();

  const handleClose = () => {
    setWhatsapp("");
    onClose();
  };
  const handleWhatsapp = (event) => {
    setWhatsapp(event.target.value);
    setWhatsappError("");
    setChanged(true);
  };
  const checkForm = () => {
    let entered = false;

    if (whatsapp === "") {
      entered = true;
      setWhatsappError("Please insert phone");
    }
    return entered;
  };
  const submitCreation = async () => {
    if (!checkForm()) {
      setLoading(true);
      editWhatsApp(whatsapp, selectedStore.value)
        .then((result) => {
          setLoading(false);
          reset();
          dispatch(
            notify({
              message: "WhatsApp number successfully edited",
              severity: "success",
            })
          );
          handleClose();
        })
        .catch((e) => {
          setLoading(false);
          notify({
            message: e,
            severity: "error",
          });
        });
    } else {
      dispatch(
        notify({
          message: "Please check the errors in the fields below",
          severity: "error",
        })
      );
    }
  };

  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center"
      open={open}
      onClose={() => {
        if (changed) attemptClose();
        else handleClose();
      }}
    >
      <div className="w-full sm:w-[50%] md:w-[30%]  h-full sm:h-3/4 bg-white overflow-hidden relative">
        <div
          id="modal-wrapper"
          className="w-full h-full overflow-y-auto px-10 pt-5 pb-10"
        >
          <div className="w-full flex flex-row items-center justify-between mt-10">
            <div className="w-[250px] relative z-[60]">
              <StoreFilter
                setActiveStoreFilter={(e) => setSelectedStore(e)}
                stores={stores}
                activeStoreFilter={selectedStore}
              />
            </div>
            <div className="">
              <IconButton
                onClick={() => {
                  if (changed) attemptClose();
                  else handleClose();
                }}
                style={{
                  backgroundColor: "#fff",
                  fill: "#fff",
                  border: "1px solid #DDDDDD",
                  borderRadius: "50%",
                  height: "30px",
                  width: "30px",
                }}
              >
                <Close style={{ fill: "#DDDDDD" }} />{" "}
              </IconButton>
            </div>
          </div>
          <Container
            className="mb-10 mt-6 !mx-0 !px-0 relative z-[50]"
            maxWidth="md"
          >
            <Grid item xs={12} spacing={5} sm={12}>
              <FormControl
                fullWidth
                className="fix-label"
                style={{ marginBottom: "20px" }}
                required={true}
                error={whatsappError !== ""}
              >
                <InputLabel htmlFor="name-input">WhatsApp Number</InputLabel>
                <Input
                  id="name-input"
                  type="string"
                  value={whatsapp}
                  onChange={handleWhatsapp}
                />
                <FormHelperText>{whatsappError}</FormHelperText>
              </FormControl>
            </Grid>
          </Container>
        </div>

        <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-60px] px-8 py-[.9rem] z-10">
          <button
            onClick={(e) => submitCreation()}
            disabled={loading}
            className="text-white rounded-full border border-[#F2F2F2] flex justify-center items-center h-[28px] p-4 disabled:opacity-50"
          >
            <Typography style={{ marginTop: "0", color: "#F2F2F2" }}>
              Submit
            </Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
