import * as React from 'react';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

export default function ProgressBar() {
  return (
    <Stack sx={{ width: '100%', backgroundColor: '#f5f5f5', position: 'fixed', top: 0, left:0 }}>
      <LinearProgress sx={{backgroundColor: '#7e5bef', borderRadius: '10px', '& span': {backgroundColor: '#f5f5f5'}}} />
    </Stack>
  );
}