import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Container,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { createTag } from "../../../services/products";
import { useDispatch } from "react-redux";
import { notify } from "../../../redux/features/alert";
import { useState } from "react";
export default function CreateTagModal({ open, handleClose, refresh, store }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(false);

  const checkForm = () => {
    if (!name) {
      setNameError(true);
      return true;
    }
    return false;
  };
  const submit = () => {
    if (!checkForm()) {
      setLoading(true);
      createTag({
        name,
        color: "#fff",
        background: "#fff",
        store,
      })
        .then(() => {
          setLoading(false);

          dispatch(
            notify({
              message: "Tag successfully created",
              severity: "success",
            })
          );

          setName("");
          refresh();
          handleClose();
        })
        .catch((e) => {
          setLoading(false);
          dispatch(notify({ message: e, severity: "error" }));
        });
    } else {
      dispatch(
        notify({ message: "Please check the errors below", severity: "error" })
      );
    }
  };
  return (
    <Modal
      className="flex flex-row h-full  items-center justify-center shadow-none"
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="w-full sm:w-[50%] md:w-[30%]  h-auto  bg-white p-5 overflow-hidden relative">
        <div className="w-full  h-auto   overflow-y-auto">
          <div className="w-full flex flex-row items-center justify-between ">
            <Typography variant="p">Add new tag</Typography>
            <IconButton
              onClick={() => handleClose()}
              style={{
                backgroundColor: "#fff",
                fill: "#fff",
                border: "1px solid #DDDDDD",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
              }}
            >
              <Close style={{ fill: "#DDDDDD" }} />{" "}
            </IconButton>
          </div>
          <Container className="mb-7 mt-5 !mx-0 !px-0" maxWidth="md">
            <FormControl
              className="fix-label"
              fullWidth
              style={{
                marginBottom: "40px",
                width: "100%",
                marginTop: "10px",
              }}
              required
              error={nameError !== "" && name === ""}
            >
              <InputLabel htmlFor="name-input">Name</InputLabel>
              <Input
                id="name-input"
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <FormHelperText>{nameError}</FormHelperText>
            </FormControl>
          </Container>
          {/*      <div className="w-0 sm:w-2/3" onClick={() => onClose()} />
           */}{" "}
        </div>
        <div className="flex justify-end absolute bg-[#612EF2] w-full h-full mt-[-45px] ml-[-1.25rem] px-8 py-[.9rem] z-10">
          <button
            disabled={loading}
            onClick={(e) => submit()}
            className="text-white rounded-full border border-[#F2F2F2] disabled:opacity-50 flex justify-center items-center h-[28px] p-4"
          >
            <Typography>Submit</Typography>
          </button>
        </div>
      </div>
    </Modal>
  );
}
