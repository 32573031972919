import React from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AddIcon from "../../../../../components/addIcon";

import { Delete } from "@mui/icons-material";

export default function SizesTab({ sizes, setSizes, sizesError }) {
  const handleChangeSize = (prop, newValue, idx) => {
    var _sizes = [...sizes];
    _sizes[idx][prop] = newValue;
    _sizes[idx]["changed"] = true;
    setSizes(_sizes);
  };

  const handleDeleteSize = (idx) => {
    var _sizes = [...sizes];
    _sizes[idx].deleted = true;
    setSizes(_sizes);
  };

  const handleAddSize = () => {
    var _sizes = [...sizes];
    _sizes.push({
      name: "",
      price: 0,
      stock: 0,
      cost: 0,
      sku: "",
      barcode: "",
    });
    setSizes(_sizes);
  };

  return (
    <>
      {sizes.map((size, idx) => (
        <>
          {!size.deleted && (
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "start",
                borderTop: "1px solid #eeeeee",
                padding: "10px",
                marginBottom: "15px",
                marginTop: "15px",
              }}
              key={idx}
            >
              <Grid className="!ml-[0px]" container spacing={2} xs={12} md={11}>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    className="fix-label"
                    error={sizesError && size.name === ""}
                    fullWidth={true}
                    margin={"normal"}
                  >
                    <InputLabel htmlFor="price-input">Name</InputLabel>
                    <Input
                      id="price-input"
                      type="string"
                      value={size.name}
                      onChange={(e) => {
                        handleChangeSize("name", e.target.value, idx);
                      }}
                    />
                    {sizesError && size.name === "" ? (
                      <FormHelperText>
                        Please fill all fields in sizes
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    className="fix-label"
                    error={sizesError && !size.price}
                    fullWidth={true}
                    margin={"normal"}
                  >
                    <InputLabel htmlFor="stock-input">Price</InputLabel>
                    <Input
                      id="stock-input"
                      type="number"
                      value={size.price}
                      onChange={(e) =>
                        handleChangeSize("price", e.target.value, idx)
                      }
                    />
                    {sizesError && !size.price ? (
                      <FormHelperText>
                        Please fill all fields in sizes
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    className="fix-label"
                    // error={sizesError && !size.price}
                    fullWidth={true}
                    margin={"normal"}
                  >
                    <InputLabel htmlFor="stock-input">Cost</InputLabel>
                    <Input
                      id="stock-input"
                      type="number"
                      value={size.cost}
                      onChange={(e) =>
                        handleChangeSize("cost", e.target.value, idx)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    className="fix-label"
                    // error={sizesError && !size.price}
                    fullWidth={true}
                    margin={"normal"}
                  >
                    <InputLabel htmlFor="stock-input">Stock</InputLabel>
                    <Input
                      id="stock-input"
                      type="number"
                      value={size.stock}
                      onChange={(e) =>
                        handleChangeSize("stock", e.target.value, idx)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    className="fix-label"
                    //error={sizesError && !size.price}
                    fullWidth={true}
                    margin={"normal"}
                  >
                    <InputLabel htmlFor="stock-input">SKU</InputLabel>
                    <Input
                      id="stock-input"
                      type="string"
                      value={size.sku}
                      onChange={(e) =>
                        handleChangeSize("sku", e.target.value, idx)
                      }
                    />
                    {/*sizesError && !size.price ? (                <FormHelperText>Please fill all fields in sizes</FormHelperText>
              ) : (
                ""
              )*/}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    className="fix-label"
                    //error={sizesError && !size.price}
                    fullWidth={true}
                    margin={"normal"}
                  >
                    <InputLabel htmlFor="stock-input">Bar code</InputLabel>
                    <Input
                      id="stock-input"
                      type="string"
                      value={size.barcode}
                      onChange={(e) =>
                        handleChangeSize("barcode", e.target.value, idx)
                      }
                    />
                    {/*sizesError && !size.price ? (
                <FormHelperText>Please fill all fields in sizes</FormHelperText>
              ) : (
                ""
              )*/}
                  </FormControl>
                </Grid>
                {/*<Grid item xs={12} sm={3}>
            <FormControl
              error={sizesError && size.stock === 0}
              fullWidth={true}
              margin={"normal"}
            >
              <InputLabel htmlFor="stock-input">Stock</InputLabel>
              <Input
                id="stock-input"
                type="number"
                value={size.stock}
                onChange={(e) => handleChangeSize("stock", e.target.value, idx)}
              />
              {sizesError && size.stock === 0 ? (
                <FormHelperText>Please fill all fields in sizes</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid> */}
              </Grid>
              <Grid
                className="flex items-center justify-center !mt-[5px]"
                item
                xs={12}
                sm={1}
              >
                <IconButton
                  onClick={() => handleDeleteSize(idx)}
                  style={{
                    backgroundColor: "#fff",
                    fill: "#fff",
                    border: "1px solid #DDDDDD",
                    borderRadius: "50%",
                    height: "25px",
                    width: "25px",
                  }}
                >
                  <Close style={{
                    fill: "#DDDDDD", height: "20px",
                    width: "20px"
                  }} />{" "}
                </IconButton>
              </Grid>
            </Grid>
          )}
        </>
      ))}
      <div className="px-10 pb-10">
        <AddIcon onClick={() => handleAddSize()} />
      </div>
    </>
  );
}
