import { useState } from "react";

export default function SwitchButton({ id, _checked, handleClick }) {
  const [checked, setChecked] = useState(_checked);
  const handleChange = () => {
    setChecked(!checked);
    handleClick(id, !checked);
  };

  return (
    <div>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        id={`switch-${id}`}
        style={{ display: "none" }}
      />
      <label
        htmlFor={`switch-${id}`}
        style={{
          display: "inline-block",
          width: "55px",
          height: "22px",
          borderRadius: "11px",
          background: !checked ? "#ccc" : "#20C680",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            display: "inline-block",
            width: "18px",
            height: "18px",
            borderRadius: "50%",
            background: "#fff",
            transform: !checked ? "translate(2px, 2px)" : "translate(34px, 2px)",
            transition: "transform 0.2s ease",
          }}
        ></span>
      </label>
    </div>
  );
}
