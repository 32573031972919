import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { Button, Typography } from "@material-tailwind/react";
import { cancelStatus } from "./../../services/orders";
import { useDispatch } from "react-redux";
import { notify } from "../../redux/features/alert";
export default function CanelOrderModal({ open, handleClose, order }) {
  const dispatch = useDispatch();
  const submit = () => {
    cancelStatus(order.order_id, { pin: 1234 })
      .then(() => {
        dispatch(
          notify({
            message: "Order successfully canceled",
            severity: "success",
          })
        );
        handleClose();
      })
      .catch((e) => {
        dispatch(notify({ message: e, severity: "error" }));
      });
  };
  return (
    <Dialog open={open} handler={handleClose} PaperProps={{ style: { borderRadius: '0', width: '18rem' } }}>
      <DialogTitle>
        <Typography className="font-medium pt-2 text-[#272727]">
          Are you sure you want to cancel the {order?.order_code} order?
        </Typography>
      </DialogTitle>
      <DialogActions style={{ display: "flex", justifyContent: "space-between", padding: '16px 24px 24px 24px' }}>
        <button
          className="bg-[#DDDDDD] rounded-[40px] w-20 py-1.5 px-4"
          onClick={handleClose}
        >
          <Typography style={{color: "#FFFFFF"}}>NO</Typography>
        </button>
        <button
          className="bg-[#612EF2] rounded-[40px] w-20 py-1.5 px-4"
          onClick={submit}
        >
          <Typography style={{color: "#FFFFFF"}}>YES</Typography>
        </button>
      </DialogActions>
    </Dialog>
  );
}
