import { useEffect, useState } from "react";
import { getBalconyOrders } from "../../services/orders";
import { NETWORK } from "../../settings";
import CompletedOrdersFilter from "./../../components/completedOrdersFilter";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { notify } from "./../../redux/features/alert";
import moment from "moment";
import Order from "./order";
import StoreFilter from "../../components/storeFilter";
import { getStores } from "../../services/stores";
import ProgressBar from "../../components/loader/progressBar";
import { useSelector } from "react-redux";
import KycError from "../../components/kycError";

export default function Completed() {
  const dispatch = useDispatch();
  const { auth } = useSelector(state => state.auth)
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [stores, setStores] = useState([]);
  const [initialDate, setInitialDate] = useState(
    moment(new Date(new Date().setDate(new Date().getDate() - 30))).format(
      "YYYY-MM-DD"
    )
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [activeStoreFilter, setActiveStoreFilter] = useState({
    value: 0,
    label: "",
  });
  const fetchStores = () => {
    getStores()
      .then((res) => {
        setActiveStoreFilter({ value: res[0].id, label: res[0].name });
        setStores(res);
      })
      .catch((e) => {
        console.log("err ", e);
      });
  };
  useEffect(() => {
    fetchStores();
  }, []);

  const getOrders = () => {
    setLoading(true);

    getBalconyOrders({
      network: NETWORK,
      starts: initialDate,
      ends: endDate,
      query,
      store: activeStoreFilter.value,
    })
      .then((res) => {
        setOrders(res);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        dispatch(notify({ message: e, severity: "error" }));
      });
  };

  const getCurrencyStore = () => {
    const store = stores.find(
      (_store) => _store.id === activeStoreFilter.value
    );
    return store?.currency;
  };

  useEffect(() => {
    getOrders();
  }, [activeStoreFilter.value]);
  return (
    <div className="mb-20">
      {auth.stripe_profiles.account_stripe_KYC_status ?
        <>
          <div className="w-[250px] mx-4 flex-wrap mt-[43px] md:mt-[43px]">
            <StoreFilter
              stores={stores}
              activeStoreFilter={activeStoreFilter}
              setActiveStoreFilter={(value) => setActiveStoreFilter(value)}
            />
          </div>
          <div>
            {loading ? (
              <div className="w-full flex items-center justify-center">
                <ProgressBar />
              </div>
            ) : (
              <div className="mt-[50px] h-full">
                <div className="text-[#3F3F3F] pb-[.2rem] sticky top-[0px] bg-white bb-solid border-b-2 border-b-grey">
                  <Typography className="text-[#3F3F3F] px-6 py-2">
                    Order ID
                  </Typography>
                </div>
                {orders.map((order) => (
                  <Order
                    order={order}
                    key={order.order_id}
                    currencyStore={getCurrencyStore()}
                  />
                ))}
              </div>
            )}
          </div>
          <CompletedOrdersFilter
            query={query}
            setQuery={(e) => setQuery(e)}
            filter={() => getOrders()}
            searchPlaceholder={"Search"}
            start={initialDate}
            setStart={(e) => setInitialDate(e)}
            end={endDate}
            setEnd={(e) => setEndDate(e)}
          />
        </> : <KycError profile={auth.stripe_profiles} />}

    </div>
  );
}
