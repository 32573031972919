import { fetch as api } from "./../utils/request";

export const handleActiveMenu = (menu_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response;
      response = await api({
        method: "POST",
        url: `products/api/menus/${menu_id}/handle_activation/`,
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const editOpenHours = (data, id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: `/orders/api/open_hours/`,
        data: data,
      }).then(async (response) => {
        await api({
          method: "POST",
          url: `/products/api/stores/${id}/store_open_hours/`,
          data: { open_hour_id: response.id },
        }).then((res) => {
          console.log("resolving open hours ", res);
          resolve(res);
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};
