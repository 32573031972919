import React, { useRef } from "react";

import ReactToPrint from "react-to-print";

export default function Print(props) {
  const { trigger, children, onBeforeGetContent } = props;
  const componentRef = useRef();
  const contentFunction = () => componentRef.current;
  const triggerFunction = () => trigger;

  return (
    <>
      <ReactToPrint
        trigger={triggerFunction}
        content={contentFunction}
        onBeforeGetContent={onBeforeGetContent}
        documentTitle={"Receipt"}
      />
      <div style={{ display: "none" }}>
        <div ref={componentRef}>{children}</div>
      </div>
    </>
  );
}
