import { fetch as api } from "./../utils/request";
import { mountQueryURL } from "../utils/query";

export const getCategories = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = `products/api/categories/`;
      let response;
      let results = [];
      let query_url;
      if (params) query_url = mountQueryURL(params);
      else query_url = "";
      do {
        url = url + query_url;
        response = await api({
          method: "GET",
          url: url,
        });
        url = response.next;
        results.push(...response.results);
      } while (response.next);
      response.results = results;
      resolve(response.results);
    } catch (error) {
      reject(error);
    }
  });
};

export const createCategory = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "POST",
        url: "products/api/categories/",
        data: data,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteCategory = (category_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      await api({
        method: "DELETE",
        url: `products/api/categories/${category_id}/`,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const getCategoriesPOS = async (network, activeStoreFilter) => {
  return new Promise(async (resolve, reject) => {
    if (network == 6) {
      await api({
        method: "GET",
        url: nextURL
          ? nextURL
          : `/products/api/menus/?search=petshop&network=${network}`,
      }).then(async (_response) => {
        const filteredData = _response.results.find(
          (el) => el.name === "Species"
        );
        var payload = [];
        var nextURL = "";
        const categorynames = filteredData.sections.map((el) => el.category);
        while (true) {
          const response = await api({
            method: "GET",
            url: nextURL
              ? nextURL
              : `/products/api/categories/?network=${network}&store=${activeStoreFilter}`,
          });
          payload.push(...response.results);
          if (!response.next) {
            break;
          } else {
            nextURL = response.next;
          }
        }
        resolve(payload.filter((el) => categorynames.includes(el.name)));
      });
    } else {
      var payload = [];
      var nextURL = "";
      while (true) {
        const response = await api({
          method: "GET",
          url: nextURL
            ? nextURL
            : `/products/api/categories/?network=${network}&store=${activeStoreFilter}`,
        });
        payload.push(...response.results);
        if (!response.next) {
          break;
        } else {
          nextURL = response.next;
        }
      }
      resolve(payload);
    }
  });
};
