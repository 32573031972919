import MenuRow from "./menuRow";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
export default function MenusTable({
  menus,
  store,
  refresh,
  storeSlug,
  categories,
  products,
}) {
  return (
    <>
      <TableContainer style={{ overflowX: 'initial', maxHeight: '100vh' }}>
        <Table stickyHeader className="table-auto min-w-[800px] w-full pb-24">
          <TableHead className="text-left border-gray border-b-2 sticky z-[50]">
            <TableCell className="table-cell-title font-normal pl-[16px] pb-[10px]">
              ID
            </TableCell>
            <TableCell className="table-cell-title font-normal pb-[10px]">
              Name
            </TableCell>
            <TableCell className="table-cell-title font-normal pb-[10px]">
              Products
            </TableCell>
            <TableCell className="table-cell-title remove-padding font-normal">
              Disable/Enable
            </TableCell>
            <TableCell className="table-cell-title"></TableCell>
          </TableHead>
          <TableBody>
            {menus.map((menu) => (
              <MenuRow
                menu={menu}
                key={menu.id}
                store={store}
                refresh={() => refresh()}
                storeSlug={storeSlug}
                categories={categories}
                products={products}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
