import MenuRow from "./categoryRow";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
export default function CategoriesTable({
  categories,
  store,
  refresh,
  storeSlug,
  ingredientsToFilterBy,
}) {
  return (
    <TableContainer style={{ overflowX: "initial", maxHeight: "100vh" }}>
      <Table stickyHeader className="table-auto min-w-[800px] w-full pb-24">
        <TableHead className="text-left border-gray border-b-2 sticky z-[50]">
          <TableCell className="table-cell-title font-normal pl-[16px] pb-[10px] w-[300px] text-[#272727]">
            ID
          </TableCell>
          <TableCell className="table-cell-title font-normal pb-[10px] text-[#272727]">
            Name
          </TableCell>
          <TableCell className="table-cell-title"></TableCell>
        </TableHead>
        <TableBody>
        {categories.map((category) => (
            <MenuRow
              category={category}
              key={category.id}
              store={store}
              refresh={() => refresh()}
              storeSlug={storeSlug}
              ingredientsToFilterBy={ingredientsToFilterBy}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
