import { Typography } from "@material-tailwind/react";
import StoreFilter from "../../components/storeFilter";
import Select from "../../components/selectInput";
import { Radio } from "@mui/material";
import AddressForm from "./addressForm";
import { useState } from "react";
import NewClientModal from "./newClientModal";
import SelectDatePicker from "./DatePicker";
import AddIcon from "../../components/addIcon";
import { TextField, FormHelperText, FormControl } from "@mui/material";

export default function ClientForm({
  stores,
  selectedStore,
  setSelectedStore,
  clients,
  selectedClient,
  handleChangeClient,
  handleChangeAdrres,
  address,
  payment,
  setPayment,
  date,
  setDate,
  typeOfOrder,
  setTypeOfOrder,
  status,
  setStatus,
  time,
  setTime,
  deliveryErrors,
  selectedCustomerError,
  dateError,
  deliveryOpen,
  setDeliveryOpen,
  billingOpen,
  setBillingOpen,
  copyBilling,
  setCopyBilling,
  billing,
  handleChangeBilling,
  billingErrors,
  getClients,
  phone,
  setPhone,
  phoneError,
  email,
  setEmail,
  emailError,
  ordersList,
  selectedOrder,
  setSelectedOrder,
  editOrderView,
  setEditOrderView,
}) {
  const [newClient, setNewClient] = useState(false);
  const isDelivery = typeOfOrder === "Delivery";
  const handleCopyBillingChange = (checked) => {
    setCopyBilling(checked);

    if (checked) {
      handleChangeAdrres("street", billing.street);
      handleChangeAdrres("street_number", billing.street_number);
      handleChangeAdrres("neighborhood", billing.neighborhood);
      handleChangeAdrres("city", billing.city);
      handleChangeAdrres("state", billing.state);
      handleChangeAdrres("country", billing.country);
      handleChangeAdrres("zipcode", billing.zipcode);
    } else {
      handleChangeAdrres("street", "");
      handleChangeAdrres("street_number", "");
      handleChangeAdrres("neighborhood", "");
      handleChangeAdrres("city", "");
      handleChangeAdrres("state", "");
      handleChangeAdrres("country", {
        code: "",
        label: "",
      });
      handleChangeAdrres("zipcode", "");
    }
  };
  return (
    <div className="flex-col justify-center pt-[43px]">
      <div className="w-[250px]">
        <StoreFilter
          stores={stores}
          setActiveStoreFilter={(e) => setSelectedStore(e)}
          activeStoreFilter={selectedStore}
        />{" "}
      </div>
      <div className=" flex flex-row items-start gap-1 justify-center">
        <Select
          placeholder={"Select client..."}
          value={selectedClient}
          disabled={selectedOrder?.id !== 0 ? true : false}
          onChange={(e) => {
            handleChangeClient(e);
          }}
          options={clients.map((el) => {
            return { label: `${el.name}${el.email ? ", " + el.email :``}`, id: el.id };
          })}
        />
        <div style={{ marginTop: "22px" }}>
          <AddIcon onClick={() => setNewClient(true)} />
        </div>
      </div>
      <div className=" flex flex-row items-start justify-start items-center">
        <button
          className="px-5 py-1 bg-[#612EF2] text-white rounded-full h-[38px] w-[40%] mt-4 mr-2 text-left"
          onClick={() => setEditOrderView()}
        >
          {" "}
          <Typography>Edit order</Typography>
        </button>
        {editOrderView ? (
          <Select
            placeholder={"Edit an order..."}
            value={selectedOrder}
            onChange={(e) => {
              setSelectedOrder(e);
            }}
            options={ordersList.map((el) => {
              return {
                label: `${el.order_code}${
                  el.onwer ? ", " + el.owner.name : ""
                }`,
                id: el.order_id,
              };
            })}
          />
        ) : null}
      </div>
      {selectedCustomerError && !selectedClient ? (
        <p className="text-[#d32f2f] mt-1 ml-1">
          Select a customer to continue
        </p>
      ) : null}
      <div className="flex items-center gap-4 w-full mt-4 flex-wrap">
        <div className="flex gap-1 items-center">
          <Radio
            value={typeOfOrder}
            checked={typeOfOrder === "Delivery"}
            onChange={() => setTypeOfOrder("Delivery")}
            sx={{
              color: "#C7C7C7",
              "&.Mui-checked": {
                color: "#272727",
              },
            }}
            id="delivery-radio"
            placeholder="Delivery"
          />
          <label for="delivery-radio">
            <Typography
              className={isDelivery ? "text-[#272727]" : "text-[#C7C7C7]"}
            >
              Delivery
            </Typography>
          </label>
        </div>
        <div className="flex gap-1 items-center">
          <Radio
            value={typeOfOrder}
            onChange={() => setTypeOfOrder("Collection")}
            checked={typeOfOrder === "Collection"}
            sx={{
              color: "#C7C7C7",
              "&.Mui-checked": {
                color: "#272727",
              },
            }}
            id="collection-radio"
            placeholder="Collect in store"
          />
          <label for="collection-radio">
            <Typography
              className={!isDelivery ? "text-[#272727]" : "text-[#C7C7C7]"}
            >
              Collect in store
            </Typography>
          </label>
        </div>
      </div>
      <SelectDatePicker
        date={date}
        setDate={setDate}
        dateError={dateError}
        time={time}
        setTime={setTime}
      />
      <div className="flex items-center gap-3 w-full mb-[35px]">
        <div className="w-[50%]">
          <Select
            value={payment}
            onChange={(e) => {
              if (e.id === "Card")
                setStatus({ label: "Not paid", id: "Not paid" });
              setPayment(e);
            }}
            label="Customer"
            options={[
              { label: "Stripe", id: "Card" },
              { label: "Invoice", id: "Invoice" },
            ]}
          />
        </div>
        <div className="w-[50%] flex items-center justify-end">
          <div className="h-full flex intems-center text-end">
            <Typography className="pr-2 pt-4 text-[#272727]">Status</Typography>
          </div>
          <div className="w-[80%]">
            <Select
              value={status}
              disabled={payment.id === "Card"}
              onChange={(e) => {
                setStatus(e);
              }}
              label="Status"
              options={[
                { label: "Paid", id: "Paid" },
                { label: "Not paid", id: "Not paid" },
              ]}
            />
          </div>
        </div>
      </div>

      <AddressForm
        title={"Billing Address"}
        handleChange={(prop, e) => handleChangeBilling(prop, e)}
        handleChangeClient={(prop, e) => handleChangeClient(prop, e)}
        address={billing}
        open={billingOpen}
        setOpen={setBillingOpen}
        typeOfOrder={typeOfOrder}
        errors={billingErrors}
        ignoreTypeOfOrder={true}
        handleCopyBillingChange={handleCopyBillingChange}
        copyBilling={copyBilling}
        inputId={"billing-form"}
      />
      <AddressForm
        title={"Delivery Address"}
        handleChange={(prop, e) => handleChangeAdrres(prop, e)}
        address={address}
        open={deliveryOpen}
        setOpen={setDeliveryOpen}
        typeOfOrder={typeOfOrder}
        errors={deliveryErrors}
        handleCopyBillingChange={handleCopyBillingChange}
        copyBilling={copyBilling}
        billingData={billing}
        inputId={"delivery-form"}
      />
      <div className="flex flex-row gap-3 mt-3">
        <div className="w-[45%]">
          <FormControl
            className={
              phoneError !== "" ? "form-control" : "form-control-incompleted"
            }
            required
            style={{ width: "100%" }}
            error={phoneError !== ""}
          >
            <TextField
              style={{ color: "#c7c7c7" }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
              variant="standard"
              label="Phone number"
            />
            {phoneError !== "" && (
              <FormHelperText style={{ marginLeft: "0px" }}>
                {phoneError}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="w-[45%]">
          <FormControl
            className={
              emailError !== "" ? "form-control" : "form-control-incompleted"
            }
            required
            style={{ width: "100%" }}
            error={emailError !== ""}
          >
            <TextField
              style={{ color: "#c7c7c7" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              variant="standard"
              label="Email"
            />
            {emailError !== "" && (
              <FormHelperText style={{ marginLeft: "0px" }}>
                {emailError}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </div>
      <NewClientModal
        onClose={() => setNewClient(false)}
        open={newClient}
        refresh={(newClient) => {
          getClients(newClient);
        }}
      />
    </div>
  );
}
